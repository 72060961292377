import { Button, Form, Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import * as teacher from "../../../../shared/routes/teacher"
import { useEffect, useState } from "react"
import { ClassResponse } from "shared/types/teacherTypes"

export interface Props {
    selectedAssignmentIds: string[]
    show: boolean
    onHide: () => void
    onSuccess: () => void
    activeClass: ClassResponse
}

const BulkCopyModal = ({ show, onHide, onSuccess, selectedAssignmentIds, activeClass } : Props) => {
    const [classes, setClasses] = useState<ClassResponse[]>(null)
    const [selectedClassesIds, setSelectedClassesIds] = useState<string[]>([])

    useEffect(() => {
        teacher.getClasses().then(res => {
            //filter out the current class so that user cannot select it
            const classesResponse = res.filter(c => c.id !== activeClass.id && c.archived !== "Yes")
            setClasses(classesResponse)
        })
    }, [activeClass?.id])

    const onBulkCopy = () => {
        teacher.bulkCopy({ assignmentIds: selectedAssignmentIds, classIds: selectedClassesIds })
        .then(() => {
            setSelectedClassesIds([])
            onSuccess()
            toast.success("Successfully copied selected assignments.")
        })
        .catch(() => toast.error("Something went wrong copying assignments."))
    }

    const hideCopyModal = () => {
        setSelectedClassesIds([])
        onHide()
    }

    return( 
        <Modal show={show} onHide={hideCopyModal}>
            <Modal.Header className="border-0" closeButton>
                <h3 className="w-100 text-center">Copy Selected Assignments</h3>
            </Modal.Header>
            <Modal.Body className="mx-5 text-center">
                <div className="mt-0">
                    <p className="fw-bold text-muted text-start">
                        {classes?.length === 0 ? `You only have one class.` : `Selected assignments will be copied to all the classes you select below.`}
                    </p>
                    {classes?.length !== 0 &&
                        <div className="d-flex justify-content-between w-100">
                            <div>
                                <Button
                                    variant="outline-theme"
                                    onClick={() => {
                                        setSelectedClassesIds([])
                                    }}
                                >Select None</Button>

                                <Button
                                    variant="outline-theme mx-3"
                                    onClick={() => {
                                        const classIds = classes.map(c => c.id)
                                        setSelectedClassesIds(classIds)
                                    }}
                                >Select All</Button>
                            </div>
                        </div>
                    }
                    <div className="mt-3">
                    {classes?.map(c => {
                        return <Form.Check
                                key={`select-${c.id}`}
                                className="text-start"
                                checked={selectedClassesIds.includes(c.id)}
                                id={`select-${c.id}`}
                                label={c.className}
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        const newClasses = selectedClassesIds.filter((value) => value !== c.id)
                                        setSelectedClassesIds(newClasses)
                                    } else {
                                        const newClasses = selectedClassesIds.concat([c.id])
                                        setSelectedClassesIds(newClasses)
                                    }
                                }}
                            />
                    })}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button
                    variant="primary"
                    disabled={selectedClassesIds.length === 0}
                    onClick={onBulkCopy}>
                    Copy
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default BulkCopyModal
