import React from "react"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import styles from "../submissionTeacher.module.scss"
import dashboardStyles from "components/Dashboards/General/general.module.scss"
import { MasteryTypes, Masteries } from "shared/types/moduleTypes"

interface Props {
    onChange: (val: string | null) => void
    id: number
    initialValue: MasteryTypes | null
    disabled?: boolean
}

export default function StandardsMasterySelection({ onChange, id, initialValue, disabled = false }: Props) {
    const [value, setValue] = React.useState(initialValue)

    return (
        <>
            {
            Masteries.map((item) => (
                <Col className="text-center" key={id + item}>
                    <Form.Check
                        value={item}
                        id={id + item}
                        checked={value === item}
                        disabled={disabled}
                        onChange={(e) => {
                            let newValue = null
                            if (e.currentTarget.value !== value) {
                                newValue = e.currentTarget.value
                            }
                            setValue(newValue)
                            onChange(newValue)
                        }}
                    />
                    <label
                        className={`${dashboardStyles.greyText} ${styles.standardsMasterLabel}`}
                        htmlFor={String(id) + item}
                    >
                        {item}
                    </label>
                </Col>
            ))}
        </>
    )
}
