import React from "react"
import * as d3 from "d3"
import styles from "./weeklyHeatMap.module.scss"
import ToolTip from "./ToolTip"


interface Props {
    weekInformation: {
        monday: number[]
        tuesday: number[]
        wednesday: number[]
        thursday: number[]
        friday: number[]
        saturday: number[]
        sunday: number[]
    }
    baseColor: string
    title: string
}

function Key({svgWidth, maxValue}) {
    const OFFSET = 1000
    const Box_Padding = 25
    const Y_BOX_START = 30
    const Y_LABELS = 15
    const KEY_WIDTH = svgWidth - OFFSET - Box_Padding*2
    return (
            <g>
                <text
                    x={OFFSET}
                    y={Y_LABELS}
                    textAnchor="middle"
                >
                    0
                </text>
                <text
                    x={OFFSET + ((KEY_WIDTH)/2)}
                    y={Y_LABELS}
                    textAnchor="middle"
                >
                    {maxValue/2}
                </text>
                <text
                    x={OFFSET + KEY_WIDTH}
                    y={Y_LABELS}
                    textAnchor="middle"
                >
                    {maxValue}
                </text>
                {
                    Array.from(Array(5)).map((_unused, index) => {
                        const RECT_WIDTH = KEY_WIDTH/5
                        const RECT_HEIGHT = 30
                        const STARTING_OPACITY = 0.09
                        const opacityScale = d3.scaleLinear()
                            .range([STARTING_OPACITY, 1])
                            .domain([0, 4])

                        return (
                            <React.Fragment>
                                <rect
                                    width={RECT_WIDTH}
                                    height={RECT_HEIGHT}
                                    x={OFFSET + RECT_WIDTH*index}
                                    y={Y_BOX_START}
                                    fill={"#3F4BBC"}
                                    opacity={opacityScale(index)}
                                />
                            </React.Fragment>
                        )
                    })
                }
            </g>
    )
}

export default function WeeklyHeatMap({ weekInformation, baseColor, title }: Props) {
    const MARGIN = {top: 80, right: 0, bottom: 0, left: 0}
    const WIDTH = 1500, HEIGHT = 600
    const dayLabelSpace = 30
    const timesLabelSpace = 80
    const adjustedWidth = WIDTH - MARGIN.left - MARGIN.right
    const adjustedHeight = HEIGHT - MARGIN.top - MARGIN.bottom

    const [dayKeys, x, y, slotHeight] = React.useMemo(() => {
        const dayKeys = Object.keys(weekInformation)

        //If we can get rid of these scaling functions then d3 can be removed as a dependency
        const x = d3.scaleBand()
            .range([ 0, adjustedWidth - timesLabelSpace ])
            .domain(dayKeys)
            .paddingInner(0.02)
            .paddingOuter(0)

        // Add y-axis
        const y = d3.scaleLinear()
            .range([ 0, adjustedHeight - dayLabelSpace ])
            .domain([ 0, 24 ])

        const slotHeight = d3.scaleBand()
            .range([ 0, adjustedHeight - dayLabelSpace ])
            .domain(Array.from(Array(24).keys()).map(String))
            .padding(0.1)
            .bandwidth()

        return [dayKeys, x, y, slotHeight]
    }, [weekInformation, adjustedWidth, adjustedHeight])

    //Find max data point
    const maxActivity = dayKeys.reduce((prev, curr) => {
        if (prev < weekInformation[curr]) {
            return weekInformation[curr]
        }
        return prev
    }, 0)

    //The max should be in increments of 10's
    const maxActivityTickMax = Math.floor((maxActivity + 10) / 10) * 10

    return (
        <svg
            viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
            className={styles.graph}
        >
            {/* Only show the rounded box and cut off any corders */}
            <clipPath id="roundedBox">
                <rect x={MARGIN.left} y={MARGIN.top} width={adjustedWidth} height={adjustedHeight} rx="20px" fill="white" />
            </clipPath>

            <text
                x={WIDTH/2}
                y={45}
                fontSize="30"
                textAnchor="middle"
                fontWeight={"bold"}
            >
                {title}
            </text>

            {/* Key Group */}
            <Key svgWidth={WIDTH} maxValue={maxActivityTickMax} />

            {/* Heat Map Group */}
            <g clipPath="url(#roundedBox)">
                <g transform={`translate(${MARGIN.left},${MARGIN.top})`}>
                    {/* Background rect */}
                    <rect
                        width={adjustedWidth}
                        height={adjustedHeight}
                        rx="20px"
                        fill="white"
                    />
                    {/* Line dividing actual times and heat map */}
                    <line
                        x1={timesLabelSpace}
                        y1={0}
                        x2={timesLabelSpace}
                        y2={HEIGHT}
                    />
                    {/* Create time labels */}
                    {Array.from(Array(24)).map((_unused, arrayIndex) => {
                        const twelveHourTime = (arrayIndex % 12) === 0 ? 12 : arrayIndex % 12
                        const militaryTime = arrayIndex
                        return (<React.Fragment key={"label-" + militaryTime}>
                            <line
                                x1={0}
                                y1={y(arrayIndex) + dayLabelSpace}
                                x2={timesLabelSpace}
                                y2={y(arrayIndex) + dayLabelSpace}
                            />
                            <text
                                x={timesLabelSpace/2}
                                y={y(arrayIndex) + slotHeight/2 + dayLabelSpace}
                                textAnchor="middle"
                                alignmentBaseline="middle"
                            >
                                {`${twelveHourTime} ${militaryTime < 12 ? "am" : "pm"}`}
                            </text>
                        </React.Fragment>)
                    })}

                    {dayKeys.map((day, dayIndex) => <React.Fragment key={day}>
                            {/* Create Day labels */}
                            <rect
                                x={x(day) + timesLabelSpace - (dayIndex === 0 ? 0 : x.step() * x.paddingInner() / 2)}
                                y={0}
                                width={x.step()}
                                height={dayLabelSpace}
                                fill="white"
                            />
                            <text
                                x={x(day) + timesLabelSpace + x.bandwidth()/2}
                                y={dayLabelSpace/2}
                                textAnchor="middle"
                                alignmentBaseline="middle"
                            >
                                {day[0].toUpperCase() + day.slice(1)}
                            </text>

                            {/* Create day/time cells */}
                            {weekInformation[day].map((activity, timeIndex) => <React.Fragment key={day + timeIndex}>
                                <ToolTip title={`${activity} active`}>
                                    <rect
                                        x={x(day) + timesLabelSpace}
                                        y={y(timeIndex) + dayLabelSpace}
                                        className={styles.slot}
                                        width={x.bandwidth()}
                                        height={slotHeight}
                                        fill={baseColor}
                                        opacity={(activity/maxActivityTickMax) + 0.09}
                                    />
                                </ToolTip>
                            </React.Fragment>)
                            }
                    </React.Fragment>)}
                </g>
            </g>
        </svg>
    )
}
