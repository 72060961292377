import * as React from "react"
import { toast } from "react-toastify"
import { LessonVariety, Type } from "shared/types/moduleTypes"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import AssignmentProgress from "./AssignmentProgress"
import styles from "./styles.module.scss"
import PureInquiryQuestionList from "components/Lessons/PureInquiry/PureInquiryQuestionList"
import LessonBody from "components/Lessons/LessonModule/LessonBody"
import lessonModel, { LessonResponse as Lesson, LessonResponse } from "../../shared/lessons"
import FullScreenWrapper from "./components/FullScreenWrapper"
import { AssignmentContext } from "./AssignmentContext"
import { LessonContext } from "components/Lessons/LessonContext"
import { AuthContext } from "AuthContext"
import { LessonSim } from "components/Lessons/LessonSim"
import { answerLengths, saveGuidedLesson, saveLessonInterval, savePureInquiryLesson } from "components/Lessons/LessonModule/helpers/lessonHelper"

interface LessonProps {
    submissionId?: string
    moduleName: string,
    type: Type,
    videoId: string,
    lessonVariety: LessonVariety,
    hasAssessment: boolean,
    preSubmit?: (submitFn: () => Promise<boolean>, lessonFormRef: any) => unknown,
    initialLessonData?: Record<string, string>
    initialPureInquiryData?: { questionAnswers: string[], tableAnswers: string[] }
}

export default function AssignmentLesson({ moduleName, type, submissionId, preSubmit, lessonVariety, videoId, hasAssessment, initialLessonData, initialPureInquiryData }: LessonProps) {
    const authContext = React.useContext(AuthContext)
    const assignmentContext = React.useContext(AssignmentContext)
    const { id: lessonId } = assignmentContext.guidedLesson ?? {}

    const [submitting, setSubmitting] = React.useState(false)
    const [ lesson, setLesson ] = React.useState<Lesson>()
    const [ refreshToken, setRefreshToken ] = React.useState(new Date())
    const [ questionAnswers, setQuestionAnswers ] = React.useState(new Array(answerLengths[type]).fill(""))
    const [ tableAnswers, setTableAnswers ] = React.useState(new Array(60).fill(""))
    const [ lessonFullScreen, setLessonFullScreen ] = React.useState(false)

    const saveLessonTimerRef = React.useRef(new Date())
    const lessonFormRef = React.useRef(null)
    const startedRef = React.useRef(new Date())
    const questionAnswersRef = React.useRef(questionAnswers)
    const tableAnswersRef = React.useRef(tableAnswers)
    const prevSavedRef = React.useRef(!!initialLessonData || (!!initialPureInquiryData && (initialPureInquiryData.questionAnswers.length > 0
        || initialPureInquiryData.tableAnswers.length > 0)))
    const lessonSubmissionIdRef = React.useRef(null) // this studentLesson id will be used to update existing studentLesson record in backend
    
    const isTeacher = authContext.isLoggedIn && !authContext.isStudent

    const onSubmit = async () => {
        setSubmitting(true)
        try {
            if(lessonVariety === "Pure Inquiry") {
                await savePureInquiryLesson({ moduleName, type, isTeacher, questionAnswers: questionAnswersRef.current, 
                    tableAnswers: tableAnswersRef.current, prevSavedRef, started: startedRef.current, completed: true, submissionId})
            } else {
                await saveGuidedLesson({ lesson, isTeacher, lessonFormRef, prevSavedRef, 
                    lessonSubmissionIdRef, started: startedRef.current, completed: true, submissionId })
            }
            setSubmitting(false)
            return true
        } catch (err) {
            setSubmitting(false)
            toast.error(err?.response?.data?.message ?? "There was an error submitting the lesson. Please try again.")
            return false
        }
    }

    const escFunction = React.useCallback((event) => {
        if (event.key === "Escape") {
          setLessonFullScreen(null)
        }
    }, [])

    React.useEffect(() => {
        const noSavedLesson = initialLessonData ? Object.keys(initialLessonData).length === 0 : null
        const noSavedPureInquiry = initialPureInquiryData ? (initialPureInquiryData.questionAnswers.length === 0 && initialPureInquiryData.tableAnswers.length === 0)  : null

        // When assignment is restarted, empty the saved refs so that submitLesson API call is triggered rather than updateLesson
        if (noSavedLesson && noSavedPureInquiry) {
            prevSavedRef.current = null
        }
    }, [initialLessonData, initialPureInquiryData])

    const debouncedSavePureInquiry = async () => {
        const now = new Date()
        if (now.getTime() - saveLessonTimerRef.current.getTime() < saveLessonInterval) {
            return
        }
        saveLessonTimerRef.current = now
        return savePureInquiryLesson({ moduleName, type, isTeacher, questionAnswers: questionAnswersRef.current, 
            tableAnswers: tableAnswersRef.current, prevSavedRef, started: startedRef.current, completed: false, submissionId })
    }

    const refreshLesson = async () => {
        if(!lessonId) return
        const res = await lessonModel.findById(lessonId)
        setLesson({id:lessonId,...res})
        setRefreshToken(new Date())
    }
    
    React.useEffect(() => {
        questionAnswersRef.current = questionAnswers
    }, [questionAnswers])

    React.useEffect(() => {
        tableAnswersRef.current = tableAnswers
    }, [tableAnswers])
    
    React.useEffect(() => {
        document.addEventListener("keydown", escFunction, false)
        refreshLesson()
        if (assignmentContext?.setOnReturn) {
            assignmentContext.setOnReturn(() => {
                return () => {
                    if(lessonVariety === "Pure Inquiry") {
                        savePureInquiryLesson({ moduleName, type, isTeacher, questionAnswers: questionAnswersRef.current, tableAnswers: tableAnswersRef.current, 
                            prevSavedRef, started: startedRef.current, completed: false, submissionId: submissionId })
                    } else {
                        // use guidedLesson from context instead of lesson (useState), which is null
                        saveGuidedLesson({ lesson: assignmentContext.guidedLesson as LessonResponse, isTeacher, lessonFormRef, prevSavedRef, 
                            lessonSubmissionIdRef, started: startedRef.current, completed: false, submissionId })
                    }
                }
            })
        }
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonId])


    return <>
        <Row className={`${styles.assignmentContents}`}>
            <Col md={6}>
                <LessonSim moduleName={moduleName} videoId={videoId} loggedIn={true} />
            </Col>
            <Col md={6} className="position-relative d-flex">
                <FullScreenWrapper fullScreen={lessonFullScreen} toggleFullScreen={() => setLessonFullScreen(prev => !prev)}>
                    {lessonVariety === "Guided" && lesson &&
                    <LessonContext.Provider value={{ lesson: lesson, submitLesson: onSubmit, refreshLesson: refreshLesson, refreshToken: refreshToken, questionAnswersRef,  tableAnswersRef,
                        lessonSubmissionIdRef, startedRef: startedRef.current, prevSavedRef: prevSavedRef, setQuestionAnswers, setTableAnswers, containerType: "assignment" }}>
                            <LessonBody
                                lessonFormRef={lessonFormRef}
                                initialLessonData={initialLessonData}
                                submissionId={submissionId}
                            />
                        </LessonContext.Provider>
                    }
                    {lessonVariety === "Pure Inquiry" &&
                        <div className={`${styles.pureInquiryBox}`}>
                            <PureInquiryQuestionList
                                module={moduleName}
                                trackingEnabled={true}
                                type={type as Exclude<Type, "Tier 0">}
                                questionAnswers={questionAnswers}
                                setQuestionAnswers={setQuestionAnswers}
                                tableAnswers={tableAnswers}
                                setTableAnswers={setTableAnswers}
                                onBlur={debouncedSavePureInquiry}
                            />
                        </div>
                    }
                    <div>
                        <i role="button" className={`fas ${lessonFullScreen ? "fa-window-minimize pb-5" : "fa-expand"} icon-btn expand-btn pe-3`}
                            onClick={() => setLessonFullScreen(prev => !prev)}
                        ></i>
                    </div>
                </FullScreenWrapper>
            </Col>
        </Row>
        <Row className={`${styles.assignmentProgress}`}>
            <Col sm={7} xl={9} className={`${styles.fitContent} m-auto`}>
                {   
                    hasAssessment ? <AssignmentProgress active="lesson" /> : ""
                }
            </Col>
            <Col sm={5} xl={3} className={`text-end ${styles.fitContent}`}>
                <Button
                    variant="outline-theme"
                    className="btn-lg mb-4 me-3"
                    disabled={submitting}
                    onClick={() => {
                        if (preSubmit) {
                            preSubmit(onSubmit, lessonFormRef)
                        } else {
                            onSubmit()
                        }
                    }}>
                        {isTeacher ? "View Assessment" : "Submit Lesson"}
                </Button>
            </Col>
        </Row>
    </>
}
