import { useParams } from "react-router"
import { Nav, Row, Col } from "react-bootstrap"
import  styles from "./ModulePage.module.scss"
import { useContext, useEffect, useState } from "react"
import moduleRoutes from "shared/routes/moduleRoutes"
import BackgroundTab from "./BackgroundTab"
import ChallengeTab from "./ChallengeTab"
import MethodsTab from "./MethodsTab"
import SimulationTab from "./SimulationTab"
import TeacherTab from "./TeacherTab"
import AssessmentTab from "./AssessmentTab"
import ToolTip from "components/General/ToolTip"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/free-solid-svg-icons"
import { sanitizePath } from "shared/helpers/moduleHelper"
import { AuthContext } from "AuthContext"
import RelevantHelpModal from "components/Modals/RelevantHelpModal"
import { helpSteps, helpLinkVideos } from "./moduleHelpData"

export type activeTab = "challenge" | "background" | "methods" | "simulation" | "assessment" | "teacher"

type offsetValues = {
    simulation: number
    assessment: number
}

export interface ModuleInfo {
    challenge: {
        challengeText?: string,
        challengeImage?: string,
        brochure?: string
    }
    background: {
        backgrounds: {title?: string, text?: string, table?: any, image?: any}[]
    }
    methods: {walkthroughVideoPath?: string, walkthroughUpdated?: string, lessonGroups?: any[]}
    teacher: {
        tier2Updated?: string
        tier1Updated?: string
    }
    assessment: {
        title: string
        type: string
        assessments: any[]
    }[]
    hasBackgroundAccess: boolean
    hasMethodsAccess: boolean
    hasAssessmentAccess: boolean
    hasTeacherAccess: boolean
    moduleAvailable: boolean
    modulePath: string
}

const ModulePage = () => {
    const editing = process.env.REACT_APP_EDITING === "true"
    const { moduleName, tab } = useParams() as { moduleName: string, tab: activeTab }
    const [activeTab, setActiveTab] = useState<activeTab>(null)
    const [moduleInfo, setModuleInfo] = useState<ModuleInfo>(null)
    const [offset, setOffset] = useState<offsetValues>(null)
    const [isSAQ, setIsSAQ] = useState<boolean>(false)

    const { isLoggedIn } = useContext(AuthContext)

    useEffect(() => {
        const getModuleInformation = (moduleName: string) => {
            moduleRoutes.moduleInformation(moduleName)
            .then(res => {
                setModuleInfo(res)
                setIsSAQ(moduleName.includes("SAQ") ? true : false)
                // only offset columns for simulation (medium screen) and assessment (small screen) if teacher tab isn't being displayed
                setOffset({simulation: res?.hasTeacherAccess ? 0 : 2, assessment: res?.hasTeacherAccess ? 0 : 3})
            })
        }

        const updateModuleHit = async (moduleName: string) => {
            await moduleRoutes.updateModuleHit(moduleName)
        }

        getModuleInformation(moduleName)

        if (isLoggedIn) {
            updateModuleHit(moduleName)
        }
    }, [moduleName, isLoggedIn])

    //Automatically redirect to challenge page if users manually go to the restricted tab by typing into URL
    useEffect(() => {
        if (!moduleInfo?.moduleAvailable) {
            setActiveTab("challenge")
        } else if (tab === "background" && !moduleInfo?.hasBackgroundAccess) {
            setActiveTab("challenge")
        } else if (tab === "methods" && !moduleInfo?.hasMethodsAccess) {
            setActiveTab("challenge")
        } else if (tab === "simulation" && isSAQ) {
            setActiveTab("challenge")
        } else if (tab === "assessment" && !moduleInfo?.hasAssessmentAccess) {
            setActiveTab("challenge")
        } else if (tab === "teacher" && !moduleInfo?.hasTeacherAccess) {
            setActiveTab("challenge")
        } else {
            setActiveTab(tab)
        }
    }, [tab, isSAQ, moduleInfo?.hasAssessmentAccess, moduleInfo?.hasBackgroundAccess, 
        moduleInfo?.hasMethodsAccess, moduleInfo?.hasTeacherAccess, moduleInfo?.moduleAvailable])

  return (
    <div>
        <RelevantHelpModal videos={helpLinkVideos} helpSteps={helpSteps}/>
        {editing && <a href={`/simulations/${sanitizePath(moduleName)}/#challenge`} className="mt-4 d-block fw-bold fs-4">Old module page for editing</a>}
        <div className="m-auto mt-4 d-flex flex-column flex-md-row justifiy-content-center align-items-center">
            <h1 className={`fw-bold m-auto mb-2 ${!moduleInfo?.moduleAvailable && `text-muted`}`}>
                {!moduleInfo?.moduleAvailable && <FontAwesomeIcon icon={faLock} size="xs" className="me-2" />}
                <span>{moduleName}</span>
            </h1>
            {!moduleInfo?.moduleAvailable && 
                <Link to="/subscribe" className={`text-decoration-none ${styles.subscribeBtn}`}>
                    <h5 className={`bg-primary px-3 py-1 rounded fw-bold m-0`}>
                        <span className="text-white">Subscribe</span>
                    </h5>
                </Link>}
        </div>
        <hr className="w-75 m-auto mt-4" />
        <Nav variant="pills" defaultActiveKey={activeTab} className={`mt-4 justify-content-center ${styles.container}`}>
            <Row className="g-2">
                {!moduleInfo?.hasTeacherAccess && <Col xs={0} lg={1}></Col>}
                {/* {Challenge} */}
                <Col xs={12} sm={6} md={4} lg={2}>
                    <Nav.Item 
                        role="button"
                        onClick={() => setActiveTab("challenge")}
                        className={`challenge-tab rounded px-5 w-100 d-flex justify-content-center ${activeTab === "challenge" ? `bg-primary ${styles.activeTab}` : `bg-white ${styles.tab}`}`}>
                        <Nav.Link eventKey="challenge" active={activeTab === "challenge"}>
                            <span className={`${activeTab === "challenge" && `text-white fw-bold`}`}>Challenge</span>
                        </Nav.Link>
                    </Nav.Item>
                </Col>
                {/* {Background} */}
                <Col xs={12} sm={6} md={4} lg={2}>
                    {moduleInfo?.hasBackgroundAccess && moduleInfo?.moduleAvailable && moduleInfo?.background?.backgrounds.length > 0 ?
                        <Nav.Item
                            onClick={() => setActiveTab("background")}
                            role="button" 
                            className={`background-tab rounded px-5 w-100 d-flex justify-content-center ${activeTab === "background" ? `bg-primary ${styles.activeTab}` : `bg-white ${styles.tab}`}`}>
                            <Nav.Link eventKey="background" active={activeTab === "background"}>
                                <span className={`${activeTab === "background" && `text-white fw-bold`}`}>Background</span>
                            </Nav.Link>
                        </Nav.Item> : 
                        <ToolTip title="Not Available">
                            <Nav.Item className={`${styles.bgDesaturated} ${styles.tab} px-5 rounded w-100 d-flex justify-content-center`}>
                                <Nav.Link
                                    disabled={true}
                                    eventKey="background">
                                        Background
                                </Nav.Link>
                            </Nav.Item>
                        </ToolTip>}
                </Col>
                {/* {Methods} */}
                <Col xs={12} sm={6} md={4} lg={2}>
                    {moduleInfo?.hasMethodsAccess && moduleInfo?.moduleAvailable ? 
                        <Nav.Item
                            role="button"
                            onClick={() => setActiveTab("methods")}
                            className={`methods-tab rounded px-5 w-100 d-flex justify-content-center ${activeTab === "methods" ? `bg-primary ${styles.activeTab}` : `bg-white ${styles.tab}`}`}>
                            <Nav.Link eventKey="methods" active={activeTab === "methods"}>
                                <span className={`${activeTab === "methods" && `text-white fw-bold`}`}>Methods</span>
                            </Nav.Link>
                        </Nav.Item> :
                        <ToolTip title="Not Available">
                            <Nav.Item className={`${styles.bgDesaturated} ${styles.tab} px-5 rounded w-100 d-flex justify-content-center`}>
                                <Nav.Link
                                    disabled={true}
                                    eventKey="methods">
                                        Methods
                                </Nav.Link>
                            </Nav.Item>
                        </ToolTip>}
                </Col>
                {/* {Simulation} */}
                <Col xs={12} sm={6} md={{ span: 4, offset: offset?.simulation }} lg={{ span: 2, offset: 0 }}>
                    {moduleInfo?.moduleAvailable && !isSAQ ?
                        <Nav.Item
                            role="button"
                            onClick={() => setActiveTab("simulation")}
                            className={`rounded px-5 w-100 d-flex justify-content-center ${activeTab === "simulation" ? `bg-primary ${styles.activeTab}` : `bg-white ${styles.tab}`}`}>
                            <Nav.Link eventKey="simulation" active={activeTab === "simulation"}>
                                <span className={`${activeTab === "simulation" && `text-white fw-bold`}`}>Simulation</span>
                            </Nav.Link>
                        </Nav.Item> :
                        <ToolTip title="Not Available">
                            <Nav.Item className={`${styles.bgDesaturated} ${styles.tab} px-5 rounded w-100 d-flex justify-content-center`}>
                                <Nav.Link
                                    disabled={true}
                                    eventKey="simulation">
                                    Simulation
                                </Nav.Link>
                            </Nav.Item>
                        </ToolTip>}
                </Col>
                {/* {Assessment} */}
                <Col xs={12} sm={{ span: 6, offset: offset?.assessment }} md={{ span: 4, offset: 0 }} lg={2}>
                    {moduleInfo?.hasAssessmentAccess && moduleInfo?.moduleAvailable ? 
                    <Nav.Item
                        role="button"
                        onClick={() => setActiveTab("assessment")}
                        className={`assessment-tab rounded w-100 px-5 d-flex justify-content-center ${activeTab === "assessment" ? `bg-primary ${styles.activeTab}` : `bg-white ${styles.tab}`}`}>
                        <Nav.Link eventKey="assessment" active={activeTab === "assessment"}>
                            <span className={`${activeTab === "assessment" && `text-white fw-bold`}`}>Assessment</span>
                        </Nav.Link>
                    </Nav.Item> : 
                    <ToolTip title="Not Available">
                        <Nav.Item className={`${styles.bgDesaturated} ${styles.tab} px-5 rounded w-100 d-flex justify-content-center`}>
                            <Nav.Link
                                disabled={true}
                                eventKey="assessment">
                                    Assessment
                            </Nav.Link>
                        </Nav.Item>
                    </ToolTip>}
                </Col>
                {/* {Teacher} */}
                {moduleInfo?.hasTeacherAccess && moduleInfo?.moduleAvailable && 
                <Col xs={12} sm={6} md={4} lg={2}>
                    <Nav.Item
                        role="button"
                        onClick={() => setActiveTab("teacher")}
                        className={`teacher-tab rounded px-5 d-flex justify-content-center w-100 ${activeTab === "teacher" ? `bg-primary ${styles.activeTab}` : `bg-white ${styles.tab}`}`}>
                        <Nav.Link eventKey="teacher" active={activeTab === "teacher"}>
                            <span className={`${activeTab === "teacher" && `text-white fw-bold`}`}>Teacher</span>
                        </Nav.Link>
                    </Nav.Item>
                </Col>}
            </Row>
        </Nav>
        <div>
            {activeTab === "challenge" &&
                <ChallengeTab 
                    moduleName={moduleName}
                    modulePath={moduleInfo?.modulePath}
                    text={moduleInfo?.challenge?.challengeText}
                    image={moduleInfo?.challenge?.challengeImage}
                    brochure={moduleInfo?.challenge?.brochure} />}

            {activeTab === "background" && moduleInfo?.moduleAvailable && moduleInfo?.hasBackgroundAccess &&
                <BackgroundTab 
                    modulePath={moduleInfo?.modulePath}
                    backgrounds={moduleInfo?.background?.backgrounds} />}
            
            {activeTab === "methods" && moduleInfo?.moduleAvailable && moduleInfo?.hasMethodsAccess &&
                <MethodsTab
                    walkthroughVideoPath={moduleInfo?.methods?.walkthroughVideoPath}
                    walkthroughUpdated={moduleInfo?.methods?.walkthroughUpdated}
                    lessonGroups={moduleInfo?.methods?.lessonGroups}
                    sanitizedModuleName={sanitizePath(moduleName)} />}

            {activeTab === "simulation" && moduleInfo?.moduleAvailable && !isSAQ &&
                <SimulationTab
                    moduleName={moduleName} />}

            {activeTab === "assessment" && moduleInfo?.moduleAvailable && moduleInfo?.hasAssessmentAccess &&
                <AssessmentTab 
                    assessmentGroups={moduleInfo?.assessment}
                    moduleName={moduleName} />}

            {activeTab === "teacher" && moduleInfo?.moduleAvailable && moduleInfo?.hasTeacherAccess &&
                <TeacherTab 
                    modulePath={moduleInfo?.modulePath}
                    lessonGroups={moduleInfo?.methods?.lessonGroups}
                    tier1Updated={moduleInfo?.teacher?.tier1Updated}
                    tier2Updated={moduleInfo?.teacher?.tier2Updated} />}
        </div>
    </div>
  )
}

export default ModulePage
