function checkRouteState(currentState, checkAgainst): currentState is typeof checkAgainst {
    const currentUrl = window.location.href
    if(!currentState) { // check current location's state
        currentState = JSON.parse(localStorage.getItem(currentUrl))
        if(!currentState) return false
    }

    let checkAgainstObj = new checkAgainst();
    let routeClassProps = Object.getOwnPropertyNames(checkAgainstObj);
    for(let i = 0; i < routeClassProps.length; i++) {
        const classProp = routeClassProps[i]
        // check if state has necessary class property
        if(currentState[classProp] === undefined) {
            const classPropertyRequired = checkAgainstObj[classProp] === undefined // if undefined, means route property is expecting to be defined
            if(classPropertyRequired) return false // route state field doesn't exist so don't allow user to navigate
        }
    }
    localStorage.setItem(currentUrl, JSON.stringify(currentState))
    return currentState
}

export default checkRouteState
