import { toast } from "react-toastify"
import { FormikProvider, useFormik, Form as FormikForm, } from "formik";
import * as Yup from "yup"
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Container from "react-bootstrap/Container"
import "./Signup.scss"
import { FormikControl } from "@stem-sims/nexus"
import { Row, Col, Card } from "react-bootstrap";
import { googleSSOInfo, oauth, signup } from "shared/routes/auth";
import { useLocation, useNavigate } from "react-router";
import { useContext, useEffect, useState } from "react";
import getURLParams from "helpers/getURLParams";
import { AuthContext } from "AuthContext";
import FloatingPage from "components/General/FloatingPage";
import { gotoCheckout } from "components/Subscribe/StripeSubcribe";
import { Link } from "react-router-dom";

interface oAuthProps {
  "sso-id": string
  email: string
  name: string
}

export type SubscribeProps = {
  studentCount?: number
  planName?: string
}

const SignupPage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const state = location.state as SubscribeProps
  const oAuthValues = getURLParams() as unknown as oAuthProps

  const [accountExists, setAccountExists] = useState<boolean>(false)

  const [googleSSOId, setGoogleSSOId] = useState<string>("")
  const { triggerAuthRefresh } = useContext(AuthContext)

    type SignupForm = {
        name: string,
        username: string,
        email: string,
        password: string,
        confirmPassword: string
    }
    const initialValues: SignupForm = {
        name: oAuthValues?.name ?? "",
        username: oAuthValues?.email ?? "",
        email: oAuthValues?.email ?? "",
        password: "",
        confirmPassword: ""
    }
    const formik = useFormik({
        initialValues: initialValues,
        validateOnBlur: false,
        validationSchema: Yup.object().shape({
            name: !oAuthValues["sso-id"] && Yup.string()
                .required("Preferred Name is required")
                .max(150),
            username: Yup.string()
                .required("Username is required")
                .max(150),
            email: !oAuthValues["sso-id"] && Yup.string()
                .required("Email is required.")
                .min(4, "Please enter a valid email address."),
            password: !oAuthValues["sso-id"] && Yup.string()
                .required("Password is required")
                .min(5, "Password must be 5 characters long")
                .oneOf([Yup.ref('confirmPassword'), null], 'The passwords entered do not match.')
                .max(150),
            confirmPassword: !oAuthValues["sso-id"] && Yup.string()
                .max(150)
        }),
        onSubmit: (values, { setSubmitting }) => {
          const signupProps = {
            name: oAuthValues["sso-id"] ? oAuthValues.name : values.name,
            username: values.username,
            email: oAuthValues["sso-id"] ? oAuthValues.email : values.email,
            password: values.password,
            confirmPassword: values.confirmPassword,
            ssoId: oAuthValues["sso-id"]
          }
          
          signup(signupProps)
          .then((res) => {
            triggerAuthRefresh()
            toast.success(res.data ?? "Signed up successfully!")

            if (state?.studentCount && state?.planName) {
              gotoCheckout(state.planName, state.studentCount)
            } else {
              navigate("/subscribe")
            }
          }).catch((err) => {
            // if username or email is already taken, show the message
            if(err.response.status === 409) {
              setAccountExists(true)
            }
            toast.error(err.response.data ?? "Could not signup due to unknown error, please contact us if error continues")
          }).finally(() => setSubmitting(false))
        }
    })

    const signUpGoogle = async () => {
      const data = await oauth({ providerId: googleSSOId })
      window.location = data.authorizeUrl
    }

    useEffect( () => {
      (async () => {
        const googleId = await googleSSOInfo()
        setGoogleSSOId(googleId.providerId)
      })()
    }, [])

    return (<FloatingPage className="signup-container">
      <Container fluid id="signupPage" className={`d-flex align-items-center justify-content-center p-0 ${oAuthValues["sso-id"] && `vh-100`}`}>
        <div className="d-md-flex justify-content-around flex-row-reverse">
          <div className="d-flex align-items-center justify-content-center">
            <div>
              <Card className="mb-4 p-3">
                  <div className="fw-bold">
                    Already have an account? <Link to="/account/log-in" state={{ planName: state?.planName, studentCount: state?.studentCount }}>Log&nbsp;In</Link>
                  </div>
              </Card>
              <h1 className="h2 fw-bold mb-4">Create Your Account</h1>
              {oAuthValues["sso-id"] && <p>Please enter the remaining information to create your account.</p>}
              {!oAuthValues["sso-id"] && <Row className="d-flex flex-column flex-md-row align-items-center">
                <Col>
                  <button 
                    className="google-button btn rounded-pill mb-2 py-0"
                    onClick={signUpGoogle}>
                      <Image src="/images/google-logo.svg"></Image>
                      Continue with Google
                  </button>
                </Col>
                <Col md={1} className="p-0 text-center mb-2 d-flex flex-row flex-md-column align-items-center">
                  <div className="faded-vertical-line"></div>
                  <span className="bg-white px-2 text-muted">or</span>
                  <div className="faded-vertical-line"></div>
                </Col>
                <Col className="w-75 text-end enter-details-label">
                  <p className="w-75">Enter your details to get started with STEM Sims.</p>
                </Col>
              </Row>}
              <FormikProvider value={formik}>
                <Form as={FormikForm} className="text-center">
                    {!oAuthValues["sso-id"] && <Form.Group className="d-inline-block w-100 text-start mb-4">
                      <Form.Label>Preferred Name*</Form.Label>
                      <FormikControl
                        placeholder="Tammy Simms"
                        name="name"
                        className="rounded-pill"
                      />
                    </Form.Group>}
                    <Form.Group className="d-inline-block w-100 text-start mb-4">
                      <Form.Label>Username*</Form.Label>
                      <FormikControl
                        placeholder="Username"
                        name="username"
                        className="rounded-pill"
                      />
                    </Form.Group>
                    {!oAuthValues["sso-id"] && <Form.Group className="d-inline-block w-100 text-start mb-4">
                      <Form.Label>Email Address*</Form.Label>
                      <FormikControl
                        type="email"
                        placeholder="tamtacular314@gmail.com"
                        name="email"
                        className="rounded-pill"
                      />
                    </Form.Group>}
                    {!oAuthValues["sso-id"] && <div className="d-flex flex-column flex-md-row">
                      <Form.Group className="d-inline-block w-100 text-start pb-2 me-4 mb-4">
                        <Form.Label>Password*</Form.Label>
                        <FormikControl
                          type="password"
                          name="password"
                          placeholder="Password"
                          className="rounded-pill"
                        />
                      </Form.Group>
                      <Form.Group className="d-inline-block w-100 text-start pb-2">
                        <Form.Label>Confirm Password*</Form.Label>
                        <FormikControl
                          type="password"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          className="rounded-pill"
                        />
                      </Form.Group>
                    </div>}
                    {accountExists && 
                      <div className="h5 fw-bold">
                        There is already an account with this username or email. 
                        <Link to="/account/recover">Recover&nbsp;your&nbsp;account</Link>.
                      </div>}
                    <Button type="submit" variant="primary" className="w-75 rounded-pill mt-4 fw-bold create-account-btn" disabled={formik.isSubmitting}>Create My Account</Button>
                    <div className="mt-3"></div>
                    <p className="text-center smaller-font text-muted mt-2">
                      By creating your account, you agree to this site's{" "}
                      <a href="/legal/terms-of-use/">Terms of Use</a>
                    </p>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </Container>
  </FloatingPage>)
}

export default SignupPage
