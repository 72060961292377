import FloatingPage from "components/General/FloatingPage"
import { useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import { Container} from "react-bootstrap"
import { useContext, useEffect, useState } from "react"
import styles from "./Quiz.module.scss"
import { toast } from "react-toastify"
import ClassQuizForm from "./ClassQuizForm"
import StudentClassQuizForm from "./StudentClassQuizForm"
import StudentQuizForm from "./StudentQuizForm"
import ProgressBar from "react-bootstrap/ProgressBar"
import * as teacher from "../../shared/routes/teacher"
import { useNavigate } from "react-router"
import { AuthContext } from "AuthContext"
import * as authModel from "../../models/auth"

type QuizActivePart = "createClass" | "createClassAccount" | "studentQuiz" | "noQuizAccess"
const OnboardingQuiz = () => {
    const navigate = useNavigate()
    const auth = useContext(AuthContext)
    const [quizActivePart, setQuizActivePart] = useState<QuizActivePart>(null)
    const [quizProgress, setQuizProgress] = useState(50)
    const [createdClassId, setCreatedClassId] = useState<string>(null)

    const classFormInitialValues = {
        className: "",
        term: "Spring",
        academicYear: new Date().getFullYear(),
        gradeLevel: "",
        standardId: "",
        requireCompletion: "No",
    }

    const studentClassFormInitialValues = {
        username: "",
        password: "",
        confirmPassword: ""
    }

    useEffect(() => {
        authModel.information().then((authRes) => {
            if (authRes.isLoggedIn) {
                setQuizActivePart(authRes.isStudent || !authRes.trackingEnabled ? "studentQuiz" : "createClass")
            } else {
                setQuizActivePart("noQuizAccess")
            }
        })
    }, [])

    const onSubmit = (values) => {
        if (quizActivePart === "createClass") {
            return teacher.createClass(values)
                .then((res) => {
                    setCreatedClassId(res.id)
                    toast.success("Your class is successfully created.")
                    setQuizProgress(100)

                    // if student class already exists, skip student class creation page
                    teacher.checkIfStudentClassExists()
                        .then((studentClassExists) => {
                            if (studentClassExists) {
                                navigate("/dashboard/teacher/overview", { state: {id: res.id} })
                            } else {
                                setQuizActivePart("createClassAccount")
                            }
                        })
                })
                .catch(() => {
                    toast.error("There has been an error creating your class. Please try again.")
                })
        } else {
            return teacher.createStudentClassAccount(values)
                .then((res) => {
                    toast.success(res)
                    navigate("/dashboard/teacher/overview", { state: {id: createdClassId} })
                })
                .catch((err) => {
                    toast.error(err.response.data)
                })
        }
    }

    const classFormik = useFormik({
        initialValues: classFormInitialValues,
        onSubmit,
        enableReinitialize: true,
        validationSchema: Yup
            .object()
            .shape({
                className: Yup
                    .string()
                    .max(40)
                    .label("Class Name")
                    .required("Class name is required."),
                term: Yup
                    .string()
                    .label("Academic Semester")
                    .required("Academic semester is required."),
                academicYear: Yup
                    .number()
                    .label("Academic Year")
                    .max(9999)
                    .min(1900)
                    .required("Academic Year is required."),
                gradeLevel: Yup.string().label("Grade Level").required(),
                standardId: Yup.string().label("Standard").required(),
                requireCompletion: Yup
                    .string()
                    .label("Require Lesson/Assessment Completion")
                    .required("Require completion option is required."),
            })
    })

    const studentClassAccountFormik = useFormik({
        initialValues: studentClassFormInitialValues,
        onSubmit,
        enableReinitialize: true,
        validationSchema: Yup
            .object()
            .shape({
                username: Yup
                    .string()
                    .max(150)
                    .label("Username")
                    .required("Username is required."),
                password: Yup
                    .string()
                    .label("Password")
                    .required("Password is required.")
                    .min(5, "Password must be 5 characters long")
                    .max(150)
                    .oneOf([Yup.ref('confirmPassword'), null], 'The passwords entered do not match.'),
                confirmPassword: Yup
                    .string()
                    .max(150)
                    .label("Confirm Password")
                    .required("Confrim Password is required."),
            })
    })

  return (
    <FloatingPage className={styles.background}>
        {!auth.isLoggedIn && <h1>This quiz can be taken only once when you sign up.</h1>}
        {quizActivePart !== "studentQuiz" && quizActivePart !== "noQuizAccess" && <ProgressBar now={quizProgress} className={`${styles.progressBar} w-50 text-center m-auto mb-4`} />}
        <h2 className="mb-3 fw-bolder">
            { quizActivePart === "createClass" || quizActivePart === "studentQuiz" ?  "Let's get started." : (quizActivePart === "noQuizAccess" ? "" : "Almost Done...") }
        </h2>
        <Container className="d-flex align-items-center justify-content-center">
            <FormikProvider value={quizActivePart === "createClass" ? classFormik : studentClassAccountFormik}>
                {quizActivePart === "createClass" && 
                    <ClassQuizForm formik={classFormik} />}
                    
                {quizActivePart === "createClassAccount" &&
                    <StudentClassQuizForm formik={studentClassAccountFormik} />}
            </FormikProvider>
            {quizActivePart === "studentQuiz" && <StudentQuizForm />}
        </Container>
    </FloatingPage>
  )
}

export default OnboardingQuiz
