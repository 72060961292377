import Accordion from "react-bootstrap/Accordion"


type FaqItem = {
    question: string,
    answer: string
}

const faqs: FaqItem[] = [
    {
        "question": "What are the minimum system requirements?",
        "answer": "To ensure that you have the best experience possible with STEM Sims, we recommend the following browser specifications. Please note that some aspects of STEM Sims may not work if your browser does not meet these minimum requirements: Chrome 40, Firefox 35, Edge 12 (requires Windows 10 or later), Internet Explorer 11 (requires Windows 7 or later), or Safari 8 (requires OS X Yosemite or later)"
    },
    {
        "question": "How do I change my username?",
        "answer": "If you would like to change the username for your account, please contact us at support@stemsims.com. Include your current username and your desired new username."
    },
    {
        "question": "How do I change my password?",
        "answer": "To change your password, visit the account password page.  Enter your current password and new password in the appropriate fields, then click \"Change Password\". Please note that anyone else currently logged in to that account will be logged out when you change the password."
    },
    {
        "question": "How do I recover my password?",
        "answer": "If you've forgotten your password, visit the account recovery page. Enter the username and/or email address associated with your STEM Sims account, and click \"Search\". An email will be sent to you that includes a link to reset your password. If you do not receive an email within 15 minutes, check your email's spam folder."
    },
    {
        "question": "How do I activate a subscription?",
        "answer": "After purchasing a subscription, you will receive an activation email from us that will allow you to activate your subscription."
    },
    {
        "question": "How do teacher/student accounts work?",
        "answer": "The teacher account is the administrative account, and includes access to teacher material, such as teacher guides. Teacher guides for each module can be accessed by clicking the \"Teacher\" button in the module and then clicking \"Teacher's Guide.\" The teacher account also controls the passwords for both the teacher account and student account. The student account is a generic account meant to be shared among all the students in a classroom.  Each student can log in with the same credentials, and independently access different aspects of STEM Sims.  Student accounts do not have access to teacher material, and cannot change the student account information or password. When you activate a subscription that includes a teacher account, you will be directed to create a student account. For security reasons, we highly recommend using separate passwords for the teacher and student account."
    },
    {
        "question": "How can I see multiple sections of a module at the same time?",
        "answer": "If you would like to view multiple sections of a module at the same time, you can simply open a section in a new tab or window. For example, if you would like to view the background and simulation at the same time, you can navigate to the background page, then right click on the \"Simulation\" button and choose to open it in a new window. If you would like to view the lessons for a module and the simulation for the module at the same time, you can also view them in two separate tabs or windows.  Navigate to the \"Methods\" section of the module, then right-click on the lesson you wish to open and click \"Open link in new window\".  Then, in the first window, click on the \"Simulation\" section."
    },
    {
        "question": "I'm having trouble viewing the simulation walkthrough videos. How can I fix this?",
        "answer": "We host our walkthrough videos on YouTube, but also provide a copy of the video on our site as a fallback in case your school blocks YouTube. If for some reason this fallback isn't working, and you aren't able to see the walkthrough videos at all, please contact us at support@stemsims.com and we will be more than happy to find a way to enable the walkthrough videos on your computers."
    },
    {
        "question": "How do I get started with a teacher subscription?",
        "answer": "Simply sign up with a Class tier account, or upgrade your subscription on the account page."
    }
]

export default function HelpFaqs() {
    return (
        <Accordion className="mt-3" defaultActiveKey="0">
            {
                faqs.map((faq, index) => {
                    return (
                        <Accordion.Item className="bg-light" eventKey={index.toString()}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    )
                })
            }
        </Accordion>
    )
}
