import { BeatLoader } from "react-spinners"
import { useModel } from "@stem-sims/nexus"
import modules from "shared/routes/moduleRoutes"
import StandardsMasteryGraph from "./StandardsMasteryGraph"
import StandardsMasteryTable from "./StandardsMasteryTable"

interface Props {
    startDate: Date
    endDate: Date
}

export default function StandardsProgression(props : Props) {
    const { response: progress, loading } = useModel({
        model: modules.getOrganizationStandardsMastery,
        props
    })
    if (loading) {
        return <BeatLoader />
    }

    return (<>
        <StandardsMasteryGraph bars={progress.data.standards} />
        <StandardsMasteryTable data={progress.data.classes} title="Class Name" />
    </>)
}
