import FloatingPage from "components/General/FloatingPage"
import { toast } from "react-toastify"
import { FormikProvider, useFormik, Form as FormikForm, } from "formik";
import * as Yup from "yup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import styles from "./Account.module.scss"
import { FormikControl } from "@stem-sims/nexus"
import { recoverTeacherAccount } from "shared/routes/auth"

const AccountRecover = () => {
    const initialValues = {
        email: ""
    }
    const formik = useFormik({
        initialValues: initialValues,
        validateOnBlur: false,
        validationSchema: Yup.object().shape({
            email: Yup.string().email()
                .required("Email is required.")
        }),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)
            recoverTeacherAccount({ email: values.email })
                .then(res => {
                    setSubmitting(false)
                    toast.success(res)
                })
                .catch(err => {
                    setSubmitting(false)
                    toast.error(err?.response?.data ?? "There is something wrong.")
                })
        }
    })

  return (
    <FloatingPage className={`w-75 ${styles.background}`}>
        <FormikProvider value={formik}>
            <h1 className="mb-4 fw-bold">Recover Your Account</h1>
            <p className="fw-light text-start w-75 m-auto my-4">Please enter email address to locate your account. Once located, an email will be sent with instructions to reset your password.</p>
            <Form as={FormikForm} className="text-center">
                <Form.Group className="d-inline-block w-75 text-start mb-2">
                    <Form.Label className="required">Email</Form.Label>
                    <FormikControl
                    placeholder="Email"
                    name="email"
                    className="rounded-pill"
                    />
                </Form.Group>
                <Button type="submit" variant="primary" className={`d-block m-auto rounded-pill mt-3 ${styles.sendEmailBtn}`} disabled={formik.isSubmitting}>Send Email</Button>
            </Form>
        </FormikProvider>
    </FloatingPage>
  )
}

export default AccountRecover
