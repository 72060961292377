import styles from "./styles.module.scss"
import CustomHTML from '../../../components/CustomHTML'
import { Instruction } from "shared/lessons"

interface Props {
    sectionNumber: number,
    instruction: Instruction,
    instructionIndex: string,
    setCheckpoint: any
}

const LessonInstruction = ({sectionNumber,instruction,instructionIndex,setCheckpoint}: Props) => {
    return(<div className="my-3">
        <div style={{fontWeight: 'bold', marginBottom: '1rem'}} className={styles.listTitle}>
            { instruction.title }
        </div>
        <div className={`d-flex flex-nowrap align-top`}>
            <div>
                <input onClick={() => setCheckpoint(instruction.index)} type="checkbox" name={`question-${sectionNumber}-${instruction.index}`} className={`${styles.bgCheckbox} form-check-input mt-0 me-2`}/> 
                <span className="ms-1 pe-1">
                    {instructionIndex}.
                </span>
                <CustomHTML html={instruction.content} />
            </div>
        </div>
        <div className="p-1">
            {instruction.imageUpdated && <img
                className={`${styles.lessonImage} img-fluid center-block`}
                src={instruction.imagePath}
                alt={ instruction.imageAltText }
            />}
        </div>
    </div>)
}
export default LessonInstruction
