import FloatingPage from "components/General/FloatingPage"
import { toast } from "react-toastify"
import { FormikProvider, useFormik, Form as FormikForm, } from "formik";
import * as Yup from "yup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import styles from "./Account.module.scss"
import { FormikControl } from "@stem-sims/nexus"
import { resetTeacherPassword } from "shared/routes/auth"
import getURLParams from "helpers/getURLParams"
import { useNavigate } from "react-router";
import { useContext, useEffect } from "react";
import { AuthContext } from "AuthContext";

const ResetPassword = () => {
    const { code, initial } = getURLParams() as unknown as {code?: string, initial?: string}
    const navigate = useNavigate()
    const { triggerAuthRefresh } = useContext(AuthContext)

    useEffect(() => {
        if (!code) {
            toast.error("Reset code is invalid")
        }
    }, [code])

    const initialValues = {
        password: "",
        confirmPassword: ""
    }
    const formik = useFormik({
        initialValues: initialValues,
        validateOnBlur: false,
        validationSchema: Yup.object().shape({
        }),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)
            resetTeacherPassword({password: values.password, confirmPassword: values.confirmPassword, code: code})
                .then(res => {
                    setSubmitting(false)
                    toast.success(res)
                    navigate("/")
                    triggerAuthRefresh()
                })
                .catch(err => {
                    setSubmitting(false)
                    toast.error(err?.response?.data ?? "There is something wrong.")
                })
        }
    })

  return (
    <FloatingPage className={`w-75 ${styles.background}`}>
        <FormikProvider value={formik}>
            <h1 className="mb-4 fw-bold">{initial ? "Set" : "Reset"} Your Password</h1>
            <p className="text-start w-75 m-auto my-4">Please enter a new password for your account.</p>
            {code && <Form as={FormikForm} className="text-center">
                <Form.Group className="d-inline-block w-75 text-start mb-2">
                    <Form.Label className="required">Password</Form.Label>
                    <FormikControl
                    placeholder="Password"
                    name="password"
                    type="password"
                    className="rounded-pill"
                    />
                </Form.Group>
                <Form.Group className="d-inline-block w-75 text-start mb-2">
                    <Form.Label className="required">Confirm Password</Form.Label>
                    <FormikControl
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    className="rounded-pill"
                    />
                </Form.Group>
                <Button type="submit" variant="primary" className={`d-block m-auto rounded-pill mt-3 ${styles.sendEmailBtn}`} disabled={formik.isSubmitting}>{initial ? "Set" : "Reset"} Password</Button>
            </Form>}
            </FormikProvider>
    </FloatingPage>
  )
}

export default ResetPassword
