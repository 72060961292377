import { Form } from "react-bootstrap"
import { StudentResponse } from "shared/types/teacherTypes"

interface Props {
    classStudents: StudentResponse[]
    selectedStudentIds: string[]
    onSelectStudent: any
    onUnselectStudent: any
}

const SelectStudents = ({ classStudents, selectedStudentIds, onSelectStudent, onUnselectStudent } : Props) => {
  return (
    <div>
        {classStudents?.map((student) => {
            return <Form.Check
                    key={`select-${student.uuid}`}
                    className="text-start"
                    checked={selectedStudentIds.includes(student.uuid)}
                    id={`select-${student.uuid}`}
                    label={student.name}
                    onChange={(e) => {
                        // logic for checked reversed here because the click has already changed its state
                        if (!e.target.checked) {
                            const newStudents = selectedStudentIds.filter((value) => value !== student.uuid)
                            onUnselectStudent(newStudents)
                        } else {
                            const newStudents = selectedStudentIds.concat([student.uuid])
                            onSelectStudent(newStudents)
                        }
                    }}
                />
        })}
    </div>
  )
}

export default SelectStudents
