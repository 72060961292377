export const checkIfAssessmentComplete = (form: HTMLFormElement): [boolean, string] => {
    if(!form) {
        return [false, "Could not verify the assessment. Please contact STEM Sims support."]
    }
    const questionGroups = form.querySelectorAll(".question-group")
    const formElements = form.elements
    const radios = [...formElements]
    const checkedRadios = radios.filter(node => node["checked"])
    if (questionGroups.length > checkedRadios.length) {
        return [false, "Please fill out the entire assessment before submitting"]
    }
    return [true, "Assessment is completed."]
}

export const getUnansweredQuestions = (form: HTMLFormElement): string[] => {
    if(!form) {
        return
    }

    const questionGroups = form.querySelectorAll(".question-group")
    const formElements = form.elements
    const radios = [...formElements]

    const unanswered = []

    const checkedRadios = radios.filter(node => node["checked"])
    const answered = checkedRadios.map(radio => {
        const a = document.getElementById(radio.id)
        const questionNum = a.dataset["qNo"]
        return questionNum
    })
    
    // Only get the question numbers that are not in the answered array
    for (let i = 1; i <= questionGroups.length; i++) {
        if (!answered.includes(i.toString())) {
            unanswered.push(i.toString())
        }
    }

    return unanswered
}
