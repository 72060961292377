import { useContext, useEffect, useState, useRef } from "react"
import { Field, useFormikContext } from "formik"
import Loader from "react-spinners/BarLoader"
import { toast } from "react-toastify"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Dropdown from "react-bootstrap/Dropdown"
import getURLParams from "../../../helpers/getURLParams"
import lessonModel, { LessonResponse as Lesson } from '../../../shared/lessons'
import { getVideoID } from "../../../models/modules"
import LessonHeader from './LessonHeader'
import LessonBody from "./LessonBody"
import EditLesson from "./EditLesson"
import EditCorrelations from "./EditCorrelations"
import lessons from "../../../shared/lessons"
import { LessonContext } from "../LessonContext"
import { sanitizePath } from "shared/helpers/moduleHelper"
import { LessonSim } from "components/Lessons/LessonSim"
import * as auth from "../../../models/auth"
import FullScreenWrapper from "components/Assignment/components/FullScreenWrapper"
import styles from "./styles.module.scss"
import TeacherTips, { EditImage } from "components/Dashboards/Teacher/TeacherTips"
import { AuthContext } from "AuthContext"
import { answerLengths, saveGuidedLesson } from "./helpers/lessonHelper"
import { MODULE_NO_ACCESS } from "shared/types/moduleTypes"
import { useNavigate } from "react-router"

type LessonModuleProps = {
    authInfo: auth.InformationReturn,
    lessonId?: string,
}

const LessonModule = ({lessonId, authInfo}: LessonModuleProps) => {
    const authContext = useContext(AuthContext)

    const id = lessonId ?? (getURLParams() as { id: string }).id
    const [ lessonPath, setLessonPath ] = useState<string>()
    const [ lesson, setLesson ] = useState<Lesson>()
    const [ refreshToken, setRefreshToken ] = useState(new Date())
    const [ completed, setCompleted ] = useState<boolean>(false)
    const [ standardCorrelations, setStandardCorrelations ] = useState<any[]>([])
    const [ tipsInput, setTipsInput ] = useState<string>()
    const [ purposeInput, setPurposeInput ] = useState<string>()
    const [ videoId, setVideoId ] = useState<string>()
    const [ questionAnswers, setQuestionAnswers ] = useState(new Array(60).fill(""))
    const [ tableAnswers, setTableAnswers ] = useState(new Array(60).fill(""))
    const [ lessonFullScreen, setLessonFullScreen ] = useState(false)

    const lessonFormRef = useRef(null)
    const startedRef = useRef(new Date())
    const questionAnswersRef = useRef(questionAnswers)
    const tableAnswersRef = useRef(tableAnswers)
    const prevSavedRef = useRef(false)
    const lessonSubmissionIdRef = useRef(null) // this studentLesson id will be used to update existing studentLesson record in backend

    const editing = process.env.REACT_APP_EDITING === "true"
    const isTeacher = authContext.isLoggedIn && !authContext.isStudent
    const isTracking = isTeacher && authContext.trackingEnabled

    const navigate = useNavigate()

    function EditFile({fileName,accept}: {fileName:string,accept:string}) {
        const { setFieldValue} = useFormikContext()
        return(<>
            <p>Upload a new {fileName}</p>
            <input type="file" name={`${fileName}Lesson`} accept={accept} onChange={(e) => {
                setFieldValue(`${fileName}Lesson`, e.currentTarget.files[0])
            }}/>
        </>)
    }
    function submit() {
        return saveGuidedLesson({ lesson, completed: true, isTeacher, lessonFormRef, prevSavedRef, lessonSubmissionIdRef, started: startedRef.current })
        .then(() => {
            setCompleted(true)
            toast.success("Lesson updated successfully.")
            return true
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? "There was an error saving the lesson.")
            if (err?.response?.data?.message === MODULE_NO_ACCESS) {
                window.location.href = `/simulations/moduleName=${lesson.moduleName}/tab=challenge`
            }
            return false
        })
    }

    async function refreshLesson() {
        const lessonRes = await lessonModel.findById(id)
        setLesson({ id: id, ...lessonRes })
        setQuestionAnswers(new Array(answerLengths[lessonRes.type]).fill(""))
        setLessonPath(`/simulations/${ sanitizePath(lessonRes.moduleName) }/lessons/lesson-${ lessonRes.number }-${ lessonRes.type.toLowerCase() }`)
        setRefreshToken(new Date())
    }

    useEffect(() => {
        if(lesson?.moduleName) {
            getVideoID(lesson.moduleName)
            .then(({videoPath}) => setVideoId(videoPath))
            .catch (err => {
                if (err?.response?.data?.message === MODULE_NO_ACCESS) {
                    return window.location.href = `/simulations/moduleName=${lesson.moduleName}/tab=challenge`
                }
                toast.error(err?.response?.data?.message ?? "There is something wrong retrieving video.")
            })
        }
    }, [lesson?.moduleName])

    useEffect(() => {
        if(editing) {
            lessons.findStandardCorrelations({lessonId: id}).then((standardsRes) => {
                setStandardCorrelations(standardsRes.standards)
            }).catch((err) => {
                toast.error(err?.response?.data?.message ?? "Could not fetch standards.")
            })
        }
        
        lessonModel.findById(id).then((res) => {
            if (!res?.userHasAccess) {
                return window.location.href = `/simulations/moduleName=${res.moduleName}/tab=challenge`
            }
            setLesson({id:id, ...res})
            setLessonPath(`/simulations/${ sanitizePath(res.moduleName) }/lessons/lesson-${ res.number }-${ res.type.toLowerCase() }`)
        })
    },[id, editing])

    useEffect(() => {
        if (!editing && isTracking && lesson) {
            navigate("/dashboard/teacher/assignments/add",
                {state: {
                    lessonId: lesson.id,
                    lessonVariety: lesson.variety,
                    module: lesson.moduleName,
                    type: lesson.type,
                    assessment: lesson.assessment,
                } 
            })
        }
    }, [editing, isTracking, lesson, navigate])

    return(<>
        <Container fluid={"lg"} className={`d-flex flex-column full-screen ${lesson?.type === "Tier 0" || lesson?.type === "Tier 1" ? styles.elementary : ''}`}>
            <Row className="text-start align-items-center m-0 my-2">
                {lesson &&
                    <Col xs={6} md={"auto"}>
                        <Button variant="outline-primary" size="sm" className="mx-2 d-print-none" onClick={() => {
                            window.history.back()
                        }}>
                            <i className="fas fa-arrow-left" /> Back to Previous Page
                        </Button>
                    </Col>
                }
                <Col className="d-none d-md-block">
                    <LessonHeader lesson={lesson} /> 
                </Col>
                <Col xs={6} md={"auto"} className={`${!lessonPath && 'd-none'}`}>
                    <Row className="float-end text-center mb-2 g-1">
                        <Col>
                            <Dropdown className="m-2 d-print-none" align="end">
                                <Dropdown.Toggle variant="transparent" className="text-theme border rounded border-theme">
                                    <i className="far fa-file-alt" /> Documents
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="border-theme">
                                    <Dropdown.Header>Lesson</Dropdown.Header>
                                    <Dropdown.Item eventKey="2" href={`${lessonPath?.toLowerCase()}.pdf`}>PDF</Dropdown.Item>
                                    <Dropdown.Item eventKey="3" href={`${lessonPath?.toLowerCase()}.docx`}>MS Word</Dropdown.Item>
                                    <Dropdown.Header>Lesson Brochure</Dropdown.Header>
                                    <Dropdown.Item eventKey="4" href={`/simulations/${lesson ? sanitizePath(lesson.moduleName) : ""}/brochure/brochure.pdf`}>Brochure</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        {editing && <>
                            <Col className="text-center text-nowrap">
                                {/* PDF Link */}
                                    <EditLesson text="Edit PDF" lesson={lesson}>
                                        <EditFile fileName="pdf" accept={'.pdf,application/pdf'} />
                                    </EditLesson>
                            </Col>
                            <Col className="pe-0 text-nowrap">
                                {/* Word Link */}
                                <EditLesson text="Edit Doc" lesson={lesson}>
                                    <EditFile fileName="doc" accept={'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'}/>
                                </EditLesson>
                            </Col>
                        </>}
                    </Row>
                </Col>
            </Row>
            {/* Student Info */}
            <Row  className={`d-none d-print-flex align-items-center mb-2`}>
                <Col xs={6}>
                    <label className="d-block" htmlFor = "name">Name:</label>
                    <input id="lesson-student-info-name" type="text" className="tab" name="name" size={30}/>
                </Col>
                <Col xs={4}>
                    <label className="d-block" htmlFor = "date">Date:</label>
                    <input id="lesson-student-info-date" type="text" className="tab" name="date" size={14}/>
                </Col>
                <Col xs={2}>
                    {lesson?.type !== "Tier 1" && <>
                        <label className="d-block" htmlFor = "period">Period:</label>
                        <input id="lesson-student-info-period" type="text" className="tab" name="period" size={2}/>
                    </>}
                </Col>
            </Row>
            
            <div>
                {editing &&
                    <EditLesson text="Edit Header" lesson={lesson}>
                        <EditFile fileName="header" accept={'image/png'} />
                    </EditLesson>
                }
            </div>
            {(editing || isTeacher) && 
                <Row className="my-3 d-print-none">
                    <Col>
                        <TeacherTips guidedLesson={lesson} />
                        <EditLesson text="Edit Teacher Tips" lesson={lesson} callback={refreshLesson} size="xl">
                            <Field as={'textarea'} name="teacherTips" defaultValue={lesson?.teacherTips} placeholder="write teacher tip here" className={`${styles.lessonInput} col-10`} rows={20} />
                            <EditImage input={tipsInput} setInput={setTipsInput} />
                        </EditLesson>
                    </Col>
                    {editing && lesson && <Col>
                        <label>Teacher Purpose:</label>
                        <p>{ lesson.teacherPurpose ?? "No teacher purpose has been added"  }</p>
                        <EditLesson text="Edit Teacher Purpose" lesson={lesson} callback={refreshLesson}>
                            <Field as={'textarea'} name="teacherPurpose" defaultValue={lesson.teacherPurpose} placeholder="write teacher purpose here" className={`${styles.lessonInput} col-10`}  />
                            <EditImage input={purposeInput} setInput={setPurposeInput} />
                        </EditLesson>
                    </Col>}
                </Row>
            }
            <Row className={`flex-fill align-items-stretch mt-2 pb-4`}>
                <Col lg={6}>
                    <LessonSim moduleName={lesson?.moduleName} videoId={videoId} loggedIn={authInfo.isLoggedIn} />
                </Col>
                <Col lg={6} className={`${styles.lessonCol} position-relative d-flex flex-column`}>
                    <FullScreenWrapper fullScreen={lessonFullScreen} toggleFullScreen={() => setLessonFullScreen(prev => !prev)}>
                        <i role="button" className={`d-print-none fas ${lessonFullScreen ? "fa-window-minimize pb-5" : "fa-expand"} expand-btn pe-3`}
                            onClick={() => setLessonFullScreen(prev => !prev)}
                        />
                        {!lesson ? <Loader /> : 
                            <LessonContext.Provider value={{lesson: lesson, submitLesson: submit, refreshLesson: refreshLesson, refreshToken: refreshToken, containerType: "module",
                                questionAnswersRef, tableAnswersRef, startedRef, prevSavedRef, lessonSubmissionIdRef, setQuestionAnswers, setTableAnswers}}>
                                <LessonBody
                                    lessonFormRef={lessonFormRef} />
                            </LessonContext.Provider>
                        }
                        <p className={`${!completed && 'd-none'} fw-bold text-center mt-2`}>
                            You can now proceed back to <a href={`/simulations/${sanitizePath(lesson?.moduleName)}#methods`}>the lessons page.</a>
                        </p>
                    </FullScreenWrapper>
                </Col>
            </Row>

            {editing && lesson && <>
                <EditCorrelations lesson={lesson}/>
                <h2 className="h5 mt-4">Lesson Standard Correlations</h2>
                {standardCorrelations.map(({standard, topicCode, subtopicCode}) => (
                    <div key={`${ standard } - ${ topicCode } - ${ subtopicCode }`} className="list-group-item">
                        { standard } - { topicCode } - { subtopicCode }
                    </div>
                ))}
            </>}
        </Container>
    </>)
}

export default LessonModule
