import CustomHTML from "components/CustomHTML"
import { Row, Col } from "react-bootstrap"
import { LessonSubmissionResponse } from "shared/types/moduleTypes"
import styles from "../submissionTeacher.module.scss"
import ItemGradeInput from "../components/ItemGradeInput"
import { Dispatch, SetStateAction } from "react"
import modules from "shared/routes/moduleRoutes"
import { TeacherAssignmentResponse } from "shared/types/teacherTypes"
import pureInquiry, { PutQuestionProps } from "shared/routes/simulations/pureInquiry"

interface Props {
    lessonSubmission: LessonSubmissionResponse
    setLessonSubmission: Dispatch<SetStateAction<LessonSubmissionResponse>>
    sumPoints: (lessonSummary: LessonSubmissionResponse) => number
    lesson: any
    assignment: TeacherAssignmentResponse
    studentLessonId: string
}

const TeacherLessonResponses = ({ lessonSubmission, setLessonSubmission, sumPoints, lesson, assignment, studentLessonId } : Props) => {

    const embeddedQuestionCount = lessonSubmission?.responses?.findIndex((response) => response.section === 2) - 1

    const updateQuestion = ({ section, questionNumber, grade = undefined, comment = undefined }: { section: number, questionNumber: number, grade?: number, comment?: string }) => {
        if (assignment.lessonVariety === "Guided") {
            const body: { studentLessonId: string, section: number, questionNumber: number, grade?: number, comment?: string } = { section, studentLessonId, questionNumber }
            if (grade !== undefined) {
                body.grade = grade
            }
            if (comment !== undefined) {
                body.comment = comment
            }
            return modules.updateQuestionGrade({
                moduleName: lesson.module,
                lessonType: lesson.type,
                lessonNumber: lesson?.number.toString(),
                body
            })
        } else if (assignment.lessonVariety === "Pure Inquiry") {
            const body: Partial<PutQuestionProps> = {
                pureInquiryId: studentLessonId,
                questionNumber,
            }
            if (grade !== undefined) {
                body.grade = grade
            }
            if (comment !== undefined) {
                body.comment = comment
            }
            return pureInquiry.putQuestion(body as PutQuestionProps)
        }
    }

    if (lessonSubmission === null) return <></>
    return <>
        <h1 className="h3 text-start pb-3 mb-3 fw-bold text-center">Lesson Responses</h1>
        <ol>
            {lessonSubmission.responses?.map((response, index) => {
                // For embedded questions, changes the index from numbered to lettered (0 -> A, etc.), based on the relative char codes
                // For standalone questions, resets the displayed index to 1 for the first question
                const questionBullet = response.section === 1 ?
                    String.fromCharCode(65 + index) :
                    String(index - embeddedQuestionCount)
                return (<div className="text-start" key={`${lessonSubmission.completed} ${index}`}>
                    <span className="pe-2"><span className="fw-bold">{questionBullet}.</span> <CustomHTML html={response.question} /></span>
                    <br />
                    <Row>
                        <Col sm={4}>
                            <p className="mb-2"><strong>Grade</strong></p>
                            <ItemGradeInput
                                currentValue={response.grade}
                                setValue={(value) => {
                                    const newLesson = { ...lessonSubmission }
                                    newLesson.responses[index].grade = value
                                    void updateQuestion({ section: response.section, questionNumber: response.questionNumber, grade: value })
                                    newLesson.points = sumPoints(newLesson)
                                    newLesson.grade = newLesson.points / newLesson.totalPoints * 100
                                    setLessonSubmission(newLesson)
                                }}
                            />
                        </Col>
                        <Col sm={8}>
                            <p className="mb-2"><strong>Student's Answer</strong></p>
                            <p style={{
                                minHeight: ""
                            }}>
                                {response.answer}
                                {/*
                                    In case the student answer is blanks, we need a none-breaking space 
                                    here so the height of the paragraph tag is always rendered.
                                    */}
                                &nbsp;
                            </p>
                        </Col>
                        <Col sm={12}>
                            {/* Only show the correct answer if one exists. A blank box would be confusing */}
                            {response.correctAnswer && <>
                                <p className="mb-2"><strong>Correct Answer</strong></p>
                                <p>
                                    <CustomHTML html={response.correctAnswer} />
                                </p>
                            </>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="mb-2"><strong>Comments</strong></p>
                            <textarea
                                className={`${styles.commentTextbox} w-100 mb-3 p-2`}
                                defaultValue={response.comment ?? ""}
                                onBlur={(e) => {
                                    const newComment = e.currentTarget.value
                                    if (newComment !== response.comment) {
                                        void updateQuestion({
                                            section: response.section,
                                            questionNumber: response.questionNumber,
                                            comment: newComment
                                        })
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </div>)
            })}
        </ol>
    </>
}

export default TeacherLessonResponses
