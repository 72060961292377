import styles from "../submissionTeacher.module.scss"

export default function ItemGradeInput({ currentValue, setValue, max = 10, min = 0 }: { currentValue: number, setValue: (value: number) => void, max?: number, min?: number}) {
    return <>
        <i
            className={`fas fa-check ${styles.correctIcon}`}
            onClick={() => { setValue(max) }}
        />

        <span className={styles.commentGradeSpan}>
            <input
                type="number"
                className={styles.commentGrade}
                max={max}
                min={min}
                value={currentValue ?? ""}
                onChange={e => {
                    const value = Math.max(Math.min(parseInt(e.target.value), max), min)
                    if (isNaN(value)) {
                        setValue(min)
                    } else {
                        setValue(value)
                    }
                }}
            />
            / {max}
        </span>
        <i
            className={`fas fa-times ${styles.incorrectIcon}`}
            onClick={() => { setValue(min) }}
        />
    </>
}
