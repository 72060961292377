import { AuthContext } from "AuthContext"
import React, { useState } from "react"
import { toast } from "react-toastify"
import { Field } from "formik"
import Loader from "react-spinners/BeatLoader"
import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"
import { useModel } from "@stem-sims/nexus"
import assignmentsModel from "shared/routes/assignments"
import { sanitizePath } from "shared/helpers/moduleHelper"
import { editingOnly } from "components/Lessons/LessonModule/EditButton"
import EditButton from "components/Lessons/LessonModule/EditButton"
import styles from './assessment.module.scss'
import CustomHTML from "components/CustomHTML"
import assessments from "shared/routes/assessments/assessments"
import moduleRoutes from "shared/routes/moduleRoutes"

interface AssessmentProps {
  assessmentId: string
  moduleName: string
  assessmentNumber: number
  type: string
}

function InlineTextEdit({field, toggleEdit, saveEdit, fieldText, editMode}) {

  return(<>
    {editMode ? <>
      <textarea className={`form-control`} defaultValue={fieldText} id={`edit-question-text`}/>
      <Button className={"m-1"} onClick={() => saveEdit(field)}>Save</Button>
      <Button className={"m-1"} onClick={() => toggleEdit(field)}>Cancel</Button>
    </>
    : <>
      <CustomHTML html={fieldText} />
      <button className={`${styles["edit-btn"]} ${editingOnly()}`} title="Edit Question" onClick={() => toggleEdit(field)} type="button">
        <i className="fas fa-edit" />
      </button>
    </>}
  </>)
}

const Assessment = (props: AssessmentProps) => {
  const { assessmentId } = props
  const authInformation = React.useContext(AuthContext)
  const [editingQuestionTextNumber, setEditingQuestionTextNumber] = useState(0)
  const editing = process.env.REACT_APP_EDITING === "true"

  const { response: assessmentQuestions, loading, refreshModel } = useModel({
    model: assignmentsModel.getAssessmentQuestions,
    props: { assessmentId }
  })

  const { response: assessment, loading: loadingAssessment } = useModel({
    model: assessments.findById,
    props: { id: assessmentId }
  })

  const getImagePath = (questionNumber: number) => {
    const imagePath = `/simulations/${sanitizePath(props.moduleName)}/assessment/${props.type.toLowerCase()}-assessment-${props.assessmentNumber}-question-${questionNumber}`
    return imagePath
  }
  const getQuestionImagePath = (questionNumber: number) => {
    const questionImagePath = `${getImagePath(questionNumber)}.png`
    return questionImagePath
  }
  const getAnswerImagePath = (questionNumber: number, answerLetter: string) => {
    const answerImagePath = `${getImagePath(questionNumber)}-answer-${answerLetter.toLowerCase()}.png`
    return answerImagePath
  }

  const editCorrectAnswer = async (question, values) => {
    await assignmentsModel.updateAssessmentQuestion({
      assessmentId: assessmentId,
      questionNumber: question.number,
      correctAnswer: values.correctAnswer
    })
    refreshModel()
  }

  const toggleQuestionTextEdit = (question) => {
    const currentlyEditing = editingQuestionTextNumber === question.number
    setEditingQuestionTextNumber(currentlyEditing ? 0 : question.number)
  }
  const saveQuestionText = (question) => {
      const questionText = document.querySelector(`#edit-question-text`)["value"]
      const questionChanged = question.question !== questionText
      if(questionChanged) {
        assignmentsModel.updateAssessmentQuestion({
          assessmentId: assessmentId,
          questionNumber: question.number,
          questionText: questionText,
        }).then(() => {
          toast.success("Question text saved successfully.")
          refreshModel()
        }).catch((err) => {
          toast.error(err.response?.data?.message ?? "There was an error saving the question text. Please try again.") 
        })
      }
      setEditingQuestionTextNumber(-1)
  }

  const deleteQuestion = async (questionNumber) => {
    await assignmentsModel.deleteAssessmentQuestion({
      assessmentId: assessmentId,
      questionNumber: questionNumber,
    })
    refreshModel()
  }
  // const formattedModuleName = `${props.lessonNumber ? `Lesson ${props.lessonNumber} Assessment` : `Assessment ${props.assessmentNumber.toString()}`}: ${props.moduleName}`
  const formattedModuleName = assessment?.lessonId ?
  `Lesson ${assessment.lessonNumber} Assessment: ${props.moduleName}` :
  `Assessment ${assessment?.number}: ${props.moduleName}`

  React.useEffect(() => {
    const updateModuleHit = async (moduleName: string) => {
        await moduleRoutes.updateModuleHit(moduleName)
    }

    if (authInformation.isLoggedIn && authInformation.isStudent) {
        updateModuleHit(props.moduleName)
    }
  }, [authInformation.isLoggedIn, authInformation.isStudent, props?.moduleName])

  return (
    <form className={styles.assessment} id="assessment-form">
      { editing && <a href={`/editing/simulations/assessment?&module=${props.moduleName}&assessmentNumber=${props.assessmentNumber}&type=${props.type}`}>Old Assessment Page For Editing</a> }
        {loadingAssessment ?
        <Loader color={"#007bff"} /> :
        <h1 className={"h4 fw-bold"}>
            {formattedModuleName}
        </h1>}
        <div className="mt-4">
            {loading && !assessmentQuestions && <Loader color={"#007bff"} />}
        </div>
        {assessmentQuestions?.map((question, index) => (
            <div key={index} className={`container ${styles.question} p-3`}>
                <div className={`${styles.text}`}>
                  <span className="pe-2">{index+1}.</span>
                    <InlineTextEdit field={question} toggleEdit={toggleQuestionTextEdit} saveEdit={saveQuestionText} editMode={editingQuestionTextNumber === question.number} fieldText={question.question} />
                </div>
                {question.imageExists && (
                  <Image src={getQuestionImagePath(question.number)} alt={question.image.altText || 'Assessment Image'} className="rounded mb-2" fluid/>
                )}
                <div className="question-group d-flex flex-column align-items-start">
                    {question.answers.map((answer, i) => (
                        <div key={i} className={styles.answerOption + " text-start"}>
                            <input type="radio" id={`q${index}a${i}`} name={`question-${question.number}`} value={answer.letter} data-q-no={index + 1} />
                            {answer.imageExists ? 
                              <Image src={`${getAnswerImagePath(question.number, answer.letter)}`} alt={question.image.altText || 'Assessment Image'} fluid/>
                            :
                              <label htmlFor={`q${index}a${i}`} className="d-inline ms-1">
                                <span>{answer.letter}. </span><CustomHTML html={answer.answer} />
                              </label>
                            }
                        </div>
                    ))}
                </div>
                {(editing || (authInformation.isLoggedIn && !authInformation.isStudent)) && (
                    <div className={`text-success fw-bold ${styles.correctAnswer} mt-2`}>
                        Correct Answer: {question.correctAnswer}
                    </div>
                )}
                <EditButton text="Remove Question" editCallback={() => deleteQuestion(question.number)}>
                    <p>Are you sure you would like to remove this assessment question?</p>
                </EditButton>
                <EditButton text="Edit Correct Answer" editCallback={(values) => editCorrectAnswer(question, values)}>
                  <div className="mb-4">
                      <h2 className="h5">
                          Question #{question.number} Correct Answer
                      </h2>
                      <Field
                          as={'textarea'}
                          name={"correctAnswer"}
                          defaultValue={question.correctAnswer}
                          className={`lesson-input w-75`}
                      />
                  </div>
                </EditButton>
            </div>
        ))}
    </form>
)
}

export default Assessment
