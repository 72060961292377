import * as React from "react"
import { Button, Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import * as teacher from "../../../../shared/routes/teacher"
import { StudentResponse } from "../../../../shared/types/teacherTypes"

export interface Props {
    show: boolean
    onHide: () => void
    onSubmit: () => void
    onError?: () => void
    student: StudentResponse
}

export default function DeleteStudentModal({show, onHide, onSubmit, student}: Props) {
    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton className="border-0" />
                <Modal.Body>
                    <h2 className="h3 text-center">Delete Student</h2>
                    <p>Deleting this student will also delete all the student's data.</p>
                    <p><strong>Are you sure you would like to delete {student?.name}?</strong></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="theme" onClick={onHide}>Cancel</Button>
                    <Button
                        variant="danger"
                        onClick={ () => {
                            return teacher.deleteStudent(student.uuid)
                                .then(() => {
                                    toast.success("The student has successfully been deleted.")
                                    onSubmit()
                                })
                                .catch(() => {
                                    //TODO: (STEM-521) Could use some client side error reporting
                                    toast.error("There has been an error deleting the student. Please try again.")
                                })
                        }}
                    >Delete</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
