import React from "react"
import * as d3 from "d3"
import { OrganizationStandardsMasteryGetResponse } from "shared/types/moduleTypes"
import MasteryGraphTooltip from "./MasteryGraphTooltip"
import { colors } from "./MasterySharedGlobals"

const WIDTH = 1500
const HEIGHT = 600

const HEIGHT_OF_BAR = 300

function Chevron({ disabled = false, onClick, ...rest }) {
    const stateColors = {
        "ACTIVE": "#000000",
        "DISABLED": "#CCD2D5",
        "HOVER": "#797676"
    }

    const [fill, setFill] = React.useState(stateColors.ACTIVE)

    React.useEffect(() => {
        setFill(disabled ? stateColors.DISABLED : stateColors.ACTIVE)
    }, [disabled, stateColors.DISABLED, stateColors.ACTIVE])

    return <>
            <path
                d="M13.9953 13.0204C13.9962 13.4536 13.8335 13.8733 13.5355 14.2069L3.54068 25.3303C3.20137 25.7089 2.7138 25.947 2.18522 25.9922C1.65664 26.0374 1.13035 25.886 0.72213 25.5713C0.313908 25.2566 0.0571929 24.8044 0.00845866 24.3142C-0.0402756 23.824 0.122963 23.3359 0.462265 22.9573L9.41764 13.0204L0.782101 3.0835C0.616055 2.89386 0.492055 2.67567 0.417231 2.44145C0.342406 2.20723 0.318231 1.9616 0.346095 1.71869C0.373959 1.47578 0.453311 1.24036 0.579594 1.02599C0.705876 0.811607 0.876598 0.622488 1.08195 0.4695C1.28748 0.29972 1.52861 0.171136 1.79022 0.0918026C2.05183 0.0124694 2.32829 -0.0159011 2.60228 0.00846481C2.87626 0.0328307 3.14187 0.109408 3.38246 0.233402C3.62305 0.357397 3.83343 0.526138 4.00044 0.729048L13.6554 11.8524C13.9063 12.1956 14.026 12.6068 13.9953 13.0204Z"
                fill={fill}
                {...rest}
            />

            {/* 
                Use a rect to handle the events to make it easier to click
            */}
            {!disabled &&
                <rect
                    cursor="pointer"
                    onMouseEnter={() => {
                        setFill(stateColors.HOVER)
                    }}
                    onMouseLeave={() => {
                        setFill(stateColors.ACTIVE)
                    }}
                    onClick={onClick}
                    x={0}
                    y={0}
                    width={20}
                    height={26}
                    fill="transparent"
                />
            }
    </>
}

// Use a fixed array for the keys since the render order matters because...
// the highest level of mastery should be at the top of the bar chart and
// the lowest level should be at the bottom
const masteryOrder = ["Exceeds Mastery",  "Mastered", "Approaches Mastery", "Not Mastered"]

function StackedBar({x, y, width, bar, onMouseEnter, onMouseLeave}) {

    return (<>
            {(() => {
                let currentHeight = 0
                return masteryOrder.map((key, index) => {
                    const sectionHeight = HEIGHT_OF_BAR*bar.count[key]
                    const newY = y + currentHeight
                    currentHeight += sectionHeight

                    return (
                        <clipPath id={bar.ID + index}>
                            <rect
                                x={x}
                                y={newY}
                                width={width}
                                height={sectionHeight}
                            />
                        </clipPath>
                    )
                })
            })()}

            {masteryOrder.map((key, index) => (
                <rect
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    x={x}
                    y={y}
                    width={width}
                    height={HEIGHT_OF_BAR}
                    fill={colors[key]}
                    rx={10}
                    clip-path={`url(#${bar?.ID + index})`}
                    cursor={"pointer"}
                />
            ))}
    </>)
}

const BARS_PER_PAGE = 8

export default function StandardsMasteryGraph({ bars }: { bars: OrganizationStandardsMasteryGetResponse["data"]["standards"] }) {
    let margin = {top: 10, right: 80, bottom: 10, left: 130}

    const xAxis = React.useRef<SVGAElement>()
    const yAxis = React.useRef<SVGAElement>()

    const [showTooltip, setShowTooltip] = React.useState(null)
    const [currentPage, setCurrentPage] = React.useState(0)

    const finalBars = React.useMemo(() => {
        return bars?.map((bar) => {
            const percentageSummation = Object.values(bar.count).reduce((previousValue, current) => previousValue + current)
            if (percentageSummation !== 1.0) {
                console.warn("The summation of one standard is over 100%. This is impossible, check the input to verify this.")
            }

            var now = new Date()
            return {
                ...bar,
                "ID": String(Math.random()) + String(now.getTime())
            }
        })
    }, [bars])

    const idsToBars = React.useMemo(() => {
        return finalBars.reduce((prev, bar) => {
            prev[bar.ID] = bar
            return prev
        }, {})
    }, [finalBars])

    const xScale = React.useMemo(() => {
        let keys = Object.keys(idsToBars).slice(BARS_PER_PAGE * currentPage, BARS_PER_PAGE * (currentPage + 1))

        if (keys.length < BARS_PER_PAGE) {
            //Create random keys so the graph is always evenly space based on BARS_PER_PAGE
            //See the following comment for more details:
            //https://github.com/STEM-Sims/stem-sims/pull/432#discussion_r869636127
            keys = keys.concat(
                Array.from(Array(BARS_PER_PAGE - keys.length)).map(() => String(Math.random()))
            )
        }

        return d3.scaleBand()
            .domain(keys)
            .range([0, WIDTH])
            .padding(.7)
    }, [idsToBars, currentPage])

    const yScale = React.useMemo(() => {
        return d3.scaleLinear()
            .domain([100, -100])
            .range([0, HEIGHT])
    }, [])

    React.useEffect(() => {
        d3.select(xAxis.current)
            .call(
                d3.axisBottom(xScale)
                //make the ticks readable
                .tickFormat((value) => {
                    //Needs to be optional because it could be a blank standard bar to keep the scale the same
                    return idsToBars[value.toString()]?.name ?? ""
                })
            )
            .style("font-size", "20px")
        
        d3.select(yAxis.current)
            .call(
                d3.axisLeft(yScale)
                .tickFormat((value) => {
                    //force all labels to show up positive
                    return String(Math.abs(value.valueOf()))
                })
            )
            .style("font-size", "20px")
    }, [yScale, xScale, idsToBars])

    const calculateY = (selectedBar) => {
        const lowerBar = ["Not Mastered", "Approaches Mastery"].reduce((prev, key) => {
            return prev + selectedBar.count[key]
        }, 0)
        if (lowerBar > 0) {
            return yScale(-100*lowerBar) - HEIGHT_OF_BAR
        }

        return yScale(0) - HEIGHT_OF_BAR
    }

    return (<>
        <svg
            viewBox={`0 0 ${WIDTH + margin.left + margin.right} ${HEIGHT + margin.left + margin.right}`}
        >
            <g transform={"translate(" + margin.left + "," + margin.right + ")"}>

                {yScale.ticks().map((tick) => {

                    const tickPadding = 10

                    //Don't draw a line on the top or bottom of the graph because it looks weird.
                    if (tick === 100 || tick === -100) {
                        return <></>
                    }

                    return (
                        <line
                            x1={0 + tickPadding}
                            x2={WIDTH - tickPadding}
                            y1={yScale(tick)}
                            y2={yScale(tick)}
                            stroke="#CCD2D5"
                            strokeWidth={2}
                        />
                    )
                })}

                {finalBars.map((bar) => {

                    //if the bar isn't shown then don't display it
                    if (xScale(bar.ID) === undefined) {
                        return <></>
                    }

                    return (
                        <StackedBar
                            key={bar.ID}
                            x={xScale(bar.ID)}
                            y={calculateY(bar)}
                            bar={bar}
                            width={xScale.bandwidth()}
                            onMouseEnter={() => setShowTooltip(bar)}
                            onMouseLeave={() => setShowTooltip(null)}
                        />
                    )
                })}

                <g ref={yAxis} />
                <text
                    x={-65}
                    y={HEIGHT/2}
                    textAnchor="middle"
                    fontSize="25px"
                    transform={`rotate(90, -65, ${HEIGHT/2})`}
                >
                    Student Percentage (%)
                </text>

                <g ref={xAxis} transform={`translate(0, ${HEIGHT})`} />
                <text x={WIDTH/2} y={HEIGHT + 70} textAnchor="middle" fontSize="25px">Standard</text>
                
                {bars.length/BARS_PER_PAGE > 1 && <>
                    {/* Right Chevron */}
                    <g transform="translate(1550,280) scale(2)">
                        <Chevron 
                            disabled={currentPage === Math.floor(bars.length/BARS_PER_PAGE)}
                            onClick={() => {
                                setCurrentPage(currentPage + 1)
                            }}
                        />
                    </g>

                    {/* Left Chevron */}
                    <g transform="translate(-100, 333) rotate(180) scale(2)">
                        <Chevron
                            disabled={currentPage === 0}
                            onClick={() => {
                                setCurrentPage(currentPage - 1)
                            }}
                        />
                    </g>
                </>}


                {showTooltip &&
                    <MasteryGraphTooltip
                        x={xScale(showTooltip.ID) + xScale.bandwidth()}
                        y={calculateY(showTooltip) + HEIGHT_OF_BAR/2}
                        mastery={
                            showTooltip.count
                        }
                        title={showTooltip.name}
                    />
                }
            </g>
        </svg>
    </>)
}
