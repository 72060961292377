import Col from "react-bootstrap/esm/Col"
import VideoModalButton from "./VideoModalButton"
import Card from "react-bootstrap/esm/Card"

type YoutubeVideo = {
    title: string,
    link: string
}

type VideoGroup = {
    heading: string,
    videos: YoutubeVideo[]
}

const helpVideos: VideoGroup[] = [
    {
        "heading": "STEM Sims Overview",
        "videos": [
            {
                "title": "What Does Each STEM Sims Module Include?",
                "link": "https://youtube.com/embed/LqyS_Yt5FeI"
            }
        ]
    },
    {
        "heading": "STEM Sims Accounts",
        "videos": [
            {
                "title": "General Teacher/Student Account Access Information",
                "link": "https://youtube.com/embed/15f2uelhgYU"
            }
        ]
    },
    {
        "heading": "Teacher Dashboard",
        "videos": [
            {
                "title": "Teacher Dashboard Overview",
                "link": "https://youtube.com/embed/YzJBid581AI"
            },
            {
                "title": "Adding a New Class",
                "link": "https://youtube.com/embed/vqkvGZrqRQI"
            },
            {
                "title": "Editing Class Settings",
                "link": "https://youtube.com/embed/GpRR5EWzaBo"
            },
            {
                "title": "Deleting an Existing Class",
                "link": "https://youtube.com/embed/Uy25RX1l88M"
            },
            {
                "title": "Adding Students to a New Class",
                "link": "https://youtube.com/embed/9_vm9c4BF5Q"
            },
            {
                "title": "Adding Students to an Existing Class",
                "link": "https://youtube.com/embed/Zr38GOfShmA"
            },
            {
                "title": "Editing Student Names",
                "link": "https://youtube.com/embed/fzd-uVJy5b8"
            },
            {
                "title": "Deleting Students from a Class",
                "link": "https://youtube.com/embed/85kNp1dQ5P0"
            },
            {
                "title": "Resetting an Individual Student’s Account Password",
                "link": "https://youtube.com/embed/X2mH7DogSCY"
            },
            {
                "title": "Archiving a Class",
                "link": "https://youtube.com/embed/3ECW9kMwscw"
            }
        ]
    },
    {
        "heading": "Lessons",
        "videos": [
            {
                "title": "What Are the Parts of a Lesson?",
                "link": "https://youtube.com/embed/5Wyg_kMM9tE"
            },
            {
                "title": "Finding Lessons by Search",
                "link": "https://youtube.com/embed/QNPQIxYFrFQ"
            },
            {
                "title": "Finding Lessons by Keywords",
                "link": "https://youtube.com/embed/CkSD9CNjVp4"
            },
            {
                "title": "Finding Lessons by Standards",
                "link": "https://youtube.com/embed/eIVPleXouaA"
            },
            {
                "title": "Assigning Lessons",
                "link": "https://youtube.com/embed/AhKwZFMuHQA"
            },
            {
                "title": "Assigning Assessments Only",
                "link": "https://youtube.com/embed/1_QVT6jm0-c"
            },
            {
                "title": "Assigning Pure Inquiry Lessons",
                "link": "https://youtube.com/embed/yLTi1LXZGlI"
            },
            {
                "title": "Printing and Modifying Lessons",
                "link": "https://youtube.com/embed/ehf_7ODe6m8"
            },
            {
                "title": "Modifying Background, Methods, or Assessment Access",
                "link": "https://youtube.com/embed/1QTfsh1OVHI"
            },
            {
                "title": "Translating STEM Sims into Other Languages",
                "link": "https://youtube.com/embed/ajp6Z8OM9Xg"
            }
        ]
    },
    {
        "heading": "Grading and Progress Tracking",
        "videos": [
            {
                "title": "Grading Submitted Assignments",
                "link": "https://youtube.com/embed/VkjaJw2OtJ8"
            },
            {
                "title": "Tracking Student and Class Progress",
                "link": "https://youtube.com/embed/4cNANSwPokU"
            }
        ]
    }
]


export default function HelpVideos() {
    return (
        <Col className="py-2">
            {
                helpVideos.map((item) => {
                    return (
                        <Card className="py-2 mb-2">
                            <Card.Title>{item.heading}</Card.Title>
                            <Card.Body>
                                {
                                    item.videos.map((video) => {
                                        return (
                                            <p><VideoModalButton videoTitle={video.title} videoUrl={video.link} /></p>
                                        )
                                    })
                                }
                            </Card.Body>
                        </Card>
                    )
                })
            }
        </Col>
    )
}
