import React from "react"
import Alert from "react-bootstrap/Alert"
import { Link } from "react-router-dom"

export default function NotFound() {
    return (
        <>
            <div role="main" className="main container-fluid margin-container max-width">
                <Alert variant="danger">
                    The page you requested could not be found.
                </Alert>
                <p>You can now proceed back to <Link to = "/">our home page</Link>, or use any of the links in the navigation bar above to continue exploring our website.</p>
            </div>
        </>
    )
}
