import * as React from "react"
import { toast } from "react-toastify"
import { sanitizePath } from "shared/helpers/moduleHelper"
import Skeleton from "react-loading-skeleton"
import AssignmentWalkthroughVideo from "components/Assignment/AssignmentWalkthroughVideo"
import AssignmentBackground from "components/Assignment/AssignmentBackground"
import FullScreenWrapper from "components/Assignment/components/FullScreenWrapper"
import Sim from "components/General/Sim"

export function LessonSim({moduleName, videoId, loggedIn}: {moduleName: string, videoId: string, loggedIn: boolean}) {
    const [ simFullScreen, setSimFullScreen ] = React.useState(false)
    const [activeSection, setActiveSection] = React.useState<"lesson" | "walkthrough" | "background" | "simulation">("lesson")
    const simulationRef = React.useRef()
    const imagePath = moduleName && `/simulations/${sanitizePath(moduleName)}/thumbnail.png` // don't pass undefined into sanitize

    if(!moduleName) return(<>
        <Skeleton className="ratio ratio-4x3"/>
    </>)

    return (<FullScreenWrapper fullScreen={simFullScreen} toggleFullScreen={() => setSimFullScreen(prev => !prev)}>
      {activeSection === "walkthrough" && videoId &&
          <AssignmentWalkthroughVideo
              videoId={videoId}
              onExit={() => {
                  setActiveSection("lesson")
              }}
          />
      }
      {activeSection === "background" && <>
          <AssignmentBackground
              moduleName={moduleName}
              onExit={() => setActiveSection("lesson")} 
          />
      </>}
      <div className={`${activeSection === "background" && "d-none"} d-flex flex-column d-print-none justify-content-center h-100 m-auto`} ref={simulationRef}>
          <div className={`d-flex ${simFullScreen ? "position-relative" : ""}  align-items-center justify-content-end ms-auto`}>
              {videoId && <i role="button" className={`fas fa-question iconButton me-5 py-3`} data-testid="video-icon"
                  onClick={() => {
                      setActiveSection("walkthrough")
                  }}
              />}
              <i role="button" className={`fas fa-info-circle iconButton me-5 py-3`}
                  onClick={() => {
                      setActiveSection("background")
                  }}
              />
              <i role="button" className={`fas ${simFullScreen ? "fa-window-minimize pb-3" : "fa-expand"} iconButton me-2 py-3`}
                  onClick={() => {
                      setSimFullScreen((prevSimFullScreen) => {
                          return !prevSimFullScreen
                      })
                  }}
              />
          </div>
          <div className={`ratio ${simFullScreen ? "modal-body mt-n2" : "ratio-4x3"}`}>
              <Sim
                  src={`/api/iframe/simulation?moduleName=${moduleName}`}
                  iframeTitle={"Assignment Simulation"}
                  allowFullScreen={true}
                  disabledPicture={imagePath}
                  onLoad={(simulationDocument, iframe) => {
                      const event = new CustomEvent("stemsims.simulation.load")
                      iframe.contentWindow.dispatchEvent(event)

                      const html = simulationDocument.getElementsByTagName("html")[0]
                      const body = simulationDocument.getElementsByTagName("body")[0]
                      const main = simulationDocument.getElementsByTagName("main")[0]

                      if (!html || !body || !main) {
                          return toast.error("There has been an error loading the simulation. Please refresh the page and try again.")
                      }

                      body.style.height = "100%"

                      html.style.overflow = "hidden"
                      html.style.height = "100%"

                      main.style.display = "flex"
                      main.style.alignItems = "center"
                      main.style.height = "100%"
                  }}
              />
          </div>
      </div>
    </FullScreenWrapper>)
}
