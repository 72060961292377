import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Accordion from "react-bootstrap/Accordion"
import BeatLoader from "react-spinners/BeatLoader"
import lessonModel, { LessonResponse, LessonSearchItem, FindStandardsResponse, FilterParams } from "shared/lessons"
import CustomHTML from "../../CustomHTML"
import styles from "./styles.module.scss"
import ToolTip from "../../General/ToolTip"
import { ClassResponse } from "../../../shared/types/teacherTypes"
import * as categoriesModel from "models/categories"
import { useModel } from "@stem-sims/nexus"
import PureInquiryDescription from "../PureInquiry/PureInquiryDescription"
import { Type, gradeRangeToTier, pureInquiryTiers, tierToGradeRange } from "shared/types/moduleTypes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRocket, faLock } from "@fortawesome/free-solid-svg-icons"
import { sanitizePath } from "shared/helpers/moduleHelper"
import Skeleton from "react-loading-skeleton"
import { formatPureInquiry } from "shared/helpers/formatLesson"
import { Row, Col } from "react-bootstrap"
import moduleRoutes from "shared/routes/moduleRoutes"

interface ResultProps {
    activeClassStandard?: categoriesModel.Standard
    filteredStandard?: categoriesModel.Standard
    lesson: LessonSearchItem
    lessonSelection: (LessonSearchItem) => any
    moduleAvailable?: boolean
}
// todo: STEM-1117 move Result to own file
function Result({ lesson, lessonSelection, activeClassStandard, filteredStandard, moduleAvailable }: ResultProps) {
    const [selectedLesson, setSelectedLesson] = React.useState<LessonResponse>(null)
    const [activeClassStandards, setActiveClassStandards] = React.useState<FindStandardsResponse["standards"]>([])
    const [filteredStandards, setFilteredStandards] = React.useState<FindStandardsResponse["standards"]>([])
    const [lessonLoading, setLessonLoading] = React.useState(true)
    const lessonAvailable = lesson.available && moduleAvailable

    const formatStandards = (standards: FindStandardsResponse["standards"]) => {
        return standards.map((standard, index) =>
            `${standard.topicCode} - ${standard.subtopicCode}${index !== standards.length - 1 ? ", " : ""}`
        )
    }

    return (
        <Accordion.Item eventKey={lesson.id + lesson.variety + lesson.type} className="rounded mb-3">
            <Accordion.Header className={(!lessonAvailable ) && styles.bgDesaturated} onClick={() => {
                //check if the lesson is already loaded
                if (lesson.variety === "Pure Inquiry") {
                    setSelectedLesson({
                        id: lesson.id,
                        moduleName: lesson.module,
                        number: 0,
                        variety: "Pure Inquiry",
                        type: lesson.type,
                        title: lesson.title,
                        description: null,
                        teacherPurpose: null,
                        teacherTips: null,
                    })
                    setLessonLoading(false)
                } else if (!selectedLesson) {
                    Promise.all([
                        activeClassStandard?.id ? 
                            lessonModel.findStandardCorrelations({
                                lessonId: lesson.id,
                                standardId: activeClassStandard.id
                            })
                            :
                            Promise.resolve(),
                            filteredStandard ? 
                            lessonModel.findStandardCorrelations({
                                lessonId: lesson.id,
                                standardId: filteredStandard.id
                            })
                            :
                            Promise.resolve(),
                        lessonModel.findById(lesson.id)
                    ])
                        .then(([activeClassStandardsResponse, filteredStandardsResponse, lessonResponse]) => {
                            setSelectedLesson({...lessonResponse, id: lesson.id })
                            if (activeClassStandardsResponse) {
                                setActiveClassStandards(activeClassStandardsResponse.standards)
                            }
                            if (filteredStandardsResponse) {
                                setFilteredStandards(filteredStandardsResponse.standards)
                            }
                        })
                        .then(() => setLessonLoading(false))
                }
            }}>
                <h2 className="my-auto">
                    {lesson.core &&
                        <span>
                            <ToolTip title="This lesson is marked as a core lesson for the selected standards">
                                <i className={`fas fa-star ${styles.resultStar}`} />
                            </ToolTip>
                        </span>
                    }
                    {lesson.variety === "Pure Inquiry" ? 
                        <span className="h6"><strong>{lesson.title}</strong> ({tierToGradeRange[lesson.type]})</span>
                        : 
                        <>
                            <span className="h6 align-middle fw-bold">Lesson {lesson.number}: </span>
                            <span className="h6 align-middle">
                                <strong><CustomHTML html={lesson.title} /></strong>
                                <span> ({tierToGradeRange[lesson.type]})</span>
                            </span>
                        </>
                    }
                </h2>
            </Accordion.Header>
            <Accordion.Body className={!lessonAvailable && styles.bgDesaturated}>
                {lessonLoading ?
                    <BeatLoader />
                    :
                    <Row>
                        <Col sm="3">
                            <img className="img-fluid rounded" src={lesson.thumbnail} alt={`${lesson.title}'s Thumbnail`}></img>
                        </Col>
                        <Col sm="9">
                            {lessonAvailable && lessonSelection(selectedLesson)}
                            {lesson.variety === "Pure Inquiry" &&
                                <p className="fw-bold h4 text-start">Pure Inquiry</p>
                            } 
                            {lesson.variety !== "Pure Inquiry" && <>
                                <p className="fw-bold h4 text-start">Lesson {lesson?.number}</p>
                                <p className="fw-light h4 text-start mb-3">
                                    <CustomHTML html={selectedLesson.title} /> 
                                    <span className="text-muted h5 ps-3">({tierToGradeRange[lesson.type]})</span>
                                </p>
                            </>}
                            {lesson.variety === "Pure Inquiry" &&
                                <PureInquiryDescription type={lesson.type} />
                            }
                            {lesson.variety === "Guided" &&
                                <p className="text-start">
                                    <CustomHTML html={selectedLesson.description} />
                                </p>
                            }
                            { (activeClassStandards.length > 0 || filteredStandards.length > 0) &&
                            <div className="text-secondary text-start">
                                Standards:

                                {filteredStandard && filteredStandards.length > 0 && <>
                                    <p className="mb-1 mt-3">{ filteredStandard.standard }</p>
                                    <p>
                                        { formatStandards(filteredStandards) }
                                    </p>
                                </>}
                            </div>
                            }
                        </Col>
                    </Row>
                }
            </Accordion.Body>
        </Accordion.Item>
    )
}

interface LessonResultsProps {
    modules: any[]
    onLessonSelection: (LessonSearchItem: LessonResponse) => any
    filterParams: FilterParams
    //Not defined for LTI classes
    activeClass?: ClassResponse
    viewType: "assignment" | "landing" | "normal"
}
/** 
 * @abstract Lists lesson modules based off filter values selected 
 */
export default function LessonResults({ modules, onLessonSelection, activeClass, filterParams, viewType }: LessonResultsProps) {
    const [selectedModule, setSelectedModule] = useState<string>(modules[0]?.name)
    const [pureInquiryLessons, setPureInquiryLessons] = useState<{[key:string]: LessonSearchItem[]}>({})
    const [associatedLessons, setAssociatedLessons] = useState<LessonSearchItem[]>([])

    const { response: challengeText } = useModel({
        model: moduleRoutes.moduleChallengeText,
        props: selectedModule
    })

    const { response: standards } = useModel({
        model: categoriesModel.getStandards
    })

    const { response: filteredLessons, loading: loadingResults } = useModel({
        model: lessonModel.list,
        props: {
            moduleName: selectedModule,
            ...filterParams
        }
    })
    
    const { response: moduleLessons, loading: loadingModuleLessons } = useModel({
        model: lessonModel.list,
        props: {
            moduleName: selectedModule,
            grades: filterParams.grades ?? null,
        }
    })

    const selectedStandard = React.useMemo(() => {
        return standards?.find((standard) => standard.id === filterParams.standardId)
    }, [standards, filterParams.standardId])

    const activeClassStandard = React.useMemo(() => {
        if (!activeClass?.standardId || !standards) {
            return null
        } 

        return standards?.find((standard) => standard.id === activeClass.standardId)
    }, [activeClass?.standardId, standards])

    const thumbnail = (moduleName: string) => `/simulations/${sanitizePath(moduleName)}/thumbnail.png`

    const loadOtherLessons = (moduleName: string) => {
        setSelectedModule(moduleName) // update the selected module name to trigger a new API call
    }

    useEffect(() => {
        // find the difference between moduleLessons and filteredLessons
        if(!moduleLessons) return setAssociatedLessons([])
        const filteredLessonIds = filteredLessons?.map((lesson) => lesson.id)
        const difference = moduleLessons?.filter(moduleLesson => !filteredLessonIds || !filteredLessonIds.includes(moduleLesson.id))
        setAssociatedLessons(difference)
    }, [moduleLessons, filteredLessons])

    useEffect(() => {
        const pureInquiryLessons = {} 
        modules.forEach((module) => { // populate pure inquiry lessons
            const selectedTiers = new Set<Type>()
            pureInquiryLessons[module.name] = []
            filterParams.grades?.forEach((grade) => {
                const selectedTier = gradeRangeToTier[grade]
                selectedTiers.add(selectedTier)
            })
            if(selectedTiers.size === 0) {
                pureInquiryTiers.forEach((tier: Type) => {
                    pureInquiryLessons[module.name].push(formatPureInquiry(module.name, tier))
                })
            } else {
                selectedTiers.forEach((tier) => {
                    pureInquiryLessons[module.name].push(formatPureInquiry(module.name, tier))
                })
            }
        })
        setPureInquiryLessons(pureInquiryLessons)
    }, [filterParams.grades, modules])

    return (
        <Accordion>
            {modules.map((module, i) =>
                <Accordion.Item eventKey={module.name + i} className={`rounded my-3`} key={module.name + i}>
                    <div className="m-auto overflow-hidden">
                        <Row className="h-100">
                            <Col {...((viewType === "assignment" || viewType === "landing") ? {xs:12} : {xs:10, sm:8, md:6, lg:8, xl:9})} className="p-0">
                                <Accordion.Header
                                    onClick={() => loadOtherLessons(module.name)}
                                    className={`${(module.available || viewType === "landing") ? styles.bgLightBlue : styles.bgDesaturated}`}>
                                        {module && <div className={`${styles.accordionImg}`}>
                                            <img src={thumbnail(module.name)} alt={`${module.name}'s Thumbnail`} />
                                        </div>}
                                    <strong className={`${styles.indent}`}>{module.name}</strong>
                                </Accordion.Header>
                            </Col>
                            { viewType === "normal" && (
                            <Col xs={2} sm={4} md={6} lg={4} xl={3} className={"d-flex align-items-stretch p-0"}>
                                {module.available ?
                                    <Button href={`/simulations/moduleName=${module.name}/tab=challenge`} className={`${styles.viewSimBtn} w-100`}>
                                        <FontAwesomeIcon icon={faRocket} className={styles.icon} size="xl" />
                                        <span className="fw-bold px-2">View Simulation</span>
                                    </Button> 
                                    : 
                                    <Button href={`/simulations/moduleName=${module.name}/tab=challenge`} className={`${styles.buyModuleBtn} w-100`}>
                                        <FontAwesomeIcon icon={faLock} className={styles.icon} size="xl" />
                                        <span className="fw-bold px-2">Learn More</span>
                                    </Button>
}
                            </Col>
                            )}
                        </Row>
                    </div>
                    <Accordion.Body className={`${styles.bgLightBlue}`}>
                        {(selectedModule !== module.name) ? 
                            <>
                                <Skeleton height={50} className={`${styles.bgLightBlue} mb-2`} />
                            </> 
                            : <>
                                {challengeText &&
                                    <div className="mt-1 mb-4">
                                        <p className="text-start mt-3">
                                            <CustomHTML html={challengeText} />
                                        </p>
                                    </div>}
                                <Accordion>
                                    {(loadingResults || !filteredLessons) ? <>
                                        <Skeleton height={50} className={`${styles.bgLightBlue} mb-2`} />
                                    </> : filteredLessons.map((lesson) => {
                                        if (viewType === "landing") {
                                            lesson.available = true
                                        }
                                        return (
                                        <Result
                                            key={lesson.id ?? (lesson.variety + i + lesson.type)}
                                            lesson={lesson}
                                            activeClassStandard={activeClassStandard}
                                            lessonSelection={onLessonSelection}
                                            filteredStandard={selectedStandard}
                                            moduleAvailable={module.available || viewType === "landing"}
                                        />)
                                    })}
                                </Accordion>
                                <Accordion>
                                    {loadingModuleLessons ? <>
                                        <Skeleton height={50} className={`${styles.bgLightBlue} mb-2`} />
                                    </> : pureInquiryLessons[module.name].map((lesson) => {
                                        if (viewType === "landing") {
                                            lesson.available = true
                                        }
                                        return (
                                        <Result
                                            key={lesson.id ?? (lesson.variety + i + lesson.type)}
                                            lesson={lesson}
                                            activeClassStandard={activeClassStandard}
                                            lessonSelection={onLessonSelection}
                                            filteredStandard={selectedStandard}
                                            moduleAvailable={module.available || viewType === "landing"} 
                                        />)
                                    })}
                                </Accordion>
                                {!loadingResults && <h3 className="h4 pb-1">Other lessons in this module</h3>}
                                <Accordion>
                                    {loadingModuleLessons ? <>
                                        <Skeleton height={50} className={`${styles.bgLightBlue} mb-2`} />
                                    </> 
                                    : associatedLessons?.length > 0 ? associatedLessons.map((lesson) => 
                                            <Result
                                                key={lesson.id ?? (lesson.variety + i + lesson.type)}
                                                lesson={lesson}
                                                activeClassStandard={activeClassStandard}
                                                lessonSelection={onLessonSelection}
                                                filteredStandard={selectedStandard}
                                                moduleAvailable={module.available || viewType === "landing"}
                                            />)
                                        : 
                                            <p>No other lessons in this module</p>
                                    }
                                </Accordion>
                            </>}
                        </Accordion.Body> 
                </Accordion.Item>
            )}
        </Accordion>
        )
}
