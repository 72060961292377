import EnumOption from "components/General/EnumOption"
import { Duration } from "models/analytics"
import { Form } from "react-bootstrap"
import styles from "./DurationSelector.module.scss"

const { dropdowns } = styles

type DurationSelectorProps = {
    initialDuration?: Duration
    setDuration: (duration: Duration) => void
}

export default function DurationSelector({ initialDuration, setDuration }: DurationSelectorProps) {
    return <>
        <Form.Select
            className={dropdowns}
            defaultValue={initialDuration ?? "7days"}
            onChange={(event) => {
                setDuration(event.currentTarget.value as Duration)
            }}
        >
            <EnumOption<Duration>
                value="7days"
            >
                7 Days
            </EnumOption>
            <EnumOption<Duration>
                value="28days"
            >
                28 Days
            </EnumOption>
            <EnumOption<Duration>
                value="90days"
            >
                90 Days
            </EnumOption>
            <EnumOption<Duration>
                value="180days"
            >
                180 Days
            </EnumOption>
            <EnumOption<Duration>
                value="365days"
            >
                365 Days
            </EnumOption>
        </Form.Select>
    </>
}
