import { useEffect, useState, useContext } from "react"
import { Container, Row, Col, Accordion, Carousel, Button, Image, Modal, Card } from "react-bootstrap"
import styles from "./sectionStyle.module.scss"
import LessonResults from "components/Lessons/Search/Results"
import { CheckCircleFill } from "react-bootstrap-icons"
import axios from "axios"
import { TypingText } from "components/General/TypingText"
import { useNavigate } from "react-router-dom"
import { gotoCheckout } from "components/Subscribe/StripeSubcribe"
import { AuthContext } from "AuthContext"

const { imgBtn, heroSection, testimonialSection } = styles

interface HeroSectionProps {
    title: string
    subtitle: string
    image: string
}

interface SectionWithImageProps {
    title: string
    sectionContents: React.ReactNode
    image: string
    imagePosition: 'left' | 'right'
    backgroundColor?: string
}

interface SectionProps {
    title: string
    sectionContents: React.ReactNode
    backgroundColor?: string
}

interface FaqSectionProps {
    faqs: { question: string, answer: string }[]
}

interface PlanInfoProps {
    planName: string
    price: string
    description: string
    features: string[]
    buttonText: string
    studentCount: number
}

interface PricingSectionProps {
    plans: PlanInfoProps[]
    backgroundColor?: string
}

export function HeroSection({ title, subtitle, image }: HeroSectionProps) {
    const bgImg = {
        backgroundImage: `linear-gradient(90deg,rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0.0)), url(${image})`
    }

    return (
        <Container style={bgImg} className={`${heroSection} position-relative`}>
            <a href="/" className="text-decoration-none text-white fw-bold fs-4">
                <div className="d-flex align-items-center position-absolute top-0 start-0 ms-4 mt-4">
                    <Image src="/images/logo.png" alt="STEM Sims Logo" width={60} height={60} className="me-2" />
                    <div className="d-flex-column align-items-center text-start">
                        <div>STEM Sims</div>
                        <div className="fs-5 fw-normal">Virtual Labs, Real <TypingText text={["Science", "Technology", "Engineering", "Math"]} /></div>
                    </div>
                </div>
            </a>
            <Row>
                <Col md={6} className="px-5 fw-bold">
                    <p className="fw-bolder fs-1 text-start">{title}</p>
                    <hr />
                    <p className="fs-4 text-start">{subtitle}</p>
                </Col>
            </Row>
        </Container>
    )
}

export function SectionWithImage({ title, sectionContents, image, imagePosition, backgroundColor }: SectionWithImageProps) {
    return (
        <Container className="section py-5" style={{ backgroundColor: backgroundColor }}>
            <Row className="mx-auto" style={{ width: '90%' }}>                
                <Col lg={8} className={`fs-5 ${imagePosition === 'left' ? 'ps-5' : 'pe-5'}`}>
                    <h2 className="text-start mb-4">{title}</h2>
                    {sectionContents}
                </Col>
                <Col lg={4} className={(imagePosition === 'left' ? "order-lg-first" : "") + " "}>
                    <img src={image} alt={title} className="img-fluid rounded-3"/>
                </Col>
            </Row>
        </Container>
    )
}

export function Section({ title, sectionContents, backgroundColor }: SectionProps) {
    return (
        <Container className="section py-5" style={{ backgroundColor: backgroundColor }}>
            <Row className="mx-auto" style={{ width: '90%' }}>                
                <Col className="fs-5">
                    <h2 className="text-start mb-4">{title}</h2>
                    {sectionContents}
                </Col>
            </Row>
        </Container>
    )
}

export function FaqSection({ faqs }: FaqSectionProps) {
    const [activeKey, setActiveKey] = useState<string>(null)

    return (
        <Container className="faq section text-start py-5" style={{ width: '90%' }}>
            <h2 className="text-start mb-4">Frequently Asked Questions</h2>
            <Accordion className="fs-5" activeKey={activeKey}>
                {faqs.map((faq, index) => (
                    <Card key={index.toString()}>
                        <Card.Header onClick={() => setActiveKey(index.toString())} >{faq.question}</Card.Header>
                        <Accordion.Collapse eventKey={index.toString()}>
                            <Card.Body>{faq.answer}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </Container>
    )
}

export function TestimonialSection({ testimonials }: { testimonials: { name: string, quote: string }[] }) {
    const SectionStyle = {
        backgroundImage: "linear-gradient(rgba(40, 51, 172, 0.65), rgba(40, 51, 172, 0.65)), url('/images/landing/hex-back.jpg')"
    }

    return (
        <Container fluid className={`w-100 px-0 ${testimonialSection}`} style={SectionStyle}>
            <Carousel className="d-flex flex-column justify-content-center" style={{ minHeight: '400px' }}>
                {testimonials.map((testimonial, index) => (
                    <Carousel.Item key={index}>
                        <blockquote className="blockquote text-center">
                            <p className="mb-5 w-75 mx-auto fs-3">{testimonial.quote}</p>
                            <p className="blockquote-footer text-white">{testimonial.name}</p>
                        </blockquote>
                    </Carousel.Item>
                ))}
            </Carousel>
        </Container>
    )
}

export function ModuleSection() {

    const [showModal, setShowModal] = useState(false)
    const [modalContent, setModalContent] = useState<React.ReactNode | null>(null)
    const [tier, setTier] = useState<string>("Grades 3-5")
    const [modules, setModules] = useState<any[]>([])

    useEffect(() => {
        axios.get(`/api/modules?grades[]=${encodeURIComponent(tier)}`)
            .then(res => {
                setModules(res.data.modules)
            })
    }, [tier])

    useEffect(() => {
        setModalContent(<LessonResults modules={modules} onLessonSelection={() => {}} filterParams={{grades: [tier], keywords: '', matchAll: false}} viewType="landing"/>)
    }, [tier, modules])

    const searchByTier = (tier: string) => {
        setTier(tier)
        setShowModal(true)
    }

    return (
        <>
            <Container className="section py-5" style={{ width: '90%' }}>
                <div className="flex-column justify-content-center">
                        <div className="text-center my-4 px-5">
                            <h2><b>Explore Our Labs</b></h2>    
                            <label>Our virtual labs cover the following grade levels below:</label>
                        </div>
                        <Row className="justify-content-center px-5 mx-auto mb-5" aria-label="Square buttons containing a student of the respective age groups' lessons.">
                            <Col xs={6} lg={3} className="mb-3">
                                <Button onClick={() => {searchByTier("Grades 3-5")}} variant="none" className={`${imgBtn} shadow-sm justify-content-center`} style={{backgroundImage: "url('/images/landing/cartoon-child-3-6.png')"}} aria-label="Cartoon of young elementary school student standing with backpack">
                                    <div>Grades 3-5</div>
                                </Button>
                            </Col>
                            <Col xs={6} lg={3} className="mb-3">
                                <Button onClick={() => {searchByTier("Grades 6-12")}} variant="none" className={`${imgBtn} shadow-sm justify-content-center`} style={{backgroundImage: "url('/images/landing/cartoon-child-6-8.png')"}} aria-label="Cartoon of young middle school student looking at tablet while sitting">
                                    <div>Grades 6-8</div>
                                </Button>
                            </Col>
                            <Col xs={6} lg={3} className="mb-3">
                                <Button onClick={() => {searchByTier("Grades 6-12")}} variant="none" className={`${imgBtn} shadow-sm justify-content-center`} style={{backgroundImage: "url('/images/landing/cartoon-child-9-12.png')"}} aria-label="Cartoon of young high school student sitting with tablet">
                                    <div>Grades 9-12</div>
                                </Button>
                            </Col>
                            <Col xs={6} lg={3} className="mb-3">
                                <Button onClick={() => {searchByTier("Advanced Placement (AP)")}} variant="none" className={`${imgBtn} justify-content-center`} style={{backgroundImage: "url('/images/landing/cartoon-child-ap.png')"}} aria-label="Cartoon of young college student walking with backpack">
                                    <div>AP</div>
                                </Button>
                            </Col>
                        </Row>
                </div>
            </Container>
            <Modal className="min-vw-100" show={showModal} onHide={() => setShowModal(false)} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{tier} Virtual Labs</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    {modalContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export function PricingSection({ plans, backgroundColor }: PricingSectionProps) {
    const navigate = useNavigate()
    const auth = useContext(AuthContext)

    function handlePlan (planName: string, studentCount: number) {
        if (!auth.isLoggedIn) {
            navigate("/account/sign-up", { state: { studentCount: studentCount, planName: planName } })
            return
        }
        gotoCheckout(planName, studentCount)
    }

    return (
        <Container id="pricing" className="section py-5" style={{ width: '100%', backgroundColor: backgroundColor }}>
            {plans.length === 0 &&
                <h2>Check out our <a href="/subscribe">pricing page</a> for more information.</h2>
            }
            {plans.length > 0 &&
                <>
                <h2 className="text-center mb-5">Our Plans</h2>
                <Row className={`${styles.planRow}`}>
                    {plans.map((plan, index) => (
                        <Col lg={4} key={index} className={`${styles.planCard} h-100`}>
                            <div className="px-4">
                            <p className={`text-muted fs-4 fw-bold ${styles.planType} mt-2 mb-0`}>
                                {plan.planName}
                            </p>
                            <p><span className="fw-bold fs-2">{plan.price}</span>/year</p>
                            
                            {plan.features.length > 0 &&
                                <>
                                    <p className="fw-bolder">What's included?</p>
                                    <ul className="p-0 text-start">
                                        {plan.features.map(feature => (
                                            <li className="pb-1 text-start fs-5"><Row><Col xs={1}><CheckCircleFill className="me-2 text-primary" /></Col><Col xs={11} className="text-muted">{feature}</Col></Row></li>
                                        ))}
                                    </ul>
                                </>
                            }
                            <hr className={styles.greyLine} />
                            <p className={`text-muted mb-0 text-start ${styles.planDescription} fs-5`}>{plan.description}</p>
                            </div>
                            <Button onClick={() => handlePlan(plan.planName, plan.studentCount)} className={`${styles.planBtn} rounded-pill w-100`}>{plan.buttonText}</Button>
                        </Col>
                    ))}
                </Row>
                </>
            }
        </Container>
    )
}