import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import AuthRoute from "../../../Auth/AuthRoute"
import * as auth from "../../../../models/auth"
import { IdBased } from "../../../../helpers/findById"

const DashboardAuth: React.FC<{ activeClass: IdBased, loading: boolean, redirectUrl: string, permission: auth.Permission }> = ({children, activeClass, loading, redirectUrl, permission}) => {
    const location = useLocation()
    const navigate = useNavigate()

    React.useEffect(() => {
        if (!loading && activeClass === null && location.pathname !== redirectUrl) {
            navigate(redirectUrl)
        }
    }, [activeClass, location, loading, navigate, redirectUrl])
    if (activeClass === null && location.pathname !== redirectUrl) {
        return <p>Loading</p>
    }

    return (
        <AuthRoute permission={permission} trackingEnabled={true}>
            {children}
        </AuthRoute>
    )
}

export default DashboardAuth
