import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import { ReactElement } from "react";

type Props = {
  id?: string
  className?: string
  children: ReactElement | ReactElement[]
}

function FloatingPage(props: Props): ReactElement {
  return (
      <Container fluid id={props.id} className={"vh-100 px-0 px-lg-2 " + props.className} style={{
        position: "fixed",
        overflowY: "auto",
        paddingTop: "7rem",
        paddingBottom: "3rem",
        top: 0,
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        }} >
          <Col md={12} lg={10} xl={8} className="rounded shadow bg-white p-4 mx-auto" >
            { props.children }
          </Col>
      </Container>
  )
}

export default FloatingPage;
