import { useContext, useEffect, useState } from "react"
import { useFormikContext } from "formik"
import { toast } from 'react-toastify'
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import loadTables from "../../../helpers/loadTables"
import lessons from '../../../shared/lessons'
import styles from "./styles.module.scss"
import EditLesson from "./EditLesson"
import Modal from "react-bootstrap/Modal"
import Loader from "react-spinners/BeatLoader"
import { LessonBodyContext, LessonContext } from "../LessonContext"
import { hideImage, showImage } from "helpers/imageVisibility"

function EditTable() {
    const [deleteModal, showDeleteModal] = useState(false)
    const [deleteExampleModal, showDeleteExampleModal] = useState(false)
    const [deletingLoader, setDeletingLoader] = useState(false)
    const [tableAnswerInput, setTableAnswerInput] = useState<string>()
    const { lesson, refreshLesson } = useContext(LessonContext)
    const { setFieldValue } = useFormikContext()

    const handleDelete = () => {
        setDeletingLoader(true)
        lessons.deleteLessonTable(lesson.id).then(() => {
            toast.success('Table deleted successfully!')
            refreshLesson()
        }).catch(err => {
            const errorMessage = err.response?.data
            toast.error(typeof errorMessage === "string" ? errorMessage : `Failed to delete table: ${errorMessage?.code ?? "unknown error"} Please try again.`)
        }).finally(() => {
            setDeletingLoader(false)
            showDeleteModal(false)
        })
    }

    const handleDeleteExample = async () => {
        setDeletingLoader(true)
        try {
            await lessons.deleteLessonExample(lesson.id)
            toast.success("Example deleted successfully!")
        } catch (e) {
            const errorData = e.response?.data
            const errorMessage = (typeof errorData === "string" && errorData) || errorData?.code || "unknown error"
            toast.error(`Failed to delete example image: ${errorMessage}. Please try again.`)
        } finally {
            refreshLesson()
            setDeletingLoader(false)
            showDeleteExampleModal(false)
        }
    }

    return(<>
        <Row>
            <Col>
                <h2 className="h5">Upload HTML table</h2>
            </Col>
            <Col>
                <input type="file" name="tableHtml" accept=".html,.htm,text/html" className="mx-auto" onChange={(e) => {
                    setFieldValue("tableHtml", e.currentTarget.files[0])
                }}/>
            </Col>
        </Row>
        <br/>
        <Row>
            <Col>
                <h2 className="h5">Upload css for the table</h2>
            </Col>
            <Col>
                <input type="file" name="tableCss" accept=".css,text/css" className="mx-auto" onChange={(event) => {
                    setFieldValue("tableCss", event.currentTarget.files[0])
                }}/>
            </Col>
        </Row>

        <Row>
            <h2 className="h5 mt-2">Upload example table answer</h2>
        </Row>

        <Row className="my-3 px-3">
            <input type="file"
                name="image"
                accept=".png,image/png"
                allow-empty="true"
                key={tableAnswerInput}
                className="col"
                onChange={(e) => setFieldValue("tableAnswerImage", e.currentTarget.files[0])}
            />
            <Button className="col-4 btn-theme btn-sm" onClick={() => {
                setFieldValue("tableAnswerImage", undefined)
                setTableAnswerInput("") // react hack for clearing input bc react is lame
            }}>
                Clear File Selection
            </Button>
        </Row>

        <br/>

        {lesson.tableHtml &&
            <Button variant="danger" className="btn-sm m-2" disabled={!lesson.tableHtml} onClick={() => showDeleteModal(true)}>
                Delete Table
            </Button>
        }
        <p><b>Note:</b>If only one file is uploaded, the other will not be altered/removed.</p>
        <Modal show={deleteModal} onHide={()=>showDeleteModal(false)} className={styles.innerModal}>
            <Modal.Header closeButton />
            <Modal.Title>Are you sure you want to delete this table?</Modal.Title>
            <Modal.Body>
                <Button className="btn-sm m-2" disabled={!lesson.tableHtml} onClick={()=>showDeleteModal(false)}>
                    Cancel
                </Button>
                <Button variant="danger" className="btn-sm m-2" disabled={!lesson.tableHtml} onClick={handleDelete}>
                    {deletingLoader ? <Loader size={10} color="white"/> : "Delete"}
                </Button>
            </Modal.Body>
        </Modal>

        <Button variant="danger" className="btn-sm m-2" onClick={() => showDeleteExampleModal(true)}>
            Delete Example Image
        </Button>
        <Modal show={deleteExampleModal} onHide={()=>showDeleteExampleModal(false)} className={styles.innerModal}>
            <Modal.Header closeButton />
            <Modal.Title>Are you sure you want to delete the example table answer?</Modal.Title>
            <Modal.Body>
                <Button className="btn-sm m-2" onClick={()=>showDeleteExampleModal(false)}>
                    Cancel
                </Button>
                <Button variant="danger" className="btn-sm m-2" onClick={handleDeleteExample}>
                    {deletingLoader ? <Loader size={10} color="white"/> : "Delete"}
                </Button>
            </Modal.Body>
        </Modal>
    </>)
}

const LessonTable = () => {
    const editing = process.env.REACT_APP_EDITING === "true"
    const { lesson, refreshLesson, refreshToken } = useContext(LessonContext)
    const { lessonPath } = useContext(LessonBodyContext)
    const { setFieldValue } = useFormikContext()

    useEffect(() => {
        loadTables(setFieldValue)
    },[setFieldValue])

    return(<>
        <link rel="stylesheet" type="text/css" href={`${lessonPath}.css`} />
        <div id="table-container" className={styles.lessonTable + ' lessonTable my-3'} dangerouslySetInnerHTML={{ __html: lesson.tableHtml }}/>
        {editing && <>
            <p>Example table answer:</p>
            <img
                onError={hideImage}
                onLoad={showImage}
                src={`${lessonPath}-table-answer.png?cache=${refreshToken}`}
                alt="Example table answer"
            />
            <div>
                <a href={`${lessonPath}.html`} download={`${lesson.moduleName}.html`}>
                    <Button variant="outline-theme" className="btn-sm m-2">Download Table HTML</Button>
                </a>
                <a href={`${lessonPath}.css`} download={`${lesson.moduleName}.css`}>
                    <Button variant="outline-theme" className="btn-sm m-2 ">Download Table CSS</Button>
                </a>
            </div>
            <EditLesson text="Edit Tables" lesson={lesson} callback={refreshLesson}>
                <EditTable />
            </ EditLesson>
            <script type={"text/javascript"} src="/scripts/lessons/data-tables.js" />
            <script type="text/javascript" src="/scripts/lessons/load-tables.js"></script>
        </>}
    </>)
}

export default LessonTable
