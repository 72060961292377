import * as React from "react"
import { Row, Col, Form, Button, Table, Modal, Image } from "react-bootstrap"
import { useFormik, Field, FormikProvider } from "formik"
import { toast } from "react-toastify"
import * as Yup from "yup"
import styles from "../teacher.module.scss"
import * as teacher from "../../../../shared/routes/teacher"
import { ClassResponse } from "../../../../shared/types/teacherTypes"
import ToolTip from "../../../General/ToolTip"
import * as google from "../../../../models/google"
import ClassImport, { Course as GenericCourse } from "./ClassImport"
import ClassLogin from "./ClassLogin"
import oauth from "shared/oauth"
const { height80 } = styles

export interface Props {
    show: boolean
    onHide: () => void
    onSubmit: () => void
    activeClass: ClassResponse
    remainingLicenses?: number | null
}

export default function AddStudentModal({ show, onHide, onSubmit, activeClass, remainingLicenses }: Props) {

    const [students, setStudents] = React.useState<string[]>([])
    const [googleCourses, setGoogleCourses] = React.useState<GenericCourse[]>([])
    const [importingGoogle, setImportingGoogle] = React.useState(false)
    const [oauthCourses, setOauthCourses] = React.useState<GenericCourse[]>([])
    const [importingOauth, setImportingOauth] = React.useState(false)
    const [isMounted, setIsMounted] = React.useState(true)

    const getLoginData = async (event: { origin: string, data: string }) => {
        if (event.data && event.origin === window.location.origin) {
            google.loadToken(event)
            try {
                let courseData = await google.getCourses()
                const courses = [...courseData.courses]
                while (courseData.nextPageToken && courseData.nextPageToken !== "") {
                    courseData = await google.getCourses({ pageToken: courseData.nextPageToken })
                    courses.push(...courseData.courses)
                }
                if (isMounted) {
                    setGoogleCourses(courses)
                }
            } catch (e) {
                toast.error("Unable to load classes.")
            }
        }
    }

    React.useEffect(() => {
        return () => {
            window.removeEventListener("message", getLoginData)
            setIsMounted(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    React.useEffect(() => {
        setStudents([])
    }, [show])

    const formik = useFormik({
        initialValues: {
            name: ""
        },
        onSubmit: async (values) => {
            if (remainingLicenses === null || remainingLicenses < 1) {
                return toast.error("You cannot add more students")
            }
            
            return teacher.createStudents({
                students: [{ name: values.name }],
                classID: activeClass.id
            })
                .then(() => {
                    setStudents([...students, values.name])
                    return formik.setFieldValue("name", "")
                })
                .then(onSubmit)
                .catch(() => {
                    //TODO: (STEM-521) Could use some client side error reporting
                    toast.error("There has been an error creating your student. Please try again.")
                })
        },
        enableReinitialize: true,
        validationSchema: Yup
            .object()
            .shape({
                name: Yup
                    .string()
                    .label("Student Name")
                    .required()
            })
    })


    return (
        <Modal show={show} onHide={() => {
            setImportingOauth(false)
            setImportingGoogle(false)
            onHide()
        }} size="xl" scrollable={true} contentClassName={importingGoogle || importingOauth ? height80 : undefined}>
            <Modal.Header className="border-0" closeButton />
            {!importingGoogle && !importingOauth &&
                <Modal.Body className="mx-5">
                    <h3 className="text-start">Add Students</h3>
                    <p className={`${styles.greyText} text-start`}>Enter your students names here. When they login, they will select themselves from the list of students.</p>
                    <p className={`${styles.greyText} text-start fw-bold ${remainingLicenses > 0 ? "text-success " :"text-danger "}`}>Remaining Student Licenses: {remainingLicenses}</p>
                    <Table>
                        <thead>
                            <tr>
                                <th className="text-start">Student Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {students.map((student, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="text-start">
                                            {student}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <Table>
                        <tbody>
                            <tr>
                                <td>
                                    <FormikProvider value={formik}>
                                        <Form onSubmit={formik.handleSubmit} className="d-flex w-100">
                                            <Form.Control
                                                name="name"
                                                as={Field}
                                                placeholder="Enter student name, then press enter."
                                                className="w-100"
                                            />
                                            <ToolTip title="Add Student">
                                                <Button
                                                    as="a"
                                                    variant="theme"
                                                    aria-label="Add Student"
                                                    onClick={formik.submitForm}
                                                >
                                                    <i className="fas fa-plus" />
                                                </Button>
                                            </ToolTip>
                                        </Form>
                                    </FormikProvider>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <hr />
                    <h3 className="text-start">Import From Third Party Classroom</h3>
                    <Row>
                        <Col lg="4">
                            <ClassLogin
                                header="Import your Google Classroom"
                                body="Easily import your students from Google Classroom"
                                onSelect={async () => {
                                    window.removeEventListener("message", getLoginData)
                                    window.open(await google.getTokenUrl(), "Google Login", "toolbar=no, menubar=no, width=400, height=700, top=100, left=100")
                                    window.addEventListener("message", getLoginData)
                                    setImportingGoogle(true)
                                }}
                            />
                        </Col>
                        {["Canvas", "Clever"].map(provider => (
                            <Col lg="4" key={provider}>
                                <ClassLogin
                                    header={`Import your ${provider} Classroom`}
                                    body={`Sync your STEM Sims classroom with your ${provider} account`}
                                    onSelect={async () => {
                                        try {
                                            const courses = await oauth.getOauthClass()
                                            setOauthCourses(courses.classes)
                                            setImportingOauth(true)
                                        } catch (e) {
                                            toast.error(e?.response?.data?.message ?? `Unable to read ${provider} classes.`)
                                        }
                                    }}
                                    disabled={true}
                                ></ClassLogin>
                            </Col>
                        ))}
                    </Row>

                    <hr></hr>

                    <h3 className="text-start">Import Students by CSV</h3>
                    <p className={`${styles.greyText} text-start`}>Add students exported from Canvas, Google Classroom, or other LMSes. Importing students from Google Classroom will allow students to log in with Google Single Sign On.</p>
                    <Form.Group as={Row} controlId="csvImport">
                        <Col lg={4}>
                            <Form.Label className="float-start fw-bold" >Student Roster CSV File Upload</Form.Label>
                            <Form.Control name="cf" type="file" accept=".csv,text/csv" className="border-primary border-2 mb-2" onChange={async (event) => {
                                const el = event.target as HTMLInputElement
                                const csvFile = el.files[0]
                                if (!csvFile) {
                                    return toast.error("No file uploaded.")
                                }
                                const csvData = await csvFile.text()
                                try {
                                    await teacher.importStudentsFromCSV(csvData, activeClass.id, remainingLicenses)
                                } catch (e) {
                                    return toast.error(e?.message || "Unable to read students from file.")
                                }
                                onSubmit()
                                onHide()
                            }}></Form.Control>
                            <p className={`${styles.greyText} text-start`}>First Name, Last Name columns also supported as alternative.</p>
                            <Image src="/images/example-csv-2.png" className="mb-2" fluid/>
                        </Col>
                        <Col lg={8}>
                            <label className={`w-100 text-center fw-bold mb-1`}>CSV format</label>
                            <Image src="/images/example-csv.png" fluid/>
                        </Col>
                    </Form.Group>
                    <hr></hr>
                    
                </Modal.Body>
            }

            {importingGoogle &&
                <Modal.Body className="mx-5">
                    <ClassImport
                        courses={googleCourses}
                        onSelect={async (course) => {
                            try {
                                let studentData = await google.getStudents({ courseId: course.id })
                                const students = studentData.students
                                while (studentData.nextPageToken && studentData.nextPageToken !== "") {
                                    studentData = await google.getStudents({ courseId: course.id, pageToken: studentData.nextPageToken })
                                    students.push(...studentData.students)
                                }

                                if (students.length > remainingLicenses || !remainingLicenses) {
                                    return toast.error("You are not allowed to add more students than your license allows.")
                                }
                                
                                await teacher.createStudents({
                                    students: students.map(s => ({ name: s.profile.name.fullName, emailAddress: s.profile.emailAddress, id: s.userId })),
                                    classID: activeClass.id
                                })
                                setImportingGoogle(false)
                                setGoogleCourses([])
                                onSubmit()
                                onHide()
                            } catch (e) {
                                toast.error("Unable to load students.")
                            }
                        }}
                    />
                </Modal.Body>
            }

            {importingOauth &&
                <Modal.Body className="mx-5">
                    <ClassImport courses={oauthCourses} onSelect={async (course) => {
                        await oauth.postOauthClass({
                            ssoClassId: course.id,
                            classId: activeClass.id,
                        })
                        setImportingOauth(false)
                        setOauthCourses([])
                        onSubmit()
                        onHide()
                    }}></ClassImport>
                </Modal.Body>
            }

            {!importingGoogle && !importingOauth && <>
                <Modal.Footer className="mx-5">
                    
                </Modal.Footer>
            </>}
        </Modal>
    )
}
