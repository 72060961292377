export const helpSteps = [
    {
        target: '.class-list',
        content: 'This is your class list class list, select a class you\'d like to make the active class',
        disableBeacon: true,
        placement: "auto",
        styles: {
            options: {
              arrowColor: 'none'
            }
        }
    },
    {
        target: '.class-add-button',
        content: 'Click to add new classes to your list',
        disableBeacon: true,
        placement: "auto"
    },
    {
        target: '.class-archive-button',
        content: 'If you have archived classes, you can toggle their visibility',
        disableBeacon: true,
        placement: "auto"
    },
    {
        target: '.class-calendar',
        content: 'This is your class calendar, your class assignments will be shown here',
        placement: "auto"
    }
]

export const helpLinkVideos = [
    {
        title: "Teacher Dashboard Overview",
        link: "https://youtube.com/embed/YzJBid581AI"
    },
    {
        title: "Adding a New Class",
        link: "https://youtube.com/embed/vqkvGZrqRQI"
    },
    {
        title: "Editing Class Settings",
        link: "https://youtube.com/embed/GpRR5EWzaBo"
    }
]
