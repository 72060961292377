import { Table } from "react-bootstrap"
import styles from "./StudentRecords.module.scss"
import { DateString } from "shared/types/commonTypes"
import { Type } from "shared/types/moduleTypes"
import { useNavigate } from "react-router"
import * as teacherRoute from "shared/routes/teacher"
import * as assignmentsRoute from "shared/routes/assignments"
import React, { useEffect, useState } from "react"
import fillTableData from "pages/Dashboard/Teacher/Grades/Submission/helpers/fillTableData"

interface LessonTableRecord {
    moduleName: string
    lessonNumber: number
    time: string
    dateSubmitted: DateString
    questions: []
    type: Type
    dataTarget: string
    tableData: {
        tableAnswers: string
        tableHtml: string
        tableCss: string
    }
    submissionId: string | null
    assignmentId: string | null
}

const LessonTableTab = ({ lessonTableRecords } : { lessonTableRecords: LessonTableRecord[] }) => {

    const navigate = useNavigate()
    const [selectedRecord, setSelectedRecord] = useState<string>(null)

    const navigateToSubmission = async (record: LessonTableRecord) => {
        const assignment = await teacherRoute.findAssignmentById(record.assignmentId)
        const submissionsResponse = await assignmentsRoute.getGrades({ assignmentId: record.assignmentId })

        const currentIndex = submissionsResponse.data.findIndex(res => res.submission.id === record.submissionId)
    
        navigate("/dashboard/teacher/assignments/grading/submission", {
            state: {
                submissions: submissionsResponse.data,
                assignment: assignment,
                currentIndex: currentIndex
            }
        })
    }

    const onClickRow = (dataTarget: string) => {
        setSelectedRecord(prev => prev === dataTarget ? null : dataTarget)
    }

    useEffect(() => {
        fillTableData()
    }, [selectedRecord])

  return (
    <div className={`mt-4 mx-auto ${styles.row} overflow-auto`}>
        <Table>
            <thead>
                <tr>
                    <th>Simulation&nbsp;Name</th>
                    <th>Lesson&nbsp;Number</th>
                    <th>Time&nbsp;Taken</th>
                    <th>Date&nbsp;Submitted</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {lessonTableRecords?.length !== 0 ? lessonTableRecords?.map((record, i) => {
                    return (
                        <React.Fragment key={`${record.dataTarget}-${i}`}>
                            <tr className={styles.tableRowBackground} onClick={() => onClickRow(`${record.dataTarget}-${i}`)} role="button">
                                <td>{record.moduleName}</td>
                                <td>{record.lessonNumber} - {record.type}</td>
                                <td>{record.time}</td>
                                <td>{record.dateSubmitted}</td>
                                {record.assignmentId ? 
                                    <td className="d-flex justify-content-between align-items-center">
                                        <span role="button" className="text-primary" onClick={() => navigateToSubmission(record)}>View&nbsp;Submission</span>
                                        <i className="fas fa-chevron-down ms-auto" />
                                    </td> : <td></td>}
                            </tr>
                            <TableRecordRow tableData={record.tableData} dataTarget={record.dataTarget} i={i} selectedRecord={selectedRecord} />
                        </React.Fragment>
                    )
                }) : <tr>
                        <td colSpan={5}>There is no data yet.</td>
                    </tr>}
            </tbody>
        </Table>
    </div>
  )
}

const TableRecordRow = ({ tableData, dataTarget, i, selectedRecord }) => {
    const recordId = `${dataTarget}-${i}`
    return (
        <tr key={recordId} className={`${styles.childTable} ${selectedRecord === recordId ? `${styles.appear}` : `${styles.disappear}`}`}>
            {selectedRecord === recordId &&
                <td colSpan={12} className="p-0" data-testid="collapsible-lesson-table">
                    {tableData.tableHtml &&
                        <>
                            <link
                                rel={"stylesheet"}
                                type={"text/css"}
                                href={tableData.tableCss}
                            />
                            <link
                                rel={"stylesheet"}
                                type={"text/css"}
                                href={"/styles/lesson.css?version=3-2-18"}
                            />
                            <script type={"text/javascript"} src="/scripts/lessons/data-tables.js" />
                            <div
                                className={"lessonTable mt-2"}
                                id={"table-data"}
                                data-info={tableData.tableAnswers}
                                dangerouslySetInnerHTML={{ __html: tableData.tableHtml }}
                            />
                        </>
                    }
                </td>}
        </tr>
    )
}

export default LessonTableTab
