import { useState } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { toast } from "react-toastify"
import { FormikProvider, useFormik } from "formik"
import * as Yup from "yup"

type EditButtonProps = {
    text: string,
    reqBody?: any,
    size?: "sm" | "lg" | "xl",
    editCallback: (body : any) => Promise<any>,
    initValues?: any,
    save?: boolean,
    children: any
}

export const editingOnly = () => {
    return process.env.REACT_APP_EDITING === "true" ? "" : "d-none"
}

const EditButton = ({text,reqBody,size,editCallback,initValues={},save,children}: EditButtonProps) => {
    const editing = process.env.REACT_APP_EDITING === "true"
    const [modal,setModal] = useState<boolean>(false)
    const formik = useFormik({
        initialValues: initValues,
        enableReinitialize: true,
        onSubmit: async (values,formikBag) => {
            if(!save) {
                setModal(false)
            }
            return editCallback({...reqBody ?? {}, ...values}).then((res) => {
                toast.success("Update was successful.")
            })
            .catch((err) => {
                if(typeof err === "object") err = err.response?.data.message
                toast.error(err || "Update failed")
            })
        },
        validationSchema: Yup.object()
    })
    if (!editing) {
        return null
    }
    return(<>
        <Button onClick={() => setModal(true)} variant="default" className={`btn-sm m-2 ${editingOnly()}`}>{text}</Button>
        <Modal show={modal} onHide={() => setModal(false)} size={size} className="editModal">
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} className="p-1">
                    <Modal.Header closeButton>{text}</Modal.Header>
                    <Modal.Body>
                        {children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="theme">{save ? "Save" : "Submit"}</Button>
                    </Modal.Footer>
                </Form>
            </FormikProvider>
        </Modal>
    </>)
}

export default EditButton
