import * as React from "react"
import { toast } from "react-toastify"
import Image from "react-bootstrap/Image"
import Skeleton from "react-loading-skeleton"
import { AuthContext } from "AuthContext"
export interface SimProps {
    src: string,
    iframeTitle: string
    allowFullScreen?: boolean
    onLoad?: (element: Document, iframe: HTMLIFrameElement) => any,
    disabledPicture?: string
}

const Sim = React.forwardRef(({
    src,
    iframeTitle,
    onLoad = (doc, iframe) => null,
    allowFullScreen = false,
    disabledPicture = ""
}: SimProps,
ref : React.MutableRefObject<HTMLIFrameElement>) => {

    const [loading, setLoading] = React.useState(true)
    const { isLoggedIn, emailVerified, isStudent } = React.useContext(AuthContext)

    const disabled = !isLoggedIn || !emailVerified

    const lockedSim = (
        <div className="center-flex flex-column rounded">
            <Image src={disabledPicture} className="blur w-75 h-100 rounded p-2" /> 
            <div className="image-overlay"></div>
            <div className="position-absolute lockedBanner">
                <strong className="h5 fw-bold w-100"><a href="/account/log-in">Log In</a> or <a href="/account/sign-up">Sign Up</a> to use simulation</strong>
            </div>
        </div>
    )

    const unverifiedEmailSim = (
        <div className="center-flex flex-column rounded">
            <Image src={disabledPicture} className="blur w-75 h-100 rounded p-2" /> 
            <div className="image-overlay"></div>
            <div className="position-absolute lockedBanner">
                {isStudent ? 
                    <strong className="h5 fw-bold w-100">Please ask teacher to verify their email to use simulation.</strong> :
                    <strong className="h5 fw-bold w-100">Please <a href="/account/verify-email-address/resend">verify email</a> to use simulation.</strong>}
                
            </div>
        </div>
    )

    //Optional parameter is not supported in function argument, so this is a workaround
    ref = ref || React.createRef()
    return <>
        <div className={`${ (!disabled && loading) ? "w-100 h-100" : "d-none" }`}>
            {<Skeleton className="ratio ratio-4x3 mw-hd" />}
        </div>

        {!disabled && <iframe
            data-testid="sim-test"
            src={src}
            ref={ref}
            title={iframeTitle}
            className={`${ loading || disabled ? "d-none" : ""}`}
            allowFullScreen={allowFullScreen}
            onError={() => {
                setLoading(false)
                toast.error("There was an error loading the resource. Please refresh the page and try again.")
            }}
            onLoad={() => {
                setLoading(false)
                const iframe = ref?.current
                const iframeDocument = iframe?.contentWindow?.document
                if (!iframeDocument || !iframe) {
                    return toast.error("There was an error loading. Please refresh the page and try again.")
                }

                ref.current.style.border = "none"
                const main = iframeDocument.getElementsByTagName("main")
                if (main.length > 0) {
                    main[0].style.marginTop = "0px"
                }
                onLoad(iframeDocument, iframe)
            }}
        />}
        {!isLoggedIn ? lockedSim : !emailVerified ? unverifiedEmailSim : ""}
    </>
})

export default Sim
