import React from "react"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Timespan, Duration } from "../../../../models/analytics"
import dashboardStyle from "../../General/general.module.scss"
import Pane from "../../General/Pane"
import styles from "./progress.module.scss"
import EnumOption from "../../../General/EnumOption"
import StandardsProgression from "./components/StandardsProgression"
import UsageGraphs from "./components/UsageGraphs"
import DurationSelector from "components/Dashboards/General/DurationSelector"

export type SelectedGraph = "usage" | "activity" | "standardsProgress"

export default function DistrictProgress() {
    const [selectedGraph, setSelectedGraph] = React.useState<SelectedGraph>("standardsProgress")
    const [duration, setDuration] = React.useState<Duration>("7days")
    const [timespan, setTimespan] = React.useState<Timespan>("day")
    const [startDate, setStartDate] = React.useState<Date>(new Date())
    //TODO: When we update this to allow for custom dates these should be added to the state
    const [endDate] = React.useMemo(() => {
        const endDate = new Date()
        return [endDate]
    }, [])

    React.useEffect(() => {
        let daysToAdd: Record<Duration, number> = {
            "7days": 7,
            "28days": 28,
            "90days": 90,
            "180days": 180,
            "365days": 365
        }
        const newDate = new Date()
        newDate.setDate(newDate.getDate() - daysToAdd[duration])
        setStartDate(newDate)
        if (duration === "7days") {
            setTimespan("day")
        } else {
            setTimespan("week")
        }
    }, [duration])

    return (
        <>
            <h1 className="text-start">District Progress</h1>
            <p className={`${dashboardStyle.greyText} text-start`}>Here you can view usage, and track progress.</p>
            <Row className="mb-3">
                <Col sm="3">
                    <Form.Select
                        className={styles.dropdowns}
                        defaultValue={selectedGraph}
                        onChange={(event) => {
                            setSelectedGraph(event.currentTarget.value as SelectedGraph)
                        }}
                    >
                        <EnumOption<SelectedGraph>
                            value="activity"
                        >
                            Teacher and Student Over Time
                        </EnumOption>
                        <EnumOption<SelectedGraph>
                            value="usage"
                        >
                            Teacher and Student Usage
                        </EnumOption>
                        <EnumOption<SelectedGraph>
                            value="standardsProgress"
                        >
                            Standards Progress and Mastery
                        </EnumOption>
                    </Form.Select>
                </Col>
                <Col sm="2">
                    <DurationSelector
                        setDuration={setDuration}
                        initialDuration={duration}
                    />
                </Col>
            </Row>
            <Pane>
                <Row>
                    <Col
                        md={{
                            span: 8,
                            offset: 2
                        }}
                    >
                        <div className="mb-2" />
                        
                        {(selectedGraph === "activity" || selectedGraph === "usage") && 
                            <UsageGraphs
                                selectedGraph={selectedGraph}
                                startDate={startDate}
                                endDate={endDate}
                                timespan={timespan}
                            />
                        }

                        {selectedGraph === "standardsProgress" &&
                            <StandardsProgression startDate={startDate} endDate={endDate} />
                        }
                    </Col>
                </Row>
            </Pane>
        </>
    )
}
