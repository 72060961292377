import React from "react"
import analytics, { AnalyticsClassesResponse } from "../../../../models/analytics"
import ClassesTable from "../components/ClassesTable"

export default function WatchedClasses() {

    const [classes, setClasses] = React.useState<AnalyticsClassesResponse[]>([])

    React.useEffect(() => {
        Promise.all([analytics.getWatched(), analytics.getAnalytics()])
            .then(([watchedClasses, analyticsClasses]) => {
                const watchedClassIds = watchedClasses.watched.map((item) => item.id)
                setClasses(
                    analyticsClasses.filter((classItem) => watchedClassIds.includes(classItem.id))
                )
            })
    }, [])
    return (
        <>
            <ClassesTable
                classes={classes}
                title="Watched Classes"
                description="A list of your watched classes."
                noClassDescription="There are no watched classes yet. To watch a class look them up and click the 'Watch' button."
            />
        </>
    )
}
