import * as BS from "react-bootstrap"
import { FormikProvider, useFormik } from "formik"
import { BeatLoader } from "react-spinners"
import styles from "./forms.module.scss"
const { input } = styles

export interface Course {
    name: string,
    id: string,
}

interface Props {
    courses: Course[]
    onSelect: (course: Course) => void
}

export default function ClassImport({ courses, onSelect }: Props) {
    const formik = useFormik({
        initialValues: {
            index: 0
        },
        onSubmit: (values) => {
            onSelect(courses[values.index - 1])
        }
    })

    return <FormikProvider value={formik}>
        <BS.Form onSubmit={formik.handleSubmit}>
            <BS.Form.Group controlId="formImportCourseName" className="my-3">
                <BS.Form.Label>Course Name</BS.Form.Label>
                <BS.Form.Select name="index" className={`${input} mx-3`} onChange={formik.handleChange} value={formik.values.index}>
                    <option disabled value="0">Select class to import</option>
                    {courses.map((course, index) => {
                        return <option key={course.id} value={index + 1}>{course.name}</option>
                    })}
                </BS.Form.Select>
            </BS.Form.Group>
            <BS.Button
                variant="theme"
                type="submit"
                disabled={formik.isSubmitting || formik.values.index === 0}
            >
                {formik.isSubmitting ?
                    <BeatLoader size={10} />
                    : "Import"
                }
            </BS.Button>
        </BS.Form>
    </FormikProvider>
}
