import lessonModel, { LessonResponse as Lesson } from '../../../shared/lessons'
import EditButton from './EditButton'

type EditLessonProps = {
    text: string,
    lesson: Lesson,
    size?: "sm" | "lg" | "xl",
    initValues?: any,
    children: any
    callback?: () => void | Promise<unknown>
}

const EditLesson = ({text,lesson,size,initValues={},children, callback}: EditLessonProps) => {

    const updateLesson = async (values) => {
        const reqBody={type:lesson.type,module:lesson.moduleName,lessonNumber:lesson.number}
        let formData = new FormData()
        for (let key in values) {
            formData.append(key, values[key])
        }
        for (let key in reqBody) {
            formData.append(key, reqBody[key])
        }
        return lessonModel.editLesson(lesson.id,formData)
    }

    return(<>
        <EditButton text={text} size={size} editCallback={async (values) => {
            await updateLesson(values)
            await callback?.()
        }} initValues={initValues}>
            {children}
        </EditButton>
    </>)
}

export default EditLesson
