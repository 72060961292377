import axios from "axios"
import { CurriculumUnit } from "shared/types/curriculumTypes"

export async function getTextbooks() {
    const res = await axios.get("/api/curriculum/books")
    return res.data
}

export async function getTextbookUnits(bookId: string): Promise<CurriculumUnit[] | boolean> {
    const res = await axios.get(`/api/curriculum/units?bookId=${bookId}`)
    return res.status !== 200 ? false : res.data
}
