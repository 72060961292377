import { DateString } from "shared/types/commonTypes";

function formatToNumericalDate(date?: DateString) {
    return date ? new Intl.DateTimeFormat().format(new Date(date.replace("Z", ""))) : "N/A"
}

const formatToLongDate = (dateString: DateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = date.toLocaleString('default', { month: "short" })
    const day = date.getDate()
    return `${month} ${day}, ${year}`
}

function formatToCalendarDate(dateString: DateString) {
    const date = new Date(dateString)

    const year = date.getUTCFullYear()
    const month = String(date.getUTCMonth() + 1).padStart(2, "0")
    const day = String(date.getUTCDate()).padStart(2, "0")

    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
}

// return true if the assignment is due within a week from now
const dueSoon = (dueDate: DateString) : boolean => {
    const due = new Date(dueDate)
    const now = new Date()
    now.setHours(0, 0, 0, 0)

    const nextWeek = new Date()
    nextWeek.setDate(now.getDate() + 7)

    return now <= due && due <= nextWeek
}

// return true if given date is before today
const beforeCurrentDay = (date: DateString) : boolean => {
    const pastDate = new Date(date)
    const now = new Date()
    now.setHours(0, 0, 0, 0)

    return now > pastDate
}

export {
    formatToNumericalDate,
    formatToLongDate,
    formatToCalendarDate,
    dueSoon,
    beforeCurrentDay
}

