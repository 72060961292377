import { Type } from "../types/moduleTypes"

export interface Assessment {
    id: string
    lessonId?: string
    lessonNumber?: number
    module: string
    number?: number
    title?: string
    type: Type
}

export const AssessmentViewOptions = ["Grade", "Correct/Incorrect", "Answers"] as const
export type AssessmentViewOptionsType = typeof AssessmentViewOptions[number]

export const assessmentViewDescriptions = {
    "Grade": "Students can only see the assessment grade",
    "Correct/Incorrect": "Students can see the assessment grade and if their answers are correct",
    "Answers": "Students can see the assessment grade and the correct answer for each assessment question"
}
