import axios from "axios"
import encodeURI from "./helpers/encodeURI"

export type JsonApi<T> = Promise<T>

export interface GetOauthLoginProps {
    providerId: string
}

export interface GetOauthLoginResponse {
    authorizeUrl: string
}

export interface GetOauthClassResponse {
    classes: Array<{
        name: string
        id: string
    }>
}

function getOauthClass(): JsonApi<GetOauthClassResponse> {
    return axios.get(
        encodeURI`/api/account/oauth-class`
    ).then(r => r.data)
}

export interface PostOauthClassProps {
    classId: string
    ssoClassId: string
}

export interface PostOauthClassResponse {
    message: "Connected."
}

function postOauthClass({ classId, ssoClassId }: PostOauthClassProps): JsonApi<PostOauthClassResponse> {
    return axios.post(
        encodeURI`/api/account/oauth-class`
        , {
            classId,
            ssoClassId,
        }
    ).then(r => r.data)
}

export interface PutOauthClassProps {
    classId: string
}

export interface PutOauthClassResponse {
    message: "Synced."
}

function putOauthClass({ classId }: PutOauthClassProps): JsonApi<PutOauthClassResponse> {
    return axios.put(
        encodeURI`/api/account/oauth-class`
        , {
            classId,
        }
    ).then(r => r.data)
}

export interface DeleteOauthClassProps {
    classId: string
}

export interface DeleteOauthClassResponse {
    message: "Disconnected."
}

function deleteOauthClass({ classId }: DeleteOauthClassProps): JsonApi<DeleteOauthClassResponse> {
    return axios.delete(
        encodeURI`/api/account/oauth-class`
        , {
            data: {
                classId,
            }
        }
    ).then(r => r.data)
}

const oauth = {
    getOauthClass,
    postOauthClass,
    putOauthClass,
    deleteOauthClass,
}

export default oauth
