import { useContext } from "react"
import styles from "./general.module.scss"
import { AuthContext } from "AuthContext"
import { teacherToStudentLabels, DUE_WITH_SUBMISSIONS, NO_SUBMISSIONS, SUBMISSIONS_EXIST } from "./Calendar"

const ColorLegend = ({ colors } : { colors: string[] }) => {
    const { isStudent } = useContext(AuthContext)
    
  return (
    <div className="container rounded p-3">
        <div className={`row ${styles.gridContainer}`}>
            {colors.map(color => (
                <div className={`col col-12 col-lg-6 d-flex align-items-center ${styles.item}`}>
                    <div className={`${styles.colorLegendBox} 
                        ${color === NO_SUBMISSIONS ? styles.noSubmission : 
                            color === SUBMISSIONS_EXIST ? styles.submissions : 
                            color === DUE_WITH_SUBMISSIONS ? styles.submissionsDue : styles.noSubmissionDue}`}>
                    </div>
                    <span className={styles.colorLegendText}>{ isStudent ? teacherToStudentLabels[color] : color }</span>
                </div>
            ))}
        </div>
    </div>
  )
}

export default ColorLegend
