import React from "react"
import * as d3 from "d3"

export interface Props {
    data: Record<string, number>
    fillColor: string
}

export default function BarChart({ data, fillColor }: Props) {
    const divRef = React.useRef<HTMLDivElement>()
    React.useEffect(() => {
        // set the dimensions and margins of the graph
        const margin = {top: 10, right: 30, bottom: 90, left: 40}
        const width = 1000 - margin.left - margin.right
        const height = 300 - margin.top - margin.bottom

        // append the svg object to the body of the page
        const svg = d3.select(divRef.current)
            .append("svg")
            .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const dataKeys = Object.keys(data)

        // x-axis
        const x = d3.scaleBand()
            .range([ 0, width ])
            .domain(dataKeys)
            .padding(0.2);

        svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(x))
            .selectAll("text")
            .attr("transform", "translate(-10,0)rotate(-45)")
            .style("text-anchor", "end");

        //Find max data point
        const maxY = dataKeys.reduce((prev, curr) => {
            if (prev < data[curr]) {
                return data[curr]
            }
            return prev
        }, 0)

        //The max should be in increments of 10's
        const yTickMax = Math.floor((maxY + 10) / 10) * 10

        // Add Y axis
        const y = d3.scaleLinear()
            .domain([ 0, yTickMax ])
            .range([ height, 0 ]);

        svg.append("g")
            .call(
                d3.axisLeft(y)
                //ensure there's always 10 tick marks on the graph
                .ticks(10)
            );

        // Bars
        svg.selectAll("mybar") //this actually doesn't select anything?
            .data(dataKeys)
            .join("rect")
            .attr("x", key => x(key))
            .attr("width", x.bandwidth())
            .attr("fill", fillColor)
            // no bar at the beginning thus:
            .attr("height", height - y(0)) // always equal to 0
            .attr("y", y(0))

        // Animation
        svg.selectAll("rect")
            .transition()
            .duration(100)
            .attr("y", (key: string) => y(data[key]))
            .attr("height", (key: string) => height - y(data[key]))
            .delay((d,i) => i*10)

        return () => {
            //There's a good chance this ref will no longer point to the original graph like this linting error is saying.
            //Bar chart could be refactored to a svg component to fix this
            //eslint-disable-next-line react-hooks/exhaustive-deps
            divRef.current.innerHTML = ""
        }
    }, [data, fillColor])

    return (
        <div ref={divRef} />
    )
}
