import React from "react"
import styles from "./styles.module.scss"

export default function Question({ question, answer, onChange, onBlur }: { question: string, answer: string, onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void, onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void }) {
    return <>
        <li className="mt-3">
            <h2 className="h4 pb-2">{question}</h2>
            <textarea className={`${styles.questionInput} input-glow`} onChange={onChange} value={answer} onBlur={onBlur}></textarea>
        </li>
    </>
}
