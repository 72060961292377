import CustomHTML from "components/CustomHTML"
import { Row, Col } from "react-bootstrap"
import { LessonSubmissionResponse } from "shared/types/moduleTypes"
import styles from "../studentSubmission.module.scss"

interface Props {
    lessonSubmission: LessonSubmissionResponse
    embeddedQuestionCount: number
}

const StudentLessonResponses = ({ lessonSubmission, embeddedQuestionCount } : Props) => {
    if (lessonSubmission === null) return <></>
  return <>
            <h2 className="h3 text-start pb-3 fw-bold text-center">Lesson Responses</h2>
            <ol className="mt-3">
                {lessonSubmission.responses?.map((response, index) => {
                    // For embedded questions, changes the index from numbered to lettered (0 -> A, etc.), based on the relative char codes
                    // For standalone questions, resets the displayed index to 1 for the first question
                    const questionBullet = response.section === 1 ?
                        String.fromCharCode(65 + index) :
                        String(index - embeddedQuestionCount)
                    return (<div className="text-start" key={`${lessonSubmission.completed} ${index}`}>
                        <span className="pe-2"><span className="fw-bold">{questionBullet}.</span> <CustomHTML html={response.question} /></span>
                        <br />
                        <Row>
                            <Col sm={4}>
                                <p className="mb-2"><strong>Grade</strong></p>
                                {response.grade ?? 0} / 10
                            </Col>
                            <Col sm={8}>
                                <p className="mb-2"><strong>Your Answer</strong></p>
                                <p style = {{
                                    minHeight: ""
                                }}>
                                    {response.answer}
                                    {/*
                                        In case the student answer is blanks, we need a none-breaking space 
                                        here so the height of the paragraph tag is always rendered.
                                        */}
                                    &nbsp;
                                </p>
                            </Col>
                            <Col sm={12}>
                                {/* Only show the correct answer if one exists. A blank box would be confusing */}
                                {response.correctAnswer && <>
                                    <p className="mb-2"><strong>Correct Answer</strong></p>
                                    <p>
                                        <CustomHTML html={response.correctAnswer} />
                                    </p>
                                </>}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="mb-2"><strong>Comments</strong></p>
                                <textarea
                                    className={`${styles.commentTextbox} w-100 mb-3`}
                                    defaultValue={response.comment ?? ""}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                    </div>)
                })}
            </ol>
        </>
}

export default StudentLessonResponses
