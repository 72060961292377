import BeatLoader from "react-spinners/BeatLoader"
import { AnalyticsActivityResponse } from "../../../../../models/analytics"
import WeeklyHeatMap from "../../../../General/WeeklyHeatMap"

export interface Props {
    usage: AnalyticsActivityResponse["usage"]
}
export default function DistrictUsage({ usage }: Props) {
    return (
        <>
            {!usage ? <>
                <BeatLoader size={15} />
            </> : <>
                <WeeklyHeatMap weekInformation={usage.teacher} baseColor={"#3F4BBC"} title="Teacher Usage" />

                <div className="pb-4" />

                <WeeklyHeatMap weekInformation={usage.student} baseColor={"#3F4BBC"} title="Student Usage" />
            </>}
        </>
    )
}
