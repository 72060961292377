export const helpLinkVideos = [
    {
        title: "Teacher Dashboard Overview",
        link: "https://youtube.com/embed/YzJBid581AI"
    },
    {
        title: "Editing Class Settings",
        link: "https://youtube.com/embed/GpRR5EWzaBo"
    },
    {
        title: "Adding a New Class",
        link: "https://youtube.com/embed/vqkvGZrqRQI"
    }
]
