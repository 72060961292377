export const helpSteps = [
    {
        target: '.challenge-tab',
        content: 'The module challenge outlines the overarching purpose of the module and will contain a brochure will 5 questions and a brief reading passage',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target: '.background-tab',
        content: 'The background section offers concise presentations on key concepts and vocabulary terms',
        disableScrolling: true,
    },
    {
        target: '.methods-tab',
        content: 'The methods section includes a brief video tutorial demonstrating how to use the simulation and the lessons associated with it',
        disableScrolling: true,
    },
    {
        target: '.assessment-tab',
        content: 'The assessment section includes between 8 and 20 multiple-choice tests associated with the lesson.',
        disableScrolling: true,
    },
    {
        target: '.teacher-tab',
        content: 'The teacher section includes a teacher guide that features tips for teaching, estimated time to complete, lesson keywords, standards addressed in the lesson, and answers to all assessment items presented in the lesson',
        disableScrolling: true,
    }
]

 export const helpLinkVideos = [
    {
        title: "What Does Each STEM Sims Module Include?",
        link: "https://youtube.com/embed/LqyS_Yt5FeI"
    },
    {
        title: "Modifying Background Access",
        link: "https://youtube.com/embed/1QTfsh1OVHI"
    }
]
