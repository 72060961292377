import { useContext, useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router"
import { CurriculumBook } from "shared/types/curriculumTypes"
import TextbookPage from "./TextbookPage"
import { BookContext } from "./BookContext"
import BookUnitsPage from "./BookUnitsPage"
import { AuthContext } from "AuthContext"
import { toast } from "react-toastify"
import * as curriculumRoutes from "shared/routes/curriculumRoutes"

export default function BookPage() {
    const [textbooks, setTextbooks] = useState<CurriculumBook[]>([])
    const auth = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        if (!auth.hasCurriculumAccess) {
            toast.error("You do not have access to the curriculum. Please subscribe to Family Plus to access.")
            navigate("/subscribe")
        }
    })

    // Load textbooks from database
    useEffect(() => {
        curriculumRoutes.getTextbooks()
            .then((data) => {
                setTextbooks(data)
            })
            .catch((error) => {
                console.error("Error fetching textbooks:", error);
            });
    }, []);

    return (
        <BookContext.Provider value={{ textbooks: textbooks }}>
            <Routes>
                <Route path="" element={<TextbookPage />} />
            <Route path="/units/:bookId" element={<BookUnitsPage />} />
            </Routes>
        </BookContext.Provider>
    )
}

