import Label from "components/General/Label"
import { Dispatch, SetStateAction, useState } from "react"
import { Button, Row } from "react-bootstrap"
import { toast } from "react-toastify"
import { LessonSubmissionResponse, MasteryTypes, PublishGradeProps } from "shared/types/moduleTypes"
import GradeInput from "../components/GradeInput"
import { TeacherAssignmentResponse } from "shared/types/teacherTypes"
import modules from "shared/routes/moduleRoutes"
import pureInquiry from "shared/routes/simulations/pureInquiry"
import styles from "../submissionTeacher.module.scss"
import ToolTip from "components/General/ToolTip"
import StandardsMasterySelection from "../components/StandardsMasterySelection"
import lessons from "shared/lessons"
import { useModel } from "@stem-sims/nexus"

interface Props {
    lessonSubmission: LessonSubmissionResponse
    setLessonSubmission: Dispatch<SetStateAction<LessonSubmissionResponse>>
    lesson: any
    assignment: TeacherAssignmentResponse
    studentLessonId: string
    activeClass?: {
        standardId: string
    }
}

const TeacherLessonSidebar = ({ lessonSubmission, setLessonSubmission, lesson, assignment, studentLessonId, activeClass } : Props) => {
    const [submitting, setSubmitting] = useState(false)
    const [selectedStandards, setSelectedStandards] = useState<PublishGradeProps["standardsMastery"]>([])

    const { response: standardsResponse } = useModel({
        model: lessons.findStandardCorrelations,
        props: {
            lessonId: assignment.lessonId ?? undefined,
            standardId: activeClass?.standardId,
            core: true
        }
    })

    const standards = standardsResponse?.standards ?? []
    
    return <>
        <div className="text-center">
            <Button
                variant="theme"
                size="lg"
                className="mb-3 mt-3 fw-bold"
                disabled={submitting}
                onClick={() => {
                    setSubmitting(true)
                    let promise
                    if (assignment.lessonVariety === "Guided") {
                        promise = modules.publishGrade({
                            moduleName: lesson.module,
                            lessonNumber: lesson?.number.toString(),
                            lessonType: lesson.type,
                            studentLessonId,
                            grade: lessonSubmission.grade,
                            comment: lessonSubmission.labComment ?? undefined,
                            standardsMastery: selectedStandards
                        })
                    } else if (assignment.lessonVariety === "Pure Inquiry") {
                        promise = pureInquiry.putGrade({
                            pureInquiryId: studentLessonId,
                            comment: lessonSubmission.labComment ?? undefined,
                            grade: lessonSubmission.grade,
                        })
                    }
                    return promise
                        .then(() => {
                            toast.success("Published grade.")
                        }).catch(() => {
                            toast.error("Failed to publish grade, please try again.")
                        })
                        .finally(() => {
                            setSubmitting(false)
                        })
                }}
            >
                {!submitting && <span className={styles.submitGradeBtnText}>Submit Lesson Grade</span>}
                {submitting && <span className={styles.submitGradeBtnText}>Submitting....</span>}
            </Button>
        </div>
        <Label title="Time Taken">
            {
                Math.ceil((
                    new Date(lessonSubmission.completed).getTime()
                    -
                    new Date(lessonSubmission.started)
                        .getTime()
                ) / (1000 * 60)
                )
            } minutes
        </Label>
        <Label title="Lesson Grade">
            <GradeInput
                className={styles.overallGradeInput}
                value={lessonSubmission.grade}
                onChange={(grade) => {
                    const newLesson = { ...lessonSubmission }
                    newLesson.grade = isNaN(grade) ? newLesson.grade : grade
                    setLessonSubmission(newLesson)
                }}
            />
            %
        </Label>
        <Label title="Points">
            {lessonSubmission.points}/{lessonSubmission.totalPoints}
        </Label>
        {standards.length > 0 && <>
            <Row className="mt-4 mb-2">
                <strong>Standards Mastery</strong>
            </Row>
            <Row>
                {standards.map((standard) => <>
                    <ToolTip title={standard.description}>
                        <p className="pt-3">{standard.subtopicCode}</p>
                    </ToolTip>
                    <StandardsMasterySelection
                        initialValue={lessonSubmission.standardsMastery.find((s) => s.lessonStandardId === standard.id)?.mastery ?? null}
                        id={standard.id}
                        onChange={(newMastery: MasteryTypes | null) => {
                            let newStandards = [...selectedStandards]
                            const existingStandardIndex = newStandards.findIndex((newStandard) => {
                                return newStandard.lessonStandardId === standard.id
                            })

                            if (existingStandardIndex !== -1) {
                                if (newMastery !== null) {
                                    newStandards[existingStandardIndex].mastery = newMastery
                                }
                                else {
                                    //Remove the standard from list
                                    newStandards = [
                                        ...newStandards.slice(0, existingStandardIndex),
                                        ...newStandards.slice(existingStandardIndex + 1)
                                    ]
                                }
                            }
                            else {
                                newStandards.push({
                                    lessonStandardId: standard.id,
                                    mastery: newMastery
                                })
                            }
                            setSelectedStandards(newStandards)
                        }}
                    />
                </>)}
            </Row>
        </>}
        <hr />
        <i className="far fa-comment-dots pe-2 pb-2" />
        <strong>Comments</strong>
        <br />
        <textarea
            className={`${styles.commentTextbox} w-100 p-2`}
            defaultValue={lessonSubmission.labComment ?? ""}
            onChange={(e) => {
                const newLesson = { ...lessonSubmission }
                newLesson.labComment = e.currentTarget.value
                setLessonSubmission(newLesson)
            }}
        />
    </>
}

export default TeacherLessonSidebar
