import { useEffect, useState } from "react"

export function TypingText({ text }: { text: string[] }) {
    const [currentText, setCurrentText] = useState("")

    useEffect(() => {
        let i = 0
        let j = 0
        let direction = 1
        const interval = setInterval(() => {
            setCurrentText(text[i].substring(0, j) + "|")
            if (j === text[i].length) {
                if (direction === 1) {
                    // Pause for 1 second
                    setTimeout(() => {
                        direction = -1
                    }, 1000)
                }
            } else if (j === 0 && direction === -1) {
                direction = 1
                i = (i + 1) % text.length
            }
            j += direction
        }, 100)
        return () => clearInterval(interval)
    }, [text])

    return <span>{currentText}</span>
}