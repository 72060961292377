import { useEffect, useState } from "react"
import { Nav, Row, Col, Button, Container } from "react-bootstrap"
import styles from "./StudentRecords.module.scss"
import { useParams } from "react-router"
import * as teacher from "shared/routes/teacher"
import AssessmentRecordsTab from "./AssessmentRecordsTab"
import LessonQuestionsTab from "./LessonQuestionsTab"
import LessonTableTab from "./LessonTableTab"
import PlanningQuestionsTab from "./PlanningQuestionsTab"

export type recordsProps = {
    assessmentRecords: any[]
    lessonQuestionRecords: any[]
    lessonTableRecords: any[]
    planningRecords: any[]
}

type studentRecordTabs = "assessments" | "lesson questions" | "lesson table" | "planning questions"
const StudentRecords = () => {
    const [activeTab, setActiveTab] = useState<studentRecordTabs>("assessments")
    const { studentName, studentId } = useParams() as { studentName: string, studentId: string }
    const [records, setRecords] = useState<recordsProps>(null)

    useEffect(() => {
        teacher.studentRecords({ studentName, studentId })
        .then(res => {
            setRecords(res)
        }) 
    }, [studentName, studentId, activeTab])

  return (
    <div className="mt-3">
        <Container>
            <Row className="align-items-center position-relative">
                <Col xs="auto" className={`d-flex justify-content-center align-items-center p-0 ${styles.backBtnContainer}`}>
                    <Button variant="outline-primary" size="sm" className="mx-2 d-print-none" onClick={() => {
                        window.history.back()
                    }}>
                        <i className="fas fa-arrow-left" /> Back To Class
                    </Button>
                </Col>
                <Col className={`p-0 ${styles.headerContainer}`}>
                    <h1 className="text-center">{studentName}'s records</h1>
                </Col>
            </Row>
        </Container>
        <hr className="w-75 m-auto mt-3" />
        <Nav variant="pills" defaultActiveKey={activeTab} className={`mt-4 justify-content-center`}>
            <Row className={`g-2 ${styles.row}`}>
                {/* {Assessments} */}
                <Col xs={12} md={6} lg={3}>
                    <Nav.Item 
                        role="button"
                        onClick={() => setActiveTab("assessments")}
                        className={`rounded px-5 w-100 d-flex justify-content-center ${activeTab === "assessments" ? `bg-primary ${styles.activeTab}` : `bg-white ${styles.tab}`}`}>
                        <Nav.Link eventKey="challenge" active={activeTab === "assessments"}>
                            <span className={`${activeTab === "assessments" && `text-white fw-bold`}`}>Assessments</span>
                        </Nav.Link>
                    </Nav.Item>
                </Col>
                {/* {Lesson Questions} */}
                <Col xs={12} md={6} lg={3}>
                    <Nav.Item
                        onClick={() => setActiveTab("lesson questions")}
                        role="button" 
                        className={`rounded px-5 w-100 d-flex justify-content-center ${activeTab === "lesson questions" ? `bg-primary ${styles.activeTab}` : `bg-white ${styles.tab}`}`}>
                        <Nav.Link eventKey="background" active={activeTab === "lesson questions"}>
                            <span className={`${activeTab === "lesson questions" && `text-white fw-bold`}`}>Lesson&nbsp;Questions</span>
                        </Nav.Link>
                    </Nav.Item>
                </Col>
                {/* {Lesson Table} */}
                <Col xs={12} md={6} lg={3}>
                    <Nav.Item
                        role="button"
                        onClick={() => setActiveTab("lesson table")}
                        className={`rounded px-5 w-100 d-flex justify-content-center ${activeTab === "lesson table" ? `bg-primary ${styles.activeTab}` : `bg-white ${styles.tab}`}`}>
                        <Nav.Link eventKey="methods" active={activeTab === "lesson table"}>
                            <span className={`${activeTab === "lesson table" && `text-white fw-bold`}`}>Lesson&nbsp;Table</span>
                        </Nav.Link>
                    </Nav.Item>
                </Col>
                {/* {Planning Questions} */}
                <Col xs={12} md={6} lg={3}>
                    <Nav.Item
                        role="button"
                        onClick={() => setActiveTab("planning questions")}
                        className={`rounded px-5 w-100 d-flex justify-content-center ${activeTab === "planning questions" ? `bg-primary ${styles.activeTab}` : `bg-white ${styles.tab}`}`}>
                        <Nav.Link eventKey="simulation" active={activeTab === "planning questions"}>
                            <span className={`${activeTab === "planning questions" && `text-white fw-bold`}`}>Planning&nbsp;Questions</span>
                        </Nav.Link>
                    </Nav.Item>
                </Col>
            </Row>
        </Nav>
        {activeTab === "assessments" && <AssessmentRecordsTab assessmentRecords={records?.assessmentRecords} />}
        {activeTab === "lesson questions" && <LessonQuestionsTab lessonQuestionRecords={records?.lessonQuestionRecords} />}
        {activeTab === "lesson table" && <LessonTableTab lessonTableRecords={records?.lessonTableRecords} />}
        {activeTab === "planning questions" && <PlanningQuestionsTab planningRecords={records?.planningRecords} />}
    </div>
  )
}

export default StudentRecords
