import React from "react"
import findById, { IdBased } from "../../../../helpers/findById"

//reference: https://typeofnan.dev/using-session-storage-in-react-with-hooks/
function getLocalStorageOrDefault(key, defaultValue) {
    const stored = localStorage.getItem(key)
    if (!stored) {
        return defaultValue
    }
    return JSON.parse(stored)
}

interface Props<ClassType extends IdBased> {
    getClasses: () => Promise<ClassType[]>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    setClasses: React.Dispatch<React.SetStateAction<ClassType[]>>
    setActiveClass: React.Dispatch<React.SetStateAction<ClassType>>
}

export default function refreshClassesFactory<ClassType extends IdBased>({ getClasses, setLoading, setClasses, setActiveClass }: Props<ClassType> ) {
    return () => {
        setLoading(true)
        return getClasses()
            .then((classes) => {
                setClasses(classes)
                const storageActiveClass = getLocalStorageOrDefault("activeClass", null)
                const activeClassResponse = findById(storageActiveClass?.id, classes)
                if (activeClassResponse) {
                    setActiveClass(activeClassResponse)
                } else {
                    setActiveClass(classes?.[0] ?? null)
                }
                return classes
            })
            .finally(() => {
                //TODO: (STEM-521) Could use some client side error reporting
                setLoading(false)
            })
    }
}
