import axios from "axios"

export async function get(): Promise<string[]> {
    const response = await axios.get("/api/states")
    return response.data?.states
}

const states = {
    get,
}

export default states
