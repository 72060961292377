import { Type } from "shared/types/moduleTypes"
import styles from "./descriptionStyles.module.scss"
const { descriptionHeader } = styles

export default function PureInquiryDescription({ type }: { type: Type }) {
    if (type === "Tier 1") {
        return <div className="text-start">
            <p className={descriptionHeader}>Overview</p>
            <p>Pure Inquiry lessons are designed for students to create their own questions, procedures, results analysis, and conclusions. When using this mode of instruction, simply showing students the simulation they are to use and helping them with basic navigation of the simulation is the desired protocol. When using this instructional mode, have your students complete the Pure Inquiry lesson template.</p>
            <p>Of particular importance on the template are the students’ stated research question, defined variables, clearly stated hypothesis, and their intended procedures. It is strongly suggested that you check these sections before students begin their activities. Once approved, data collection activities can take place. Once the students complete all sections of the template you require, they may select the “Submit” button to transfer the work for your review.</p>
            <p>However, for students at the upper elementary level this might not be enough guidance to fully support your students. Guided, Structured, or Confirmation Inquiry approaches may better suit specific student needs.</p>
            <p className={descriptionHeader}>Guided Inquiry</p>
            <p>Guided Inquiry differs from Pure Inquiry in that the general research question is presented to the students. Each simulation module and/or lesson has a Challenge that presents a broad research question to the students. Share this question with the students and then have them generate their own methodologies and conclusions. The Pure Inquiry lesson template may be used for this approach.</p>
            <p className={descriptionHeader}>Structured Inquiry</p>
            <p>In this instructional mode, students are provided the research question (see above), and a brief outline of the procedure. The Walkthrough Videos are a valuable tool that outline the steps for completing the activity or you may choose to present the procedure to your students. Again, the Pure Inquiry lesson template may be used for this approach since students will still be deciding which data to collect, how to analyze their data, and formulating their own conclusions.</p>
            <p className={descriptionHeader}>Confirmation Inquiry</p>
            <p>This instructional approach provides the students with the questions to be researched, the procedure, and structured data tables for the collection of data. The data analysis mode is defined and questions are provided to guide students to appropriate conclusions. The Pure Inquiry lesson template is not recommended for this approach. Instead, select a structured lesson for the database of lessons in STEM Sims.</p>
        </div>
    } else if (type === "Tier 2") {
        return <div className="text-start">
            <p className={descriptionHeader}>Overview</p>
            <p>Pure Inquiry lessons are designed for students to create their own questions, procedures, results analysis, and conclusions. When using this mode of instruction, simply showing students the simulation they are to use and helping them with basic navigation of the simulation is the desired protocol. When using this instructional mode, have your students complete the Pure Inquiry lesson template.</p>
            <p>Of particular importance on the template are the students’ stated research question, defined variables, clearly stated hypothesis, and their intended procedures. It is strongly suggested that you check these sections before students begin their activities. Once approved, data collection activities can take place. Once the students complete all sections of the template you require, they may select the “Submit” button to transfer the work for your review.</p>
            <p>However, for some students the Pure Inquiry approach might not be enough guidance to fully support your students. Guided or Structured Inquiry approaches may better suit specific student needs.</p>
            <p className={descriptionHeader}>Guided Inquiry</p>
            <p>Guided Inquiry differs from Pure Inquiry in that the general research question is presented to the students. Each simulation module and/or lesson has a Challenge that presents a broad research question to the students. Share this question with the students and then have them generate their own methodologies and conclusions. The Pure Inquiry lesson template may be used for this approach.</p>
            <p className={descriptionHeader}>Structured Inquiry</p>
            <p>In this instructional mode, students are provided the research question (see above), and a brief outline of the procedure. The Walkthrough Videos are a valuable tool that outline the steps for completing the activity or you may choose to present the procedure to your students. Again, the Pure Inquiry lesson template may be used for this approach since students will still be deciding which data to collect, how to analyze their data, and formulating their own conclusions.</p>
        </div>
    } else if (type === "AP Tier") {
        return <div className="text-start">
            <p className={descriptionHeader}>Overview</p>
            <p>Pure Inquiry lessons are designed for students to create their own questions, procedures, results analysis, and conclusions. When using this mode of instruction, simply showing students the simulation they are to use and helping them with basic navigation of the simulation is the desired protocol. When using this instructional mode, have your students complete the Pure Inquiry lesson template.</p>
            <p>Of particular importance on the template are the students’ stated research question, defined variables, clearly stated hypothesis, and their intended procedures. It is strongly suggested that you check these sections before students begin their activities. Once approved, data collection activities can take place. Once the students complete all sections of the template you require, they may select the “Submit” button to transfer the work for your review.</p>
            <p>However, for some students the Pure Inquiry approach might not be enough guidance to fully support your students. Guided or Structured Inquiry approaches may better suit specific student needs.</p>
            <p className={descriptionHeader}>Guided Inquiry</p>
            <p>Guided Inquiry differs from Pure Inquiry in that the general research question is presented to the students. Each simulation module and/or lesson has a Challenge that presents a broad research question to the students. Share this question with the students and then have them generate their own methodologies and conclusions. The Pure Inquiry lesson template may be used for this approach.</p>
            <p className={descriptionHeader}>Structured Inquiry</p>
            <p>In this instructional mode, students are provided the research question (see above), and a brief outline of the procedure. The Walkthrough Videos are a valuable tool that outline the steps for completing the activity or you may choose to present the procedure to your students. Again, the Pure Inquiry lesson template may be used for this approach since students will still be deciding which data to collect, how to analyze their data, and formulating their own conclusions.</p>
        </div>
    } else { // there are no tier 0 pure inquiry lessons
        return null
    }
}
