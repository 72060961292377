export const helpLinkVideos = [
    {
        title: "Adding Students to a New Class",
        link: "https://youtube.com/embed/9_vm9c4BF5Q"
    },
    {
        title: "Adding Students to an Existing Class",
        link: "https://youtube.com/embed/Zr38GOfShmA"
    },
    {
        title: "Resetting an Individual Student’s Account Password",
        link: "https://youtube.com/embed/X2mH7DogSCY"
    }
]
