import { useEffect } from "react"
import { BeatLoader } from "react-spinners"

export default function TranslateWidget({show}: {show: boolean}) {

    useEffect(() => {
        document.getElementById("translate-script")?.remove()
        const script = document.createElement("script")
        script.id = "translate-script"
        script.innerHTML = `
            new google.translate.TranslateElement({pageLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL}, 'google_translate_element')
            document.getElementById("translate-loader").style.display = "none"
        `
        document.body.appendChild(script)
    }, [show])

    return (
        <div className={`${show ? "" : "d-none"} position-absolute bg-white border border-primary rounded-3 p-2`} style={{ top: "5em", right: "2em" }} id="google_translate_element">
            <div id="translate-loader">
                <BeatLoader size={10} speedMultiplier={0.8} />
            </div>
        </div>
    )
}