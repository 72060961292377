import { toast } from "react-toastify"
import { FormikProvider, useFormik, Form as FormikForm, } from "formik"
import * as Yup from "yup"
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Container from "react-bootstrap/Container"
import { googleSSOInfo, login, oauth } from "shared/routes/auth"
import "./Login.scss"
import { FormikControl } from "@stem-sims/nexus"
import { useLocation, useNavigate } from "react-router"
import { useCallback, useContext, useEffect, useState } from "react"
import getURLParams from "helpers/getURLParams"
import { AuthContext } from "AuthContext"
import PasswordControl from "components/General/PasswordControl"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { gotoCheckout } from "components/Subscribe/StripeSubcribe"

type LogInProps = {
  username?: string
  studentCount?: number
}

function LoginPage() {
  const navigate = useNavigate()
  const location = useLocation()

  const state = location.state as LogInProps

  const [googleSSOId, setGoogleSSOId] = useState<string>("")
  const oAuthValues = useCallback(() => getURLParams() as { code: string, state: string, "page-hash"?: string }, [])

  const { triggerAuthRefresh } = useContext(AuthContext)

    type LoginForm = {
        username: string,
        password: string,
        rememberMe: boolean
    }
    const initialValues: LoginForm = {
        username: state?.username ?? "",
        password: "",
        rememberMe: false
    }
    const formik = useFormik({
        initialValues: initialValues,
        validateOnBlur: false,
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .required("Password is required")
                .max(150),
            username: Yup.string()
                .required("Username is required")
                .max(150)
        }),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            login({ username: values.username, password: values.password, trialCode: "", oAuthCode: oAuthValues().code, 
              oAuthState: oAuthValues().state, referer: document.referrer, pageHash: oAuthValues()["page-hash"]})
            .then((res) => {
              toast.success(res.data.message)

              // if user is coming from subscribe page, redirect to checkout
              if (state?.studentCount) {
                gotoCheckout("Class", state.studentCount)
                return
              }

              // only use navigate if the route is in React
              if (res.data.redirectURI.includes("/dashboard") || res.data.redirectURI === "/" || res.data.redirectURI === "/lessons/search/") {
                navigate(res.data.redirectURI)
                triggerAuthRefresh()
              } else {
                window.location.href = res.data.redirectURI
              }
            })
            .catch((err) => {
              toast.error(err.response.data.message ?? "Could not login due to unknown error, please contact us if error continues")
            }).finally(() => setSubmitting(false))
        }
    })

    const logInGoogle = async () => {
      const data = await oauth({ providerId: googleSSOId })
      window.location = data.authorizeUrl
    }

    useEffect( () => {
      // make an API call to obtain google provider id
      (async () => {
        const googleId = await googleSSOInfo()
        setGoogleSSOId(googleId.providerId)
      })()

      // make a login API call once Google Login user is redirected back to the page
      if (oAuthValues().code && oAuthValues().state) {
        login({ username: "", password: "", trialCode: "", oAuthCode: oAuthValues().code, oAuthState: oAuthValues().state })
        .then((res) => {
          toast.success(res.data.message)
          // only use navigate if the route is in React
          if (res.data.redirectURI.includes("/dashboard") || res.data.redirectURI === "/" || res.data.redirectURI === "/lessons/search/") {
            navigate(res.data.redirectURI)
            triggerAuthRefresh()
          } else {
            window.location.href = res.data.redirectURI
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message ?? "Could not login due to unknown error, please contact us if error continues")
        })
      }
    }, [oAuthValues, navigate, triggerAuthRefresh])

    return (<Container fluid="md" id="loginPage">

      <Row>

        <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }} className="planets d-flex justify-content-center align-items-center">
          <Image src="/images/planets_login.svg" className="px-3 pe-md-4 mb-2" fluid/>
        </Col>

        <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 2 }} className="d-flex align-items-center justify-content-center">
          <div className="form-container">
            <h1 className="h2 fw-bold">Sign In</h1>
            <p className=" text-center my-4 smaller-font text-muted">
              Sign in to your account to start accessing our extensive simulation content library for grades K-12.
            </p>
            <button 
              className="google-button btn rounded-pill pe-4 mb-4 py-0"
              onClick={logInGoogle}>
              <Image src="/images/google-logo.svg"></Image>
              Continue with Google
            </button>
            <div className="text-center mb-4 d-flex align-items-center">
              <div className="faded-line"></div>
              <span className="bg-white px-2 text-muted">Or</span>
              <div className="faded-line"></div>
            </div>
            <FormikProvider value={formik}>
              <Form as={FormikForm} className="text-center">
                <Form.Group className="d-inline-block w-75 text-start mb-4">
                  <Form.Label className="required">Username</Form.Label>
                  <FormikControl required
                    placeholder="Username"
                    name="username"
                    className="rounded-pill formik-input-padding"
                  />
                </Form.Group>
                <Form.Group className="d-inline-block w-75 text-start pb-2">
                  <Form.Label className="required">Password or Student Code</Form.Label>
                  <PasswordControl />
                </Form.Group>
                {/* todo STEM-1053: add remember me
                <Form.Group className="d-flex justify-content-between align-items-center my-2">
                  <Form.Check
                    id="remember-me"
                    name="rememberMe"
                    label={<span className="smaller-font">Remember me</span>}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                  />
                </Form.Group>*/}
                <Form.Group className="d-inline-block w-75 text-start mt-2">
                  <a href="/account/recover" className="forgot-password-link smaller-font text-primary">
                    Forgot Password?
                  </a>
                </Form.Group>
                <Button disabled={formik.isSubmitting} type="submit" variant="primary" className="w-75 rounded-pill mt-4">Login</Button>
                <div className="mt-3"></div>
                <p className="text-center fw-bold mt-5">
                  Not registered yet? <a href="/account/sign-up">Create an Account</a>
                </p>
                <p className="text-center smaller-font text-muted mt-2">
                  By creating your account, you agree to this site's{" "}
                  <a href="/legal/terms-of-use/">Terms of Use</a>
                </p>
              </Form>
            </FormikProvider>
          </div>
        </Col>

      </Row>
    </Container>)
}

export default LoginPage;
