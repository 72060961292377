import * as React from "react"
import { Button, Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import * as teacher from "../../../../shared/routes/teacher"
import { ClassResponse } from "../../../../shared/types/teacherTypes"

export interface Props {
    activeClass: ClassResponse
    show: boolean
    onHide: () => void
    onSuccess: () => void
}

export default function DeleteClass({ activeClass, onSuccess, onHide, show }: Props) {
    return(
        <Modal show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <h1 className="h3 w-100 text-center">Delete {activeClass.className}</h1>
            </Modal.Header>
            <Modal.Body className="mx-5 text-start">
                <p>Deleting this class will also delete all the students in the class and all their data. If you need to access the data in the future, please Archive the class instead.</p>
                <p><strong>Are you sure you would like to delete {activeClass.className}?</strong></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="theme" onClick={onHide}>Cancel</Button>
                <DeleteButton
                    onClick={ () => {
                        return teacher.deleteClass({
                            id: activeClass.id
                        })
                            .then(() => {
                                onHide()
                                toast.success("The class has successfully been deleted.")
                                onSuccess()
                            })
                            .catch(() => {
                                onHide()
                                //TODO: (STEM-521) Could use some client side error reporting
                                toast.error("There has been an error deleting the class, please try again.")
                            })
                    }}
                />
            </Modal.Footer>
        </Modal>
    )
}

export function DeleteButton({ onClick } : { onClick: () => void }) {
    return (
        <Button
            variant="danger"
            onClick={onClick}
            data-testid="delete-class-button"
        ><i className="fas fa-trash-alt pe-2" />Delete Class</Button>
    )
}
