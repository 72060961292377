export const helpSteps = [
  {
    target: '.lesson-search-1',
    content: 'To find simulations, enter a relevant keyword matching the topic or concept you\'re interested in. This keyword should be closely related to the specific area of study or type of simulation you seek',
    disableBeacon: true,
  },
  {
    target: '.grade-filter',
    content: 'Filtering by grade level only shows the simulations with the appropriate grade lessons',
  },
  {
    target: '.category-filter',
    content: 'First, choose a category based on state and grade level, then narrow your selection further by filtering through subcategories and sections as needed',
    placement: "right",
    styles: {
      options: {
        arrowColor: 'none'
      }
    }
  },
  {
    target: '.standard-filter',
    content: 'Select a standard based on state and grade level first, then further refine your search by filtering through topics and subtopics if needed',
    placement: "right",
    styles: {
      options: {
        arrowColor: 'none'
      }
    }
  }
]

export const helpLinkVideos = [
  {
    title: "Finding Lessons by Search",
    link: "https://youtube.com/embed/QNPQIxYFrFQ"
  },
  {
    title: "Finding Lessons by Keywords",
    link: "https://youtube.com/embed/CkSD9CNjVp4"
  }
]
