import axios from "axios"
import { useState, useEffect } from "react"
import { Table } from "react-bootstrap"
import { Plan } from "shared/types/adminTypes"

interface PlanSelectorProps {
    onSelect?: (plan: Plan) => void
}

export function PlanSelector({ onSelect }: PlanSelectorProps) {
    const [selectedPlan, setSelectedPlan] = useState<Plan>(null)
    const [plans, setPlans] = useState<Plan[]>([])

    useEffect(() => {
        axios.get("/api/account/admin/list-plans").then((res) => {
            setPlans(res.data)
        })
    }, [])

    const handleSelect = (plan: Plan) => {
        setSelectedPlan(plan)
        if (onSelect) {
            onSelect(plan)
        }
    }

    return (
        <>
        <Table bordered hover>
            <thead>
                <tr>
                    <th>Plan</th>
                    <th>Feature Teacher</th>
                    <th>Feature Tracking</th>
                    <th>Feature District</th>
                </tr>
            </thead>
            <tbody>
                {plans.map((plan) => (
                    <tr key={plan.planId} title={plan.description} onClick={() => handleSelect(plan)} className={selectedPlan?.planId === plan.planId ? "table-primary" : ""}>
                        <td>{plan.displayName}</td>
                        <td>{plan.featureTeacher ? <i className="fas fa-check text-success"></i> : <i className="fas fa-times text-danger"></i>}</td>
                        <td>{plan.featureTracking ? <i className="fas fa-check text-success"></i> : <i className="fas fa-times text-danger"></i>}</td>
                        <td>{plan.featureDistrict ? <i className="fas fa-check text-success"></i> : <i className="fas fa-times text-danger"></i>}</td>
                    </tr>
                ))}
            </tbody>
        </Table>

        </>
    )
}