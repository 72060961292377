import React from "react"
import { Table } from "react-bootstrap"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import * as teacher from "../../../../../shared/routes/teacher"
import { AssignmentResponse } from "../../../../../shared/types/teacherTypes"
import dashboardStyle from "../../../General/general.module.scss"
import { AnalyticsClassesResponse } from "../../../../../models/analytics"
import EmptyTablePreview from "../../../General/EmptyTablePreview"

export interface AssignmentsStateProps {
    selectedClass: AnalyticsClassesResponse
}

export default function DistrictClassAssignments() {
    const location = useLocation()
    const state = location.state as AssignmentsStateProps

    const [assignments, setAssignments] = React.useState<AssignmentResponse[]>([])

    React.useEffect(() => {
        if (state.selectedClass.id) {
            teacher.getAssignments({ classID: state.selectedClass.id })
                .then(setAssignments)
        }
    }, [state])
    return (<>
        <div className="text-start mb-3">
            <Link
                style={{
                    "textDecoration": "none"
                }}
                to="/dashboard/district/classes/info"
                state={{
                    selectedClass: state.selectedClass
                }}
            >
                <i className="fas fa-arrow-left" /> Return to Class
            </Link>
        </div>

        {assignments.length > 0 ?
        <>
        <h1 className="text-start">Assignments</h1>
        <p className={`${dashboardStyle.greyText} text-start`}>Assignments assigned to this class.</p>
        <Table>
            <thead>
                <tr>
                    <th>
                        Assignment Title
                    </th>
                    <th>
                        Due Date
                    </th>
                </tr>
            </thead>
            <tbody>
                {assignments.map((assignment) => (
                    <tr key={assignment.id}>
                        <td>{assignment.title}</td>
                        <td>{new Intl.DateTimeFormat().format(new Date(assignment.dueDate))}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
        </>
        : <EmptyTablePreview title="Assignments" description="This class does not have any assignments yet." />
        }
    </>)
}
