import { createContext } from "react"

type SidebarContextType = {
    collapsed: boolean,
    toggleCollapse: () => void,
}

export const SidebarContext = createContext<SidebarContextType>({
    collapsed: false,
    toggleCollapse: null,
})
