import { useFormikContext, useField } from "formik";
import useBoolean from "helpers/useBoolean";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import styles from "./PasswordControl.module.scss"

const { visibilityToggle } = styles

export default function PasswordControl() {
    const { isSubmitting } = useFormikContext()
    const [field, meta] = useField("password")
    const [ visible, toggleVisibility ] = useBoolean(false)
    const isInvalid = !!meta.error && meta.touched

    return (<InputGroup>
        <Form.Control
            disabled={isSubmitting}
            isInvalid={isInvalid}
            {...field}
            placeholder="Password"
            type={visible ? undefined : "password"}
            autoComplete="off current-password"
            className="rounded-pill formik-input-padding"
        />

        <i
            className={`fas ${visible ? "fa-eye" : "fa-eye-slash"} my-auto ${visibilityToggle}`}
            onClick={toggleVisibility}
        />

        {isInvalid &&
            <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
        }
        </InputGroup>
    )
}
