import * as React from "react"
import { Modal, Button, Form } from "react-bootstrap"
import { toast } from "react-toastify"
import { useFormik, FormikProvider, Field } from "formik"
import * as Yup from "yup"
import * as teacher from "../../../../shared/routes/teacher"
import { StudentResponse } from "../../../../shared/types/teacherTypes"
import { Standard, getGradeLevels, getStandards } from "models/categories"

export interface Props {
    show: boolean
    onHide: () => void
    onSubmit: () => void
    student: StudentResponse
}

export default function EditStudentModal({show, onHide, onSubmit, student}: Props) {
    const formik = useFormik({
        initialValues: {
            name: student?.name ?? "",
            gradeLevel: student?.studentGradeLevel ?? "",
            standardId: student?.studentStandardId ?? ""
        },
        onSubmit: (values) => {
            return teacher.editStudent({
                uuid: student.uuid,
                ...values
            })
                .then(() => {
                    onSubmit()
                    toast.success("The student has successfully been edited.")
                })
                .catch(() => {
                    //TODO: (STEM-521) Could use some client side error reporting
                    toast.error("There has been an error editing the student. Please try again.")
                })
        },
        enableReinitialize: true,
        validationSchema: Yup
            .object()
            .shape({
                name: Yup
                    .string()
                    .label("Student Name")
                    .required(),
                gradeLevel: Yup
                    .string()
                    .label("Grade Level")
                    .optional(),
                standardId: Yup
                    .string()
                    .label("Standard")
                    .optional(),
            })
    })

    const [gradeLevels, setGradeLevels] = React.useState<string[]>([])
    React.useEffect(() => {
        getGradeLevels().then(gradeLevels => {
            setGradeLevels(gradeLevels ?? [])
        })
    }, [])

    const [standards, setStandards] = React.useState<Standard[]>([])
    React.useEffect(() => {
        getStandards().then(standards => {
            setStandards(standards ?? [])
        })
    }, [])

    return (
        <Modal show={show} onHide={onHide}>
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} className="text-start">
                    <Modal.Header className="border-0" closeButton />
                    <Modal.Body className="mx-5">
                        <h2 className="h3 text-center">Edit Student</h2>

                        <Form.Group controlId="formEditStudentName"  className="my-3">
                            <Form.Label>Student Name</Form.Label>
                            <Form.Control
                                className="mx-3"
                                name="name"
                                type="text"
                                placeholder="Enter a name for your student"
                                as={Field}
                                isInvalid={formik.touched.name && !!formik.errors.name}
                                disabled={formik.isSubmitting}
                            />
                            <Form.Control.Feedback type="invalid" className="text-center">
                                { formik.errors.name }
                            </Form.Control.Feedback>
                        </Form.Group>

                        <hr />

                        <Form.Group controlId="formEditStudentGradeLevel"  className="my-3">
                            <Form.Label>Grade Level</Form.Label>
                            <Form.Select
                                className="mx-3"
                                name="gradeLevel"
                                as={Field}
                                value={formik.values.gradeLevel}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.gradeLevel && !!formik.errors.gradeLevel}
                                disabled={formik.isSubmitting}
                            >
                                <option value="">No grade level specified</option>
                                {gradeLevels.map(grade => (
                                    <option key={grade} value={grade}>{grade}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid" className="text-center">
                                {formik.errors.gradeLevel}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formEditStudentStandard"  className="my-3">
                            <Form.Label>Standard</Form.Label>
                            <Form.Select
                                className="mx-3"
                                name="standardId"
                                type="text"
                                as={Field}
                                value={formik.values.standardId}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.standardId && !!formik.errors.standardId}
                                disabled={formik.isSubmitting}
                            >
                                <option value="">No Standard specified</option>
                                {standards.map(({ id, standard }) => (
                                    <option key={id} value={id}>{standard}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid" className="text-center">
                                {formik.errors.standardId}
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="theme" onClick={onHide}>Cancel</Button>
                        <Button 
                            variant="warning"
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.dirty
                            }
                        >Edit</Button>
                    </Modal.Footer>
                </Form>
            </FormikProvider>
        </Modal>
    )
}
