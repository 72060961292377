import * as BS from "react-bootstrap"
import styles from "./forms.module.scss"
const { googleImportCard, googleImportHeader, googleImportBody, disabled } = styles

interface Props {
    onSelect: () => void,
    header: string,
    body: string,
    disabled?: boolean
}

export default function ClassLogin({ onSelect, header, body, disabled: disabledBool }: Props) {
    return <>
            <BS.Card className={`${googleImportCard} ${ disabledBool ? disabled : ""}`} onClick={onSelect} style={{minHeight: "100%"}}>
                <BS.Card.Body className="">
                    <span className="fas fa-users fa-5x"></span>
                    <BS.Row>
                        <BS.Col sm={{ span: 8, offset: 2 }}>
                            <p className={googleImportHeader}>{header}</p>
                        </BS.Col>
                    </BS.Row>
                    <BS.Row>
                        <BS.Col sm={{ span: 8, offset: 2 }}>
                            <p className={googleImportBody}>{body}</p>
                        </BS.Col>
                    </BS.Row>
                </BS.Card.Body>
            </BS.Card>
    </>
}
