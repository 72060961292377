/**
 * Sorts an array of objects based on a given key
 * 
 * example:
 * ```js
 *   objectKeySort(
 *   { name: "Bagel" },
 *   { name: "Apple" },
 *   "name"
 *   )
 *
 *   //result:
 *   //[{ name: "Apple" },
 *   //{ name: "Bagel" }]
 * ```
 */
export default function objectKeySort<T, K extends keyof T>(ary: T[], key: K) {
    return ary.sort((i1, i2) => {
        if (i1[key] < i2[key]) {
            return -1
        } else if (i1[key] > i2[key]) {
            return 1
        }
        return 0
    })
}
