import styles from "./styles.module.scss"

export default function FilterHeader({ onClick, title } : { onClick, title: string }) {
    return (
        <div className={""}>
            <label className={`${styles.primaryText} mb-0`}>{title}</label>
            <p
                className={`${styles.clearText} float-end`}
                onClick={onClick}
            >
                Clear
            </p>
        </div>
    )
}
