import CustomHTML from "components/CustomHTML"
import { AssessmentSubmissionResponse } from "shared/types/moduleTypes"
import Pane from "components/Dashboards/General/Pane"
import { AssessmentViewOptionsType } from "shared/types/assessmentTypes"

interface Props {
    assessmentSubmission: AssessmentSubmissionResponse
    assessmentViewOption: AssessmentViewOptionsType
}

const StudentAssessmentResponses = ({ assessmentSubmission, assessmentViewOption } : Props) => {
    if (assessmentSubmission === null || assessmentViewOption === "Grade") return <></>
    const showCorrectness = assessmentViewOption === "Correct/Incorrect" || assessmentViewOption === "Answers"
    const showCorrectAnswer = assessmentViewOption === "Answers"

    return <>
        <h2 className="h3 text-start pb-3 fw-bold text-center">Assessment Responses</h2>
        <ol className="mt-3">
            {assessmentSubmission.responses?.map((response, index) => {
                const correct: boolean = response.answer === response.correctAnswer

                return (<Pane className="mb-4 shadow-sm">
                    <div className="text-start" key={`${assessmentSubmission.completed} ${index}`}>
                        <span 
                            className={"pe-2 " + (response.answer && showCorrectness ? (correct ? "text-success" : "text-danger"): "text-secondary")}>
                            <span className="fw-bold">{response.questionNumber ?? (index + 1)}. </span> 
                            <CustomHTML html={response.question} />
                            {showCorrectness && response.answer && <i className={`fas ${correct ? `fa-check text-success` : `fa-times text-danger`} ms-2`} />}
                        </span>
                        <br /><br />
                        {response.answer && 
                            <div className="mb-2"><strong>Your Answer: </strong><CustomHTML html={response.answer} /></div>}
                        {/* Only show the correct answer if correct answer for the question exists AND teacher allows student to view correct answer */}
                        {(response.correctAnswer && showCorrectAnswer && !correct && response.answer &&
                            <div className="mb-2"><strong>Correct Answer: </strong><CustomHTML html={response.correctAnswer} /></div>)}
                    </div>
                </Pane>)
            })}
        </ol>
    </>
}

export default StudentAssessmentResponses
