import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import AssignmentComponent from "../../../Assignment/AssignmentComponent"
import styles from "../styles.module.scss"
import { TeacherGradesState } from "../GradeList/TeacherGrades"
import { AssignmentContext, AssignmentSection } from "components/Assignment/AssignmentContext"
import { TeacherAssignmentResponse } from "../../../../shared/types/teacherTypes"
import { LessonResponse } from "shared/lessons"
import React, { useEffect } from "react"
import moduleRoutes from "shared/routes/moduleRoutes"

export class AssignmentPreviewStateProps { // class so that route state helper function can read all properties
    constructor() {
        this.lesson = null
    }
    assignment: TeacherAssignmentResponse
    lesson?: LessonResponse
}
/**
 * @abstract Renders assignment preview
 * NOTE: Currently being used when teacher clicks on assignment and then clicks "View Assignment"
 */
export default function Preview() {
    const location = useLocation()
    const state = location.state as AssignmentPreviewStateProps

    // if it has assessment but no lesson, than its assessment only assignment
    const [activePart, setActivePart] = React.useState<AssignmentSection>((state.assignment.assessmentId && !state.assignment.lessonId) ? "assessment" : "lesson")

    const TeacherGradesState: TeacherGradesState = {
        assignment: state.assignment,
        lesson: state.lesson
    }
    const assignmentValues = {
        activePart: activePart,
        setActivePart: setActivePart,
        moduleName: state.lesson?.moduleName || state.assignment.moduleName,
        lessonVariety: state.assignment.lessonVariety,
        type: state.lesson?.type || state.assignment.pureInquiryType,
        guidedLesson: state.lesson,
    }

    useEffect(() => {
        moduleRoutes.checkModuleAccess(state.lesson?.moduleName)
        .then(userHasAccess => {
            if (!userHasAccess) {
                return window.location.href = `/simulations/moduleName=${state.lesson?.moduleName}/tab=challenge`
            }
        })
    }, [state.lesson?.moduleName])

    return <>
        <div className="text-start my-3">
            <Link
                style={{
                    "textDecoration": "none"
                }}
                to="../"
                state={TeacherGradesState}
            >
                <i className="fas fa-arrow-left" /> Return to Submissions
            </Link>
        </div>
        <div className={styles.previewBottom}>
            <AssignmentContext.Provider value={assignmentValues}>
                <AssignmentComponent
                    assessmentId={state.assignment.assessmentId}
                    moduleName={state.lesson?.moduleName || state.assignment.moduleName}
                    assessmentNumber={state.assignment.assessment?.number}
                    lessonVariety={state.assignment.lessonVariety}
                    type={state.lesson?.type || state.assignment.pureInquiryType}
                ></AssignmentComponent>
            </AssignmentContext.Provider>
        </div>
    </>
}
