import { Table } from "react-bootstrap"
import styles from "./StudentRecords.module.scss"
import { Type } from "shared/types/moduleTypes"
import React, { useState } from "react"
import { useNavigate } from "react-router"
import * as teacherRoute from "shared/routes/teacher"
import * as assignmentsRoute from "shared/routes/assignments"

interface LessonQuestionRecord {
    moduleName: string
    lessonNumber: number
    time: string 
    dateSubmitted: string
    questions: []
    dataTarget: string
    type: Type
    submissionId: string | null
    assignmentId: string | null
}

const LessonQuestionsTab = ({ lessonQuestionRecords } : { lessonQuestionRecords: LessonQuestionRecord[] }) => {
    const navigate = useNavigate()
    const [selectedRecord, setSelectedRecord] = useState<string>(null)

    const navigateToSubmission = async (record: LessonQuestionRecord) => {
        const assignment = await teacherRoute.findAssignmentById(record.assignmentId)
        const submissionsResponse = await assignmentsRoute.getGrades({ assignmentId: record.assignmentId })

        const currentIndex = submissionsResponse.data.findIndex(res => res.submission.id === record.submissionId)

        navigate("/dashboard/teacher/assignments/grading/submission", {
            state: {
                submissions: submissionsResponse.data,
                assignment: assignment,
                currentIndex: currentIndex
            }
        })
    }

    const onClickRow = (dataTarget: string) => {
        setSelectedRecord(prev => prev === dataTarget ? null : dataTarget)
    }

  return (
    <div className={`mt-4 mx-auto ${styles.row} overflow-auto`}>
            <Table>
                <thead>
                    <tr>
                        <th>Simulation&nbsp;Name</th>
                        <th>Lesson&nbsp;Number</th>
                        <th>Time&nbsp;Taken</th>
                        <th>Date&nbsp;Submitted</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {lessonQuestionRecords?.length !== 0 ? lessonQuestionRecords?.map((record, i) => {
                        return (
                        <React.Fragment key={`${record.dataTarget}-${i}`}>
                            <tr className={styles.tableRowBackground} role="button" onClick={() => onClickRow(`${record.dataTarget}-${i}`)}>
                                <td>{record.moduleName}</td>
                                <td>{record.lessonNumber} - {record.type}</td>
                                <td>{record.time}</td>
                                <td>{record.dateSubmitted}</td>
                                {record.assignmentId ? 
                                    <td className="d-flex justify-content-between align-items-center">
                                        <span role="button" className="text-primary" onClick={() => navigateToSubmission(record)}>View&nbsp;Submission</span>
                                        <i className="fas fa-chevron-down ms-auto" />
                                    </td> : <td></td>
                                }
                            </tr>
                            <LessonRecordRow record={record} i={i} selectedRecord={selectedRecord} />
                        </React.Fragment>)
                    }) : <tr>
                            <td colSpan={4}>There is no data yet.</td>
                        </tr>}
                </tbody>
            </Table>
    </div>
  )
}

const LessonRecordRow = ({ record, i, selectedRecord }) => {
    const recordId = `${record.dataTarget}-${i}`
    return (
        <tr key={recordId} className={`${styles.childTable} ${selectedRecord === recordId ? `${styles.appear}` : `${styles.disappear}`}`}>
            {selectedRecord === recordId &&
                <td colSpan={12} className="p-0">
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>Question&nbsp;Number</th>
                                <th>Question</th>
                                <th>Submission</th>
                            </tr>
                        </thead>
                        <tbody>
                            {record.questions?.map(question => {
                                return (
                                    <tr key={question.questionNumber}>
                                        <td>{question.questionNumber}</td>
                                        <td className="text-start">{question.question}</td>
                                        <td>{question.answer}</td>
                                    </tr>
                                )
                            })} 
                        </tbody>
                    </Table>
                </td>}
        </tr>
    )
}

export default LessonQuestionsTab
