import * as React from "react"
import { Modal, Button } from "react-bootstrap"
import { toast } from "react-toastify"
import * as teacher from "../../../../shared/routes/teacher"
import { ClassResponse } from "../../../../shared/types/teacherTypes"

export interface Props {
    activeClass: ClassResponse
    show: boolean
    onHide: () => void
    onSuccess: () => void
}

export default function ArchiveClass({ activeClass, onSuccess, onHide, show }: Props) {
    return(
        <Modal show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <h1 className="h3 w-100 text-center">Archive {activeClass.className}</h1>
            </Modal.Header>
            <Modal.Body className="mx-5 text-start">
                <p>After archiving, your students will no longer be able to login and do assignments. You won't be able to add new students or assign assignments to the class. You can view this class in your "Archived Classes".</p>
                <p><strong>Are you sure you would like to archive {activeClass.className}?</strong></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="theme" onClick={onHide}>Cancel</Button>
                <ArchiveButton
                    variant="danger"
                    onClick={ () => {
                        return teacher.updateClass({
                            id: activeClass.id,
                            archived: "Yes",
                            studentAssessmentViewOption: activeClass.studentAssessmentViewOption
                        })
                            .then(() => {
                                onHide()
                                toast.success("The class has successfully been archived.")
                                onSuccess()
                            })
                            .catch(() => {
                                onHide()
                                //TODO: (STEM-521) Could use some client side error reporting
                                toast.error("There has been an error archiving the class, please try again.")
                            })
                    }}
                />
            </Modal.Footer>
        </Modal>
    )
}

export function ArchiveButton({onClick, variant = "theme"}: { onClick: () => void, variant?: string }) {
    return (
        <Button onClick={onClick} variant={variant}>
            <i className="fas fa-archive pe-2" />Archive Class
        </Button>
    )
}

export function UnArchiveButton({onClick, variant = "theme"}: { onClick: () => void, variant?: string }) {
    return (
        <Button onClick={onClick} variant={variant}>
            <i className="fas fa-archive pe-2" />Unarchive Class
        </Button>
    )
}
