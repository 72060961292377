import React from "react"
import { Table } from "react-bootstrap"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import * as teacher from "../../../../../shared/routes/teacher"
import { StudentResponse } from "../../../../../shared/types/teacherTypes"
import dashboardStyle from "../../../General/general.module.scss"
import { AnalyticsClassesResponse } from "../../../../../models/analytics"
import EmptyTablePreview from "../../../General/EmptyTablePreview"

export interface StudentsStateProps {
    selectedClass: AnalyticsClassesResponse
}

export default function DistrictClassStudents() {
    const location = useLocation()
    const state = location.state as StudentsStateProps

    const [students, setStudents] = React.useState<StudentResponse[]>([])

    React.useEffect(() => {
        if (state.selectedClass.id) {
            teacher.getStudents({ classID: state.selectedClass.id })
                .then(setStudents)
                .catch(() => {
                    toast.error("There has been an error loading the students.")
                })
        }
    }, [state])

    return (<>
        <div className="text-start mb-3">
            <Link
                style={{
                    "textDecoration": "none"
                }}
                to="/dashboard/district/classes/info"
                state={state}
            >
                <i className="fas fa-arrow-left" /> Return to Class
            </Link>
        </div>

        {students.length > 0 ?
        <>
        <h1 className="text-start mt-3">Student Roster</h1>
        <p className={`${dashboardStyle.greyText} text-start`}>All the students in the selected class.</p>
        <Table>
            <thead>
                <tr>
                    <th>
                        Student Name
                    </th>
                </tr>
            </thead>
            <tbody>
                {students.map((student) => (
                    <tr key={student.uuid}>
                        <td>{student.name}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
        </>
        : <EmptyTablePreview title="Student Roster" description="This class does not have students yet." />
        }
    </>)
}
