import React from "react"

export default function Footer() {
    return <footer className = "container-fluid margin-container max-width hidden-print skiptranslate">
        <hr />
        <div className = "row text-center social-links">
            <div className = "col-6 d-flex flex-row-reverse g-1">
                <a href = "https://facebook.com/stemsims" rel="noreferrer" target = "_blank">
                    <img src = "/images/facebook.png" alt = "Facebook" title = "Like STEM Sims on Facebook" />
                </a>
            </div>
            <div className = "col-6 d-flex g-1">
                <a href = "https://twitter.com/stemsims" rel="noreferrer" target = "_blank">
                    <img src = "/images/twitter.png" alt = "Twitter" title = "Follow STEM Sims on Twitter" />
                </a>
            </div>
        </div>
        <div className = "row">
            <p>© {new Date().getFullYear()} STEM Sims</p>
            <p><a href = "/legal/terms-of-use">Terms of Use</a> - <a href = "/legal/privacy-policy">Privacy Policy</a> - <a href = "/about/credits">Credits</a></p>
        </div>
    </footer>
}
