import { useState } from "react"
import Button from "react-bootstrap/esm/Button"
import Modal from "react-bootstrap/esm/Modal"

export default function VideoModalButton({ videoTitle, videoUrl } : { videoTitle: string, videoUrl: string }) {
    const [show, setShow] = useState(false)

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    return (
        <>
            <Button onClick={handleShow}>{videoTitle}</Button>
            <Modal show={show} onHide={handleClose} size="xl" centered>
                <Modal.Body style={{ 'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto' }}>
                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                        <iframe
                            title={videoTitle}
                            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                            src={`${videoUrl}?rel=0`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
