import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import { ClassResponse } from "../../../../shared/types/teacherTypes"
import checkRouteState from "helpers/checkRouteState"
import TeacherAssignmentGrades, { TeacherGradesState } from "../../../Lessons/Grading/GradeList/TeacherGrades"
import TeacherAssignmentStudentSubmission, { SubmissionTeacherState } from "pages/Dashboard/Teacher/Grades/Submission/SubmissionTeacher"
import TeacherAssignmentPreview, { AssignmentPreviewStateProps } from "../../../Lessons/Grading/AssignmentPreview/Preview"

// We need to have this extra Routes layer due to a bug in the current relative navigation design
// navigated to ../ sends you up a Router instead of a directory
// https://github.com/remix-run/react-router/issues/8350
export default function AssignmentGrading({ activeClass }: { activeClass: ClassResponse }) {
    const location = useLocation()

    // if user refreshes or comes from different page, will redirect or get state from localStorage
    const validateGradingRoute = (routeStateClass, Component, url="/dashboard/teacher/assignments") => {
        const validStateSubmission = checkRouteState(location?.state, routeStateClass)
        if(validStateSubmission) {
            location.state = validStateSubmission
            return Component
        }
        return <Navigate to={url} /> 
    }
    return (
        <>
            <Routes>
                <Route path="/submission" element={validateGradingRoute(SubmissionTeacherState, <TeacherAssignmentStudentSubmission activeClass={activeClass} />)
                } />
                <Route path="/preview" element={validateGradingRoute(AssignmentPreviewStateProps, <TeacherAssignmentPreview />)} />
                <Route path="/" element={validateGradingRoute(TeacherGradesState, <TeacherAssignmentGrades isLTI={false} />)} />
            </Routes>
        </>
    )
}
