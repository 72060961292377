import { Table } from "react-bootstrap"
import styles from "./StudentRecords.module.scss"
import React, { useState } from "react"

interface PlanningRecord {
    moduleName: string
    time: string 
    dateSubmitted: string
    questions: []
    dataTarget: string
}

const PlanningQuestionsTab = ({ planningRecords } : { planningRecords: PlanningRecord[] }) => {

    const [selectedRecord, setSelectedRecord] = useState<string>(null)

    const onClickRow = (dataTarget: string) => {
        setSelectedRecord(prev => prev === dataTarget ? null : dataTarget)
    }

  return (
    <div className={`mt-4 mx-auto ${styles.row} overflow-auto`}>
            <Table>
                <thead>
                    <tr>
                        <th>Simulation&nbsp;Name</th>
                        <th>Time&nbsp;Taken</th>
                        <th>Date&nbsp;Submitted</th>
                    </tr>
                </thead>
                <tbody>
                    {planningRecords?.length !== 0 ? planningRecords?.map((record, i) => {
                        return (
                        <React.Fragment key={`${record.dataTarget}-${i}`}>
                            <tr className={styles.tableRowBackground} role="button" onClick={() => onClickRow(`${record.dataTarget}-${i}`)}>
                                <td>{record.moduleName}</td>
                                <td>{record.time}</td>
                                <td className="d-flex justify-content-between align-items-center">
                                    <span className="ms-auto">{record.dateSubmitted}</span>
                                    <i className="fas fa-chevron-down ms-auto" />
                                </td>
                            </tr>
                            <PlanningRecordRow record={record} i={i} selectedRecord={selectedRecord} />
                        </React.Fragment>)
                    }) : <tr>
                            <td colSpan={3}>There is no data yet.</td>
                        </tr>}
                </tbody>
            </Table>
    </div>
  )
}

const PlanningRecordRow = ({ record, i, selectedRecord }) => {
    const recordId = `${record.dataTarget}-${i}`
    return (
        <tr key={recordId} className={`${styles.childTable} ${selectedRecord === recordId ? `${styles.appear}` : `${styles.disappear}`}`}>
            {selectedRecord === recordId &&
                <td colSpan={12} className="p-0">
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>Question&nbsp;Number</th>
                                <th>Submission</th>
                            </tr>
                        </thead>
                        <tbody>
                            {record.questions?.map(question => {
                                return (<tr key={question.questionNumber}>
                                            <td>{question.questionNumber}</td>
                                            <td>{question.answer}</td>
                                        </tr>
                                )
                            })} 
                        </tbody>
                    </Table>
                </td>}
        </tr>
    )
}

export default PlanningQuestionsTab
