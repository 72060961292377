import React from "react"
import Button from "react-bootstrap/Button"
import Papa from "papaparse"

interface Props {
    fileName: string
    title: string
    data: Record<any, any>[]
}

export default function CsvExport({ fileName, title, data }: Props) {
    const [dataURI, setDataURI] = React.useState<string>("")
    React.useEffect(() => {
        const csvClasses = Papa.unparse(data)
        const blob = new Blob([csvClasses], { type: "text/csv" })
        setDataURI(URL.createObjectURL(blob))
    }, [data])
    return (
        <Button
            className="float-end"
            variant="theme"
            as="a"
            download={fileName}
            href={dataURI}
        >
            <i className="fas fa-download pe-2" />
            {title}
        </Button>
    )
}
