import Col from "react-bootstrap/Col"
import EditLesson from "./EditLesson"
import { Field } from "formik"
import CustomHTML from "components/CustomHTML"
import styles from "./styles.module.scss"
import { LessonResponse as Lesson } from '../../../shared/lessons'

type Props = {
    lesson: Lesson,
    currentSection: number
    refreshCallback: () => Promise<unknown>
}

const LessonIntro = ({lesson,currentSection, refreshCallback}: Props) => {
    const editing = process.env.REACT_APP_EDITING === "true"

    return(<>
        <Col className={`text-start my-3 mx-3`}>
            {/* Summary with title */}
            {lesson.title && <>
                <h1 className="h4 text-start pb-3">Lesson { lesson.number }: <CustomHTML html={lesson.title} /></h1>
            </>}
            <CustomHTML html={lesson.description} />
            <br/>
            {editing && <div className="mb-3">
                <EditLesson text="Edit Summary" lesson={lesson} size={'lg'} callback={refreshCallback}>
                    <h2>Title</h2>
                    <Field as={'textarea'} name="title" className={`${styles.lessonInput} col-10`} defaultValue={lesson.title} />
                    <h2>Body</h2>
                    <Field as={'textarea'} name="description" className={`${styles.lessonInput} col-10`} defaultValue={lesson.description} rows={20} />
                </EditLesson>
            </div>}
            <br/>
        </Col>
    </>)
}
export default LessonIntro
