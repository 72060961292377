import FloatingPage from "components/General/FloatingPage"
import styles from "./thanks.module.scss"
import { Row, Col } from "react-bootstrap";
import ReactPixel from 'react-facebook-pixel'
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import getPrice from "../Subscribe/PricingCalc"

function ThankYouPage() {

    const { productName, quantity } = useParams()

    useEffect(() => {
        if (productName) {
            ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
            const parsedQuantity = parseInt(quantity);
            const validQuantity = isNaN(parsedQuantity) ? 0 : parsedQuantity;
            if (validQuantity < 1) {
                return;
            }
            ReactPixel.track('Purchase', {currency: "USD", value: getPrice(productName, validQuantity)})
        }
    }, [productName, quantity]);

    return (
        <FloatingPage className={styles.background}>
            <Row>
                <Col xs={12} lg={6}>
                    <img src="/images/thanks-bg.png" alt="STEM Sims logo" className="w-100 rounded mb-4" />
                </Col>
                <Col xs={12} md={6} className="text-start px-4">
                    <h1>Thank you for your purchase!</h1>
                    <p className="fs-5">Your {productName && productName + " "}plan has been activated. You can now access your account and start using STEM Sims.</p>
                    <p className="fs-5">To get started, you can visit our onboarding quiz <a href="/onboarding/quiz">here</a>.</p>
                </Col>
            </Row>
            
        </FloatingPage>
    )
}

export default ThankYouPage;