import { ClassResponse } from "shared/types/teacherTypes"
import { useModel } from "@stem-sims/nexus"
import modules from "shared/routes/moduleRoutes"
import React from "react"
import { Duration, Timespan } from "models/analytics"
import { BeatLoader } from "react-spinners"
import { Col, Form, Row } from "react-bootstrap"
import EnumOption from "components/General/EnumOption"
import styles from "../District/Progress/progress.module.scss"
import StandardsMasteryGraph from "../District/Progress/components/StandardsMasteryGraph"
import StandardsMasteryTable from "../District/Progress/components/StandardsMasteryTable"
import Pane from "../General/Pane"
import analyticsModel from "../../../models/analytics"
import OvertimeGraph from "../District/Progress/components/OvertimeGraph"
import WeeklyHeatMap from "components/General/WeeklyHeatMap"
import DurationSelector from "../General/DurationSelector"

export type TeacherGraph = "usage" | "activity" | "standardsProgress"

export default function Analytics({ activeClass }: { activeClass: ClassResponse }) {
    const [duration, setDuration] = React.useState<Duration>("7days")
    const [startDate, setStartDate] = React.useState(new Date())
    const [timespan, setTimespan] = React.useState<Timespan>("day")
    const [selectedGraph, setSelectedGraph] = React.useState<TeacherGraph>("standardsProgress")
    const endDate = React.useMemo(() => {
        const endDate = new Date()
        return endDate
    }, [])

    React.useEffect(() => {
        const date = new Date()
        date.setDate(date.getDate() - parseInt(duration))
        setStartDate(date)
        if (duration === "7days") {
            setTimespan("day")
        } else {
            setTimespan("week")
        }
    }, [duration])

    const { response: progress, loading: loadingMastery } = useModel({
        model: modules.getClassStandardsMastery,
        props: {
            startDate: startDate,
            classId: activeClass.id
        }
    })

    const { response: analytics, loading: loadingUsage } = useModel({
        model: analyticsModel.getActivity,
        props: {
            start: startDate,
            end: endDate,
            timespan
        }
    })

    const loading = (selectedGraph === "standardsProgress" && loadingMastery) || (selectedGraph !== "standardsProgress" && loadingUsage)

    return <>
        <h1 className="text-start mt-3">Class Analytics</h1>
        <Row className="mb-3">
            <Col sm="3">
                <Form.Select
                    className={styles.dropdowns}
                    defaultValue={selectedGraph}
                    onChange={(event) => {
                        setSelectedGraph(event.currentTarget.value as TeacherGraph)
                    }}
                >
                    <EnumOption<TeacherGraph>
                        value="activity"
                    >
                        Student Activity Over Time
                    </EnumOption>
                    <EnumOption<TeacherGraph>
                        value="usage"
                    >
                        Student Usage Per Time of Day
                    </EnumOption>
                    <EnumOption<TeacherGraph>
                        value="standardsProgress"
                    >
                        Standards Progress and Mastery
                    </EnumOption>
                </Form.Select>
            </Col>

            <Col sm="2">
                    <DurationSelector
                        setDuration={setDuration}
                        initialDuration={duration}
                    />
            </Col>
        </Row>
        <Pane>
            {loading && <>
                <BeatLoader />
            </>}

            {!loading && <>
                <Row>
                    <Col xl={{
                        span: 8,
                        offset: 2
                    }}>

                        {selectedGraph === "standardsProgress" && <>
                            <h3>Student Standards Mastery</h3>
                            <StandardsMasteryGraph bars={progress.data.standards} />
                            <StandardsMasteryTable data={progress.data.students} title="Student Name" />
                        </>}

                        {selectedGraph === "activity" && <>
                            <h3>Student Activity</h3>
                            <OvertimeGraph
                                activity={analytics?.activity?.student}
                                fillColor="#3F4BBC"
                                start={startDate}
                                end={endDate}
                                timespan={timespan}
                            />
                        </>}

                        {selectedGraph === "usage" &&
                            <WeeklyHeatMap weekInformation={analytics?.usage.student} baseColor={"#3F4BBC"} title="Student Usage" />
                        }
                    </Col>
                </Row>
            </>}
        </Pane>
    </>
}
