import React from "react"
import Table from "react-bootstrap/Table"
import roundToFixed from "shared/helpers/roundToFixed"
import { OrganizationStandardsMasteryGetResponse } from "shared/types/moduleTypes"
import { colors } from "./MasterySharedGlobals"
import { numberToMasteryGrade } from "shared/helpers/masteryGradeConverter"
import ToolTip from "components/General/ToolTip"
import styles from "./usage.module.scss"

interface Props {
    data: OrganizationStandardsMasteryGetResponse["data"]["classes"]
    // this is the title that shows up in the table
    // ie. Student Name, Class Name
    title: string
}

export default function StandardsMasteryTable({ data, title }: Props) {
    //get all standards for the table header
    const possibleStandards = React.useMemo(() => {
        const { result } = data.reduce((acc, item) => {
            item.standards.forEach((standard) => {
                if (!acc.map[standard.id]) {
                    acc.map[standard.id] = true
                    acc.result.push({
                        id: standard.id,
                        name: standard.name,
                        description: standard.description
                    })
                }
            })
            return acc
        }, {
            map: {},
            result: []
        })
        return result
    }, [data])

    return <>
        {possibleStandards.length > 0 ?
            <Table style={{borderCollapse: 'separate', borderSpacing: 'initial'}} responsive>
                <thead>
                    <tr>
                        <th className={styles.stickyCol}>{title}</th>
                        <th>Overall</th>
                        {possibleStandards.map((standard) => {
                            return <th>
                                <ToolTip title={standard.description}>
                                    <span>{standard.name}</span>
                                </ToolTip>
                            </th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => {
                        const standardMap = item.standards.reduce((map, standard) => {
                            map[standard.id] = standard
                            return map
                        }, {})
                        return <tr>
                            <th className={styles.stickyCol}>{item.name}</th>
                            <th>{item.overall !== null ? roundToFixed(item.overall, 2) : "N/A"}</th>
                            {possibleStandards.map((standard) => {
                                return (
                                    <th>{standardMap[standard.id] && standardMap[standard.id].average !== null ?
                                        <div style={{
                                            borderRadius: "3px",
                                            backgroundColor: (() => {
                                                const avg = standardMap[standard.id].average
                                                return colors[numberToMasteryGrade(avg)]
                                            })(),
                                            color: standardMap[standard.id].average < 2 ? "white" : "black"
                                        }}>
                                            {roundToFixed(standardMap[standard.id].average, 2)}
                                        </div>
                                        : "N/A"}
                                    </th>
                                )
                            })}
                        </tr>
                    })}
                </tbody>
            </Table>
        : <div>No lessons correlated with core standards have been submitted</div>}
    </>
}
