
export default function expandKeys(object: Record<string, any>) {
    const keys = Object.keys(object)
    let newResponse = {}
    for (let key of keys) {
        //convert key from camelcase to to spaces
        //reference https://stackoverflow.com/a/58845751/8082502
        let newKey = key.replace(/([A-Z](?=[a-z]+)|[A-Z]+(?![a-z]))/g, ' $1').trim()
        newKey = newKey[0].toUpperCase() + newKey.slice(1)

        newResponse[newKey] = object[key]
    }
    return newResponse
}
