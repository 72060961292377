import * as React from "react"
import BeatLoader from "react-spinners/BeatLoader"
import { useLocation } from "react-router-dom"
import * as auth from "../../models/auth"
import { AuthContext } from "AuthContext"

const AuthRoute: React.FC<{ permission: auth.Permission, trackingEnabled?: boolean }> = ({ children, permission, trackingEnabled }) => {
    const location = useLocation()
    const [isLoaded, setIsLoaded] = React.useState(false)
    const [authInfo, setAuthInfo] = React.useState<auth.InformationReturn>({
        isLoggedIn: false,
        emailVerified: false,
        isStudent: false,
        isAdmin: false,
        isStaff: false,
        trackingEnabled: false,
        hasCurriculumAccess: false,
        csrfToken: "",
        permissions: [],
        triggerAuthRefresh: null
    })

    React.useEffect(() => {
        setIsLoaded(false)
        void auth.information()
            .then((response) => {
                setAuthInfo(response)
                if (response.permissions?.includes(permission)) {
                    if (trackingEnabled && response.trackingEnabled !== trackingEnabled) {
                        window.location.href = "/"
                    }
                    else {
                        setIsLoaded(true)
                    }
                } else {
                    if (response.isLoggedIn) {
                        window.location.href = "/"
                    } else {
                        window.location.href = "/account/log-in"
                    }
                }
            })
    }, [location, permission, trackingEnabled])

    if (isLoaded === false) {
        return (
            <div className="d-table min-vh-100 mx-auto">
                <div className="d-table-cell text-center align-middle">
                    <BeatLoader size={15} />
                </div>
            </div>
        )
    }

    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthRoute
