import CustomHTML from "components/CustomHTML"
import Table from "react-bootstrap/Table"
import styles from "./ModulePage.module.scss"

type BackgroundProps = { backgrounds: {title?: string; text?: string; table?: any; image?:  any}[], modulePath: string}

const BackgroundTab = ({backgrounds, modulePath}: BackgroundProps) => {
  return (
    <div className={`text-start mt-5 ${styles.container}`}>
        {backgrounds?.map((background, i) => 
            <div key={i}>
                <h3><CustomHTML html={background?.title} /></h3>
                <p><CustomHTML html={background?.text} /></p>
                {background?.table &&
                    <Table className={styles.backgroundTable} bordered>
                        {background.table.map((row, rowIndex) => <tr key={rowIndex}>
                            {row && row.map((cell, cellIndex) => cell && <td key={cellIndex} className="p-2"><CustomHTML html={cell} /></td>)}
                        </tr>)}
                    </Table>}
                {background?.image &&
                    <div className="d-flex justify-content-center">
                       {background.image?.caption && <div><CustomHTML html={background.image.caption} /></div>}
                       <img
                        className="text-center d-block img-fluid"
                        src={`${modulePath}background/background-${background.image?.backgroundNumber}-image-${background.image?.imageNumber}.png?version=${background.image?.updated }`} 
                        alt={`${background.image?.altText ?? "Supplemental Background Figure"}`} />
                        {background.image?.captionSource && <p>{background.image.captionSource}</p>}
                    </div>}
            </div>
        )} 
    </div>
  )
}

export default BackgroundTab
