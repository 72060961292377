/**
 * A tagged template literal that URI encodes the string variables
 * 
 * Example:
 * ```js
 * encodeURI`/this/is/a/${"test space"}/string` // --> '/this/is/a/test%20space/string'
 * ```
 */
export default function encodeURI(strings: TemplateStringsArray, ...expressions: string[]) {
    return expressions.reduce((acc, i, index) => {
        return acc + encodeURIComponent(i) + strings[index + 1]
    }, strings[0])
}
