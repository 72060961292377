import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Pane from "../../../General/Pane"
import dashboardStyle from "../../../General/general.module.scss"
import classStyles from "./info.module.scss"
import analytics, { AnalyticsClassesResponse } from "../../../../../models/analytics"
import CsvExport from "../../../../General/CsvExport"
import expandKeys from "../../../../../helpers/expandKeys"
import { Button } from "react-bootstrap"
import { toast } from "react-toastify"
import roundToFixed from "shared/helpers/roundToFixed"

function calculateLetterGrade(grade: number) {

    if (grade === undefined || grade === null) {
        return ""
    }

    if (grade >= 90) {
        return "A"
    }
    else if (grade >= 80) {
        return "B"
    }
    else if (grade >= 70) {
        return "C"
    }
    else if (grade >= 60) {
        return "D"
    }
    else {
        return "F"
    }
}

export interface InfoStateProps {
    selectedClass: AnalyticsClassesResponse
    referrerUrl: string
    initialSearch: string
    searchOrder: keyof AnalyticsClassesResponse
}

export default function Info() {
    const location = useLocation()
    const navigate = useNavigate()
    const [isWatched, setIsWatched] = React.useState(false)
    const state = location.state as InfoStateProps
    const { selectedClass, referrerUrl } = state
    if (!selectedClass) {
        navigate(referrerUrl ?? "/dashboard/district/classes")
    }

    React.useEffect(() => {
        analytics.getWatched()
        .then((watchedClasses) => {
            const watchedClassIds = watchedClasses.watched.map((watchedClass) => watchedClass.id)
            setIsWatched(watchedClassIds.includes(selectedClass.id))
        })
    }, [selectedClass])

    return (
        <div>
            <div className="text-start mb-3">
                <Link
                    style={{
                        "textDecoration": "none"
                    }}
                    to={referrerUrl ?? "/dashboard/district/classes"}
                    state={{
                        initialSearch: state.initialSearch,
                        searchOrder: state.searchOrder
                    }}
                >
                    <i className="fas fa-arrow-left" /> Return to Class Search
                </Link>
            </div>
            <Button
                className="float-end ms-4"
                variant="theme"
                onClick={() => {
                    if (!isWatched) {
                        analytics.createWatched({
                            classId: selectedClass.id
                        })
                        .then(() => {
                            setIsWatched(true)
                        })
                        .catch(() => {
                            toast.error("There was an error watching the class. Please try again.")
                        })
                    }
                    else {
                        analytics.deleteWatched({
                            classId: selectedClass.id
                        })
                        .then(() => {
                            setIsWatched(false)
                        })
                        .catch(() => {
                            toast.error("There was an error un-watching the class. Please try again.")
                        })
                    }
                }}
            >
                {isWatched && <>
                    <i className="fas fa-eye pe-2" />
                    Watching Class
                </>}
                {!isWatched && <>
                    <i className="fas fa-star pe-2" />
                    Watch Class
                </>}
            </Button>
            <CsvExport
                title="Download Summary"
                fileName={`${selectedClass.className}.csv`}
                data={[expandKeys(selectedClass)]}
            />
            <h1 className="text-start">{selectedClass?.className}</h1>
            <p className={`${dashboardStyle.greyText} text-start`}>Class analytics containing progress information for the class.</p>
            <Row>
                <Col>
                    <Pane>
                        <div className="text-start">
                            <Row
                                className="float-end"
                                style={{
                                    backgroundColor: "rgba(63,75,188, .2)",
                                    marginRight: "-15px"
                                }}
                            >
                                <Col sm={3}>
                                    <strong style={{ fontSize: "45px" }}>{calculateLetterGrade(selectedClass?.classAverage)}</strong>
                                </Col>
                                <Col>
                                    <div><span className="h3">{roundToFixed(selectedClass?.classAverage ?? 0, 2)}%</span></div>
                                    <p className={`${dashboardStyle.greyText}`}>Average Class <br /> Grade</p>
                                </Col>
                            </Row>
                            <h2>Overview</h2>
                            <Row>
                                <Col sm={3}>
                                    { selectedClass?.customerName && <p><strong>Teacher Name:</strong></p> }
                                    { selectedClass?.gradeLevel && <p><strong>Grade Level:</strong></p> }
                                    { selectedClass?.totalStudents !== null && <p><strong>Student Total:</strong></p> }
                                    { selectedClass?.standards && <p><strong>Standards Covered:</strong></p> }
                                </Col>
                                <Col className="text-start">
                                    { selectedClass?.customerName && <p>{selectedClass.customerName}</p> }
                                    { selectedClass?.gradeLevel && <p>{selectedClass.gradeLevel}</p> }
                                    { selectedClass?.totalStudents !== null && <p>{selectedClass.totalStudents}</p> }
                                    { selectedClass?.standards && <p>{selectedClass.standards}</p> }
                                </Col>
                            </Row>
                        </div>
                    </Pane>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={{
                    span: 4
                }}>
                    <Card
                        className={classStyles.actionCards}
                        role="button"
                        onClick={() => {
                            navigate("/dashboard/district/classes/students", {
                                state
                            })
                        }}
                    >
                        <Card.Body>
                            <i className="fas fa-users"></i>
                            <br />
                            Student Roster
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={{
                    span: 4
                }}>
                    <Card
                        className={classStyles.actionCards}
                        role="button"
                        onClick={() => {
                            navigate("/dashboard/district/classes/assignments", {
                                state
                            })
                        }}
                    >
                        <Card.Body>
                            <i className="far fa-file-alt"></i>
                            <br />
                            Assignments
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={{
                    span: 4
                }}>
                    <Card
                        className={classStyles.actionCards}
                        role="button"
                        onClick={() => {
                            navigate("/dashboard/district/classes/progress", {
                                state
                            })
                        }}
                    >
                        <Card.Body>
                            <i className="fas fa-chart-bar"></i>
                            <br />
                            Class Progress
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
