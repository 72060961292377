import { AnalyticsActivityResponse, AnalyticsActivityProps } from "../../../../../models/analytics"
import OvertimeGraph from "./OvertimeGraph"

export interface Props {
    start: AnalyticsActivityProps["start"]
    end: AnalyticsActivityProps["end"]
    timespan: AnalyticsActivityProps["timespan"]
    activity: AnalyticsActivityResponse["activity"]
}

export default function DistrictActivity({ activity, start, end, timespan }: Props) {
    return (
        <>
            <h3>Teacher Activity</h3>
            <OvertimeGraph
                activity={activity?.teacher}
                fillColor="#C4C7EA"
                start={start}
                end={end}
                timespan={timespan}
            />

            <h3>Student Activity</h3>
            <OvertimeGraph
                activity={activity?.student}
                fillColor="#3F4BBC"
                start={start}
                end={end}
                timespan={timespan}
            />

        </>
    )
}
