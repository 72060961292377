import styles from "./styles.module.scss"
import { LessonResponse as Lesson } from '../../../shared/lessons'
import { sanitizePath } from "shared/helpers/moduleHelper"

type LessonHeaderProps = {
    lesson: Lesson
}

const LessonHeader = ({lesson}: LessonHeaderProps) => {
    return(<>
        {lesson && 
            <div className={`${styles.heightFit}`}>
                <img src={`/simulations/${ sanitizePath(lesson.moduleName) }/lessons/header.png`} className="img-fluid center-block" alt={lesson.moduleName}/>
            </div>
        }
    </>)
}

export default LessonHeader
