import { useEffect } from "react"
import Question from "./Question"
import Table from "./Table"
import styles from "./styles.module.scss"
import moduleRoutes from "shared/routes/moduleRoutes"

interface Props {
    trackingEnabled: boolean
    type: "Tier 1" | "Tier 2" | "AP Tier"
    module: string
    questionAnswers: string[]
    setQuestionAnswers: (answers: string[]) => void
    tableAnswers: string[]
    setTableAnswers: (answers: string[]) => void
    onBlur?: () => void
}

const PureInquiryQuestionList = ({ trackingEnabled, type, module, questionAnswers, setQuestionAnswers, tableAnswers, setTableAnswers, onBlur }: Props) => {
    const setQuestionAnswer = (index: number, value: string) => {
        const values = [...questionAnswers]
        values[index] = value
        setQuestionAnswers(values)
    }

    useEffect(() => {
        moduleRoutes.checkModuleAccess(module)
        .then(userHasAccess => {
            if (!userHasAccess) {
                return window.location.href = `/simulations/moduleName=${module}/tab=challenge`
            }
        })
    }, [module])

    return <>
        <h1 className="h4 text-start fw-bold">
           Pure Inquiry
        </h1>
        <div className="flex-1 overflow-auto mb-3">
            <div className="d-none d-print-block flex-column flex-max-size">
                <div id="lesson-student-info" className={`flex-max-size ${styles.studentInfo}`}>
                    <label htmlFor="lesson-student-info-name">Name:</label>
                    <input id="lesson-student-info-name" type="text" className="tab mx-3" name="name" />
                    <label htmlFor="lesson-student-info-date">Date:</label>
                    <input id="lesson-student-info-date" type="date" className="tab mx-3" name="date" />
                    {type !== "Tier 1" && <>
                        <label htmlFor="lesson-student-info-period">Period:</label>
                        <input id="lesson-student-info-period" type="number" className="tab mx-3" name="period" />
                    </>}
                </div>
            </div>
            <form id="pure-inquiry-form">
                <ol className={`${styles.list} px-3`}>
                    {type === "Tier 1" && <>
                        {["Module", "Research Question", "Variables", "Hypothesis", "Your Methods"].map((question, index) => (
                            <Question key={index} question={question} answer={questionAnswers[index]} onChange={e => setQuestionAnswer(index, e.target.value)} onBlur={onBlur}></Question>
                        ))}
                        <Table rowCount={10} columnCount={6} values={tableAnswers} onBlur={onBlur} onChange={(row, col, e) => {
                            const values = [...tableAnswers]
                            values[row * 6 + col] = e.target.value
                            setTableAnswers(values)
                        }} />
                        {["Results", "Conclusion", "Questions You Have"].map((question, index) => (
                            <Question key={index + 5} question={question} answer={questionAnswers[index + 5]} onChange={e => setQuestionAnswer(index + 5, e.target.value)} onBlur={onBlur}></Question>
                        ))}
                    </>}
                    {type === "Tier 2" && <>
                        {["Module", "Research Question", "Variables", "Hypothesis", "Your Methods"].map((question, index) => (
                            <Question key={index} question={question} answer={questionAnswers[index]} onChange={e => setQuestionAnswer(index, e.target.value)} onBlur={onBlur}></Question>
                        ))}
                        <Table rowCount={10} columnCount={6} values={tableAnswers} onBlur={onBlur} onChange={(row, col, e) => {
                            const values = [...tableAnswers]
                            values[row * 6 + col] = e.target.value
                            setTableAnswers(values)
                        }} />
                        {["Results", "Conclusion", "Possible Errors", "Investigation Limitations", "Additional Questions to be Researched"].map((question, index) => (
                            <Question key={index + 5} question={question} answer={questionAnswers[index + 5]} onChange={e => setQuestionAnswer(index + 5, e.target.value)} onBlur={onBlur}></Question>
                        ))}
                    </>}
                    {type === "AP Tier" && <>
                        {["Module", "Research Question", "Variables", "Hypothesis", "Your Methods"].map((question, index) => (
                            <Question key={index} question={question} answer={questionAnswers[index]} onChange={e => setQuestionAnswer(index, e.target.value)} onBlur={onBlur}></Question>
                        ))}
                        <Table rowCount={10} columnCount={6} values={tableAnswers} onBlur={onBlur} onChange={(row, col, e) => {
                            const values = [...tableAnswers]
                            values[row * 6 + col] = e.target.value
                            setTableAnswers(values)
                        }} />
                        {["Data Analysis", "Results", "Conclusion", "Possible Errors", "Investigation Limitations", "Additional Questions to be Researched"].map((question, index) => (
                            <Question key={index + 5} question={question} answer={questionAnswers[index + 5]} onChange={e => setQuestionAnswer(index + 5, e.target.value)} onBlur={onBlur}></Question>
                        ))}
                    </>}
                </ol>
            </form>
        </div>
    </>
}

export default PureInquiryQuestionList
