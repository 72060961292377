import { Dispatch, createContext } from "react"
import { Lesson } from "shared/lessons"
import { LessonVariety, Type } from "shared/types/moduleTypes"

export type AssignmentSection = "lesson" | "assessment" | "completed"
export type AssignmentContextType = {
    moduleName: string
    type: Type
    lessonVariety: LessonVariety
    guidedLesson: Lesson
    activePart: AssignmentSection
    setActivePart?: (activePart: AssignmentSection) => void
    setOnReturn?: Dispatch<any>
    restartAssignment?: () => Promise<void>
}

export const AssignmentContext = createContext<AssignmentContextType>({
    activePart: "lesson",
    setActivePart: () => {},
    restartAssignment: () => Promise.resolve(),
    guidedLesson: null,
    moduleName: null,
    type: null,
    lessonVariety: null,
})
