import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Label: React.FC<{title: string}> = ({title, children}) => {
    return (
        <Row className="my-4">
            <Col>
                <strong>
                    {title}
                </strong>
            </Col>
            <Col>
            {children}
            </Col>
        </Row>
    )
}

export default Label
