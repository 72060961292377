import * as React from "react"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useLocation, useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { SubmissionTeacherState } from "pages/Dashboard/Teacher/Grades/Submission/SubmissionTeacher"
import { AssignmentPreviewStateProps } from "../AssignmentPreview/Preview"
import styles from "../styles.module.scss"
import search from "../../../../scss/search.module.scss"
import { Assignment } from "shared/types/assignmentTypes"
import { MODULE_NO_ACCESS, StudentSubmission } from "shared/types/moduleTypes"
import EmptyTablePreview from "../../../Dashboards/General/EmptyTablePreview"
import assignmentsModel from "shared/routes/assignments"
import { TeacherAssignmentResponse } from "../../../../shared/types/teacherTypes"
import { LessonResponse } from "shared/lessons"
import moduleRoutes from "shared/routes/moduleRoutes"
import { Button } from "react-bootstrap"

export class TeacherGradesState {
    constructor() {
        this.lesson = null
    }
    assignment: TeacherAssignmentResponse
    lesson?: LessonResponse
}
/**
 * @param isLTI whether component is loaded in an LTI such as Canvas 
 * @abstract Shows a list of grade submissions for a student
 * NOTE: Currently being used when student clicks on an assignment 
 */
export default function TeacherGrades({isLTI} : {isLTI: boolean}) {
    const [ studentSearch, setStudentSearch ] = React.useState("")
    const [ currentPage, setCurrentPage ] = React.useState(1)
    const [ rowsPerPage, setRowsPerPage ] = React.useState(10)
    const [ grades, setGrades ] = React.useState<StudentSubmission[]>([])
    const location = useLocation()
    const navigate = useNavigate()
    const state = location.state as TeacherGradesState
    const assignment = state.assignment
    const [canViewAssignment, setCanViewAssignment] = React.useState(false)
    
    React.useEffect(() => {
        moduleRoutes.checkModuleAccess(assignment.moduleName)
        .then(userHasAccess => setCanViewAssignment(userHasAccess))

        assignmentsModel.getGrades({ assignmentId: assignment.id })
        .then(response => setGrades(response.data))
        .catch(err => {
            if (err?.response?.data?.message === MODULE_NO_ACCESS) {
                return window.location.href = `/simulations/moduleName=${assignment.moduleName}/tab=challenge`
            }
        })
    }, [assignment])

    const filteredSubmissions = React.useMemo(() => {
        return grades
        .filter( l => l.studentName.includes(studentSearch))
    }, [grades, studentSearch])

    const pageCount = Math.ceil(filteredSubmissions.length / rowsPerPage)

    const pages = React.useMemo(() => {
        const firstDisplayedPage = Math.max(1, currentPage - 2)
        const lastDisplayedPage = Math.min(currentPage + 2, pageCount)
        return Array.from(Array(lastDisplayedPage + 1).keys()).slice(firstDisplayedPage)
    }, [currentPage, pageCount])

    const formatGrade = (grade: string) => {
        const parsedGrade = parseFloat(grade)
        return isNaN(parsedGrade) ? "-" : `${parsedGrade.toFixed(2)}%`
    }

    const navigateToSubmission = (submission: StudentSubmission, index: number) => {
        const assignment_: Assignment = {
            ...state.assignment,
            lessonId: assignment.lessonId || null,
            assessmentId: assignment.assessmentId || null,
            moduleName: state.lesson?.moduleName || assignment.moduleName,
            type: state.lesson?.type || assignment.pureInquiryType,
        }
        const SubmissionStudentState: SubmissionTeacherState = {
            submissions: grades,
            assignment: assignment_,
            currentIndex: index,
            lesson: state.lesson,
            previousPage: "..",
        }
        navigate("submission", {
            state: {
                ...SubmissionStudentState
            }
        })
    }

    const previewStateProps: AssignmentPreviewStateProps = {
        assignment: state.assignment,
        lesson: state.lesson
    }
    return <>
        <h1 className="text-start h2 mt-3">
            <span>Grades for {assignment.title}</span>
            {canViewAssignment ? 
                <Link to="./preview" state={previewStateProps} className="btn btn-theme float-end">
                    <i className={`fas fa-glasses ${styles.buttonGlasses}`}/>
                    View Assignment
                </Link> :
                <Button href={`/simulations/moduleName=${assignment.moduleName}/tab=challenge`} className={`btn btn-theme float-end ${styles.noAccessModuleBtn}`}>
                    Module Information
                </Button>}
        </h1>
        <h2 className="text-start h3">{assignment.lesson ? `Lesson Number ${assignment.lesson.number}` : ""}</h2> 
        <p className={`text-start ${styles.greyText}`}>
            Grade student assignment submission.
        </p>
        <div className="text-start mb-3">
            {window.parent === window && isLTI && (
                <button className="text-decoration-none" onClick={() => window.close()}>
                    <i className="fas fa-arrow-left" />
                    Return to Canvas
                </button>
            )}
            {!isLTI && (
                <Link
                        style={{
                            "textDecoration": "none"
                        }}
                        to="/dashboard/teacher/assignments"
                    >
                        <i className="fas fa-arrow-left pe-2" />
                        <span>Return to Assignments</span>
                </Link>
            )}
        </div>
        {grades.length > 0 && <>
        <div className={"row"}>
            <Col sm={5} lg={3}>
                <div className={search.search}>
                    <i className={"fas fa-search"} />
                    <input
                        type="text"
                        className={"form-control"}
                        value={studentSearch}
                        onChange={ e => {
                            setCurrentPage(1)
                            setStudentSearch(e.target.value)
                        }}
                        placeholder="Search for student"
                        id={"studentSearch"}
                    />
                </div>
            </Col>

            <Col sm={{
                span: 6,
                offset: 1
            }} lg={{
                span: 4,
                offset: 5
            }}>
                <Form.Group as={Row}>
                    <Form.Label
                        as={Col}
                        sm={4}
                        className="text-end"
                        // The `for` attribute is not valid on `div`'s
                        // and this is being transformed into a `div`
                        // because of the `as={Col}`
                        id="studentPageCount"
                    >
                        Show:
                    </Form.Label>
                    <Col sm={6}>
                        <Form.Select
                            aria-labelledby="studentPageCount"
                            defaultValue={10}
                            onChange={(e) => {
                                const value = Math.max(Math.min(parseInt(e.currentTarget.value), 100), 1)
                                if (!isNaN(value)) {
                                    setRowsPerPage(value)
                                }
                            }}
                        >
                            {[10, 25, 50, 100].map((rowCount) => {
                                return <option key={rowCount} value={rowCount}>{rowCount} Students</option>
                            })}
                        </Form.Select>
                    </Col>
                </Form.Group>
            </Col>
        </div>
        
        <table className={`table table-hover ${styles.submissionList}`}>
            <thead>
                <tr>
                    <th>Student Name</th>
                    <th>Lesson Grade</th>
                    <th>Assessment Grade</th>
                    <th>Completed</th>
                </tr>
            </thead>

            <tbody>
                {filteredSubmissions
                    .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
                    .map( (submission, index) => (
                        <tr data-testid="student"
                            key={submission.submission.id}
                            onClick={() => navigateToSubmission(submission, index + (currentPage - 1) * rowsPerPage)}>
                            <td>{submission.studentName}</td>
                            <td>{formatGrade(submission.lessonGrade)}</td>
                            <td>{formatGrade(submission.assessmentGrade)}</td>
                            <td>{new Intl.DateTimeFormat().format(new Date(submission.completed)) ?? "Not Completed"}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>

        <div className="row">
            <div className={"offset-md-5 col-md-2"}>
                <nav aria-label="Student Results pages">
                    <ul className="pagination">
                        <li key={"prev"} className={`page-item ${currentPage <= 1 ? "disabled" : ""}`}>
                            <button className={"page-link"} onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}>Previous</button>
                        </li>
                        {pages.map( page => (
                            <li key={page} className={`page-item ${page === currentPage ? "active" : ""}`}>
                                <button className={"page-link"} onClick={() => setCurrentPage(page)}>{page}</button>
                            </li>
                        ))}
                        <li key={"next"} className={`page-item ${currentPage >= pageCount ? "disabled" : ""}`}>
                            <button className={"page-link"} onClick={() => setCurrentPage(Math.min(pageCount, currentPage + 1))}>Next</button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        </>}

        {grades.length === 0 && 
            <EmptyTablePreview 
                title="Grade Student Submission"
                description="No students have submitted this assignment yet."
            />
        }
    </>
}
