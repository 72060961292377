export const helpSteps = [
    {
        target: '.class-assignment-add',
        content: 'Clicking "Add Assignment" navigates you to the assignment search where you can select which assignment you want to add to your class',
        disableBeacon: true,
    },
    {
        target: '.class-assignment-edit',
        content: 'Clicking here returns you to your assignment where you can make edits',
        placement: "auto"
    }
]

export const helpLinkVideos = [
    {
        title: "Teacher Dashboard Overview",
        link: "https://youtube.com/embed/YzJBid581AI"
    },
    {
        title: "Grading Submitted Assignments",
        link: "https://youtube.com/embed/VkjaJw2OtJ8"
    },
    {
        title: "Assigning Lessons",
        link: "https://youtube.com/embed/AhKwZFMuHQA"
    }
]
