import { Button, Col, Form, Row } from "react-bootstrap"
import { useEffect, useState } from "react"
import AccountSearch from "./AccountSearch"
import { Account, License, Plan } from "shared/types/adminTypes"
import { PlanSelector } from "./PlanSelector"
import axios from "axios"
import { toast } from "react-toastify"
import React from "react"
import { AuthContext } from "AuthContext"

export default function ManageAccount() {

    const authContext = React.useContext(AuthContext)
    const isStaff = authContext.isStaff

    const [key, setKey] = useState(0)
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)
    const [accountLicense, setAccountLicense] = useState<License | null>(null)
    const [showPlanSelector, setShowPlanSelector] = useState(false)
    const [newPlan, setNewPlan] = useState<Plan | null>(null)
    const [newCount, setNewCount] = useState<number | null>(null)
    const [newExpiration, setNewExpiration] = useState<string | null>(null)

    useEffect(() => {
        axios.post("/api/account/license", {username: selectedAccount?.username}).then((res) => {
            setAccountLicense(res.data)
            setNewCount(res.data.count)
            setNewExpiration(new Date(res.data.expiration).toISOString().split('T')[0])
            setNewPlan(res.data.plan)
            setShowPlanSelector(false)
        }).catch((err) => {
            setAccountLicense(null)
            setNewCount(null)
            setNewExpiration(null)
            setNewPlan(null)
            setShowPlanSelector(false)
        })
    }, [selectedAccount])

    function updateLicense(): void {
        //  Send only changed parameters
        const params: { planId?: string, expiration?: string, count?: number } = {}
        if (newPlan !== accountLicense?.plan) {
            params.planId = newPlan.planId
        }
        if (newExpiration !== accountLicense?.expiration) {
            params.expiration = newExpiration
        }
        if (newCount !== accountLicense?.count) {
            params.count = newCount
        }

        if (accountLicense) {
            axios.post("/api/account/admin/update-license", {
                username: selectedAccount?.username,
            ...params
        }).then((res) => {
                toast.success("License updated")
                setSelectedAccount(null)
                setShowPlanSelector(false)
                setAccountLicense(null)
                setNewCount(null)
                setNewExpiration(null)
                setNewPlan(null)
                setKey(key + 1)
            }).catch((err) => {
                toast.error("License update failed")
            })
        } else {
            axios.post("/api/account/admin/create-license", {
                username: selectedAccount?.username,
                ...params
            }).then((res) => {
                toast.success("License created")
            }).catch((err) => {
                toast.error("License creation failed")
            })
        }
    }

    return (
        <>
            <h1>Manage Licenses</h1>
            <AccountSearch key={key} onSelect={setSelectedAccount} />
            { isStaff && selectedAccount !== null &&
                <div className="w-75 m-auto">
                    <Row className="p-3 mb-3">
                        <Col md={8} className="p-3 border rounded">
                            <h3>Update License</h3>
                            <p><strong>Plan:</strong> {newPlan?.displayName}</p>
                            <Form.Group controlId="formNewCount" className="d-flex align-items-center mb-3">
                                <Form.Label className="me-2" style={{ whiteSpace: "nowrap" }}>New Count</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={newCount ?? accountLicense?.count ?? ""}
                                    onChange={(e) => setNewCount(parseInt(e.target.value))}
                                    placeholder="Enter new count"
                                />
                            </Form.Group>
                            <Form.Group controlId="formNewExpiration" className="d-flex align-items-center mb-3">
                                <Form.Label className="me-2" style={{ whiteSpace: "nowrap" }}>New Expiration</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={newExpiration ? newExpiration : accountLicense?.expiration ? accountLicense.expiration : ""}
                                    onChange={(e) => setNewExpiration(e.target.value)}
                                    placeholder="Enter new expiration date"
                                />
                            </Form.Group>
                            <div className="d-flex justify-content-between">
                                <Button variant="primary" onClick={() => setShowPlanSelector(!showPlanSelector)} >Change Plan</Button>
                                <Button variant="primary" onClick={() => updateLicense()}>Update License</Button>
                            </div>
                        </Col>
                        
                            <Col md={4} className="p-3 border rounded">
                                <h3>Current License</h3>
                                { accountLicense ?
                                <>
                                <p><strong>Plan:</strong> {accountLicense?.plan.displayName}</p>
                                <p><strong>Expiration:</strong> {accountLicense ? new Date(accountLicense.expiration).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : ""}</p>
                                <p><strong>Count:</strong> {accountLicense?.count}</p>
                                </>
                                :
                                <p>No license found for {selectedAccount.username}</p>
                                }
                            </Col>
                            
                    </Row>
                    { showPlanSelector &&
                        <PlanSelector onSelect={setNewPlan} />
                    }
                </div>
            }
        { !isStaff &&
            <div>
                <p>You do not have permission to create an account.</p>
            </div>
        }
        </>
    )
}
