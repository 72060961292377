import Sim from "components/General/Sim"
import { toast } from "react-toastify"
import { sanitizePath } from "shared/helpers/moduleHelper"

type SimulationProps = {
    moduleName: string
}

const SimulationTab = ({ moduleName } : SimulationProps) => {
  const imagePath = moduleName && `/simulations/${sanitizePath(moduleName)}/thumbnail.png`
  
  return (
    <div className="ratio ratio-16x9 mt-4">
      <Sim
          src={`/api/iframe/simulation?moduleName=${moduleName}`}
          iframeTitle={"Module Simulation"}
          allowFullScreen={true}
          disabledPicture={imagePath}
          onLoad={(simulationDocument, iframe) => {
              const event = new CustomEvent("stemsims.simulation.load")
              iframe.contentWindow.dispatchEvent(event)

              const html = simulationDocument.getElementsByTagName("html")[0]
              const body = simulationDocument.getElementsByTagName("body")[0]
              const main = simulationDocument.getElementsByTagName("main")[0]

              if (!html || !body || !main) {
                  return toast.error("There has been an error loading the simulation. Please refresh the page and try again.")
              }

              body.style.height = "100%"

              html.style.overflow = "hidden"
              html.style.height = "100%"
          }} />
    </div>
  )
}


export default SimulationTab
