import { Row, Col, Card, Button } from "react-bootstrap"
import React from "react"
import FloatingPage from "components/General/FloatingPage";
import styles from "./HelpPage.module.scss"
import HelpFaqs from "./HelpFaqs";
import HelpVideos from "./HelpVideos";

const { background } = styles

function HelpPage() {
    return (
        <FloatingPage className={background}>
            <h1>Get Help With STEM Sims</h1>
            <Row className="my-4">
                <Col sm={12} md={4} className="d-flex mb-2">
                    <Card className="w-100">
                        <Card.Body className="d-flex flex-column">
                            <Card.Title>Technical Issue</Card.Title>
                            <Card.Text>If you've run into a bug, or are having another technical issue with the site, please let us know!</Card.Text>
                            <Card.Text>Be sure to include your username and a good description of the problem.</Card.Text>
                            <Card.Link className="mt-auto" href="mailto:support@stemsims.com?subject=Technical%20Issue:%20" >
                                <Button>Contact Tech Support</Button>
                            </Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={4} className="d-flex mb-2">
                    <Card className="w-100">
                        <Card.Body className="d-flex flex-column">
                            <Card.Title>Professional Development</Card.Title>
                            <Card.Text>If you want to learn how to use STEM Sims more effectively with your classes, reach out to us to set up video or in person training that's right for your needs.</Card.Text>
                            <Card.Link className="mt-auto" href="mailto:sales@stemsims.com?subject=Professional%20Development%20Inquiry">
                                <Button>Contact Us</Button>
                            </Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={4} className="d-flex mb-2">
                    <Card className="w-100">
                        <Card.Body className="d-flex flex-column">
                            <Card.Title>Account Recovery</Card.Title>
                            <Card.Text>If you've forgotten your password, reset your password by clicking this link.</Card.Text>
                            <Card.Link className="mt-auto" href="/account/recover">
                                <Button>Recover Account</Button>
                            </Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col sm={12} md={6}>
                    <h1>Frequently Asked Questions</h1>
                    <HelpFaqs />
                </Col>
                <Col sm={12} md={6}>
                    <h1>Help Videos</h1>
                    <HelpVideos />
                </Col>
            </Row>
        </FloatingPage>
    )
}

export default HelpPage;
