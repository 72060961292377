import Joyride, { ACTIONS, STATUS } from 'react-joyride'

function HelpTourSystem({ steps, run, setRun }) {

  const handleJoyrideCallback = (data) => {
    const { action, status } = data

    if (action === ACTIONS.CLOSE || status === STATUS.SKIPPED || action === ACTIONS.RESET) {
      setRun(false)
    }
  }

  return (
    <div>
      <Joyride
        run={run}
        steps={steps}
        continuous
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: '#404bbd',
          }
        }}
      />
    </div>
  )
}

export default HelpTourSystem
