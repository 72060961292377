
/**
 * This function is meant to fix the shortcomings of num.toFixed and allow for round to a specific decimal point. 
 * 
 * Examples:
 * ```
 * roundToFixed(1.10, 2) //-> 1.1
 * roundToFixed(1, 2) //-> 1
 * roundToFixed(1.111, 2) //-> 1.11
 * ```
 */
export default function roundToFixed(value, digits) {
    const modifier = Math.pow(10, digits)
    return String(
        //loosely based on this post: https://stackoverflow.com/a/21907972/8082502
        Math.round(parseFloat(value) * modifier) / modifier
    )
}
