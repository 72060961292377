import styles from "./ModulePage.module.scss"

type ChallengeProps = {
    moduleName: string
    modulePath: string
    text?: string
    image?: string
    brochure?: string
}
const ChallengeTab = ({ moduleName, modulePath, text, image, brochure }: ChallengeProps) => {
  return (
    <div className={styles.container}>
        <img className="mb-3" src={`${modulePath}thumbnail.png?${image}`} alt={`${moduleName} Thumbnail`} />
        <p className="text-start">{text}</p>
        <hr />
        <div className="mt-3">
            <a href={`/simulations/planning/?module-name=${moduleName}`}>
                <img className={styles.planningIcon} src = "/images/planning-img-1.png" alt="Investigator Icon" />
                <p className="d-inline-block mx-3 fs-2">Planning and Carrying Out Investigations</p>
                <img className={styles.planningIcon} src = "/images/planning-img-2.png" alt="Timer Icon" />
            </a>
        </div>
        {brochure && <div>
            <hr />
            <a href={`${modulePath}brochure/brochure.pdf?version=${brochure}`} className="text-decoration-none">
                <img
                    src={`${modulePath}brochure/thumbnail.png?version=${brochure}`}
                    alt={`${moduleName} Brochure's Thumbnail`} />
                <h2>{moduleName} Brochure</h2>
            </a>
		</div>}
    </div>
  )
}

export default ChallengeTab
