import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile } from "@fortawesome/free-solid-svg-icons"
import { Row, Col } from "react-bootstrap"
import styles from "./ModulePage.module.scss"
import CustomHTML from "components/CustomHTML"

type AssessmentItemProps = { title: string, type: string, assessments: any[] }
type AssessmentProps = { assessmentGroups: AssessmentItemProps[], moduleName: string }

const AssessmentTab = ({ assessmentGroups, moduleName } : AssessmentProps) => {

    const navigate = (link: string) => {
      window.location.href = link
    }

  return (
    <div className="mt-5">
      {assessmentGroups?.map((assessmentGroup, i) => <div className={styles.container} key={i}>
            <h3 className="fw-bold">
              <CustomHTML html={assessmentGroup?.title.replace("rade ", "rades ").replace(" Lessons", "")} />
            </h3>
            <Row className="justify-content-center">
              {assessmentGroup?.assessments?.map((assessment, index) => 
                <Col xs={12} lg={4}
                  key={index} 
                  className={`border pe-auto shadow-sm rounded p-2 me-1 mt-3 ${styles.lessonItem} ${!assessment?.lessonTitle && `p-4`}`} 
                  onClick={() => navigate(`/simulations/assessment?assessmentId=${assessment?.id}&module=${moduleName}&assessmentNumber=${assessment?.number}&type=${assessment?.type}`)}>
                    <div className="text-primary">
                        <p className="d-inline me-2 fs-5 fw-bold"><CustomHTML html={assessment?.assessmentTitle} /></p>
                        <FontAwesomeIcon icon={faFile} />
                    </div>
                    {assessment?.lessonTitle && <p className="fs-6 mt-1 mb-0 text-primary"><CustomHTML html={assessment?.lessonTitle} /></p>}
                </Col>)}
            </Row>
          </div>)}
    </div>
  )
}

export default AssessmentTab
