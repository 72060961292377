import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import lessons, { LessonResponse } from "shared/lessons"
import * as teacher from "../../../../shared/routes/teacher"
import { ClassResponse, CreateProps, StudentResponse } from "../../../../shared/types/teacherTypes"
import AssignmentForm from "../Forms/AssignmentForm/AssignmentForm"
import { LessonVariety, MODULE_NO_ACCESS, Type } from "shared/types/moduleTypes"
import { AssignmentContext, AssignmentSection } from "components/Assignment/AssignmentContext"
import { Assessment } from "shared/types/assessmentTypes"
import moment from "moment"
import RelevantHelpModal from "components/Modals/RelevantHelpModal"

const helpLinkVideos = [
    {
        "title": "Assigning Lessons",
        "link": "https://youtube.com/embed/AhKwZFMuHQA"
    },
    {
        "title": "Assigning Assessments Only",
        "link": "https://youtube.com/embed/1_QVT6jm0-c"
    }
]

const removeTags = (str: string) => {
    return str.replace(/<[^<>]+>/g, "")
}

/**
 * To verify the route is working correctly, checks all fields of this class with the route state
 * constructor allows some fields to be bypassed if they are not required
 */
export class CreateAssignmentProps {
    constructor() {
        this.assessmentOnly = "No"
        this.assessment = null
        this.lessonId = null
        this.selectedStudent = null
    }
    lessonId: string
    lessonVariety: LessonVariety
    module: string
    type: Type
    assessment: Assessment
    assessmentOnly: string
    selectedStudent: StudentResponse
}

export default function CreateAssignment({activeClass}: {activeClass: ClassResponse}) {

    const [selectedLesson, setSelectedLesson] = React.useState<LessonResponse | null>(null)
    const location = useLocation()
    const navigate = useNavigate()
    const state = location.state as CreateAssignmentProps
    const [activePart, setActivePart] = React.useState<AssignmentSection>(state.assessmentOnly === "Yes" ? "assessment" : "lesson")

    const [students, setStudents] = React.useState<StudentResponse[]>(null)

    const createAssignmentSubmit = (values) => {
        const data = {
            classID: activeClass.id,
            lessonVariety: state.lessonVariety,
            assessmentId: state.assessment?.id,
            moduleName: state.module,
            pureInquiryType: state.type,
            ...values,
            dueDate: values.dueDate ? moment(values.dueDate).utc().toDate() : undefined
        } as CreateProps
        if (state.lessonVariety === "Guided") {
            data.lessonId = values.assessmentOnly === "Yes" ? undefined : state.lessonId
        }
        return teacher.createAssignment(data).then(() => {
            toast.success("The assignment has been created.")
            navigate("/dashboard/teacher/assignments")
        }).catch((err) => {
            if(typeof err === "object") err = err.response?.data.message
            if(err === MODULE_NO_ACCESS) {
                return window.location.href = `/simulations/moduleName=${state.module}/tab=challenge`
            }
            toast.error(err || "There was an error adding your assignment. Please try again.")
        })
    }

    React.useEffect(() => {
        if (state.lessonId) {
            if (typeof state?.lessonId !== "string") {
                navigate("/dashboard/teacher/assignments")
                toast.error("There was an error loading the assignment. Please try to reselect the assignment.")
                return
            }
            void lessons.findById(state.lessonId).then((lesson) => {
                if (!lesson.userHasAccess) {
                    return window.location.href = `/simulations/moduleName=${state.module}/tab=challenge`
                }
                if (state.assessmentOnly === "Yes") {
                    lesson.title =  `${lesson.title} Assessment`
                }
                setSelectedLesson({...lesson, id: state.lessonId})
            }).catch((err) => {
                if(typeof err === "object") err = err.response?.data.message
                navigate("/dashboard/teacher/assignments")
                toast.error(err || "There was an error loading the assignment. Please try to re-add the assignment.")
            })
        } else {
            setSelectedLesson(null)
        }

    }, [state.lessonId, navigate, state.lessonVariety, state.module, state.type, state.assessment, state.assessmentOnly])

    React.useEffect(() => {
        if (activeClass?.id) {
            teacher.getStudents({ classID: activeClass.id }).then((response) => {
                setStudents(response)
            })
        } else {
            setStudents(null)
        }
    }, [activeClass?.id])

    const assignmentValues = {
        moduleName: state.module,
        type: state.type,
        lessonVariety: state.lessonVariety,
        guidedLesson: selectedLesson,
        activePart: activePart,
        setActivePart: setActivePart,
    };

    return (<>
        <div className="text-start mb-3">
            <Link style={{
                "textDecoration": "none"
            }} to="/dashboard/teacher/assignments/search"><i className="fas fa-arrow-left mt-3" /> Return to Search</Link>
        </div>
        <RelevantHelpModal videos={helpLinkVideos}/>
        <AssignmentContext.Provider value={assignmentValues}>
            <AssignmentForm
                submitButtonText="Create Assignment"
                initialValues={{
                    title: removeTags(selectedLesson?.title ?? `Pure Inquiry - ${state.module}`),
                    dueDate: undefined,
                    notes: "",
                    assessmentOnly: state.assessmentOnly || "No",
                    studentAssessmentViewOption: activeClass?.studentAssessmentViewOption ?? "Grade",
                    students: state.selectedStudent ? [state.selectedStudent.uuid] : null,
                }}
                assessment={state.assessment}
                onSubmit={createAssignmentSubmit}
                students={students}
            />
        </AssignmentContext.Provider>
    </>)
}
