import { useEffect, useState } from "react";
import { Button, Container, Form, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import { Account } from "../../shared/types/adminTypes";

interface AccountSearchProps {
    onSelect: (account: Account) => void;
}

export default function AccountSearch({ onSelect }: AccountSearchProps) {
    const [searchTerm, setSearchTerm] = useState("");
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
    const [noResults, setNoResults] = useState(false);

    function handleSearch() {
        axios.get(`/api/account/admin/account-search?search=${encodeURIComponent(searchTerm)}`).then((res) => {
            setAccounts(res.data as Account[])
            setNoResults(res.data.length === 0)
            setSelectedAccount(null)
        })
    }

    useEffect(() => {
        onSelect(selectedAccount)
    }, [onSelect, selectedAccount])

    return (
        <Container className="p-3 w-75 border border-primary rounded mt-3 mb-3" style={{ textAlign: "left" }}>
            <Row>
                <Col md={10}>
                    <Form.Control 
                        type="text" 
                        value={searchTerm} 
                        onChange={(e) => setSearchTerm(e.target.value)} 
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            } else if (e.key === 'Escape') {
                                setSearchTerm("");
                                setAccounts([]);
                                setSelectedAccount(null)
                            }
                        }}
                        placeholder="Search for an account"
                        autoFocus
                    />
                </Col>
                <Col md={2} className="d-flex justify-content-start">
                    <Button variant="primary" onClick={handleSearch}>Search</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                {accounts.length > 0 && (
                    <Table>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>License</th>
                                <th>Expiration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {accounts.map((account) => (
                                <tr key={account.username} onClick={() => {
                                    setSelectedAccount(account)
                                    }} className={selectedAccount?.username === account.username ? "table-primary" : ""}>
                                    <td>{account.username}</td>
                                    <td>{account.email}</td>
                                    <td>{account.license === "No license" ? "---" : account.license}</td>
                                    <td>{account.expiration === "No license" ? "---" : new Date(account.expiration).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
                {noResults && (
                    <p className="text-center mt-3 fw-bold fst-italic">No results found</p>
                )}
                </Col>
            </Row>
        </Container>
    )
}