import axios from "axios"

export type AssessmentResponse = {
    module: string,
    type: string,
    number: number,
    lessonNumber: number,
    title: string,
    id: string,
    lessonId: string,
}

export function findById({ id }: { id: string }): Promise<AssessmentResponse> {
    return axios.get(`/api/assessments/${id}`).then((res) => res.data)
}

const assessments = {
    findById,
}

export default assessments
