import * as React from "react"
import styles from "./sidebar.module.scss"

const SidebarContent: React.FC = ({children}) => {
    return <div className={`${styles.sidebarContent} full-screen d-flex flex-column`}>
        {children}
    </div>
}

export default SidebarContent
