import Table from "react-bootstrap/Table"
import CommonClass from "./Types/CommonClass"

interface ClassProps {
    classes: CommonClass[]
    activeClass: CommonClass
    onClassSelected: (selectedClass: CommonClass) => void
    showArchived?: boolean
}

export default function ClassesTable({ classes, activeClass, onClassSelected, showArchived = false }: ClassProps) {
    return (
        <Table hover className="text-start">
            <thead>
                <tr>
                    <th>Name</th>
                </tr>
            </thead>
            <tbody>
                {classes
                .sort((a, b) => a.className.localeCompare(b.className))
                .map((classItem) => {
                    if (!showArchived && classItem.archived === "Yes") {
                        return null
                    }
                    return (
                        <tr
                            key={classItem.id}
                            onClick={() => onClassSelected(classItem)}
                            role="button"
                            className={`${classItem.id === activeClass?.id ? "pe-none" : ""}`}
                        >
                            <td className={classItem.archived === "Yes" ? "text-muted" : ""}>{classItem.className}{classItem.archived === "Yes" ? ` [Archived, ${classItem.term} ${classItem.academicYear}]` : ""}{classItem.id === activeClass?.id && <span className="text-white bg-primary p-1 ps-2 pe-2 rounded-pill ms-4 small-text">Selected</span>}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
