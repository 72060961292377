import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function LogoutWarning({ isLoggedIn=false }: { isLoggedIn: boolean }) {
  const [showModal, setShowModal] = useState(false);
  let warningTimer: NodeJS.Timeout;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function resetTimeouts() {
    if (warningTimer) clearTimeout(warningTimer);
    warningTimer = setTimeout(() => {
      setShowModal(true);
    }, 55 * 60 * 1000); // 55 minutes
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    resetTimeouts();

    const handleUserActivity = () => {
      if (!showModal) {
        resetTimeouts();
      }
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      clearTimeout(warningTimer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [isLoggedIn, resetTimeouts, showModal, warningTimer]);

  const handleLogout = () => {
    window.location.href = '/account/log-out';
  };

  const handleClose = () => {
    setShowModal(false);
    resetTimeouts();
  };

  useEffect(() => {
    let logoutTimer;
    if (showModal) {
      logoutTimer = setTimeout(() => {
        handleLogout();
      }, 5 * 60 * 1000); // 5 minutes
    }

    return () => clearTimeout(logoutTimer);
  }, [showModal]);

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Session Timeout Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You will be logged out in 5 minutes due to inactivity.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Stay Logged In
        </Button>
        <Button variant="secondary" onClick={handleLogout}>
          Log Out Now
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutWarning;
