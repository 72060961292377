import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

interface CustomModalProps {
    showModal: boolean,
    header: string,
    body: string,
    onConfirm: () => void,
    onDecline: () => void,
    confirmBtnText: string,
    declineBtnText: string
}

const CustomModal = ({ showModal, header, body, onConfirm, onDecline, confirmBtnText, declineBtnText }: CustomModalProps) => {
  return (
    <Modal show={showModal} centered size="lg">
        <Modal.Header>
            <Modal.Title className="w-100">{ header }</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p className="text-center">{ body }</p>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={onDecline}>{ declineBtnText }</Button>
            <Button variant="theme" onClick={onConfirm}>{ confirmBtnText }</Button>
        </Modal.Footer>
    </Modal>
  )
}

export default CustomModal
