import * as React from "react"
import { Tooltip, OverlayTrigger } from "react-bootstrap"

interface Props {
    title: string
    classNames?: string
    children: any
}

export default function ToolTip({title, classNames, children} : Props) {
    return (
        <OverlayTrigger
            overlay={(props) => (
                <Tooltip {...props} className={classNames}>
                    {title}
                </Tooltip>
            )}
        >
            {children}
        </OverlayTrigger>
    )
}
