import { Button } from "react-bootstrap"
import { useEffect, useState } from "react"
import AccountSearch from "./AccountSearch"
import { Account } from "shared/types/adminTypes"
import axios from "axios"
import { toast } from "react-toastify"
import { AuthContext } from "AuthContext"
import React from "react"

export default function DeleteAccount() {

    const authContext = React.useContext(AuthContext)
    const isStaff = authContext.isStaff
    
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)
    const [confirmation, setConfirmation] = useState(false)
    const [key, setKey] = useState(0)

    useEffect(() => {
        setConfirmation(false)
    }, [selectedAccount])

    function handleDelete() {
        setConfirmation(false)
        setSelectedAccount(null)
        axios.post("/api/account/admin/delete-account", {username: selectedAccount.username}).then((res) => {
            if (res.status === 200) {
                toast.success("Account deleted")
            }
            setKey(key + 1)
        }).catch((err) => {
            toast.error("Failed to delete account")
        })
    }

    return (
        <>
            <h1>Delete Account</h1>
            <AccountSearch key={key} onSelect={setSelectedAccount} />
            { isStaff && selectedAccount &&
            <div className="w-50 m-auto">
                <h3>Are you sure you want to delete {selectedAccount.username}?</h3>
                <div className="border border-primary rounded p-3 mt-3" style={{ textAlign: "left" }}>
                    <p><strong>Username:</strong> {selectedAccount.username}</p>
                    <p><strong>Email:</strong> {selectedAccount.email}</p>
                    <p><strong>License:</strong> {selectedAccount.license}</p>
                    <p><strong>Expiration:</strong> {selectedAccount.expiration === "No license" ? "---" : new Date(selectedAccount.expiration).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</p>
                </div>
                {!confirmation && <Button variant="danger" className="mt-3" onClick={() => setConfirmation(true)}>Delete?</Button>}
                {confirmation && (
                    <div className="d-flex justify-content-between">
                        <Button variant="secondary" className="mt-3" onClick={() => setConfirmation(false)}>Cancel</Button>
                        <Button variant="danger" className="mt-3" onClick={handleDelete}>Really Delete</Button>
                    </div>
                )}
            </div>
        }
        { !isStaff &&
            <div>
                <p>You do not have permission to delete an account.</p>
            </div>
        }
        </>
    )
}
