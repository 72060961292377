import { Button, Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import * as teacher from "../../../../shared/routes/teacher"
import { AssignOption, StudentResponse } from "../../../../shared/types/teacherTypes"
import { useEffect, useState } from "react"
import { ClassResponse } from "shared/types/teacherTypes"
import AssignOptionToggle from "./AssignmentForm/AssignOptionToggle"
import SelectStudents from "./AssignmentForm/SelectStudents"

export interface Props {
    selectedAssignmentIds: string[]
    show: boolean
    onHide: () => void
    onSuccess: () => void
    activeClass: ClassResponse
}

const BulkAssignModal = ({ show, onHide, onSuccess, selectedAssignmentIds, activeClass } : Props) => {
    const [assignOption, setAssignOption] = useState<AssignOption>("class")
    const [classStudents, setClassStudents] = useState<StudentResponse[]>([])
    const [selectedStudentIds, setSelectedStudentIds] = useState<string[]>([])

    useEffect(() => {
        if (activeClass?.id) {
            teacher.getStudents({ classID: activeClass.id }).then(setClassStudents)
        } else {
            setClassStudents(null)
        }
    }, [activeClass?.id])

    const onBulkAssign = () => {
        teacher.bulkAssign({ assignmentIds: selectedAssignmentIds, studentIds: selectedStudentIds, assignOption: assignOption })
        .then(() => {
            if (assignOption === "class") {
                toast.success(`Assigned to all students in ${activeClass.className}.`)
            } else {
                toast.success("Assigned to selected students.")
            }
        })
        setSelectedStudentIds([])
        onSuccess()
    }


    const hideAssignModal = () => {
        setSelectedStudentIds([])
        onHide()
    }

    const assignBtnDisabled = (assignOption === "students" && selectedStudentIds.length === 0) || (assignOption === "class" && classStudents.length === 0)

    return( 
        <Modal show={show} onHide={hideAssignModal}>
            <Modal.Header className="border-0" closeButton>
                <h3 className="w-100 text-center">Assign to Class or Students</h3>
            </Modal.Header>
            <Modal.Body className="mx-5 text-center">
                <AssignOptionToggle
                    onClassChosen={() => {
                        setAssignOption("class")
                        setSelectedStudentIds([])
                    }}
                    onStudentsChosen={() => {
                        setAssignOption("students")
                    }}
                    assignOption={assignOption}
                />
                
                {assignOption === "class" &&
                    <div className="mt-3">
                        {classStudents?.length > 0 ? 
                            <p className="fw-bold text-muted text-start">Selected assignments will be assigned to all students in {activeClass.className}.</p> :
                            <p className="fw-bold text-muted text-start">{activeClass.className} has no students.</p>}
                        <ol className="list-group list-group-numbered text-start">
                            {classStudents?.map(classStudent => <li className="list-group-item" key={classStudent.name}>
                                {classStudent.name}
                            </li>)}
                        </ol>
                    </div>
                }

                {assignOption === "students" &&
                    <div>
                        <p className="fw-bold text-muted mt-3 text-start">
                            {classStudents.length === 0 ? `${activeClass.className} has no students.` : `Selected assignments will be assigned to all the students you select below.`}
                        </p>
                        {classStudents.length !== 0 &&
                            <div className="d-flex justify-content-between w-100">
                                <div>
                                    <Button
                                        variant="outline-theme"
                                        onClick={() => {
                                            setSelectedStudentIds([])
                                        }}
                                    >Select None</Button>

                                    <Button
                                        variant="outline-theme mx-3"
                                        onClick={() => {
                                            const classStudentIds = classStudents.map(cs => cs.uuid)
                                            setSelectedStudentIds(classStudentIds)
                                        }}
                                    >Select All</Button>
                                </div>
                            </div>
                        }
                        <div className="mt-3">
                            <SelectStudents
                                    classStudents={classStudents}
                                    selectedStudentIds={selectedStudentIds}
                                    onSelectStudent={newStudents => setSelectedStudentIds(newStudents)}
                                    onUnselectStudent={newStudents => setSelectedStudentIds(newStudents)}
                                />
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button
                    variant="primary"
                    disabled={assignBtnDisabled}
                    onClick={onBulkAssign}>
                    Assign
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default BulkAssignModal
