import * as React from "react"
import { Row, Col, Button, Modal } from "react-bootstrap"
import ClassForm from "./Forms/ClassForm"
import DeleteClass, { DeleteButton } from "./Forms/DeleteClass"
import ArchiveClass, { ArchiveButton, UnArchiveButton } from "./Forms/ArchiveClass"
import styles from "./teacher.module.scss"
import * as teacher from "../../../shared/routes/teacher"
import { ClassResponse } from "../../../shared/types/teacherTypes"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import oauth from "shared/oauth"
import RelevantHelpModal from "components/Modals/RelevantHelpModal"
import { helpLinkVideos } from "./settingsHelpData"

interface Props {
    activeClass: ClassResponse
    refreshClasses: () => Promise<unknown>
}

export default function Settings({ activeClass, refreshClasses }: Props) {
    const [deleteClassOpen, setDeleteClassOpen] = React.useState(false)
    const [archiveClassOpen, setArchiveClassOpen] = React.useState(false)
    const [removeLinkOpen, setRemoveLinkOpen] = React.useState(false)

    const toggleRemoveLinkOpen = () => setRemoveLinkOpen(b => !b)

    const unarchiveClass = () => {
        return teacher.updateClass({
            id: activeClass.id,
            archived: "No",
            studentAssessmentViewOption: activeClass.studentAssessmentViewOption
        })
        .then(() => {
            toast.success("The class has successfully been unarchived.")
            refreshClasses()
        }).catch((err) => {
            toast.error("There has been an error unarchiving the class, please try again.\n" + err)
        })
    }

    const navigate = useNavigate()
    return (
        <>
            <RelevantHelpModal videos={helpLinkVideos}/>
            <div className="text-start">
                <h1 className="h2 mt-3">Settings</h1>
                <p className={`${styles.greyText}`}>Manage {activeClass.className}.</p>
                <Row className="pt-3">
                    <Col sm={12} md={6} className="mb-5">
                        <h2 className="h4">Class Information</h2>
                        <div className="mx-1">
                            <ClassForm
                                disableSubmitWhenNotModified={true}
                                classFormType="Settings"
                                initialValues={{
                                    className: activeClass.className,
                                    academicYear: activeClass.academicYear ?? null,
                                    term: activeClass.term ?? null,
                                    gradeLevel: activeClass.gradeLevel ?? null,
                                    requireCompletion: activeClass.requireCompletion,
                                    standardId: activeClass.standardId ?? null,
                                    studentAssessmentViewOption: activeClass.studentAssessmentViewOption
                                }}
                                onSubmit={(values) => {
                                    return teacher.updateClass({ ...values, academicYear: values?.academicYear || null, id: activeClass.id })
                                        .then(() => {
                                            toast.success("Class has been successfully edited.")
                                        })
                                        .catch(() => {
                                            toast.error("There was an error updating your class.")
                                        })
                                        .finally(() => {
                                            refreshClasses()
                                        })
                                }}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="mx-5">
                            {activeClass.archived === "No" &&
                                <div className="mb-5">
                                    <h2 className="h4">Archive Class Data</h2>
                                    <p className={styles.greyText}>Makes class read-only and stops students from being able to login to this class.</p>
                                    <ArchiveButton onClick={() => setArchiveClassOpen(true)} />
                                </div>
                            }
                            {activeClass.archived === "Yes" &&
                                <div className="mb-5">
                                    <h2 className="h4">Unarchive Class</h2>
                                    <p className={styles.greyText}>Makes class editable and allows students to login to this class.</p>
                                    <UnArchiveButton onClick={unarchiveClass} />
                                </div>
                            }

                            <h2 className="h4">Delete Class</h2>
                            <p className={styles.greyText}>Deletes class and all student data.</p>
                            <DeleteButton onClick={() => setDeleteClassOpen(true)} />

                            {activeClass.ssoClass &&
                                <div className="my-5">
                                    <h2 className="h4">Remove Clever Class Link</h2>
                                    <p className={styles.greyText}>Disconnect your imported classroom.</p>
                                    <Button variant="theme" onClick={toggleRemoveLinkOpen}><span className="fas fa-unlink me-2"></span>Remove Class Link</Button>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            <DeleteClass
                activeClass={activeClass}
                onHide={() => setDeleteClassOpen(false)}
                show={deleteClassOpen}
                onSuccess={() => {
                    refreshClasses().then(() => {
                        navigate("/dashboard/teacher")
                    })
                }}
            />
            <ArchiveClass
                activeClass={activeClass}
                onHide={() => setArchiveClassOpen(false)}
                show={archiveClassOpen}
                onSuccess={() => {
                    refreshClasses().then(() => {
                        navigate("/dashboard/teacher")
                    })
                }}
            />
        <Modal show={removeLinkOpen} onHide={toggleRemoveLinkOpen}>
            <Modal.Header className="border-0" closeButton>
                <h1 className="h3 w-100 text-center">Remove SSO Class Link</h1>
            </Modal.Header>
            <Modal.Body className="mx-5 text-start">
                <p>If you disconnect your SSO account from STEM Sims, your imported SSO class roster will be disconnected. If any assignments have been assigned to students in this imported roster, these students will no longer have access to those assignments.</p>
                <p><strong>Warning: Once confirmed, you will need to link your imported class again through your account settings to access your class through STEM Sims again.</strong></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="theme" onClick={toggleRemoveLinkOpen}>Cancel</Button>
                <Button
                    variant="danger"
                    onClick={ () => {
                        return oauth.deleteOauthClass({
                            classId: activeClass.id
                        })
                            .then(() => {
                                toggleRemoveLinkOpen()
                                toast.success("SSO link successfully removed")
                                refreshClasses()
                            })
                            .catch(() => {
                                toggleRemoveLinkOpen()
                                //TODO: (STEM-521) Could use some client side error reporting
                                toast.error("There has been an error removing the link, please try again.")
                            })
                    }}
                ><span className="fas fa-unlink me-2"></span>Remove Class Link</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}
