/**
 * Fill Form elements with initial data.
 */
export default function fillFormData(form: HTMLFormElement, formData: Record<string, string>) {
    for (const element of form?.elements ?? []) {
        if (element.tagName === "INPUT" && element.getAttribute("type") === "radio") {

            if ((element as HTMLInputElement).checked) {
                (element as HTMLInputElement).value = formData[element.getAttribute("name")] ?? null
            }
        } else if (element.tagName === "INPUT" || element.tagName === "TEXTAREA" || element.tagName === "SELECT") {
            (element as HTMLInputElement).value = formData[element.getAttribute("name")] ?? null
        }
    }
}
