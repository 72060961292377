import Button from "react-bootstrap/Button"
import AssignmentIframe from "../General/Sim"
import styles from "./styles.module.scss"

interface Props {
    moduleName: string
    onExit: () => void
}

export default function AssignmentBackground({ moduleName, onExit }: Props) {
    return <div className={`${styles.assignmentBackground}`}>
        <div className="d-flex row-cols-3 py-3">
            <div>
                <Button variant="outline-primary" className="float-start my-1" onClick={() => { onExit() }}>Back to Sim</Button>
            </div>
            <h2 className={`h4 text-nowrap mt-2`}>Lesson Background</h2>
        </div>
        <div className={`${styles.assignmentBackgroundText}`}>
            <AssignmentIframe
                iframeTitle={"Assignment Background"}
                src={`/api/iframe/backgrounds?moduleName=${moduleName}`}
                onLoad={(doc) => {
                    doc.documentElement.style.overflow = "auto"
                }} />
        </div>
    </div>
}
