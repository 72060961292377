import { Field, Form as FormikForm } from "formik"
import { Form, Button } from "react-bootstrap"
import styles from "./Quiz.module.scss"

const StudentClassQuizForm = ({ formik }) => {
    return(
        <Form as={FormikForm} className={`text-center ${styles.quizContainer}`}>
            <h5 className={`text-muted mb-3 fw-light text-start ${styles.quizPurpose}`}>The following questionnaire will help you create your student class account.</h5>
            <p className="mb-3 text-start">This account will be shared across all your students so we recommend not to use the same password as your teacher account.</p>
            <Form.Group controlId="formCreateUsername" className="mt-4 mb-2">
                <Form.Label className={`${styles.label} d-block text-muted`}>What is your username for student class account?</Form.Label>
                <Form.Control
                    className={`${styles.input}`}
                    name="username"
                    type="text"
                    placeholder="Username"
                    as={Field}
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    isInvalid={formik.touched.username && !!formik.errors.username}
                />
                <Form.Control.Feedback type="invalid" className="text-start">
                    {formik.errors.username}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formCreatePassword" className="my-2">
                <Form.Label className={`${styles.label} d-block text-muted`}>Choose Password</Form.Label>
                <Form.Control
                    className={`${styles.input}`}
                    name="password"
                    type="password"
                    placeholder="Password"
                    as={Field}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    isInvalid={formik.touched.password && !!formik.errors.password}
                />
                <Form.Control.Feedback type="invalid" className="text-start">
                    {formik.errors.password}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formCreateConfirmPassword" className="my-2">
                <Form.Label className={`${styles.label} d-block text-muted`}>Confirm Password</Form.Label>
                <Form.Control
                    className={`${styles.input}`}
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                    as={Field}
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    isInvalid={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
                />
                <Form.Control.Feedback type="invalid" className="text-start">
                    {formik.errors.confirmPassword}
                </Form.Control.Feedback>
            </Form.Group>
            
            <Button
                className="rounded-lg px-4 py-2 mt-4"
                variant="theme"
                type="submit">
                    Create Class Account
            </Button>
        </Form>
    )
}

export default StudentClassQuizForm
