import React from "react"
import LessonSearch from "../Lessons/Search/Root"
import axios from "axios"
import Button from "react-bootstrap/Button"


export default function LTILessonSearch() {
    const spanRef = React.useRef<HTMLSpanElement>()
    const onSelect = async (lesson) => {
        const body = await axios.post("/api/lti/lesson-search", {
            lessonId: lesson.id,
            lessonVariety: lesson.variety === "Pure Inquiry" ? "Pure Inquiry" : "Guided",
            module: lesson.module,
            type: lesson.type
        })
        //Referenced from https://github.com/christo-pr/dangerously-set-html-content/blob/main/libs/dangerously-set-html-content/src/lib/dangerously-set-html-content.tsx
        const slotHtml = document.createRange().createContextualFragment(body.data.form)
        spanRef.current.innerHTML = ""
        spanRef.current.appendChild(slotHtml)
    }
    const lessonBtn = (lesson) => <Button variant="theme float-end" onClick={() => onSelect(lesson)}>Assign Lesson</Button>
    return (
        <div>
            <LessonSearch
                onLessonSelection={lessonBtn}
                viewType="normal"
            />
            <span ref={spanRef} />
        </div>
    )
}
