import React from "react"
import { Route, Routes, useLocation } from "react-router"
import { useNavigate } from "react-router-dom"
import Container from "react-bootstrap/Container"
import getURLParams from "../../helpers/getURLParams"
import { GradingAssignmentProps } from "shared/types/moduleTypes"
import TeacherAssignmentStudentSubmission from "pages/Dashboard/Teacher/Grades/Submission/SubmissionTeacher"
import TeacherAssignmentPreview from "../Lessons/Grading/AssignmentPreview/Preview"
import TeacherAssignmentGrades, { TeacherGradesState } from "../Lessons/Grading/GradeList/TeacherGrades"

function NavigateWithState() {
    const params = getURLParams() as unknown as GradingAssignmentProps
    const navigate = useNavigate()
    const location = useLocation()
    const currentState = location.state as TeacherGradesState
    React.useEffect(() => {
        let state: TeacherGradesState

        if (currentState?.assignment) {
            state = currentState
        }
        else {
            state = {
                assignment: currentState.assignment,
                lesson: {
                    id: params.lessonId,
                    description: "",
                    title: null,
                    variety: params.lessonVariety,
                    moduleName: params.moduleName,
                    number: parseInt(params.lessonNumber),
                    type: params.lessonType,
                    teacherTips: "",
                    teacherPurpose: "",
                }
            }
        }
        navigate("grade", { state })
    }, [params, navigate, currentState])
    return (
        <p>Redirecting..</p>
    )
}

export default function Grading() {
    return (
        <Container className="mt-5 min-full-screen">
            <Routes>
                <Route path="/grade/submission" element={<TeacherAssignmentStudentSubmission />} />
                <Route path="/grade/preview" element={<TeacherAssignmentPreview />} />
                <Route path="/grade" element={<TeacherAssignmentGrades isLTI={true} />} />
                <Route path="/" element={<NavigateWithState />} />
            </Routes>
        </Container>
    )
}
