import React from "react"
import { BeatLoader } from "react-spinners"
import { Link, useLocation } from "react-router-dom"
import { useModel } from "@stem-sims/nexus"
import modules from "shared/routes/moduleRoutes"
import { AnalyticsClassesResponse, Duration } from "../../../../../models/analytics"
import Pane from "components/Dashboards/General/Pane"
import { Row, Col } from "react-bootstrap"
import StandardsMasteryGraph from "../../Progress/components/StandardsMasteryGraph"
import StandardsMasteryTable from "../../Progress/components/StandardsMasteryTable"
import dashboardStyle from "../../../General/general.module.scss"
import DurationSelector from "components/Dashboards/General/DurationSelector"

export interface InfoStateProps {
    selectedClass: AnalyticsClassesResponse
    referrerUrl: string
    initialSearch: string
    searchOrder: keyof AnalyticsClassesResponse
}

export default function DistrictClassProgress() {
    const location = useLocation()
    const state = location.state as InfoStateProps
    const [duration, setDuration] = React.useState<Duration>("7days")
    const [startDate, setStartDate] = React.useState(new Date())

    React.useEffect(() => {
        const date = new Date()
        date.setDate(date.getDate() - parseInt(duration))
        setStartDate(date)
    }, [duration])

    const { response: progress, loading } = useModel({
        model: modules.getClassStandardsMastery,
        props: {
            startDate: startDate,
            classId: state.selectedClass.id
        }
    })

    if (loading) {
        return <BeatLoader />
    }

    return <>
        <div className="text-start mb-3">
            <Link
                style={{
                    "textDecoration": "none"
                }}
                to="/dashboard/district/classes/info"
                state={{
                    selectedClass: state.selectedClass
                }}
            >
                <i className="fas fa-arrow-left" /> Return to Class
            </Link>
        </div>

        <h1 className="text-start">Class Progress</h1>
        <p className={`${dashboardStyle.greyText} text-start`}>Here you can view class standards progress.</p>

        <Pane>
            <Row>
                <Col sm="2">
                    <DurationSelector
                        setDuration={setDuration}
                        initialDuration={duration}
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{
                    span: 8,
                    offset: 2
                }}>
                    <StandardsMasteryGraph bars={progress.data.standards} />
                    <StandardsMasteryTable data={progress.data.students} title="Student Name" />
                </Col>
            </Row>
        </Pane>
    </>
}

