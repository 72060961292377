import { LessonSubmissionResponse } from "shared/types/moduleTypes"
import { Dispatch, SetStateAction } from "react"
import Table from "components/Lessons/PureInquiry/Table"
import { Button, Modal } from "react-bootstrap"
import useBoolean from "helpers/useBoolean"
import ItemGradeInput from "../components/ItemGradeInput"
import styles from "../submissionTeacher.module.scss"
import { TeacherAssignmentResponse } from "shared/types/teacherTypes"
import modules from "shared/routes/moduleRoutes"
import pureInquiry, { PutTableProps } from "shared/routes/simulations/pureInquiry"

interface Props {
    lessonSubmission: LessonSubmissionResponse
    setLessonSubmission: Dispatch<SetStateAction<LessonSubmissionResponse>>
    sumPoints: (lessonSummary: LessonSubmissionResponse) => number
    lesson: any
    assignment: TeacherAssignmentResponse
    studentLessonId: string
}

const TeacherTableResponses = ({ lessonSubmission, setLessonSubmission, sumPoints, lesson, assignment, studentLessonId } : Props) => {
    const [tableAnswer, toggleTableAnswer] = useBoolean(false)

    const updateTable = ({ grade = undefined, comment = undefined }: { grade?: number, comment?: string }) => {
        if (assignment.lessonVariety === "Guided") {
            const body: { studentLessonId: string, grade?: number, comment?: string } = { studentLessonId }
            if (grade !== undefined) {
                body.grade = grade
            }
            if (comment !== undefined) {
                body.comment = comment
            }
            return modules.updateTableGrade({
                moduleName: lesson.module,
                lessonType: lesson.type,
                lessonNumber: lesson?.number.toString(),
                body
            })
        } else if (assignment.lessonVariety === "Pure Inquiry") {
            const body: Partial<PutTableProps> = {
                pureInquiryId: studentLessonId
            }
            if (grade !== undefined) {
                body.grade = grade
            }
            if (comment !== undefined) {
                body.comment = comment
            }
            return pureInquiry.putTable(body as PutTableProps)
        }
    }

    if (!(lessonSubmission?.tableHtml || lessonSubmission?.tableResponses)) return <></>
    return <div className="px-5 mt-3">
        {lessonSubmission.tableHtml && <>
            <link
                rel={"stylesheet"}
                type={"text/css"}
                href={lessonSubmission.tableCssPath}
            />
            <link
                rel={"stylesheet"}
                type={"text/css"}
                href={"/styles/lesson.css?version=3-2-18"}
            />
            <script type={"text/javascript"} src="/scripts/lessons/data-tables.js" />
            <div
                className={"lessonTable"}
                id={"table-data"}
                data-info={lessonSubmission.tableData}
                dangerouslySetInnerHTML={{ __html: lessonSubmission.tableHtml }}
            />

        </>}

        {lessonSubmission.tableResponses && <>
            <Table rowCount={10} columnCount={6} values={lessonSubmission.tableResponses} />
        </>}

        <Button variant="theme" onClick={toggleTableAnswer}>Show Example Answer</Button>
        <Modal show={tableAnswer} onHide={toggleTableAnswer} size={"xl"} centered>
            <Modal.Header closeButton>
                <Modal.Title>Example Table Answer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img className={styles.tableAnswer} src={lessonSubmission.tableAnswerPath} alt="" />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={toggleTableAnswer}>Close</Button>
            </Modal.Footer>
        </Modal>

        <br />
        <p className="mb-2"><strong>Grade</strong></p>

        <ItemGradeInput
            currentValue={lessonSubmission.tableGrade}
            setValue={(value) => {
                const newLesson = { ...lessonSubmission }
                newLesson.tableGrade = value
                void updateTable({ grade: newLesson.tableGrade })
                newLesson.points = sumPoints(newLesson)
                newLesson.grade = newLesson.points / newLesson.totalPoints * 100
                setLessonSubmission(newLesson)
            }}
        />
        <br />
        <p className="mb-2"><strong>Comments</strong></p>
        <br />
        <textarea
            className={`${styles.commentTextbox} w-100 p-2`}
            defaultValue={lessonSubmission.tableComment ?? ""}
            onBlur={(e) => {
                void updateTable({ comment: e.currentTarget.value })
            }}
        />
    </div>
}

export default TeacherTableResponses
