import { CurriculumUnit } from "shared/types/curriculumTypes";
import { useState, useEffect, useContext } from "react";
import FloatingPage from "../General/FloatingPage";
import { BeatLoader } from "react-spinners";
import { useParams } from "react-router";
import { Accordion, Button, Card, Row, Col } from "react-bootstrap";
import styles from "./Curriculum.module.scss";
import { AuthContext } from "AuthContext";
import { BookContext } from "./BookContext";
import * as curriculumRoutes from "shared/routes/curriculumRoutes"

export default function BookUnitsPage() {
    const authInfo = useContext(AuthContext)
    const bookInfo = useContext(BookContext)
    const textbooks = bookInfo.textbooks

    const { bookId } = useParams()

    const currentBook = textbooks.find((book) => book.id === bookId)

    const [bookUnits, setBookUnits] = useState<CurriculumUnit[]>([])
    const [activeUnit, setActiveUnit] = useState(null)
    const [activeTopic, setActiveTopic] = useState(null)
    const [bookError, setBookError] = useState(false)
    const [viewTopicLoading, setViewTopicLoading] = useState(null);
    const [viewKeyLoading, setViewKeyLoading] = useState(null);

    useEffect(() => {
        curriculumRoutes.getTextbookUnits(bookId).then(data => {
            if (!data) {
                // if status code isn't 200, the route return false
                return setBookError(true)
            }
            const sortedBookUnits = (data as CurriculumUnit[]).sort((a, b) => a.name.localeCompare(b.name))
            setBookUnits(sortedBookUnits)
            if (sortedBookUnits.length > 0) {
                setActiveUnit(sortedBookUnits[0].id)
            }

        }).catch(error => {
            setBookError(true)
        })
    }, [bookId])


    return (
        <FloatingPage className={styles.background}>
            {bookError ? (
                <>
                    <h2>There was an error loading the book. Please return to the <a href="/books">textbook page</a> and try again.</h2>
                </>
            ) : (
                <>
                    {currentBook && (
                        <div className="d-flex p-3">
                            {currentBook.image && <img src={currentBook.image} alt={currentBook.name} height="100px" className="me-3"/>}
                            <div className="d-flex flex-column justify-content-center text-start">
                                <h1>{currentBook.name}</h1>
                                <p>{currentBook.description}</p>
                            </div>
                        </div>
                    )}
                    <h1>Table Of Contents</h1>
                    {currentBook === null ? (
                        <BeatLoader size={10} speedMultiplier={0.8} />
                    ) : (
                        <Accordion className="my-5 text-start" activeKey={activeUnit}>
                            {bookUnits.map((unit) => (
                                <Card key={unit.id} >
                                    <Card.Header 
                                        className="d-flex align-items-center bg-light fs-4 fw-bold" 
                                        style={{ cursor: "pointer" }} 
                                        onClick={() => {
                                            if (activeUnit === unit.id) {
                                                setActiveUnit(null)
                                            } else {
                                                setActiveUnit(unit.id)
                                            }
                                        }}>
                                        <span data-testid={`${activeUnit === unit.id ? "Active" : "Not Active"}`}>{unit.name}</span>
                                        {unit.topics && unit.topics.length > 0 && 
                                            <i className={`fas text-secondary ${activeUnit === unit.id ? `fa-chevron-up` : `fa-chevron-down`} ${styles.downUpArrow}`} />}
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={unit.id}>
                                        <Card.Body className="p-4">
                                            {unit.description}
                                            {unit.topics && unit.topics.length > 0 && (
                                                <Accordion activeKey={activeTopic} className="mt-4">
                                                    {unit.topics.map((topic) => (
                                                        <Card key={topic.id}>
                                                            <Card.Header className={`fs-5 fw-bold`} style={{ cursor: topic.description ? "pointer" : "default" }} onClick={() => {
                                                                if (topic.description) {
                                                                    if (activeTopic === topic.id) {
                                                                        setActiveTopic(null)
                                                                    } else {
                                                                        setActiveTopic(topic.id)
                                                                    }
                                                                } else {
                                                                    setActiveTopic(null)
                                                                }
                                                                }}>
                                                                <Row className="justify-content-between">
                                                                    <Col md={7}>
                                                                        {topic.image && <img src={topic.image} alt="" className="me-4 object-fit-cover" width="50" height="50" />}
                                                                        {topic.name}
                                                                    </Col>
                                                                    <Col md={5} className="text-end d-flex align-items-center justify-content-end">
                                                                        {!authInfo.isStudent && <Button variant="primary" onClick={() => {
                                                                            setViewKeyLoading(topic.id)
                                                                            window.location.assign(`/api/curriculum/topic/answer-key?topicId=${topic.id}`)
                                                                            setTimeout(() => {
                                                                                setViewKeyLoading(null)
                                                                            }, 3000)
                                                                        }}>
                                                                            {viewKeyLoading === topic.id ? (
                                                                                <BeatLoader size={8} color="#ffffff" />
                                                                            ) : (
                                                                                "Answer Key"
                                                                            )}
                                                                        </Button>}
                                                                        <Button variant="primary" className={`${!authInfo.isStudent && "ms-3"}`} onClick={() => {
                                                                            setViewTopicLoading(topic.id)
                                                                            window.location.assign(`/api/curriculum/topic/pdf?topicId=${topic.id}`)
                                                                            setTimeout(() => {
                                                                                setViewTopicLoading(null)
                                                                            }, 3000)
                                                                        }}>
                                                                            {viewTopicLoading === topic.id ? (
                                                                                <BeatLoader size={8} color="#ffffff" />
                                                                            ) : (
                                                                                "View Topic"
                                                                            )}
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey={topic.id}>
                                                                <Card.Body>{topic.description}</Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    ))}
                                                </Accordion>
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    )}
                </>
            )}
        </FloatingPage>
        );
}
