import { Field, Form as FormikForm } from "formik"
import { Form, Col, Button, ToggleButton, ButtonGroup } from "react-bootstrap"
import { TermArray } from "shared/types/moduleTypes"
import { getGradeLevels, getStandards } from "../../models/categories"
import { useEffect, useState } from "react"
import { useModel } from "@stem-sims/nexus"
import styles from "./Quiz.module.scss"

const ClassQuizForm = ({ formik }) => {
    const [selectedTerm, setSelectedTerm] = useState("Spring")
    const [requireCompletion, setRequireCompletion] = useState("No")
    const [gradeLevels, setGradeLevels] = useState<string[]>([])

    const { response: standards, loading } = useModel({
        model: getStandards,
    })

    useEffect(() => {
        getGradeLevels().then(gradeLevels => {
            setGradeLevels(gradeLevels ?? [])
        })
    }, [])

    return (
        <Form as={FormikForm} className={`text-center ${styles.quizContainer}`}>
            <h5 className={`text-muted mb-3 fw-light text-start ${styles.quizPurpose}`}>The following questionnaire will help you create your first class.</h5>
            <Form.Group controlId="formCreateClassName" className="my-4">
                <Form.Label className={`${styles.label} d-block text-muted`}>What is your class name?</Form.Label>
                <Form.Control
                    className={`${styles.input}`}
                    name="className"
                    type="text"
                    placeholder="Class Name"
                    as={Field}
                    onChange={formik.handleChange}
                    value={formik.values.className}
                    isInvalid={formik.touched.className && !!formik.errors.className}
                />
                <Form.Control.Feedback type="invalid" className="text-start">
                    {formik.errors.className}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formCreateClassAcademicYear" className="my-4">
                <Form.Label className={`${styles.label} d-block text-muted`}>Which academic year are you teaching?</Form.Label>
                <Form.Control
                    className={`${styles.input}`}
                    name="academicYear"
                    type="number"
                    placeholder="Academic Year"
                    as={Field}
                    onChange={formik.handleChange}
                    value={formik.values.academicYear}
                    isInvalid={formik.touched.academicYear && !!formik.errors.academicYear}
                />
                <Form.Control.Feedback type="invalid" className="text-start">
                    {formik.errors.academicYear}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formCreateClassTerm" className="my-4" as={Col}>
                <Form.Label className={`${styles.label} d-block text-muted`}>Choose one academic semester you are teaching.</Form.Label>
                <ButtonGroup className={`mt-2 mb-3 d-block text-start ${styles.termsContainer}`}>
                    {TermArray.map((termType) => {
                        return (
                            <ToggleButton 
                                key={termType}
                                id={`${termType}`}
                                type="radio"
                                name="term"
                                className={`${selectedTerm === termType.toString() ? styles.termBgSelect : styles.termBgUnselect} rounded border-0 me-3 mt-2 ${styles.radioBtn}`}
                                value={termType}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                                    {
                                        e.stopPropagation()
                                        setSelectedTerm(termType.toString())
                                        formik.handleChange(e)
                                    }}>
                                    <span className={`text-white`}>{ termType }</span>
                            </ToggleButton>
                        )
                    })}
                </ButtonGroup>
            </Form.Group>
            <Form.Group controlId="formCreateGradeLevel" className="my-4" as={Col}>
                <Form.Label className={`${styles.label} d-block text-muted`}>Which grade level are you teaching for this class?</Form.Label>
                <Form.Select
                    className={`${styles.input}`}
                    name="gradeLevel"
                    placeholder="Select grade level"
                    onChange={formik.handleChange}
                    value={formik.values.gradeLevel}
                    isInvalid={formik.touched.gradeLevel && !!formik.errors.gradeLevel}>
                        <option value="" disabled>-- Select a grade level</option>
                        {gradeLevels.map(grade => (
                            <option key={grade} value={grade}>{grade}</option>
                        ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid" className="text-start">
                    {formik.errors.gradeLevel}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formCreateStandard" className="my-4" as={Col}>
                <Form.Label className={`${styles.label} d-block text-muted`}>Which standard are you teaching for this class?</Form.Label>
                <Form.Select
                    className={`${styles.input}`}
                    name="standardId"
                    placeholder="Select the standard used by the class"
                    onChange={formik.handleChange}
                    value={formik.values.standardId}
                    isInvalid={formik.touched.standardId && !!formik.errors.standardId}>
                    <option value="" disabled>-- Select a standard</option>
                    {!loading && standards?.map(({ id, standard }) => (
                        <option key={id} value={id}>{standard}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid" className="text-start">
                    {formik.errors.standardId}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formRequireCompletion" className="my-4" as={Col}>
                <Form.Label className={`${styles.label} d-block text-muted`}>Should students completely fill out assignments to submit?</Form.Label>
                <ButtonGroup className="mt-2 mb-3 d-block text-start">
                    {["Yes", "No"].map((option) => {
                        return (
                            <ToggleButton 
                                key={option}
                                id={`${option}`}
                                type="radio"
                                name="requireCompletion"
                                className={`${requireCompletion === option ? styles.termBgSelect : styles.termBgUnselect} rounded border-0 fw-bold me-3 ${styles.radioBtn}`}
                                value={option}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                                    {
                                        e.stopPropagation()
                                        setRequireCompletion(option)
                                        formik.handleChange(e)
                                    }}>
                                    <span className={`text-white`}>{ option }</span>
                            </ToggleButton>
                        )
                    })}        
                </ButtonGroup>
            </Form.Group>
            <Button
                className="rounded-lg px-4 py-2 mt-4"
                variant="theme"
                type="submit">
                    Create Your First Class
            </Button>
        </Form>
    )
}

export default ClassQuizForm
