import { Table } from "react-bootstrap"
import styles from "./StudentRecords.module.scss"
import { Type } from 'shared/types/moduleTypes'
import * as teacherRoute from "shared/routes/teacher"
import * as assignmentsRoute from "shared/routes/assignments"
import { useNavigate } from "react-router"

interface AssessmentRecord {
    assessmentNumber: number
    dateSubmitted: string 
    moduleName: string
    numberCorrect: number
    percentageCorrect: string
    time: string
    totalQuestions: number
    type: Type
    submissionId: string | null
    assignmentId: string | null
}

const AssessmentRecordsTab = ({ assessmentRecords } : { assessmentRecords: AssessmentRecord[] }) => {

    const navigate = useNavigate()

    const navigateToSubmission = async (record: AssessmentRecord) => {
        const assignment = await teacherRoute.findAssignmentById(record.assignmentId)
        const submissionsResponse = await assignmentsRoute.getGrades({ assignmentId: record.assignmentId })

        const currentIndex = submissionsResponse.data.findIndex(res => res.submission.id === record.submissionId)
    
        navigate("/dashboard/teacher/assignments/grading/submission", {
            state: {
                submissions: submissionsResponse.data,
                assignment: assignment,
                currentIndex: currentIndex
            }
        })
    }

  return (
    <div className={`mt-4 mx-auto ${styles.row} overflow-auto`}>
        <Table>
            <thead>
                <tr>
                    <th>Simulation&nbsp;Name</th>
                    <th>Assessment</th>
                    <th>Grade</th>
                    <th>Time&nbsp;Taken</th>
                    <th>Date&nbsp;Submitted</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {assessmentRecords?.length !== 0 ? assessmentRecords?.map((record, i) => {
                    return <tr key={i}>
                        <td>{record.moduleName}</td>
                        <td>{record.assessmentNumber} - {record.type}</td>
                        <td>{`${record.percentageCorrect}% (${record.numberCorrect}/${record.totalQuestions})`}</td>
                        <td>{record.time}</td>
                        <td>{record.dateSubmitted}</td>
                        {record.assignmentId ? 
                            <td>
                                <span role="button" className="text-primary" onClick={() => navigateToSubmission(record)}>View&nbsp;Submission</span>
                            </td> : <td></td>}
                    </tr>
                }) : <tr>
                        <td colSpan={5}>There is no data yet.</td>
                    </tr>}
            </tbody>
        </Table>
    </div>
  )
}

export default AssessmentRecordsTab
