import { BeatLoader } from "react-spinners"
import Usage from "./Usage"
import Activity from "./Activity"
import { useModel } from "@stem-sims/nexus"
import analyticsModel, { Timespan } from "../../../../../models/analytics"
import { SelectedGraph } from "../Progress"


interface Props {
    timespan: Timespan
    startDate: Date
    endDate: Date
    selectedGraph: SelectedGraph
}

export default function UsageGraphs({selectedGraph, startDate, endDate, timespan}: Props) {
    const { response: analytics, loading } = useModel({
        model: analyticsModel.getActivity,
        props: {
            start: startDate,
            end: endDate,
            timespan
        }
    })

    if (loading) {
        return <BeatLoader />
    }

    return <>
        {selectedGraph === "activity" && 
            <Activity activity={analytics?.activity} start={startDate} end={endDate} timespan={timespan} />
        }

        {selectedGraph === "usage" && 
            <Usage usage={analytics?.usage} />
        }
    </>
}

