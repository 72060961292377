import { Button } from "react-bootstrap"
import GradeFilter from "components/Lessons/Search/GradeFilter"
import { useEffect, useState } from "react"
import { gradeRangeToTier } from "shared/types/moduleTypes"
import { setSelectedItemDropdown, setSelectedGradeItem } from "helpers/filterItemSelect"
import LessonFilter from "components/Lessons/Search/Filter"
import LessonFilterHeader from "../Lessons/Search/FilterHeader"
import modules from "shared/routes/moduleRoutes"
import { useModel } from "@stem-sims/nexus"
import styles from "./Quiz.module.scss"
import { useNavigate } from "react-router"

const StudentQuizForm = () => {
    const [grades, setGrades] = useState<{ name: string, selected: boolean, invalid: boolean }[]>(Object.keys(gradeRangeToTier).map(grade => ({ name: grade, selected: false, invalid: false })))
    const [selectedGrades, setSelectedGrade] = useState<string[] | null>(null)
    const [categories, setCategories] = useState<{ name: string, id: number, invalid: boolean, selected: boolean }[]>([])
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null)

    const navigate = useNavigate()

    const { response: lessonSearchResults, loading: loadingResults } = useModel({
        model: modules.list,
        props: {
            grades: selectedGrades,
            categoryId: selectedCategoryId,
        }
    })

    useEffect(() => {
        if(!lessonSearchResults) return
        setGrades((grades) =>
            lessonSearchResults.filters.grades.map((grade, index) => ({
                name: grade.level,
                invalid: grade.invalid,
                selected: selectedGrades === null ? false : (grades[index]?.selected ?? false) // if selected grade is null, don't check previous selected state
            }))
        )
        setCategories((categories) =>
            lessonSearchResults.filters.categories.map((category, index) => ({
                name: category.name,
                id: category.id as number,
                invalid: category.invalid,
                selected: selectedCategoryId === null ? false : (categories[index]?.selected ?? false) // if selected category is null, don't check previous selected state
            }))
        )
        // this is already updated when selectCategory and selectGrade are changed, since it changes lessonSearchResults
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },  [lessonSearchResults])

    const searchByRedirect = () => {
        // Remove selectedGrades from localstorage so that current selected grades would be pre-selected on lesson search page
        localStorage.removeItem("selectedGrades")
        navigate("/lessons/search", { state: { grades: grades, grade: selectedGrades, category: selectedCategoryId } })
    }

  return (
        <div className={`text-center ${styles.studentQuizContainer}`}>
            <h5 className={`text-muted mb-3 fw-light text-start ${styles.quizPurpose}`}>
                Your grade(s) and category choices will help us determine the simulations you might be interested in.
            </h5>
            <div className="mt-4 mb-2">
                <div className="d-block text-start mt-2">
                    <LessonFilterHeader
                        title="Select Grade(s) *"
                        onClick={() => {
                            setSelectedGrade(null)
                            setSelectedCategoryId(null)
                        }}
                    />
                </div>
                <div className="d-block text-start mt-2">
                    <GradeFilter
                        grades={grades}
                        onSelectGrade={(index) => {
                            setSelectedGradeItem(grades, index, setGrades, setSelectedGrade)
                            setSelectedCategoryId(null)
                        }}
                        page="quiz" 
                    />
                </div>
            </div>
            <div className="mt-4 mb-2">
                <div className="d-block text-start mt-2">
                    <LessonFilterHeader
                        title={"Select Category *"}
                        onClick={() => setSelectedCategoryId(null)}
                    />
                </div>
                <div className="d-flex mt-2">
                    <LessonFilter
                        type="singleselect"
                        name="category"
                        items={categories}
                        onSelect={(index) => {
                            setSelectedItemDropdown(categories, index, setCategories, setSelectedCategoryId)
                        }}
                        disabled={!selectedGrades}
                        toolTip={"Select Category"}
                        toolTipDisabled="Grade Required"
                        loading={loadingResults}
                    />
                </div>
            </div>
            <Button
                className="rounded-lg px-4 py-2 mt-5"
                variant="theme"
                type="button"
                onClick={searchByRedirect}
                disabled={!selectedCategoryId || !selectedGrades} >
                    <span className="fw-bold px-2">Go to Simulations</span>
            </Button>
        </div>
  )
}

export default StudentQuizForm
