import axios from 'axios'
import { toast } from 'react-toastify'

export function gotoCheckout(productName: string, quantity: number = 1) {
    axios.get(`/api/stripe/checkout-session?productName=${productName}&quantity=${quantity}`)
        .then(response => {
            if (response.status === 200) {
                window.location.href = response.data.url
            } else {
                toast.error("Failed to create checkout session: " + response.statusText)
            }
        })
        .catch(error => {
            toast.error("Failed to create checkout session: " + error.message)
        })
}
