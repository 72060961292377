import React from "react"
import Dropdown from "react-bootstrap/Dropdown"
import { DropDirection } from "react-bootstrap/DropdownContext";
import { FormSelectProps } from 'react-bootstrap';
import Form from "react-bootstrap/Form"
import styles from "./styles.module.scss"
import ToolTip from "components/General/ToolTip";
import Loader from "react-spinners/BeatLoader";

interface FilterProps {
    name: string
    items: { name: string, invalid: boolean, selected: boolean }[]
    onSelect: (index: number) => void
    type: "singleselect" | "multiselect"
    disabled?: boolean
    drop?: DropDirection
    toolTip?: string
    toolTipDisabled?: string
    loading?: boolean
}

const CustomToggle = React.forwardRef<HTMLSelectElement, FormSelectProps>(
    (props, ref) => ( 
        <Form.Select ref={ref} onMouseDown={(e) => e.preventDefault()} {...props} >
            {props.children}
        </Form.Select>
    )
)

export default function LessonFilter({ name, items, onSelect, type, disabled=false, drop="down", toolTip="Open Dropdown", toolTipDisabled="Dropdown Disabled", loading=false }: FilterProps) {
    const selectedItems = items.filter((item) => item.selected)

    let selected = `Select a ${name.toLocaleLowerCase()}...`
    if(selectedItems.length === 1) selected = `${selectedItems[0].name}`
    else if(selectedItems.length === 2) selected = `${selectedItems[0].name} & ${selectedItems[1].name}`
    else if(selectedItems.length > 2) selected = selectedItems.length + " Selected " + name.toLocaleLowerCase() + "s"

    const categoryOptions = items.map((item, index) => (
        <option
            key={item.name + index}
            disabled={item.invalid}
            value={item.name}
        >
            {item.name}
        </option>
    ))

    return (<>
        {type === "multiselect" &&
            <Dropdown drop={drop} className="mt-3">
                <Dropdown.Toggle as={CustomToggle}>
                    <option>{selected}</option>
                </Dropdown.Toggle>
                <Dropdown.Menu className={`${drop === "up" ? styles.dropdownScroll : ''}`}>
                    {
                        items.length === 0 ? <Dropdown.Item disabled={true}>Loading<Loader color={"#999"} size={4} /></Dropdown.Item> : items.map((item, index) => {
                        return (
                            <Dropdown.Item
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onSelect(index)
                                }}
                                disabled={item.invalid}
                                key={item.name + index}
                            >
                                <input
                                    type="checkbox"
                                    checked={item.selected}
                                    className="pe-none"
                                />
                                <span className="ps-3">{item.name}</span>
                            </Dropdown.Item>
                        )
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>
        }
        {type === "singleselect" &&
        <ToolTip title={disabled ? toolTipDisabled : toolTip}>
            <div className="flex-grow-1"> {/* div helps with disabled tooltip appearing */}
                <Form.Select
                    data-testid="category-select"
                    value={loading ? "" : (selectedItems?.[0]?.name || "")}
                    disabled={items.length === 0 || disabled}
                    className={`${disabled && "pe-none"}`}
                    onChange={(e) => {
                        onSelect(items.findIndex((item) => e.target.value === item.name))
                    }}
                >
                    <option value="" disabled={true}>{selected}</option>
                    {loading ? <option className="fw-bold" disabled={true}>Loading...</option> : categoryOptions}
                </Form.Select>
            </div>
        </ToolTip>
        }
    </>)
}
