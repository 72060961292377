import React from "react"
import { BeatLoader } from "react-spinners"
import { MODULE_NO_ACCESS, Type } from "shared/types/moduleTypes"
import pureInquiry from "shared/routes/simulations/pureInquiry"
import getURLParams from "../../../helpers/getURLParams"
import PureInquiryQuestionList from "./PureInquiryQuestionList"
import { sanitizePath } from "shared/helpers/moduleHelper"

interface Props {
    trackingEnabled: boolean
}

const PureInquiry = ({trackingEnabled}: Props) => {
    const params = getURLParams() as { type: "Tier 1" | "Tier 2" | "AP Tier", module: string }
    const [ state, setState ] = React.useState("Filling")
    const started = React.useRef(new Date())
    const answerLengths = {
        "Tier 1": 8,
        "Tier 2": 10,
        "AP Tier": 11
    }

    const [ questionAnswers, setQuestionAnswers ] = React.useState(new Array(answerLengths[params.type]).fill(""))
    const [ tableAnswers, setTableAnswers ] = React.useState(new Array(60).fill(""))

    return <>
        <div role="main" className="main container-fluid margin-container max-width">
            <div className="row">
                <a href={`/simulations/moduleName=${sanitizePath(params?.module)}`} style={{ textDecoration: "none", textAlign: "left" }}>Back to Methods</a>
            </div>
            {state === "Filling" && <>
                <PureInquiryQuestionList
                    trackingEnabled={trackingEnabled}
                    module={params.module}
                    type={params.type}
                    questionAnswers={questionAnswers}
                    setQuestionAnswers={setQuestionAnswers}
                    tableAnswers={tableAnswers}
                    setTableAnswers={setTableAnswers}
                />
                {trackingEnabled &&
                    <button type="submit" onClick={async event => {
                        setState("Submitting")
                        event.preventDefault()
                        pureInquiry.post({
                            module: params.module,
                            type: params.type as Type,
                            started: started.current,
                            questionAnswers: questionAnswers,
                            tableAnswers: tableAnswers,
                            completed: true
                        }).catch(err => {
                            if (err?.response?.data === MODULE_NO_ACCESS) {
                                return window.location.href = `/simulations/moduleName=${params.module}/tab=challenge`
                            }
                        })
                        
                        setState("Submitted")
                    }} className="btn btn-default btn-large d-block ms-auto me-auto">Submit Lesson</button>
                }
            </>
            }
            {state === "Submitting" && <>
                <div className="d-table-cell text-center align-middle">
                    <BeatLoader size={15} />
                </div>
            </>}
            {state === "Submitted" && <>
                <p className="text-center">You can now proceed back to <a href={`/simulations/${params.module}#methods`}>the lessons page.</a></p>
            </>}
        </div>
    </>
}

export default PureInquiry
