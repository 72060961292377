import axios from "axios"
import { Type } from "shared/types/moduleTypes"

interface SubmitAssessmentProps {
    submissionId?: string
    moduleName: string
    assessmentType: Type
    assessmentNumber: number
    postParams: Record<string, unknown>
}

interface UpdateAssessmentProps {
    submissionId: string
    moduleName: string
    assessmentType: Type
    assessmentNumber: number
    putParams: Record<string, unknown>
}

/**
 * Submits an assessment's data to be graded
 */
export function submitAssessment({moduleName, assessmentType, assessmentNumber, submissionId, postParams}: SubmitAssessmentProps) {
    return axios.post(`/api/modules/${moduleName}/assessments/${assessmentType}/${assessmentNumber}`, {
        postParams: postParams,
        submissionId: submissionId
    })
        .then((response) => {
            return response.data
        })
}

/**
 * Update student's assessment submission
 */
export function updateAssessment({ moduleName, assessmentType, assessmentNumber, submissionId, putParams } : UpdateAssessmentProps) {
    return axios.put(`/api/modules/${moduleName}/assessments/${assessmentType}/${assessmentNumber}`, {
        putParams: putParams,
        submissionId: submissionId
    })
    .then((response) => {
        return response.data
    })
}

/**
 * Get the youtube video id for the module
 */
export function getVideoID(moduleName: string): Promise<{ videoPath: string }> {
    return axios.get(`/api/modules/${moduleName}/video`)
        .then((response) => response.data)
}
