import { Form as FormikForm, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { Button, Form } from "react-bootstrap"
import { useEffect, useState } from "react"
import axios from "axios"
import { PlanSelector } from "./PlanSelector"
import { Plan } from "shared/types/adminTypes"
import { toast } from "react-toastify"
import { AuthContext } from "AuthContext"
import React from "react"

export default function CreateAccount() {

    const authContext = React.useContext(AuthContext)
    const isStaff = authContext.isStaff

    const [plans, setPlans] = useState([])
    const [showPlanSelector, setShowPlanSelector] = useState(false)
    const [customPlan, setCustomPlan] = useState<Plan>(null)

    // Get the plans
    useEffect(() => {
        axios.get("/api/account/admin/list-plans").then((res) => {
            setPlans(res.data)
        })
    }, [])

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            licenseType: "trial",
            expiration: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split("T")[0],
            count: 4
        },
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required("Name is required")
                .max(150),
            email: Yup.string()
                .required("Email is required.")
                .email("Please enter a valid email address.")
                .test("email-available", "Email is already taken", checkEmailAvailability)
                .test("username-available", "Username is already taken", checkUsernameAvailability),
            expiration: Yup.date()
                .required("Expiration is required")
                .min(new Date(), "Expiration must be in the future"),
            count: Yup.number()
                .required("Count is required")
                .min(1, "Count must be at least 1")
        }),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)
            let plan = null
            if (values.licenseType === "teacher") {
                plan = plans.find((plan) => plan.displayName === "Class")?.planId
                values.expiration = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split("T")[0]
                values.count = 4
            } else if (values.licenseType === "trial") {
                plan = plans.find((plan) => plan.displayName === "Class")?.planId
                values.expiration = new Date(new Date().setDate(new Date().getDate() + 10)).toISOString().split("T")[0]
                values.count = 4
            } else if (values.licenseType === "custom") {
                if (customPlan) {
                    plan = customPlan.planId
                } else {
                    toast.error("Please select a plan")
                    return
                }
            }

            axios.post("/api/signup", {
                username: values.email,
                name: values.name,
                email: values.email,
                batch: true
            }).then((res) => {
                toast.success("Account created successfully")
            }).catch((err) => {
                toast.error("Account creation failed")
            }).finally(() => {
                axios.post("/api/account/admin/create-license", {
                    username: values.email,
                    planId: plan,
                    expiration: values.expiration,
                    count: values.count
                }).then((res) => {
                    toast.success("License created successfully")
                    formik.resetForm({
                        values: {
                            ...formik.initialValues,
                            licenseType: values.licenseType,
                            expiration: values.expiration,
                            count: values.count
                        }
                    })
                }).catch((err) => {
                    toast.error("License creation failed")
                })
                setSubmitting(false)
            })
        },
    })

    async function checkEmailAvailability() {
        try {
            const email = formik.values.email
            if (email === "") {
                return false
            }
            const emailResponse = await fetch(`/api/email/address/available?email=${email}`)
            const isEmailAvailable = await emailResponse.json()
            return isEmailAvailable
        } catch (error) {
            return false
        }
    }

    async function checkUsernameAvailability() {
        try {
            const username = formik.values.email
            if (username === "") {
                return false
            }
            const userRespose = await fetch(`/api/username/available?username=${username}`)
            const isUsernameAvailable = await userRespose.json()
            return isUsernameAvailable
        } catch (error) {
            return false
        }
    }

    return (
        <>
            <h1>Create Account</h1>
            { isStaff &&
            <div className="d-flex align-items-center justify-content-center w-50 m-auto">
                <FormikProvider value={formik}>
                    <Form as={FormikForm}>
                        <Form.Group controlId="name" className="d-inline-block w-100 text-start mb-4">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="rounded-pill"
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <div>{formik.errors.name}</div>
                            ) : null}
                        </Form.Group>
                        <Form.Group controlId="email" className="d-inline-block w-100 text-start mb-4">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="rounded-pill"
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div>{formik.errors.email}</div>
                            ) : null}
                        </Form.Group>
                        <Form.Group controlId="licenseType" className="d-inline-block w-100 text-start mb-4">
                            <Form.Label>License Type</Form.Label>
                            <div>
                                <Form.Check
                                    type="radio"
                                    id="trial"
                                    name="licenseType"
                                    label="Trial - 10 Day License"
                                    value="trial"
                                    checked={formik.values.licenseType === "trial"}
                                    onChange={formik.handleChange}
                                    onClick={() => setShowPlanSelector(false)}
                                    onBlur={formik.handleBlur}
                                />
                                <Form.Check
                                    type="radio"
                                    id="teacher"
                                    name="licenseType"
                                    label="Teacher - 1 Year License"
                                    value="teacher"
                                    checked={formik.values.licenseType === "teacher"}
                                    onChange={formik.handleChange}
                                    onClick={() => setShowPlanSelector(false)}
                                    onBlur={formik.handleBlur}
                                />
                                <Form.Check
                                    type="radio"
                                    id="custom"
                                    name="licenseType"
                                    label="Custom"
                                    value="custom"
                                    checked={formik.values.licenseType === "custom"}
                                    onChange={formik.handleChange}
                                    onClick={() => setShowPlanSelector(true)}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </Form.Group>
                        { showPlanSelector &&
                        <div>
                            <Form.Group controlId="customExpiration" className="d-inline-block w-100 text-start mb-4">
                                <Form.Label>Custom Expiration</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="expiration"
                                    value={formik.values.expiration}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="rounded-pill"
                                />
                                {formik.touched.expiration && formik.errors.expiration ? (
                                    <div>{formik.errors.expiration}</div>
                                ) : null}
                            </Form.Group>
                            <Form.Group controlId="count" className="d-inline-block w-100 text-start mb-4">
                                <Form.Label>Count</Form.Label>
                                <Form.Control type="number" name="count" value={formik.values.count} onChange={formik.handleChange} onBlur={formik.handleBlur} className="rounded-pill" />
                            </Form.Group>
                            <PlanSelector onSelect={setCustomPlan}
                            />
                        </div>
                        }
                        <Button type="submit" className="me-2">Create Account</Button>
                    </Form>
                </FormikProvider>
            </div>
        }
        { !isStaff &&
            <div>
                <p>You do not have permission to create an account.</p>
            </div>
        }
        </>
    )
}
