import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import { Field } from "formik"
import { Task } from '../../../shared/lessons'
import { useFormikContext } from "formik"
import styles from "./styles.module.scss"
import { useState } from "react"

type TaskFormProps = {
    task: Task,
    sectionNumber: number,
    removeTask: () => void
    path: string
}

function hideImage({ target }) {
    target.parentNode.style.display = "none"
}

function TaskForm({ task, sectionNumber, removeTask, path }: TaskFormProps) {
    const { setFieldValue } = useFormikContext()
    const fieldEditable = (field: string) => {
        return !(["lessonNumber", "module", "type", "category", "questionNumber", "index", "imageUpdated", "imagePath", "marker"].includes(field))
    }
    /**
     * @abstract Adds spaces to camelcase variables
     */
    const formatField = (field: string) => {
        field = field[0].toUpperCase() + field.slice(1)
        return field.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ")
    }
    const [imgInput, setImgInput] = useState<string>()
    return <>
        <div>
            {Object.keys(task).map((field) => fieldEditable(field) &&
                <div key={`header-${field}`} className="mb-4">
                    <h2 className="h5">
                        {task.category} #{task.index} {formatField(field)}
                    </h2>
                    <Field
                        as={'textarea'}
                        name={field}
                        defaultValue={task[field]?.toString()}
                        className={`${styles.lessonInput} ${styles.lessonInputLg} w-75`}
                    />
                </div>
            )}
            <h2 className="h5">{task.category} #{task.index} Image</h2>
            {task.imageUpdated && <div>
                <img
                    onError={hideImage}
                    className={`${styles.lessonImageSm} sm d-block mx-auto my-3`}
                    src={`${path}-section ${sectionNumber}-${task.category.toLowerCase()} ${task.index}.png`}
                    alt={`${task.category}`}
                />
                <Button variant="danger" className="btn-sm" type="button" onClick={(e) => {
                    // setting img to null will remove it in backend once submitted
                    setFieldValue("image", null)
                    hideImage(e)
                }}>
                    Delete Image
                </Button>
            </div>}
            <Row className="my-3 px-3">
                <input type="file"
                    name="image"
                    accept=".png,image/png"
                    allow-empty="true"
                    key={imgInput}
                    className="col"
                    onChange={(e) => setFieldValue("image", e.currentTarget.files[0])}
                />
                <Button className="col-4 btn-theme btn-sm" onClick={() => {
                    setFieldValue("image", undefined)
                    setImgInput("") // react hack for clearing input bc react is lame
                }}>
                    Clear File Selection
                </Button>
            </Row>
            <Button onClick={removeTask}  type="button" variant="danger" className="btn-sm">Remove {task.category}</Button>
            <hr />
        </div>
    </>
}
export default TaskForm
