import React from "react"
import styles from "./general.module.scss"

interface Props {
    title: string
    description: string
}

const EmptyTablePreview: React.FC<Props> = ({ children, title, description }) => {
    return (
        <>
            <div className={styles.addSection}>
                <h2>{title}</h2>
                <p className="w-50 m-auto p-3">{description}</p>
                {children}
            </div>
            <div className={`h-100 ${styles.addBottom}`} />
        </>
    )
}

export default EmptyTablePreview
