import React from "react"
import styles from "./styles.module.scss"
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

interface GradeFilterProps {
    grades: { name: string, invalid: boolean, selected: boolean }[]
    onSelectGrade: (index: number) => void,
    page: "landing" | "quiz"
}

export default function GradeFilter({ grades, onSelectGrade, page }: GradeFilterProps) {

    // Remove "Grades" from Grades K-2, 3-5, 6-12 for display purpose
    const transformGradeName = (grade: string): string => {
        if (grade === "Advanced Placement (AP)") {
            return "AP"
        } else {
            const newGrade = grade.split(" ")[1]
            return newGrade
        }
    }

    return (
        <ToggleButtonGroup type="checkbox" className="mt-2 mb-3">
            {grades.map((grade, index) => {
                return (
                    <ToggleButton
                        key={grade.name}
                        id={`${grade.name}`}
                        className={`${styles.grade} rounded border-0 fw-bold me-3 ${grade.selected ? `bg-primary` : `${page === "landing" ? `bg-white` : styles.gradeBgColor}`}`}
                        value={grade.name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                            {
                                e.stopPropagation()
                                onSelectGrade(index)
                            }}>
                            <span className={`${styles.grade} ${grade.selected || page === "quiz" ? `text-white` : `theme-color`}`}>{ transformGradeName(grade.name) }</span>
                    </ToggleButton>
                )
                })}        
        </ToggleButtonGroup>
    )
}
