import * as qs from "qs"

//duplicate from https://github.com/STEM-Sims/great-leaps-react-common/blob/7ac14da16be6f9f7325869c3d6a4261fe05c4567/src/helpers/getURLParams.ts

/**
 * Returns the GET url parameters on the current page
 * 
 *  * Example:
 *  ```JavaScript
 *  //URL: https://digital.greatleaps.com/probe?number=1
 *  getURLParams()
 *  //Output: { number: 1 }
 *  ```
 */
export default function getURLParams() {
    return qs.parse(window.location.search, { ignoreQueryPrefix: true })
}
