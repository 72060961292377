import { createContext } from "react"

export const AuthContext = createContext(({
    isLoggedIn: false,
    emailVerified: false,
    isStudent: false,
    isAdmin: false,
    isStaff: false,
    trackingEnabled: false,
    csrfToken: "",
    permissions: [],
    hasCurriculumAccess: false,
    triggerAuthRefresh: null
}))

