import styles from "./general.module.scss"

const Pane: React.FC<{ className?: string }> = ({ children, className }) => {
    return (
        <div className={`${styles.collection} ${className}`}>
            {children}
        </div>
    )
}

export default Pane
