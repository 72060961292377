import { LessonSearchItem, LessonType } from "shared/lessons"

export function formatPureInquiry(module: string, tier: LessonType): LessonSearchItem {
    return {
        id: null,
        core: false,
        available: true,
        module: module,
        number: 0,
        title: "Pure Inquiry",
        thumbnail: `/simulations/${module.replace(/[.']/g, "").replace(/ /g, "-").toLowerCase()}/thumbnail.png`,
        type: tier,
        variety: "Pure Inquiry",
    }
}

export function formatGuidedLesson(lesson, availableLessons, showCore, coreMap): LessonSearchItem {
    return {
        core: showCore && coreMap.has(lesson.id) ? coreMap.get(lesson.id) : false,
        module: lesson.module,
        number: lesson.number,
        available: availableLessons ? availableLessons.some(al => al === lesson.id) : true,
        title: lesson.title,
        thumbnail: `/simulations/${lesson.module.replace(/[.']/g, "").replace(/ /g, "-").toLowerCase()}/thumbnail.png`,
        id: lesson.id,
        type: lesson.type,
        variety: "Guided",
        assessment: lesson.assessment
    }
}
