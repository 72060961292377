import React from "react"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import * as modules from "../../models/modules"
import { Type } from "shared/types/moduleTypes"
import { parseFormData } from "../../helpers/formDataHelper"
import Assessment from "./Assessment"
import AssignmentProgress from "./AssignmentProgress"
import styles from "./styles.module.scss"
import { AuthContext } from "AuthContext"
import { checkIfAssessmentComplete, getUnansweredQuestions } from "./helpers/assessmentHelper"
import { Modal } from "react-bootstrap"

interface AssessmentProps {
    assessmentId: string
    moduleName: string,
    assessmentNumber: number,
    type: Type,
    submissionId?: string
    requireCompletion?: boolean
    submitted: () => void
}

export default function AssignmentAssessment({ assessmentId, moduleName, assessmentNumber, type, submissionId, requireCompletion, submitted }: AssessmentProps) {
    const authContext = React.useContext(AuthContext)
    const firstLoadTime = useRef(null)
    const [showModal, setShowModal] = useState(false)
    const [unanswered, setUnanswered] = useState<string[]>(null)
    const isTeacher = authContext.isLoggedIn && !authContext.isStudent
    const [submitting, setSubmitting] = useState(false)

    const submitAssessment = async () => {
        if (isTeacher) {
            return submitted()
        }
        const form = document.getElementById("assessment-form") as HTMLFormElement
        if (requireCompletion) {
            const formCompleteCheck  = checkIfAssessmentComplete(form)
            if (!formCompleteCheck[0]) {
                return toast.error(formCompleteCheck[1])
            }
        }
        setSubmitting(true)

        if (!form) {
            return toast.error("There was an error loading the assessment. Please refresh the page and try again.")
        }
        const putParams = {...parseFormData(form)}
        try {
            await modules.updateAssessment({
                moduleName: moduleName,
                assessmentType: type,
                assessmentNumber: assessmentNumber,
                submissionId: submissionId,
                putParams: putParams
            }).then((res) => {
                if(res.valid) {
                    toast.success(res.message)
                } else {
                    toast.error(res.message)
                }
            }).catch((err) => 
                toast.error(err.response?.data?.message ?? "There was an error submitting the assessment. Please try again.")
            )
            submitted()
        } catch (err) {
            toast.error(
                err?.response?.data?.message ?? "There was an error submitting the assessment. Please try again."
            )
        }
        setSubmitting(false)
    }

    useEffect(() => {
      if (!firstLoadTime.current) {
        firstLoadTime.current = new Date().toISOString().slice(0,19).replace('T',' ')
      }

      const initialSubmit = async () => {
          await modules.submitAssessment({
            moduleName: moduleName,
            assessmentType: type,
            assessmentNumber: assessmentNumber,
            submissionId: submissionId,
            postParams: { startTime: firstLoadTime.current }
          })
      }

      initialSubmit()
    }, [moduleName, type, assessmentNumber, submissionId])

    const submitOrShowModal = () => {
        const form = document.getElementById("assessment-form") as HTMLFormElement
        setUnanswered(getUnansweredQuestions(form))
        const formCompleteCheck  = checkIfAssessmentComplete(form)
        if (!formCompleteCheck[0]) {
            setShowModal(true)
        } else {
            submitAssessment()
        }
    }

    return <>
        <Modal show={showModal} centered size="lg">
            <Modal.Header>
                <Modal.Title className="w-100">Submit Assessment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to submit assessment without completing all the questions?</p>
                <p className="text-muted">Unanswered questions: {unanswered?.map(item => item).join(", ")}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="theme" onClick={() => setShowModal(false)}>No</Button>
                <Button variant="secondary" onClick={submitAssessment}>Yes</Button>
            </Modal.Footer>
        </Modal>

        <div className={styles.assignmentContents}>
            <div className="offset-lg-1 col-lg-10 overflow-auto">
                <Assessment assessmentId={assessmentId} moduleName={moduleName} assessmentNumber={assessmentNumber} type={type} />
                {(moduleName && moduleName.toLowerCase().match("saq")) && <>
                    <p className="font-weight-bold">This assessment includes copyrighted property of Science 21© 2024.</p>
                </>}
            </div>
        </div>
        <Row className={`move-front align-items-center my-4`}>
            <div className="col-sm-7 col-lg-9">
                <AssignmentProgress active="assessment" />
            </div>
            <div className="col-sm-5 col-lg-3">
                <Button className="btn-lg" id="assessment-submit" disabled={submitting} onClick={submitOrShowModal}>
                    {isTeacher ? "Return to Lesson" : "Submit Assessment"}
                </Button>
            </div>
        </Row>
    </>
}
