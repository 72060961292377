import { FilterContainer, Lesson } from "shared/lessons"
import { StudentAssignmentResponse } from "./studentTypes"

export interface ErrorResponse {
    message: string
}

export const MODULE_NO_ACCESS = "You do not have access to this module."

export enum Term {
    "Spring" = "Spring",
    "Summer" = "Summer",
    "Fall" = "Fall",
    "Full Year" = "Full Year"
}
export const TermArray = [
    Term.Spring,
    Term.Summer,
    Term.Fall,
    Term["Full Year"]
]

export const Types = ["Tier 0", "Tier 1", "Tier 2", "AP Tier"] as const
export type Type = typeof Types[number]

export const LessonVarieties = ["Guided", "Pure Inquiry", "Topic"] as const
export type LessonVariety = typeof LessonVarieties[number]

export type Module = {
    name: string
    module: string // todo: this will be deprecated in STEM-1129
    visible: boolean
    lessons?: Lesson[]
    available: boolean
}

export type ModulesResponse = {
    modules: Module[]
    filters: FilterContainer
}

export interface LessonProps {
    moduleName: string
    lessonType: Type
    lessonNumber: string
}

export type SubmissionInfo = {
    id: string,
    studentLessonId: string
}
export interface StudentSubmission {
    submission: SubmissionInfo
    studentId: string
    studentName: string
    lessonGrade: string
    assessmentGrade: string
    completed: string
}

export interface AssignmentProps {
    type: Type,
    moduleName: string,
    lessonVariety: LessonVariety,
    lessonNumber?: number,
    videoId?: string
    assessmentNumber?: number,
    submissionId?: string,
}

export interface GradingAssignmentProps {
    assignmentId: string
    moduleName: string
    lessonVariety: LessonVariety
    lessonId: string
    lessonNumber?: string
    lessonType: Type
}

export interface StudentAssignmentStateProps {
    submissionId: string
    assignment: StudentAssignmentResponse
    continueAssessment: boolean
}

export interface AssignmentSubmissionProps {
    moduleName: string
    lessonType: Type
    lessonNumber: number
    studentLessonId: string
}
export const Masteries = ["Not Mastered", "Approaches Mastery", "Mastered", "Exceeds Mastery"] as const
export type MasteryTypes = (typeof Masteries)[number]

export interface SubmissionResponse {
    section?: number,
    questionNumber?: number,
    question: string
    answer: string
    correctAnswer: string
    grade: number
    comment: string
}

export interface LessonSubmissionResponse {
    studentName: string
    points: number
    totalPoints: number
    started: string
    completed: string
    tableResponses?: string[]
    tableData: string
    tableHtml: string
    tableCssPath: string
    tableAnswerPath: string
    tableGrade: number
    tableComment: string
    labComment: string
    grade: number
    standardsMastery?: {
        lessonStandardId: number,
        mastery: MasteryTypes,
        description?: string
    }[]
    responses: SubmissionResponse[]
}

export type AssessmentSubmissionProps = {
    assessmentId: string
    studentId: string
    submissionId: string
}

export type AssessmentSubmissionResponse = {
    studentName: string,
    completed: boolean,
    grade: number,
    responses: SubmissionResponse[]
}
export const pureInquiryTiers = ["Tier 1", "Tier 2", "AP Tier"] as Type[]
export const tierToGradeRange = {
    "Tier 0": "Grades K-2",
    "Tier 1": "Grades 3-5",
    "Tier 2": "Grades 6-12",
    "AP Tier": "Advanced Placement (AP)"
}
export const gradeRangeToTier = {
    "Grades K-2": "Tier 0",
    "Grades 3-5": "Tier 1",
    "Grades 6-12": "Tier 2",
    "Advanced Placement (AP)": "AP Tier"
}

export const classGradeRange = {
    "3rd Grade": ["Grades 3-5"],
    "4th Grade": ["Grades 3-5"],
    "5th Grade":["Grades 3-5"],
    "6th Grade": ["Grades 6-12"],
    "7th Grade": ["Grades 6-12"],
    "8th Grade": ["Grades 6-12"],
    "9th Grade":["Grades 6-12"],
    "10th Grade": ["Grades 6-12"],
    "11th Grade": ["Grades 6-12"],
    "12th Grade": ["Grades 6-12"],
    "Other": ["Grades K-2", "Advanced Placement (AP)"]
}

export interface PublishGradeProps {
    moduleName: string
    lessonType: Type
    lessonNumber: string
    studentLessonId: string,
    grade?: number,
    comment?: string,
    standardsMastery?: {
        lessonStandardId: number,
        mastery: MasteryTypes
    }[]
}

export interface GetOrganizationStandardsMasteryProps {
    startDate?: Date
    endDate?: Date
}

export interface OrganizationStandardsMasteryGetResponse {
    data: {
        standards: {
            name: string,
            id: string,
            count: {
                [MasteryType in MasteryTypes]: number
            }
        }[]
        classes: {
            id: string,
            name: string,
            overall: number,
            standards: {
                id: string,
                name: string,
                average: number,
                description: string,
            }[]
        }[]
    }
}

export interface GetClassStandardsMasteryProps {
    startDate?: Date
    endDate?: Date
    classId: string
}

export interface GetClassStandardsMasteryResponse {
    data: {
        standards: {
            id: string,
            name: string,
            count: {
                [MasteryType in MasteryTypes]: number
            }
        }[]
        students: {
            id: string,
            name: string,
            overall: number,
            standards: {
                id: string,
                name: string,
                average: number,
                description: string,
            }[]
        }[]
    }
}

export interface GetStudentStandardsMasteryProps {
    startDate?: Date
    endDate?: Date
    classId: string
    studentId: string
}

export interface GetStudentStandardsMasteryResponse {
    data: {
        overall: number,
        standards: {
            id: string,
            name: string,
            average: number,
            description: string,
        }[]
    }
}
