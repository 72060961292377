import React from "react"
import roundToFixed from "shared/helpers/roundToFixed"
import { colors } from "./MasterySharedGlobals"

const TOOL_TIP_WIDTH = 300
const POINTER_WIDTH = 30
const LEFT_PADDING = 10
const TOP_PADDING = 20

interface Props {
    x: number
    y: number
    mastery: {
        "Exceeds Mastery": number
        "Mastered": number
        "Approaches Mastery": number
        "Not Mastered": number
    }
    title: string
}


export default function MasteryGraphTooltip({ x, y, mastery, title }: Props) {

    const noneZeroCategories = React.useMemo(() => {
        return Object.values(mastery).reduce((sum, curr) => {
            if (curr > 0) {
                return sum + 1
            }
            return sum
        }, 0)
    }, [mastery])

    
    const TOOL_TIP_HEIGHT = 50 + noneZeroCategories*25
    const POINTER_SPACING = TOOL_TIP_HEIGHT/2 - 20

    const rectangleCoord = {
        x: x + POINTER_WIDTH,
        y: y - TOOL_TIP_HEIGHT/2,
    }

    return (<>
        <rect
            x={rectangleCoord.x}
            y={rectangleCoord.y}
            width={TOOL_TIP_WIDTH}
            height={TOOL_TIP_HEIGHT}
            fill={"#FFFFFF"}
            stroke={"#CCD2D5"}
            strokeWidth={3}
        />

        {/*
            Draw a triangle on top of the rectangle to create a point
        */}
        <polygon
            points={`
                ${rectangleCoord.x + 3},${rectangleCoord.y + POINTER_SPACING}
                ${rectangleCoord.x + 3},${rectangleCoord.y + TOOL_TIP_HEIGHT - POINTER_SPACING}
                ${x},${y}
            `}
            fill={"#FFFFFF"}
        />
        
        {/*
            Draw a border around the triangle 
        */}
        <path
            d={`
                M ${rectangleCoord.x} ${rectangleCoord.y + POINTER_SPACING}
                L ${x} ${y}
                L ${rectangleCoord.x} ${rectangleCoord.y + TOOL_TIP_HEIGHT - POINTER_SPACING}
            `}
            fill="transparent"
            stroke="#CCD2D5"
            strokeWidth={3}
        />

        <text
            x={rectangleCoord.x + LEFT_PADDING}
            y={rectangleCoord.y + TOP_PADDING}
            alignmentBaseline="middle"
            fontSize={25}
            fontWeight="bold"
        >
            {title}
        </text>

        {/* Draw key with percentages */}
        {(() => {
            let outerOffset = 0
            return Object.keys(mastery).map((key) => {
                if (mastery[key] === 0) {
                    return null
                }

                const COLOR_KEY_SIZE = 20
                const TITLE_TOP_OFFSET = rectangleCoord.y + TOP_PADDING + outerOffset + 25

                outerOffset += 25

                return (<React.Fragment key={key}>
                    <rect
                        x={rectangleCoord.x + LEFT_PADDING}
                        y={TITLE_TOP_OFFSET}
                        width={COLOR_KEY_SIZE}
                        height={COLOR_KEY_SIZE}
                        fill={colors[key]}
                    />
                    <text
                        x={rectangleCoord.x + LEFT_PADDING + COLOR_KEY_SIZE + 5}
                        y={TITLE_TOP_OFFSET + COLOR_KEY_SIZE/2}
                        alignmentBaseline="middle"
                        fontSize={20}
                    >
                        {roundToFixed(mastery[key]*100, 2)}% {key}
                    </text>
                </React.Fragment>)
            })
    })()}

    </>)
}
