import React from "react"
import { Table, Form, Row, Col } from "react-bootstrap"
import { useNavigate, useLocation } from "react-router-dom"
import objectKeySort from "shared/helpers/objectKeySort"
import dashboardStyle from "../../General/general.module.scss"
import classesStyle from "./classes.module.scss"
import CsvExport from "../../../General/CsvExport"
import ToolTip from "../../../General/ToolTip"
import { AnalyticsClassesResponse } from "../../../../models/analytics"
import EmptyTablePreview from "../../General/EmptyTablePreview"
import expandKeys from "../../../../helpers/expandKeys"
import EnumOption from "../../../General/EnumOption"

interface Props {
    classes: AnalyticsClassesResponse[]
    title: string
    description: string
    noClassDescription: string
}


export default function ClassesTable({ classes, title, description, noClassDescription }: Props) {
    const [searchTerm, setSearchTerm] = React.useState("")
    const [searchOrder, setSearchOrder] = React.useState<keyof AnalyticsClassesResponse>(null)
    const navigate = useNavigate()
    const location = useLocation()

    React.useEffect(() => {
        const state = location.state as { initialSearch?: string, searchOrder?: keyof AnalyticsClassesResponse }
        if (state?.initialSearch) {
            setSearchTerm(state.initialSearch)
        }
        if (state?.searchOrder) {
            setSearchOrder(state.searchOrder)
        }
    }, [location])

    let searchedClasses = React.useMemo(() => {
        if (!searchTerm) {
            return classes
        }

        return classes.filter((classItem) => {
            //Find class items that match the search term filter
            return [classItem.className, classItem.gradeLevel, classItem.customerName].some((field) => {
                return field?.includes(searchTerm)
            })
        })
    }, [searchTerm, classes])

    searchedClasses = React.useMemo(() => {
        if (searchOrder === null) {
            return searchedClasses
        }
        return objectKeySort(searchedClasses, searchOrder)
    }, [searchedClasses, searchOrder])

    return (
        <>
            {classes.length > 0 ?
            <>
                <h1 className="text-start mt-3">
                    {title}
                    <CsvExport
                        title="Download Report"
                        fileName="classes.csv"
                        data={searchedClasses.map(expandKeys)}
                    />
                </h1>
                <Row>
                    <Col>
                        <p className={`${dashboardStyle.greyText} text-start`}>
                            {description}
                        </p>
                    </Col>
                    <Col>
                        <div className={classesStyle.searchBox}>
                            <i className="fas fa-search" />
                            <Form.Control
                                type="text"
                                className={`${dashboardStyle.input}`}
                                defaultValue={searchTerm}
                                placeholder="Search for a Class Name, Teacher, or Grade Level"
                                onChange={(event) => {
                                    setSearchTerm(event.currentTarget.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col sm={2}>
                        <Form.Group as={Row}>
                            <Col className="d-flex align-items-center" sm={4}>
                                <Form.Label className="float-end ms-auto">
                                    Sort By
                                </Form.Label>
                            </Col>
                            <Col>
                                <div className={`${classesStyle.orderBySelect}`}>
                                    <Form.Select
                                        data-testid="orderby"
                                        defaultValue={searchOrder}
                                        onChange={(event) => {
                                            setSearchOrder(event.currentTarget.value as keyof AnalyticsClassesResponse)
                                        }}
                                    >
                                        <EnumOption<keyof AnalyticsClassesResponse> value="className">Class Name</EnumOption>
                                        <EnumOption<keyof AnalyticsClassesResponse> value="gradeLevel">Grade Level</EnumOption>
                                        <EnumOption<keyof AnalyticsClassesResponse> value="customerName">Teacher Name</EnumOption>
                                    </Form.Select>
                                </div>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Table hover className="mt-3">
                    <thead>
                        <tr>
                            <th>Class Name</th>
                            <th>Grade Level</th>
                            <th>Teacher Name</th>
                            <th>Students</th>
                            <th>Standards</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchedClasses.map((classItem) => {
                            return (
                                <ToolTip title="Click to view more details" key={classItem.id}>
                                    <tr
                                        role="button"
                                        onClick={() => {
                                            navigate("/dashboard/district/classes/info", {
                                                state: {
                                                    selectedClass: classItem,
                                                    referrerUrl: location.pathname,
                                                    initialSearch: searchTerm,
                                                    searchOrder: searchOrder
                                                }
                                            })
                                        }}>
                                        <th>{classItem.className}</th>
                                        <th>{classItem.gradeLevel}</th>
                                        <th>{classItem.customerName}</th>
                                        <th>{classItem.totalStudents}</th>
                                        <th>{classItem.standards}</th>
                                    </tr>
                                </ToolTip>
                            )
                        })}
                    </tbody>
                </Table>
            </>
                : 
                <EmptyTablePreview title={title} description={noClassDescription} />
            }
        </>
    )
}
