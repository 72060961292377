/**
 * Returns a copy of the object with all keys with null values removed.
 * @param obj the object to remove the null values from.
 * @returns a copy of the object with no null values.
 */
export default function compactObject(obj: Record<string, unknown>): Record<string, unknown> {
    return Object.entries(obj).reduce( (acc, [k, v]) => {
        if (v !== null) {
            acc[k] = v
        }
        return acc
    }, {})
}
