import * as React from "react"
import { Modal, Button } from "react-bootstrap"
import { toast } from "react-toastify"
import * as teacher from "../../../../shared/routes/teacher"
import { StudentResponse } from "../../../../shared/types/teacherTypes"

export interface Props {
    show: boolean
    onHide: () => void
    onSubmit: () => void
    student: StudentResponse
}

export default function ResetStudentPasswordModal({show, onHide, onSubmit, student}: Props) {
    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton className="border-0" />
                <Modal.Body>
                    <h2 className="h3 text-center">Reset Student's Password and Student Code</h2>
                    <p>After resetting the student's password, they will be asked to create a new password when they login next time and will have a new student code to log in with.</p>
                    <p><strong>Are you sure you would like to reset {student?.name}'s password?</strong></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="theme" onClick={onHide}>Cancel</Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            return teacher.resetPassword(student.uuid).then(() => {
                                onSubmit()
                                toast.success("The student's password and student code have been successfully reset.")
                            }).catch((err) => {
                                toast.error(err?.response?.data?.message ?? "There has been an error resetting the student's password. Please try again.")
                            })
                        }}
                    >Reset</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
