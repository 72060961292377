import FloatingPage from "../General/FloatingPage"
import { useContext } from "react"
import { CurriculumBook } from "shared/types/curriculumTypes"
import { Row, Col, Card } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import styles from "./Curriculum.module.scss";
import { AuthContext } from "AuthContext";
import { BookContext } from "./BookContext";
import { useNavigate } from "react-router-dom";

export default function TextbookPage() {
    const authInfo = useContext(AuthContext)
    const bookInfo = useContext(BookContext)
    const textbooks = bookInfo.textbooks

    return (
        <FloatingPage className={styles.background}>
            {authInfo.hasCurriculumAccess && (
                <>
                <h1>Textbooks</h1>
                <Row className="my-5 justify-content-center">
                {textbooks.length === 0 ? (
                    <Col md={4}>
                        <h3>Loading textbooks...</h3>
                        <BeatLoader size={10} speedMultiplier={0.8} />
                    </Col>
                ) : (
                    <>
                        {textbooks.map((textbook) => <TextbookItem key={textbook.id} textbook={textbook} />)}
                    </>
                    )}
                </Row>
                </>
            )}
        </FloatingPage>
    );
}

function TextbookItem({ textbook }: { textbook: CurriculumBook }) {
    const navigate = useNavigate()
    
    return (
        <Col md={4}>
            <Card>
                <Card.Body onClick={() => {
                    navigate(`/books/units/${textbook.id}`)
                }} className={styles.textbookCardBody}>
                    {textbook.image ? (
                        <Card.Img variant="top" src={textbook.image} className="mb-3" />
                    ) : (
                        <Card.Img variant="top" src="/images/placeholder.png" className="mb-3" />
                    )}
                    <Card.Title>{textbook.name}</Card.Title>
                    <Card.Text>{textbook.description}</Card.Text>
                </Card.Body>
            </Card>            
        </Col>
    )
}
