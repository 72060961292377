import { Button, Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import * as teacher from "../../../../shared/routes/teacher"
import { AssignmentResponse } from "../../../../shared/types/teacherTypes"

export interface Props {
    assignment: AssignmentResponse | null
    bulkDelete: boolean
    selectedAssignmentIds: string[]
    show: boolean
    onHide: () => void
    onSuccess: () => void
}

export default function DeleteAssignment({ assignment, bulkDelete, selectedAssignmentIds, show, onHide, onSuccess }: Props) {
    return(
        <Modal show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <h3 className="w-100 text-center">{bulkDelete ? `Delete Selected Assignments` : `Delete Assignment`}</h3>
            </Modal.Header>
            <Modal.Body className="mx-5 text-start">
                {bulkDelete ? 
                    <>
                        <p>Deleting selected assignments will also delete all student submissions associated with these assignments.</p>
                        <p><strong>Are you sure you would like to delete all selected assignments?</strong></p>
                    </> : 
                    <>
                        <p>Deleting this assignment will also delete all student submissions for this assignment.</p>
                        <p><strong>Are you sure you would like to delete {assignment?.title}?</strong></p>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="theme" onClick={onHide}>Cancel</Button>
                <Button
                    variant="danger"
                    onClick={() => {
                        const assignmentsToDelete = bulkDelete ? selectedAssignmentIds : [assignment?.id]
                        return teacher.deleteAssignments({ ids: assignmentsToDelete })
                            .then(() => {
                                onHide()
                                bulkDelete ? 
                                    toast.success("The assignments have successfully been deleted.") :
                                    toast.success("The assignment has successfully been deleted.")
                                
                                onSuccess()
                            })
                            .catch(() => {
                                onHide()
                                //TODO: (STEM-521) Could use some client side error reporting
                                toast.error("There has been an error deleting the assignment, please try again.")
                            })
                    }}
                >
                    Delete Assignment{bulkDelete && `s`}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
