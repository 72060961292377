import * as React from "react"
import styles from "./styles.module.scss"

export default function AssignmentWalkthroughVideo({videoId, onExit}) {

    const backdropRef = React.useRef()
    const backdropRowRef = React.useRef()

    return <>
        <div
            className={styles.assignmentWalkthrough}
            ref={backdropRef}
            data-testid="walkthrough-backdrop"
            onClick={(event) => {
                //WARNING: This will need to be updated if we change the html structure
                const backdropClicked = event.target === backdropRef.current || event.target === backdropRowRef.current

                if (backdropClicked) {
                    onExit()
                }
            }}
        >
            <div 
                className={`${styles.lessonBackdropClose} py-3`}
                onClick={() => onExit()}
                data-testid="walkthrough-exit-icon"
            >
                <i role="button" className="fas fa-times iconButton"></i>
            </div>
            <div 
                className="row h-100"
                ref={backdropRowRef}
            >
                <div className="offset-sm-2 col-sm-8 m-auto g-0">
                    <div className="ratio ratio-16x9">
                        <iframe
                            src = {`https://www.youtube-nocookie.com/embed/${videoId}?rel=0&amp;showinfo=0`}
                            title="Walkthrough Video"
                            allow="fullscreen"
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
}
