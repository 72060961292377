import React from "react"
import styles from "./styles.module.scss"

type Props = {
    rowCount: number
    columnCount: number
    values: string[]
    onChange?: (row: number, column: number, event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export default function Table({ rowCount, columnCount, values, onChange, onBlur }: Props) {
    const rowArray = Array.from(new Array(rowCount).keys())
    const cellArray = Array.from(new Array(columnCount).keys())
    return <>
        <li className="mt-3"><h2 className="h4">Your Data</h2></li>
        <table className={styles.table}>
            <tbody>
                {rowArray.map( rowIndex => (
                    <tr key={`row-${rowIndex}`}>
                        {cellArray.map( cellIndex => (
                            <td className={styles.tableCell} key={`cell-${rowIndex}-${cellIndex}`}>
                                <input className={styles.tableInput} type="text" value={values[rowIndex * columnCount + cellIndex]} onChange={e => onChange(rowIndex, cellIndex, e)} onBlur={onBlur}></input>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </>
}
