import * as React from "react"
import { Modal, Button } from "react-bootstrap"
import { StudentResponse } from "../../../../shared/types/teacherTypes"

export interface Props {
    show: boolean
    onHide: () => void
    onSubmit: () => void
    student: StudentResponse
}

export default function StudentLoginModal({show, onHide, onSubmit, student}: Props) {
    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton className="border-0" />
                <Modal.Body>
                    <h2 className="h3 text-center">Log in as {student?.name}</h2>
                    <p>This will switch the site to being logged in as the student. You will need to log out to access your account again.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="theme" onClick={onHide}>Cancel</Button>
                    <Button
                        variant="success"
                        onClick={onSubmit}
                    >Login</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

