import { MasteryTypes } from "shared/types/moduleTypes"

export const numberToMasteryGrade = (value: number): MasteryTypes => {
    if (value >= 3) {
        return "Exceeds Mastery"
    } else if (value >= 2) {
        return "Mastered"
    } else if (value >= 1) {
        return "Approaches Mastery"
    } else if (value >= 0) {
        return "Not Mastered"
    }
}

export const masteryGradeToNumber = (mastery: MasteryTypes) => {
    if (mastery === "Exceeds Mastery") {
        return 3
    } else if (mastery === "Mastered") {
        return 2
    } else if (mastery === "Approaches Mastery") {
        return 1
    } else {
        return 0
    }
}
