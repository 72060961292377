import * as React from "react"
import { Form, Row, Col, Button, ButtonGroup, ToggleButton } from "react-bootstrap"
import Formik, { useFormik, Field, FormikProvider } from "formik"
import * as Yup from "yup"
import BeatLoader from "react-spinners/BeatLoader"
import { useModel } from "@stem-sims/nexus"
import { getGradeLevels, getStandards } from "../../../../models/categories"
import { TermArray } from "shared/types/moduleTypes"
import styles from "./forms.module.scss"
import { AssessmentViewOptions, AssessmentViewOptionsType, assessmentViewDescriptions } from "shared/types/assessmentTypes"

interface FormItems {
    className: string,
    term: string,
    academicYear: number,
    gradeLevel: string,
    standardId: string,
    requireCompletion: string,
    studentAssessmentViewOption: AssessmentViewOptionsType
}

interface Props {
    initialValues?: FormItems
    onSubmit: (values: FormItems, formikHelpers: Formik.FormikHelpers<FormItems>) => Promise<void>
    /**
     * Disable the save button when the form is not modified. This is mainly used for editing the class.
     */
    disableSubmitWhenNotModified?: boolean
    requireGradeLevel?: boolean
    requireStandard?: boolean
    classFormType: "Settings" | "Add Class"
}


export default function ClassForm({
    initialValues = undefined,
    disableSubmitWhenNotModified = false,
    classFormType = "Add Class",
    onSubmit
}: Props) {

    const initialValuesWithDefaults: FormItems = {
        className: initialValues?.className ?? "",
        term: initialValues?.term ?? null,
        academicYear: initialValues?.academicYear ?? null,
        gradeLevel: initialValues?.gradeLevel ?? null,
        standardId: initialValues?.standardId ?? null,
        requireCompletion: initialValues?.requireCompletion ?? "No",
        studentAssessmentViewOption: initialValues?.studentAssessmentViewOption ?? "Grade",
    }

    const [gradeLevels, setGradeLevels] = React.useState<string[]>([])
    const [selectedAssessmentView, setSelectedAssessmentView] = React.useState<AssessmentViewOptionsType>(null)

    const { response: standards, loading } = useModel({
        model: getStandards,
    })

    React.useEffect(() => {
        getGradeLevels().then(gradeLevels => {
            setGradeLevels(gradeLevels ?? [])
        })
    }, [])

    const formik = useFormik({
        initialValues: initialValuesWithDefaults,
        onSubmit,
        enableReinitialize: true,
        validationSchema: Yup
            .object()
            .shape({
                className: Yup
                    .string()
                    .max(40)
                    .label("Class Name")
                    .required(),
                term: Yup
                    .string()
                    .nullable()
                    .label("Academic Semester"),
                academicYear: Yup
                    .number()
                    .nullable()
                    .label("Academic Year")
                    .max(9999)
                    .min(1900),
                requireCompletion: Yup
                    .string()
                    .label("Require Lesson/Assessment Completion")
                    .required(),
            })
    })

    React.useEffect(() => {
        setSelectedAssessmentView(formik.values.studentAssessmentViewOption)
    }, [formik.values.studentAssessmentViewOption])

    return (
        <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} className="text-start">
                <Form.Group controlId="formCreateClassName" className="my-3">
                    <Form.Label className="fw-bold">Class Name</Form.Label>
                    <Form.Control
                        className={`${styles.input} `}
                        name="className"
                        type="text"
                        placeholder="Enter a useful name to refer to the class as"
                        as={Field}
                        isInvalid={formik.touched.className && !!formik.errors.className}
                        disabled={formik.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid" className="text-center">
                        {formik.errors.className}
                    </Form.Control.Feedback>
                </Form.Group>
                <Row>
                    <Col>
                        <Form.Group controlId="formCreateClassAcademicYear" className="my-3">
                            <Form.Label className="fw-bold">Academic Year</Form.Label>
                            <Form.Control
                                className={`${styles.input}`}
                                name="academicYear"
                                type="number"
                                placeholder="Enter the year this class is for"
                                as={Field}
                                isInvalid={formik.touched.academicYear && !!formik.errors.academicYear}
                                disabled={formik.isSubmitting}
                            />
                            <Form.Control.Feedback type="invalid" className="text-center">
                                {formik.errors.academicYear}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formCreateClassTerm" className="my-3" as={Col}>
                            <Form.Label className="fw-bold">Academic Semester</Form.Label>
                            <Form.Select
                                className={`${styles.input}`}
                                name="term"
                                placeholder="Select the semester this class is for"
                                onChange={(e) => {
                                    formik.setFieldValue("term", e.target.value === "-- Select a term" ? null : e.target.value)
                                }}
                                value={formik.values.term}
                                isInvalid={formik.touched.term && !!formik.errors.term}
                                disabled={formik.isSubmitting}
                            >
                                <option value={null} selected={!formik.values.term}>-- Select a term</option>
                                {TermArray.map(termType => (
                                    <option key={termType} value={termType}>{termType}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid" className="text-center">
                                {formik.errors.term}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formCreateGradeLevel" className="my-3" as={Col}>
                            <Form.Label className="fw-bold">Grade Level</Form.Label>
                            <Form.Select
                                className={`${styles.input}`}
                                name="gradeLevel"
                                placeholder="Select the grade level of the class"
                                onChange={(e) => {
                                    formik.setFieldValue("gradeLevel", e.target.value === "-- Select a grade level" ? null : e.target.value)
                                }}
                                value={formik.values.gradeLevel}
                                isInvalid={formik.touched.gradeLevel && !!formik.errors.gradeLevel}
                                disabled={formik.isSubmitting}
                            >
                                <option value={null} selected={!formik.values.gradeLevel}>-- Select a grade level</option>
                                {gradeLevels.map(grade => (
                                    <option key={grade} value={grade}>{grade}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid" className="text-center">
                                {formik.errors.gradeLevel}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formCreateStandard" className="my-3" as={Col}>
                            <Form.Label className="fw-bold">Standard</Form.Label>
                            <Form.Select
                                className={`${styles.input}`}
                                name="standardId"
                                placeholder="Select the standard used by the class"
                                onChange={(e) => {
                                    formik.setFieldValue("standardId", e.target.value === "-- Select a standard" ? null : e.target.value)
                                }}
                                value={formik.values.standardId}
                                isInvalid={formik.touched.standardId && !!formik.errors.standardId}
                                disabled={formik.isSubmitting}
                            >
                                <option value={null} selected={!formik.values.standardId}>-- Select a standard</option>
                                {!loading && standards?.map(({ id, standard }) => (
                                    <option key={id} value={id}>{standard}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid" className="text-center">
                                {formik.errors.standardId}
                            </Form.Control.Feedback>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Form.Group controlId="formRequireCompletion" className="my-3" as={Col}>
                        <Form.Label className="fw-bold">Require Lesson/Assessment Completion</Form.Label>
                        <Form.Select
                            className={`${styles.input}`}
                            name="requireCompletion"
                            placeholder="Select if the lessons and assessments must be fully filled to submit"
                            onChange={formik.handleChange}
                            value={formik.values.requireCompletion}
                            disabled={formik.isSubmitting}
                        >
                            <option value="" disabled>-- Select an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
                {classFormType === "Settings" &&
                    <Row>
                        <Form.Group className="mb-3 text-start" as={Col}>
                            <Form.Label>Choose Student Assessment View Option</Form.Label>
                            <ButtonGroup className={`mt-2 mb-1 text-start ${styles.assessmentViewOptionsContainer}`}>
                                {AssessmentViewOptions.map((option) => {
                                    return (
                                        <ToggleButton 
                                            id={`${option}`}
                                            type="radio"
                                            name="studentAssessmentViewOption"
                                            className={`${selectedAssessmentView === option ? styles.termBgSelect : styles.termBgUnselect} rounded border-0 fw-bold me-2 mb-1 ${styles.assessmentViewOption}`}
                                            value={option}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                e.stopPropagation()
                                                setSelectedAssessmentView(option)
                                                formik.handleChange(e)
                                            }}>
                                                <span className={`text-white`}>{ option }</span>
                                        </ToggleButton>
                                    )
                                })}
                            </ButtonGroup>
                            <p className="text-muted fst-italic fw-lighter">{assessmentViewDescriptions[selectedAssessmentView]}</p>
                            <Form.Control.Feedback type="invalid" className="text-center">
                                {formik.errors.studentAssessmentViewOption}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>}
                <div className={`mt-2 ${styles.saveBtnContainer}`}>
                    <Button
                        className={styles.saveBtn}
                        variant="theme"
                        type="submit"
                        disabled={
                            formik.isSubmitting
                            || (
                                disableSubmitWhenNotModified && (
                                    !formik.isValid ||
                                    !formik.dirty
                                    )
                                    )
                                }
                                >
                        {formik.isSubmitting ?
                            <BeatLoader size={10} />
                            : "Save"
                        }
                    </Button>
                </div>
            </Form>
        </FormikProvider>
    )
}
