import { useContext, useState } from "react"
import "bootstrap"
import getURLParams from "../helpers/getURLParams"
import removeMessageQuery from "../helpers/removeMessageQuery"
import LogoutWarning from "./Logout/LogoutWarning"
import TranslateWidget from "./General/TranslateWidget"
import { logout } from "shared/routes/auth"
import { toast } from "react-toastify"
import { useNavigate } from "react-router"
import { AuthContext } from "AuthContext"

type Props = {
    isLoggedIn: boolean
    isStudent: boolean
    isAdmin: boolean
    isStaff: boolean
    trackingEnabled: boolean
    project: string
    csrfToken: string
    hasCurriculumAccess: boolean
}

export default function Header({ project, isLoggedIn, isAdmin, isStaff, isStudent, trackingEnabled, csrfToken, hasCurriculumAccess }: Props) {
    const [ params ] = useState<{ success?: string, error?: string, info?: string }>(getURLParams())
    const cleanedUrl = new URL(removeMessageQuery(window.location.href))
    window.history.replaceState({}, "", cleanedUrl.href)
    const cleanedRelativePath = `${cleanedUrl.pathname}${cleanedUrl.search}${cleanedUrl.hash}`
    const editing = process.env.REACT_APP_EDITING === "true"
    const [ showTranslateWidget, setShowTranslateWidget ] = useState(false)
    const navigate = useNavigate()
    const { triggerAuthRefresh } = useContext(AuthContext)

    const logOut = async (e) => {
        e.preventDefault()
        logout({ referrer: cleanedRelativePath }).then(res => {
            navigate(res.data.url)
            triggerAuthRefresh()
            return toast.success("You have successfully logged out.")
        })
    }

    return <>
        <LogoutWarning isLoggedIn={isLoggedIn} />
        <nav className="navbar navbar-expand-md navbar-light shadow-sm bg-white fixed-top px-1 pt-0 pb-0 d-print-none d-flex flex-wrap" >
            <div className="navbar-image navbar-brand navbar-brand-default ps-5 py-2 d-none d-md-block">
                <a href="/">
                    <img
                        src="/images/logo.png"
                        alt={`${project} Logo`}
                        title="Home" />
                </a>
            </div>
            <button className="navbar-toggler position-absolute top-0 mt-2 d-md-none d-flex align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                        <img
                            src="/images/logo.png"
                            alt={`${project} Logo`}
                            className="d-none d-sm-block" 
                            width="30"
                            height="30" />
                    <span className="mx-2 d-none d-sm-block">STEM Sims</span>
                    <span className="navbar-toggler-icon"></span>
            </button>
            
            <div className="collapse navbar-collapse flex-column align-items-start justify-content-start text-start order-1 order-md-0" id="navbarContent">
                <div className="pb-3 pb-md-0 px-5 px-md-0 ms-auto">
                    <ul className="navbar-nav">
                        <li className="nav-item d-md-none mt-3">
                            <a href="/" className="nav-link">Home</a>
                        </li>
                        {!isStudent && (
                            <li className="nav-item">
                                <a href="/about/" className="nav-link">About</a>
                            </li>
                        )}
                        <li className="nav-item">
                            <a href="/lessons/search" className="nav-link">Simulations</a>
                        </li>
                        {isLoggedIn && !isStudent && trackingEnabled && (
                            <li className="nav-item">
                                <a href="/dashboard/teacher" className="nav-link">Classes</a>
                            </li>
                        )}
                        {isLoggedIn && isStudent && trackingEnabled && (
                            <li className="nav-item">
                                <a href="/dashboard/student/assignments" className="nav-link">Assignments</a>
                            </li>
                        )}
                        {isAdmin && (
                            <li className="nav-item">
                                <a href="/dashboard/district" className="nav-link">Analytics</a>
                            </li>
                        )}
                        {isStaff && (
                            <li className="nav-item">
                                <a href="/admin" className="nav-link">Admin</a>
                            </li>
                        )}
                        {(hasCurriculumAccess) && (
                            <li className="nav-item">
                                <a href="/books" className="nav-link">Textbooks</a>
                            </li>
                        )}
                        {editing && (
                            <li className="nav-item">
                                <a href="/simulations/categories/" className="nav-link">Categories</a>
                            </li>
                        )}
                        {!isStudent && !trackingEnabled && (
                            <li className="nav-item">
                                <a href="/subscribe" className="nav-link">Subscribe</a>
                            </li>
                        )}
                        {isLoggedIn && !isStudent && (
                            <li className="nav-item">
                                <a href="/account" className="nav-link">Account</a>
                            </li>
                        )}
                        <li className="nav-item">
                            <a href="/help" className="nav-link">Help</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="ms-auto d-grid d-md-flex align-items-center justify-content-end py-2">
                <div className="d-flex align-items-center order-md-2">
                    {isLoggedIn ?
                         <div>
                            <button className="btn btn-default navbar-btn ms-3" onClick={logOut}>
                                Log Out
                            </button>
                         </div>:
                        <div className="d-flex align-items-center">
                            <a href="/account/sign-up" className="btn btn-primary navbar-btn ms-3 me-3 me-sm-0">Sign Up</a>
                            <span className="px-2 d-none d-sm-block">or</span>
                            <a href="/account/log-in" id="log-in-button" className="btn btn-outline-primary navbar-btn">Log In</a>
                        </div>
                    }
                    <span className="fa fa-globe px-3 text-primary fs-5" onClick={() => setShowTranslateWidget(!showTranslateWidget)}></span>
                    <TranslateWidget show={showTranslateWidget} />
                </div>
                

                
            </div>
        </nav>
        <div role="region" className="container-fluid max-width padding-header d-print-none">
            <div className="row">
                { params.error &&
                    <div className="alert alert-danger">{params.error}</div>
                }
                { params.success &&
                    <div className="alert alert-success">{params.success}</div>
                }
                { params.info &&
                    <div className="alert alert-info">{params.info}</div>
                }
            </div>
        </div>
    </>
}
