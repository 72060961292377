import FloatingPage from "components/General/FloatingPage";
import { useState } from "react";
import CreateAccount from "./CreateAccount";
import ManageAccount from "./ManageAccount";
import DeleteAccount from "./DeleteAccount";
import styles from "./admin.module.scss"
import { Button } from "react-bootstrap";
import React from "react";
import { AuthContext } from "AuthContext";

function AdminPage() {
    const authContext = React.useContext(AuthContext)
    const isStaff = authContext.isStaff

    const [currentTab, setCurrentTab] = useState<string>("create")


    return (<>
            {isStaff ? ( <>
                <FloatingPage className={styles.background}>
                    <h1>Account Admin</h1>
                    <div className={styles.buttonContainer}>
                        <Button variant={currentTab === "create" ? "primary" : "secondary"} onClick={() => setCurrentTab("create")}>Create Account</Button>
                        <Button variant={currentTab === "manage" ? "primary" : "secondary"} onClick={() => setCurrentTab("manage")}>Manage Licenses</Button>
                        <Button variant={currentTab === "delete" ? "primary" : "secondary"} onClick={() => setCurrentTab("delete")}>Delete Account</Button>
                    </div>
                    <hr />
                    {currentTab === "create" ? <CreateAccount /> : null}
                    {currentTab === "manage" ? <ManageAccount /> : null}
                    {currentTab === "delete" ? <DeleteAccount /> : null}
                </FloatingPage>
                </>
            ) : (
                <FloatingPage className={styles.background}>
                    <h1>You are not an admin</h1>
                </FloatingPage>
            )}
        </>
    )
}

export default AdminPage