import React from "react"
import BeatLoader from "react-spinners/BeatLoader"
import BarChart, { Props as BarChartProps } from "../../../../General/BarChart"
import { AnalyticsActivityResponse, AnalyticsActivityProps } from "../../../../../models/analytics"

interface Props {
    fillColor: BarChartProps["fillColor"]
    activity: AnalyticsActivityResponse["activity"]["teacher"]
    start: AnalyticsActivityProps["start"]
    end: AnalyticsActivityProps["end"]
    timespan: AnalyticsActivityProps["timespan"]
}

export default function OvertimeGraph({fillColor, activity, start, end, timespan} : Props) {
    const graphData = React.useMemo(() => {
        if (!activity) {
            return null
        }
        let dateMutator: keyof Date
        let dateGetter: keyof Date
        let mutateAmount: number = 1
        if (timespan === "day") {
            //This is a little confusing because .getDate/setDate sets the day of the month...
            //but, when the day is outside the range of the month it will update the month
            dateMutator = "setDate"
            dateGetter = "getDate"
        }
        else if (timespan === "month") {
            dateMutator = "setMonth"
            dateGetter = "getMonth"
        }
        else if (timespan === "week") {
            dateMutator = "setDate"
            dateGetter = "getDate"
            mutateAmount = 7
        }
        else if (timespan === "year") {
            dateMutator = "setFullYear"
            dateGetter = "getFullYear"
        }
        else {
            throw new Error("There has been an invalid timespan parameter passed")
        }

        const data = {}
        const dateFormatter = new Intl.DateTimeFormat(undefined, { month:"short", day:"2-digit" })
        const cursor = new Date(start)
        const endCursor = new Date(end)
        for (let i = 0; endCursor > cursor; i++) {
            data[dateFormatter.format(cursor)] = activity[i]
            cursor[dateMutator](cursor[dateGetter]() + mutateAmount)
        }

        return data
    }, [activity, end, start, timespan])

    return (
        graphData !== null ?
        <BarChart fillColor={fillColor} data={graphData} />
        :
        <BeatLoader size={15} />
    )
}
