import axios from "axios";

export interface AnalyticsClassesResponse {
    id: string
    className: string
    customerUsername: string
    customerName: string
    gradeLevel: string
    totalStudents: number
    academicYear: number
    academicSemester: "Fall" | "Spring" | "Summer" | "Full Year"
    standards: string
    classAverage: number
}

export type Duration = "7days" | "28days" | "90days" | "180days" | "365days"

function getAnalytics(): Promise<AnalyticsClassesResponse[]> {
    return axios.get("/api/teacher/classes/analytics")
        .then(({data}) => data)
}

export type Timespan = "day" | "week" | "month" | "year"

export interface AnalyticsActivityResponse {
    usage: {
        teacher: {
            monday: number[]
            tuesday: number[]
            wednesday: number[]
            thursday: number[]
            friday: number[]
            saturday: number[]
            sunday: number[]
        }
        student: {
            monday: number[]
            tuesday: number[]
            wednesday: number[]
            thursday: number[]
            friday: number[]
            saturday: number[]
            sunday: number[]
        }
    }
    activity: {
        teacher: number[]
        student: number[]
        timespan: Timespan
    }
}

export interface AnalyticsActivityProps {
    start: Date
    end: Date
    timespan: Timespan
}

function getActivity(params: AnalyticsActivityProps): Promise<AnalyticsActivityResponse> {
    return axios.get("/api/organization/analytics", { params })
        .then(({data}) => data)
}

export interface WatchedClassesResponse {
    watched: {
        id: string
    }[]
}

function getWatched(): Promise<WatchedClassesResponse> {
    return axios.get("/api/teacher/classes/watched")
        .then(({data}) => data)
}

function createWatched(props: { classId }) {
    return axios.post("/api/teacher/classes/watched", props)
        .then(({data}) => data)
}


function deleteWatched(props: { classId }) {
    return axios.delete("/api/teacher/classes/watched", {
        data: props
    })
        .then(({data}) => data)
}

const analytics = {
    getAnalytics,
    getActivity,
    getWatched,
    createWatched,
    deleteWatched
}

export default analytics
