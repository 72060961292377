import React from "react"
import { toast } from "react-toastify"
import analytics, { AnalyticsClassesResponse } from "../../../../models/analytics"
import ClassesTable from "../components/ClassesTable"


export default function DistrictClasses() {
    const [classes, setClasses] = React.useState<AnalyticsClassesResponse[]>([])

    React.useEffect(() => {
        analytics.getAnalytics()
            .then(setClasses)
            .catch(() => {
                toast.error("There has been an error loading the watched classes.")
            })
    }, [])

    return (
        <>
            <ClassesTable
                classes={classes}
                title="Classes"
                description="All classes your account oversees, click on any of them to get more information."
                noClassDescription="Your teachers have not created any classes yet."
            />
        </>
    )
}
