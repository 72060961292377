import { useFormikContext } from "formik"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import BeatLoader from "react-spinners/BeatLoader"
import { Lesson } from "shared/lessons" 
import { hideImage, showImage } from "helpers/imageVisibility"
import Pane from "../General/Pane"
import CustomHTML from "components/CustomHTML"

export function EditImage({input, setInput}) {
    const { setFieldValue } = useFormikContext()
    return <Row className="my-3 px-3">
        <input type="file"
            name="image"
            accept=".png,image/png"
            allow-empty="true"
            key={input}
            className="col"
            onChange={(e) => setFieldValue("teacherTipsImage", e.currentTarget.files[0])}
        />
        <Button className="col-4 btn-theme btn-sm" onClick={() => {
            setFieldValue("teacherTipsImage", undefined)
            setInput("") // react hack for clearing input bc react is lame
        }}>
            Clear File Selection
        </Button>
    </Row>
}

export default function TeacherTips({guidedLesson}: {guidedLesson: Lesson}) {

    const editing = process.env.REACT_APP_EDITING === "true"

    if(!guidedLesson) {
      return(<div className="d-table h-100 mx-auto">
          <div className="d-table-cell text-center align-middle">
              <BeatLoader size={15} />
          </div>
      </div>)
    }
  
    const modulePath = `/simulations/${ guidedLesson.moduleName?.toLowerCase().replace(/ /g,'-').replace(/[.']/g, "") }`
    const lessonPath = `${modulePath}/lessons/lesson-${ guidedLesson.number }-${ guidedLesson.type.toLowerCase() }`

    return (<>
        {(guidedLesson?.teacherTips || editing) &&
            <Pane className="mb-3">
                <h2>Teacher Tips</h2>
                <hr />
                <p data-testid="teacher-tips" className="text-start">
                    <CustomHTML html={guidedLesson.teacherTips ?? "No teacher tips have been added" }/>
                </p>
                <img
                    onError={hideImage}
                    onLoad={showImage}
                    src={`${lessonPath}-teacher-tips.png`}
                    alt={"additional teacher tips"}
                />
            </Pane>
          }
    </>)
}
