import CustomHTML from "components/CustomHTML"
import Pane from "components/Dashboards/General/Pane"
import { AssessmentSubmissionResponse } from "shared/types/moduleTypes"

interface Props {
    assessmentSubmission: AssessmentSubmissionResponse
}

const TeacherAssessmentResponses = ({ assessmentSubmission } : Props) => {
    if (!assessmentSubmission) return <></>
    return <Pane className="mt-3 shadow p-4">
        <h1 className="h3 text-start pb-3 fw-bold text-center">Assessment Responses</h1>
        <ol>
            {assessmentSubmission.responses?.map((response, index) => {
                const correct: boolean = response.answer === response.correctAnswer

                return (<Pane className="mb-4 shadow-sm">
                    <div className="text-start" key={`${assessmentSubmission.completed} ${index}`}>
                        <span className={"pe-2 " + (response.answer ? (correct ? "text-success" : "text-danger"): "text-secondary")}>
                            <span className="fw-bold">{response.questionNumber ?? (index + 1)}.</span> <CustomHTML html={response.question} />
                            {response.answer && <i className={`fas ${correct ? `fa-check text-success` : `fa-times text-danger`} ms-2`} />}
                        </span>
                        <br /><br />
                        {response.answer && 
                            <div className="mb-2"><strong>Student's Answer: </strong><CustomHTML html={response.answer} /></div>
                        }
                        {/* Only show the correct answer if the student provided an incorrect answer */}
                        {!correct && response.correctAnswer &&
                            <div className="mb-2"><strong>Correct Answer: </strong><CustomHTML html={response.correctAnswer} /></div>
                        }
                    </div>
                </Pane>)
            })}
        </ol>
    </Pane>
}

export default TeacherAssessmentResponses
