import getURLParams from "../../helpers/getURLParams"
import AssignmentComponent from "../Assignment/AssignmentComponent"
import { AssignmentProps } from "shared/types/moduleTypes"

export default function Assignment() {
    const params = getURLParams() as unknown as AssignmentProps
    return (
        <AssignmentComponent {...params} goBack={() => window.close()}/>
    )
}
