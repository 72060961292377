import axios from "axios"
import { ClassResponse, StudentAssignmentResponse } from "shared/types/studentTypes"

/**
 * Get all classes that student is part of
 */
function getClasses(): Promise<ClassResponse[]> {
    return axios.get("/api/student/classes")
        .then((response) => response.data)
}

/**
 * Get all assignments for a particular class
 */
function getAssignments({ classId }: { classId: string }): Promise<StudentAssignmentResponse[]> {
    return axios.get("/api/student/assignments", {
        params: { classId }
    })
        .then((response) => response.data)
}

/**
 * Get a particular assignment
 */
function getAssignment(assignmentId: string): Promise<StudentAssignmentResponse> {
    return axios.get(`/api/assignments/${assignmentId}`, {
        params: { assignmentId }
    }).then((response) => response.data)
}

const students = {
    getClasses,
    getAssignments,
    getAssignment
}

export default students
