import CustomHTML from "components/CustomHTML"
import { Row, Col } from "react-bootstrap"
import styles from "./ModulePage.module.scss"

type LessonGroupProps = { title?: string, type?: string, lessons?: any[] }

type TeacherProps = {
    tier1Updated?: string
    tier2Updated?: string
    modulePath: string
    lessonGroups?: LessonGroupProps[]
}

const TeacherTab = ({ tier1Updated, tier2Updated, modulePath, lessonGroups } : TeacherProps) => {
  return (
    <div className="mt-5">
        <h1>Individual Lesson Guides</h1>
        <hr className="w-75 m-auto" />
        {lessonGroups?.map((lessonGroup, i) => <div className="mt-3" key={i}>
            {lessonGroup?.lessons.length > 0 && <div className={`text-start ${styles.container}`}>
                <h2>{lessonGroup?.title}</h2>
                <ol>
                    {lessonGroup?.lessons?.map((lesson, index) =>
                        <li className="p-1" key={index}>
                            <a href={`/api/lessons/${lesson?.id}/guides/lessonGuides`} className="text-decoration-none" target="_blank" rel="noreferrer">{<CustomHTML html={lesson?.title} />}</a>
                        </li>)}
                </ol>
            </div>}
        </div>)}

        <div className="mt-5"></div>
        {(tier1Updated || tier2Updated) && <h1>Module Guides</h1>}
        {(tier1Updated || tier2Updated) && <hr className="w-75 m-auto" />}
        <Row className={`justify-content-center ${styles.container}`}>
            {tier2Updated && 
                <Col xs={12} md={6}>
                    <a href={`${modulePath}teacher/teacher.pdf?version=${tier2Updated}`} className="text-decoration-none" target="_blank" rel="noreferrer">
                        <img className="img-fluid" src={`${modulePath}teacher/teacher-thumbnail.png?version=${tier2Updated}`} alt="Teacher's Guide Tier 2: Grades 6 - 12+" />
                        <h2>Teacher's Guide Tier 2: Grades 6 - 12+</h2> 
                    </a>
                </Col>}
            {tier1Updated &&
                <Col xs={12} md={6}>
                    <a href={`${modulePath}teacher/teacher.pdf?version=${tier1Updated}`} className="text-decoration-none" target="_blank" rel="noreferrer">
                        <img className="img-fluid" src={`${modulePath}teacher/teacher-thumbnail.png?version=${tier1Updated}`} alt="Teacher's Guide Tier 2: Grades 6 - 12+" />
                        <h2>Teacher’s Guide Tier 1: Grades 3 - 5</h2>
                    </a>
                </Col>}
        </Row>
    </div>
  )
}

export default TeacherTab
