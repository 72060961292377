import axios from "axios"
import compactObject from "../helpers/compactObject"

let tokenInfo: { access_token: string, token_type: string, expires_in: string, scope: string }

export interface Course {
    id: string
    name: string
    section: string
    descriptionHeading: string
    description: string
    room: string
    ownerId: string
    creationTime: string
    updateTime: string
    enrollmentCode: string
    courseState: CourseState
    alternateLink: string
    teacherGroupEmail: string
    courseGroupEmail: string
    teacherFolder: DriveFolder
    courseMaterialSets: CourseMaterialSet[]
    guardiansEnabled: boolean
    calendarId: string
}

export interface Student {
    courseId: string
    userId: string
    profile: UserProfile
    studentWorkFolder: DriveFolder
}

export interface Person {
    resourceName: string
    etag: string
}

export interface UserProfile {
    id: string
    name: Name
    emailAddress: string
    photoUrl: string
    permissions: GlobalPermission[]
    verifiedTeacher: boolean
}

export interface Name {
    givenName: string
    familyName: string
    fullName: string
}

export interface GlobalPermission {
    permission: Permission
}

export type Permission = "PERMISSION_UNSPECIFIED" | "CREATE_COURSE"

export type CourseState = "COURSE_STATE_UNSPECIFIED" | "ACTIVE" | "ARCHIVED" | "PROVISIONED" | "DECLINED" | "SUSPENDED"

export interface DriveFolder {
    id: string
    title: string
    alternateLink: string
}

export interface CourseMaterialSet {
    title: string
    materials: CourseMaterial[]
}

export type CourseMaterial = DriveFile | YouTubeVideo | Link | Form

export interface DriveFile {
    id: string
    title: string
    alternateLink: string
    thumbnailUrl: string
}

export interface YouTubeVideo {
    id: string
    title: string
    alternateLink: string
    thumbnailUrl: string
}

export interface Link {
    url: string
    title: string
    thumbnailUrl: string
}

export interface Form {
    formUrl: string
    responseUrl: string
    title: string
    thumbnailUrl: string
}


enum googleApi { Classroom, People }

const baseUrl = {
    [googleApi.Classroom]: "https://classroom.googleapis.com/v1/",
    [googleApi.People]: "https://people.googleapis.com/v1/",
}

function getSsoInfo() {
    return axios.get("/api/account/google-sso-info").then(({ data }: { data: { clientId: string, redirectUri: string } }) => data)
}

export async function getTokenUrl(): Promise<string> {
    const ssoInfo = await getSsoInfo()
    const url = new URL("o/oauth2/v2/auth", "https://accounts.google.com")
    url.searchParams.set("client_id", ssoInfo.clientId)
    url.searchParams.set("redirect_uri", ssoInfo.redirectUri)
    url.searchParams.set("response_type", "token")
    url.searchParams.set("scope", "https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/classroom.profile.emails profile")
    return url.href
}

export function loadToken(event: { data: string }) {
    tokenInfo = event.data.slice(1).split("&").reduce((acc, i) => {
        const [key, value] = i.split("=")
        acc[key] = value
        return acc
    }, {}) as { access_token: string, token_type: string, expires_in: string, scope: string }
}

export function getCourses({ pageToken }: { pageToken?: string } = {}): Promise<{ courses: Course[], nextPageToken?: string }> {
    return authGet({ path: "courses", params: compactObject({ pageToken }) })
}

export function getStudents({ courseId, pageToken }: { courseId: string, pageToken?: string }): Promise<{ students: Student[], nextPageToken?: string }> {
    return authGet({ path: `courses/${courseId}/students`, params: compactObject({ pageToken }) })
}

export function getPerson(id: string): Promise<Person> {
    return authGet({ path: `people/${id}`, resourceType: googleApi.People, query: { personFields: "emailAddresses,names" } })
}
async function authGet({ path, resourceType = googleApi.Classroom, params = {}, query = {} }: { path: string, resourceType?: googleApi, params?: Record<string, unknown>, query?: Record<string, string> }) {
    if (!tokenInfo) {
        throw new Error("Token info must be loaded before calling an authorized route")
    }
    const url = new URL(path, baseUrl[resourceType])
    Object.entries(query).forEach(([k, v]) => {
        url.searchParams.set(k, v)
    })
    const { data } = await axios.get(url.href, { params, headers: { Authorization: `Bearer ${tokenInfo.access_token}` } })
    return data
}
