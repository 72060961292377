export function setSelectedItemDropdown <T extends { selected: boolean, id: string | number }>(
    list: Array<T>,
    index: number,
    setList: React.Dispatch<React.SetStateAction<T[]>>,
    setSelected: React.Dispatch<React.SetStateAction<string | string[] | number>>
) {
    const newList = list.map((item, listIndex) => ({ ...item, selected: index === listIndex }))
    setList(newList)
    setSelected(newList[index].id)
}

export function setSelectedGradeItem <T extends { selected: boolean, name: string }>(
    list: Array<T>,
    index: number,
    setList: React.Dispatch<React.SetStateAction<T[]>>,
    setSelected: React.Dispatch<React.SetStateAction<string | string[]>>
) {
    const newList = [...list]
    newList[index].selected = !newList[index].selected
    setList(newList)
  
    const selectedItems = newList.filter(item => item.selected).map(item => item.name)
    if (selectedItems.length > 0) {
      setSelected(selectedItems)
    } else {
      setSelected(null) // or setSelected([]) if you want to set it to an empty array instead
    }
}
