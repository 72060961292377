import * as React from "react"
import { Dropdown } from "react-bootstrap"
import styles from "./sidebar.module.scss"

interface Props {
    title: string
    activeItem: {
        title: string
        id: string
    }
    listItems: {
        title: string
        id: string
    }[]
    onSelect: (item: string) => void,
    onAddItem?: () => void
    addItemLabel?: string
}

export default function SidebarDropdown({ title, activeItem, listItems, onSelect, onAddItem, addItemLabel }: Props) {
    return (
        <>
            <Dropdown
                className={styles.sidebarDropdown}
                onSelect={onSelect}
            >
                <Dropdown.Toggle
                    variant="transparent"
                    className={styles.sidebarDropdownButton}
                    size="lg"
                >
                    {activeItem?.title ?? title}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {listItems.map((item, index) => {
                        return (
                            <Dropdown.Item
                                className={styles.sidebarDropdownOption}
                                eventKey={item.id}
                                disabled={item.id === activeItem?.id}
                                key={item.id}
                            >
                                {item.title}
                            </Dropdown.Item>
                        )
                    })}
                    {addItemLabel && 
                        <Dropdown.Item
                            className={`${styles.sidebarDropdownOption} ${styles.sidebarDropdownAddOption}`}
                            eventKey={"add-class"}
                            onClick={(event) => {
                                //Prevent `onSelect` from being called
                                event.stopPropagation()
                                onAddItem()
                            }}
                        >
                            {addItemLabel}
                        </Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}
