import replaceAll from "./replaceAll"
import escapeHTML from "escape-html"

export default function parseCustomHTML(html: string) {
    const toReplace =
    {
        "&lt;bold&gt;"                : "<b>",
        "&lt;/bold&gt;"                : "</b>",
        "&lt;italic&gt;"            : "<i>",
        "&lt;/italic&gt;"            : "</i>",
        "&lt;superscript&gt;"        : "<sup>",
        "&lt;/superscript&gt;"        : "</sup>",
        "&lt;subscript&gt;"            : "<sub>",
        "&lt;/subscript&gt;"        : "</sub>",
        "&lt;fraction&gt;"            : "<span class = 'fraction'>",
        "&lt;/fraction&gt;"            : "</span>",
        "&lt;fraction-top&gt;"        : "<span class = 'top'>",
        "&lt;/fraction-top&gt;"        : "</span>",
        "&lt;fraction-bottom&gt;"    : "<span class = 'bottom'>",
        "&lt;/fraction-bottom&gt;"    : "</span>",
        "&lt;unordered&gt;"            : "<ul>",
        "&lt;/unordered&gt;"        : "</ul>",
        "&lt;ordered&gt;"            : "<ol>",
        "&lt;/ordered&gt;"            : "</ol>",
        "&lt;list&gt;"                : "<li>",
        "&lt;/list&gt;"                : "</li>",
        "&lt;line-break&gt;"        : "<br />",
        "&lt;link&gt;"                : "<link>",
        "&lt;/link&gt;"                : "</link>",
    }

    let body = escapeHTML(html)
    for (const replace in toReplace) {
        body = replaceAll(body, replace, toReplace[replace])
    }
    return body
}
