import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile } from "@fortawesome/free-solid-svg-icons"
import { Row, Col } from "react-bootstrap"
import styles from "./ModulePage.module.scss"

type LessonGroupProps = { title?: string, type?: string, lessons?: any[] }
type MethodsProps = { walkthroughVideoPath?: string, walkthroughUpdated?: string, lessonGroups?: LessonGroupProps[], sanitizedModuleName: string }

const MethodsTab = ({ walkthroughVideoPath, walkthroughUpdated, lessonGroups, sanitizedModuleName } : MethodsProps) => {

  const navigate = (link: string) => {
    window.location.href = link
  }

  return (
    <div>
      {walkthroughVideoPath && walkthroughUpdated && <div>
        <div className="mt-5">
          <h3 className="fw-bold mb-2">Walkthrough</h3>
          <iframe
            data-testid="iframe-test"
            title={walkthroughUpdated}
            src={`https://www.youtube-nocookie.com/embed/${walkthroughVideoPath}?rel=0&amp;showinfo=0`} 
            className={`${styles.walkthroughVideo}`} allowFullScreen>
          </iframe>
        </div>
        <hr className="w-50 m-auto my-5" />
      </div>}
      <div className={!walkthroughVideoPath && !walkthroughUpdated ? `my-5` : `m-0 p-0`}></div>
      {lessonGroups?.map((lessonGroup, i) => <div className={styles.container} key={i}>
            <h3 className="fw-bold">{lessonGroup?.title}</h3>
            <Row className="justify-content-center">
              {lessonGroup?.lessons?.map((lesson, index) => 
                <Col xs={12} lg={4}
                  key={index} 
                  className={`border pe-auto shadow-sm rounded p-2 me-1 mt-3 ${styles.lessonItem}`} 
                  onClick={() => navigate(`/simulations/lesson?id=${lesson?.id}`)}>
                    <div className="text-primary">
                        <p className="d-inline me-2 fs-5 fw-bold">Lesson {lesson?.number}</p>
                        <FontAwesomeIcon icon={faFile} />
                    </div>
                    <p className="fs-6 mt-1 mb-0 text-primary">{lesson?.title}</p>
                </Col>)}

              {lessonGroup?.type !== "Tier 0" && 
                <Col xs={12} lg={4} 
                  className={`mt-3 d-flex align-items-center justify-content-center shadow-sm border p-4 rounded ${styles.lessonItem}`}
                  onClick={() => navigate(`/simulations/pure-inquiry?module=${sanitizedModuleName}&type=${lessonGroup?.type}`)}>
                    <p className="fs-5 mb-0 fw-bold text-primary">
                      Pure Inquiry
                      <FontAwesomeIcon icon={faFile} className="ms-2" />
                    </p>
                </Col>}
            </Row>
          </div>)}
    </div>
  )
}

export default MethodsTab
