function replaceAll(string: string, toReplace: string, replaceAs: string) {

    if (!string) {
        return string
    }

    function escapeRegExp(str) {
        return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1")
    }
    
    return string.replace(new RegExp(escapeRegExp(toReplace), "g"), replaceAs)
}

export default replaceAll
